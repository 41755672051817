import { FeatureItemResponse } from 'Models/Maps/FeatureItemResponse.model';
import * as ol from 'ol';
import { Coordinate } from 'ol/coordinate';
import { MVT } from 'ol/format';
import { VectorTile as ol_layer_VectorTile } from 'ol/layer';
import RenderFeature from 'ol/render/Feature';
import { VectorTile as ol_source_VectorTile } from 'ol/source';
import { Stroke, Style } from 'ol/style';
import { take } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';

export class MapChangesTileLayer {
    private _VectorLayer: ol_layer_VectorTile;
    get Layer(): ol_layer_VectorTile {
        return this._VectorLayer;
    }

    constructor(private _Map: ol.Map, tileURL: string, private _AuthenticationService: AuthenticationService) {
        this.CreateLayer(tileURL);
    }

    private CreateLayer(tileURL: string): void {
        //  ** Note that the VectorTile source has a grid size of 512 pixels.  Where the image based XYZ source
        //  is 256.  This results in the vector tiles being 1 zoom level less than the zoom level of the image tiles.
        //  The resolutions we calculate are from the "map" - not the source - which seems to match up with the
        //  image source resolution.  So when relating vector tile zoom levels to resolutions, we need to add 1.

        const me = this;
        this._VectorLayer = new ol_layer_VectorTile({
            maxResolution: this._Map.getView().getResolutionForZoom(12),
            minResolution: this._Map.getView().getResolutionForZoom(20 + 1),
            declutter: true,
            source: new ol_source_VectorTile({
                //  By default, the MVT format will generate RenderFeatures *NOT* regular Features.  These are lightweght/read-only
                //  features that CANNOT be used as regular features in regular VectorSource layers.
                //  Can set "{ featureClass: ol.Feature }" in the constructor to make it generate regular features if needed.
                format: new MVT(/*{ featureClass: ol.Feature }*/),
                url: tileURL,
                tileLoadFunction: function (tile: ol.VectorTile, url) {
                    //  https://stackoverflow.com/questions/50471595/openlayers-4-load-wms-image-layer-require-authentication/50477911

                    //  This changed in OpenLayers 6.  See these links:
                    //      main issue: https://github.com/openlayers/openlayers/issues/9293
                    //      code fix issue: https://github.com/openlayers/openlayers/pull/9308
                    //      merge: https://github.com/openlayers/openlayers/pull/9308/commits/b2722542feef255d4718c60be72dbe4535b73e63
                    tile.setLoader(function (extent, resolution, projection) {
                        me._AuthenticationService.getAuthorizationHeaderValueObservable().pipe(take(1))
                            .subscribe(authVal => {
                                //  Using XMLHttpRequest here instead of the Angular HttpClient because the response is binary.
                                //  To make that work in Angular, need to set "responseType: 'arraybuffer'" option on the
                                //  http.get call (in the options param).  But that shows as an error even though it works
                                //  and the intellisense shows it's correct!
                                const xhr = new XMLHttpRequest();
                                xhr.open('GET', url);
                                xhr.withCredentials = true;     //  This is needed to send our cookie - not doing this will cause the api to look up our session info on EVERY call!
                                xhr.setRequestHeader("Authorization", authVal);
                                xhr.responseType = 'arraybuffer';       //  This is key - does not work without this!
                                xhr.onload = function () {
                                    const data = xhr.response;
                                    const format = tile.getFormat() as MVT;
                                    const features = format.readFeatures(data, {
                                        extent: extent,
                                        featureProjection: projection
                                    });
                                    tile.setFeatures(features as ol.Feature<any>[]);
                                }
                                xhr.send();
                            });
                    });
                }
            }),
            style: (feature, resolution) => me.BuildStyleForFeature(feature, resolution)
        });

        //  These 2 properties are for the layer switcher.  Layer is also set to be initially hidden.
        this._VectorLayer.set("title", "Pending Map Changes");
        this._VectorLayer.set("displayInLayerSwitcher", true);
        this._VectorLayer.setVisible(false);

        this._Map.addLayer(this._VectorLayer);
    }

    public GetFeatureAttributesAtPixel(pixel: Coordinate): FeatureItemResponse[] {
        let featureList: FeatureItemResponse[] = [];

        //  Use this method to find the features.  source.getFeaturesInExtent() only does a bounding box test
        //  and returns lots of extra features.
        const me = this;
        this._Map.forEachFeatureAtPixel(pixel,
            (feature) => {
                const changeType = feature.get("ChangeType");

                let newLabelWithAddresses = feature.get("NewLabelWithAddresses");
                if (!newLabelWithAddresses || (newLabelWithAddresses === ""))
                    newLabelWithAddresses = "(unnamed)";

                let oldLabelWithAddresses = feature.get("OldLabelWithAddresses");
                if (!oldLabelWithAddresses || (oldLabelWithAddresses === ""))
                    oldLabelWithAddresses = "(unnamed)";

                let description = "";
                switch (changeType) {
                    case "A":
                        description = "Add: " + newLabelWithAddresses;
                        break;
                    case "D":
                        description = "Delete: " + oldLabelWithAddresses;
                        break;
                    case "C":
                        const attributesChanged = feature.get("AttributesChanged");
                        const geometryChanged = feature.get("GeometryChanged");
                        description = "Changed ";
                        if (attributesChanged)
                            description += "attributes";
                        if (attributesChanged && geometryChanged)
                            description += " and ";
                        if (geometryChanged)
                            description += "geometry";
                        description += ": "
                        if (attributesChanged)
                            description += oldLabelWithAddresses + " -> " + newLabelWithAddresses;
                        else
                            description += oldLabelWithAddresses;
                        break;
                    default:
                        console.error("Unhandled Change Type", changeType);
                        return;
                }

                featureList.push(new FeatureItemResponse("Map Change", description, null));
            },
            {
                layerFilter: layer => { return layer === me._VectorLayer },
                hitTolerance: 20
            });

        //  Sort by the FeatureName and then remove duplicates (once sorted, can check that by looking at the previous value).
        featureList = featureList.sort((a, b) => {
            if (a.FeatureName < b.FeatureName)
                return -1;
            else if (a.FeatureName > b.FeatureName)
                return 1;
            return 0;
        }).filter((value, index, array) => {
            if (index === 0)
                return true;
            return (value.FeatureName !== array[index - 1].FeatureName);
        });

        //  Return a max of 5 items.  If more than 5, will return the first 4 plus a line that says "...plus x more";
        const num = featureList.length;
        if (num > 5) {
            featureList = featureList.slice(0, 4);
            featureList.push(new FeatureItemResponse("Map Change", "...plus " + (num - 4) + " more", null));
        }

        return featureList;
    }

    private _LastResolution: number;
    private _LastZoom: number;

    public BuildStyleForFeature(feature: ol.Feature<any> | RenderFeature, resolution: number): Style | Style[] {
        //console.warn("BuildStyleForFeature", feature);

        if (this._LastResolution !== resolution) {
            this._LastResolution = resolution;
            this._LastZoom = this._Map.getView().getZoomForResolution(resolution);
            //console.warn("Resolution/Zoom = ", this._LastResolution, this._LastZoom);
        }

        const properties = feature.getProperties();
        const layer = properties["Layer"];
        const geometryChanged = (layer === 'C') && properties["GeometryChanged"];

        const outlineStyle = new Style({
            stroke: new Stroke({
                color: "gray",
                width: this.OutlineWidthForZoom()
            }),
            zIndex: 10
        });

        const fillStyle = new Style({
            stroke: new Stroke({
                color: "white",
                width: this.FillWidthForZoom()
            }),
            zIndex: 20
        });

        const dashWidthForZoom = this.DashWidthForZoom();
        const dashLengthForZoom = this.DashLengthForZoom();

        const deleteStyle = new Style({
            stroke: new Stroke({
                color: "red",
                width: dashWidthForZoom,
                lineDash: [dashLengthForZoom, dashLengthForZoom*2],
                lineDashOffset: dashLengthForZoom + dashLengthForZoom/2
            }),
            zIndex: 30
        });

        const addStyle = new Style({
            stroke: new Stroke({
                color: "green",
                width: dashWidthForZoom,
                lineDash: [dashLengthForZoom, dashLengthForZoom * 2],
                lineDashOffset: 0
            }),
            zIndex: 40
        });

        const styleList = [
            outlineStyle,
            fillStyle
        ];

        //  Changed features that have changed geometry are shown twice such that the old geometry is Deleted
        //  and New is Added.  The Deleted are included by the tile fetch (with Layer = 'D').
        if ((layer === "A") || geometryChanged)
            styleList.push(addStyle);
        else if (layer === "D")
            styleList.push(deleteStyle);

        return styleList;
    }

    private OutlineWidthForZoom(): number {
        if (this._LastZoom < 16)
            return 10;
        else if (this._LastZoom < 17)
            return 12;
        else if (this._LastZoom < 18)
            return 14;
        else if (this._LastZoom < 19)
            return 16;
        else if (this._LastZoom < 20)
            return 30;
        else
            return 44;
    }

    private FillWidthForZoom(): number {
        if (this._LastZoom < 16)
            return 5;
        else if (this._LastZoom < 17)
            return 6;
        else if (this._LastZoom < 18)
            return 7;
        else if (this._LastZoom < 19)
            return 8;
        else if (this._LastZoom < 20)
            return 24;
        else
            return 36;
    }

    private DashWidthForZoom(): number {
        if (this._LastZoom < 16)
            return 1;
        else if (this._LastZoom < 17)
            return 2;
        else if (this._LastZoom < 18)
            return 3;
        else if (this._LastZoom < 19)
            return 3;
        else if (this._LastZoom < 20)
            return 4;
        else
            return 5;
    }

    private DashLengthForZoom(): number {
        if (this._LastZoom < 16)
            return 10;
        else if (this._LastZoom < 17)
            return 10;
        else if (this._LastZoom < 18)
            return 10;
        else if (this._LastZoom < 19)
            return 10;
        else if (this._LastZoom < 20)
            return 10;
        else
            return 20;
    }
}
