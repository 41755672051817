
export enum ScheduledTaskTypeEnum {
    QueueEODAudits = 1,
    PurgeOrphanedFileUploads = 2,
    VoidIncompleteTickets = 3,
    CheckPendingMessagesTask = 4,
    UnlockTicketsTask = 5,
    CoursettraQASendReceiveTask = 6,
    PurgeOldSequenceNumbersTask = 7,
    CoursettraQAQueueByUserTask = 8,
    LateTickets = 9,
    RefreshMaterializedViewsTask = 10,
    QueuePositiveResponsesToExcavatorsTask = 11,
    PurgeLogEventsTask = 12,
    BackupCreateBaseBackup = 13,
    PurgeDaily = 14,
    PurgeWeeky = 15,


    //  One Time tasks.
    RegistrationImportShapeFileTask = 100,              //  ReferencedEntityID = ServiceAreaID because a new registration is being created
    RegistrationApprove = 101,                          //  ReferencedEntityID = Registration.ID that is being approved
    RegistrationCopy = 102,                             //  ReferencedEntityID = ServiceAreaID because a new registration is being created
    RegistrationMerge = 103,                            //  ReferencedEntityID = Registration.ID that we are merging into (so registration page can show status).
    MCD_BuildRegistrationRecommendations = 104,         //  For Map Change Detection: Build registration recommendations for next map update
    MCD_ApplyRegistrationRecommendations = 105,         //  For Map Change Detection: Apply registration recommendations to service area registrations
    MCD_SendReviewRecommendationsNotifications = 106,   //  For Map Change Detection: Send notifications to service area contacts to review unreviewed recommendations

    WarehouseRebuild = 200,

    BillingRecalculate = 300,
    BillingRecalculateFromFailedToBillAlerts = 301,

    RequeueTicketsFromNoDeliveryRulesAlerts = 400,

    CreateProjectTickets = 500,

    SendRegistrationInvites = 600,

    SendScheduledReports = 650,

    EntityPropertyUpdate = 700,                     // Scheduled property update (i.e. set IsActive = true at specified date/time)
    AddServiceAreaToExistingTickets = 701,
    ExecuteSql = 702,
    BuildMissingMapImageAttachmentsTask = 703,

    MoveMessageContent = 800,
    CognitoBulkActions = 801,
    BuildTicketSearch = 802,
    BuildRegistrationPlaces = 803,

    MapFeaturesDownload = 900,
    MapImport = 910,
    BuildMapChanges = 911,
    AddZipToMapImportBatch = 912,

    UDIGNY_NowDueTicketsTask = 1000,
    SC811_CreateSafetyNoticesForFires = 1001,
}

export enum ScheduledTaskTypeEnumDescription {
    QueueEODAudits = "Queue EOD Audits",
    PurgeOrphanedFileUploads = "Purge Orphaned File Uploads",
    VoidIncompleteTickets = "Void Incomplete Tickets",
    CheckPendingMessagesTask = "Check Pending Messages Task",
    UnlockTicketsTask = "Unlock Tickets Task",
    CoursettraQASendReceiveTask = "Coursettra QA Send Receive Task",
    PurgeOldSequenceNumbersTask = "Purge Old Sequence Numbers Task",
    CoursettraQAQueueByUserTask = "Coursettra QA Queue By User Task",
    LateTickets = "Late Tickets",
    RefreshMaterializedViewsTask = "Refresh Materialized Views Task",
    QueuePositiveResponsesToExcavatorsTask = "Queue Positive Responses To Excavators Task",
    PurgeLogEventsTask = "Purge Log Events Task",
    BackupCreateBaseBackup = "Backup Create Base Backup",
    PurgeDaily = "Purge Daily",
    PurgeWeeky = "Purge Weeky",

    //  One Time tasks.
    RegistrationImportShapeFileTask = "Create Registration From Shape Files Task",
    RegistrationApprove = "Approve Registration",
    RegistrationCopy = "Copy Registration",
    RegistrationMerge = "Merge Registration",
    MCD_BuildRegistrationRecommendations = "MCD: Build Registration Recommendations for next Map Landbase update",
    MCD_ApplyRegistrationRecommendations = "MCD: Apply registration recommendations to service area registrations",
    MCD_SendReviewRecommendationsNotifications = "MCD: Send 'Review Recommendations' Notifications to Service Area Contacts",

    WarehouseRebuild = "Warehouse Rebuild",

    BillingRecalculate = "Billing Recalculate",
    BillingRecalculateFromFailedToBillAlerts = "Billing Recalculate From Failed To Bill Alerts",

    RequeueTicketsFromNoDeliveryRulesAlerts = "Requeue Tickets From No Delivery Rules Alerts",

    CreateProjectTickets = "Create Project Tickets",

    SendRegistrationInvites = "Send Registration Invites",
    SendScheduledReports = "Send Scheduled Reports",

    EntityPropertyUpdate = "Entity Property Update",                     // Scheduled property update (i.e. set IsActive = true at specified date/time)
    AddServiceAreaToExistingTickets = "Add Service Area To Existing Tickets",
    ExecuteSql = "Execute SQL",
    BuildMissingMapImageAttachmentsTask = "Build Missing Map Image Attachments",

    MoveMessageContent = "Move Message Content",
    CognitoBulkActions = "Cognito Bulk Actions",
    BuildTicketSearch = "Build TicketSearch table",
    BuildRegistrationPlaces = "Build RegistrationPlaces table",

    MapFeaturesDownload = "Map Features Download",
    MapImport = "Map Import",
    BuildMapChanges = "Build Map Changes",
    AddZipToMapImportBatch = "Add Zip to Map Import Batch",

    UDIGNY_NowDueTicketsTask = "UDIGNY - Now Due Tickets Task",
    SC811_CreateSafetyNoticesForFires = "SC811 - Create Safety Notices for Fires"
}
