import { AuthenticationService } from 'Services/AuthenticationService';
import { GeometryUtils } from 'Shared/Components/Maps/GeometryUtils';
import { OLGeometryTypeEnum } from 'Enums/GeometryType.enum';
import * as ol from 'ol';
import { VectorTile as ol_layer_VectorTile } from 'ol/layer';
import { VectorTile as ol_source_VectorTile } from 'ol/source';
import { MVT } from 'ol/format';
import { Style, Text, Fill, Stroke } from 'ol/style';
import RenderFeature from 'ol/render/Feature';
import { take } from 'rxjs/operators';
import { MapConstants } from 'Shared/Components/Maps/MapConstants';

export class TicketsLayer {
    private _VectorLayer: ol_layer_VectorTile;
    get Layer(): ol_layer_VectorTile {
        return this._VectorLayer;
    }

    constructor(private _Map: ol.Map, tileURL: string, private _AuthenticationService: AuthenticationService) {
        this.CreateLayer(tileURL);
    }

    private CreateLayer(tileURL: string): void {
        //  ** Note that the VectorTile source has a grid size of 512 pixels.  Where the image based XYZ source
        //  is 256.  This results in the vector tiles being 1 zoom level less than the zoom level of the image tiles.
        //  The resolutions we calculate are from the "map" - not the source - which seems to match up with the
        //  image source resolution.  So when relating vector tile zoom levels to resolutions, we need to add 1.

        const me = this;
        this._VectorLayer = new ol_layer_VectorTile({
            maxResolution: this._Map.getView().getResolutionForZoom(8),
            minResolution: this._Map.getView().getResolutionForZoom(20 + 1),
            declutter: true,
            source: new ol_source_VectorTile({
                //  By default, the MVT format will generate RenderFeatures *NOT* regular Features.  These are lightweght/read-only
                //  features that CANNOT be used as regular features in regular VectorSource layers.
                //  Can set "{ featureClass: ol.Feature }" in the constructor to make it generate regular features if needed.
                format: new MVT(/*{ featureClass: ol.Feature }*/),
                url: tileURL,
                tileLoadFunction: function (tile: ol.VectorTile, url) {
                    //  https://stackoverflow.com/questions/50471595/openlayers-4-load-wms-image-layer-require-authentication/50477911

                    //  This changed in OpenLayers 6.  See these links:
                    //      main issue: https://github.com/openlayers/openlayers/issues/9293
                    //      code fix issue: https://github.com/openlayers/openlayers/pull/9308
                    //      merge: https://github.com/openlayers/openlayers/pull/9308/commits/b2722542feef255d4718c60be72dbe4535b73e63
                    tile.setLoader(function (extent, resolution, projection) {
                        me._AuthenticationService.getAuthorizationHeaderValueObservable().pipe(take(1))
                            .subscribe(authVal => {
                                //  Using XMLHttpRequest here instead of the Angular HttpClient because the response is binary.
                                //  To make that work in Angular, need to set "responseType: 'arraybuffer'" option on the
                                //  http.get call (in the options param).  But that shows as an error even though it works
                                //  and the intellisense shows it's correct!
                                const xhr = new XMLHttpRequest();
                                xhr.open('GET', url);
                                xhr.withCredentials = true;     //  This is needed to send our cookie - not doing this will cause the api to look up our session info on EVERY call!
                                xhr.setRequestHeader("Authorization", authVal);
                                xhr.responseType = 'arraybuffer';       //  This is key - does not work without this!
                                xhr.onload = function () {
                                    const data = xhr.response;
                                    const format = tile.getFormat() as MVT;
                                    const features = format.readFeatures(data, {
                                        extent: extent,
                                        featureProjection: projection
                                    });
                                    tile.setFeatures(features as ol.Feature<any>[]);
                                }
                                xhr.send();
                            });
                    });
                } 
            }),
            style: (feature/*, resolution*/) => me.BuildStyleForFeature(feature)
        });

        this._Map.addLayer(this._VectorLayer);
    }

    private _SelectedTicketID: string;
    get SelectedTicketID(): string { return this._SelectedTicketID; }
    set SelectedTicketID(val: string) {
        const changed = val !== this._SelectedTicketID;
        this._SelectedTicketID = val;
        if (changed)
            this._VectorLayer.getSource().changed();
    }

    public BuildStyleForFeature(feature: ol.Feature<any> | RenderFeature): Style | Style[] {
        let fill = [0x0d, 0x72, 0xef, 0.2];         //  0d72ef
        let outline = [0x0d, 0x72, 0xef, 0.5];
        let zIndex = 1;

        const isSelected = this.SelectedTicketID === feature.getProperties()["ID"];
        if (isSelected) {
            fill = [255, 0, 0, 0.2];
            outline = [255, 0, 0, 0.6],
            zIndex = 100;
        }

        const geomType = GeometryUtils.GeometryTypeOfFeature(feature);
        if (geomType === OLGeometryTypeEnum.Point) {
            fill[3] = 1;        //  remove opacity
            return new Style({
                text: new Text({
                    text: '\uf3c5',     //  fa-map-marker-alt
                    font: '900 24px "' + MapConstants.FONT_AWESOME_FREE_NAME + '"',     //  If doesn't work, see comments on this constant - package version probably changed!
                    textBaseline: 'bottom',
                    fill: new Fill({
                        color: fill
                    })
                })
            });
        } else {
            return new Style({
                fill: new Fill({
                    color: fill
                }),
                stroke: new Stroke({
                    color: outline,
                    width: 2
                }),
                zIndex: zIndex
            });
        }
    }
}
