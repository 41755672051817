<div class="app-content iq-phone-page" style="display:flex; flex-direction:column;">
    <general-busy-indicator></general-busy-indicator>
    <!-- If creating a custom phone header on another page, set the MainMenuService.HideTopMenuBar property to true to hide this.  -->
    <iq-phone-header *ngIf="!MainMenuService.HideTopMenuBar" [IsTopLevelAppMenu]="true">
        <ng-template *ngIf="!ShowingLoginPage && !(IsSignedIn | async)" iqPhoneHeaderLeft>
            <button mat-icon-button routerLink="/login">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </ng-template>
    </iq-phone-header>
    <!-- Route animations not working - see Shared\Animations.ts.  This should be needed for it to work.  -->
    <!-- [@phoneRouteAnimations]="PrepareRouteAnimation(outlet)" -->
    <div style="flex-grow:1; overflow:hidden">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</div>
