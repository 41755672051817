import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddPositiveResponseComponent, AddPositiveResponseData } from '../AddPositiveResponse.component';

@Component({
    templateUrl: './AddPositiveResponseDialog.component.html',
    styleUrls: ['./AddPositiveResponseDialog.component.scss']
})
export class AddPositiveResponseDialog {

    @ViewChild(AddPositiveResponseComponent)
    private _AddPositiveResponseComponent: AddPositiveResponseComponent;

    constructor(private _DialogRef: MatDialogRef<AddPositiveResponseDialog>, @Inject(MAT_DIALOG_DATA) public Data: AddPositiveResponseData) {
    }

    public Save(addAnother: boolean): void {
        this._AddPositiveResponseComponent.Save().subscribe((savedResponse) => {
            if (this.Data.OnResponseSaved)
                this.Data.OnResponseSaved(savedResponse);

            if (savedResponse && !addAnother)
                this._DialogRef.close(true);
        })
    }
}
