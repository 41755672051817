<mat-card style="margin: 6px 5px; padding: unset;" matRipple matRippleCentered routerLink="/tickets/view/{{Item.ID}}">
    <div style="display: flex">
        <div style="flex: 0 0 6px; margin-right: 5px" [ngStyle]="{'background-color': Item.TicketType_BackgroundColor }"></div>
        <div style="flex: 1 1 auto; display: flex; flex-direction: column; width: 100%; overflow: hidden; padding: 3px 0;">
            <ng-container [ngSwitch]="View">

                <ticket-list-item-localuser-default *ngSwitchCase="TicketListItemViewEnum.LocalUser_Default" [Item]="Item" [SearchColumns]="SearchColumns"></ticket-list-item-localuser-default>
                <ticket-list-item-localuser-incomplete *ngSwitchCase="TicketListItemViewEnum.LocalUser_Incomplete" [Item]="Item" [SearchColumns]="SearchColumns"></ticket-list-item-localuser-incomplete>
                <ticket-list-item-localuser-suspended *ngSwitchCase="TicketListItemViewEnum.LocalUser_Suspended" [Item]="Item" [SearchColumns]="SearchColumns"></ticket-list-item-localuser-suspended>

                <ticket-list-item-excavatoruser-default *ngSwitchCase="TicketListItemViewEnum.ExcavatorUser_Default" [Item]="Item" [SearchColumns]="SearchColumns"></ticket-list-item-excavatoruser-default>
                <ticket-list-item-excavatoruser-expiring *ngSwitchCase="TicketListItemViewEnum.ExcavatorUser_Expiring" [Item]="Item" [SearchColumns]="SearchColumns"></ticket-list-item-excavatoruser-expiring>
                <ticket-list-item-excavatoruser-responsestatus *ngSwitchCase="TicketListItemViewEnum.ExcavatorUser_ResponseStatus" [Item]="Item" [SearchColumns]="SearchColumns"></ticket-list-item-excavatoruser-responsestatus>

                <ticket-list-item-serviceareauser-default *ngSwitchCase="TicketListItemViewEnum.ServiceAreaUser_Default" [Item]="Item" [SearchColumns]="SearchColumns"></ticket-list-item-serviceareauser-default>
                <ticket-list-item-serviceareauser-noposresp *ngSwitchCase="TicketListItemViewEnum.ServiceAreaUser_NoPosResp" [Item]="Item" [SearchColumns]="SearchColumns"></ticket-list-item-serviceareauser-noposresp>
                <ticket-list-item-serviceareauser-response *ngSwitchCase="TicketListItemViewEnum.ServiceAreaUser_Response" [Item]="Item" [SearchColumns]="SearchColumns"></ticket-list-item-serviceareauser-response>

                <ticket-list-item-regulatoruser-default *ngSwitchCase="TicketListItemViewEnum.RegulatorUser_Default" [Item]="Item" [SearchColumns]="SearchColumns"></ticket-list-item-regulatoruser-default>

                <ng-container *ngSwitchDefault>
                    <div style="color:red; font-size:xx-large">view not defined!</div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</mat-card>
