<div style="display:flex; flex-direction:column; overflow:hidden; flex-grow:1">
    <div class="page-header">
        <div class="header-content">
            <div class="header-title-xx" style="padding-bottom: unset; padding-right: 20px">
                Home
            </div>
        </div>
    </div>

    <div fxLayout="column" fxLayoutGap="10px" style="margin: 0 20px; position: relative; overflow:hidden; flex-grow:1">
        <busy-indicator [busyKey]="busyKey"></busy-indicator>
        <mat-card style="padding:inherit">
            <iq-section-group-container>
                <iq-section-group [HeaderText]="'Welcome to Exactix!'" [Edit]="false" [EditAllowed]="false">
                    <iq-section-item>
                        <div class="view margin-no-label" style="display: flex; padding-top:20px; flex-wrap:wrap; column-gap:5em; row-gap:20px">
                            <iq-icon-button icon="View" routerLink="/tickets/dashboard" *iqHasPermission="ticketViewPermission hasAnywhere true">Go to Ticket Dashboard</iq-icon-button>
                            <iq-icon-button *ngIf="ShowEmergencyContactsPage" icon="View" routerLink="/emergencyContacts">View Emergency Contacts</iq-icon-button>
                        </div>
                    </iq-section-item>
                </iq-section-group>
            </iq-section-group-container>
        </mat-card>
        <mat-card *ngIf="showAnnouncements" style="overflow:hidden; flex-grow:1; display:flex; flex-direction:column">
            <iq-announcements-carousel style="overflow:hidden; flex-grow:1; display:flex; flex-direction: column"></iq-announcements-carousel>
        </mat-card>
    </div>
</div>

