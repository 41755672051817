import { Component, Input, AfterContentInit, OnDestroy, HostListener, ViewChild, ElementRef, ContentChildren, QueryList, Output, EventEmitter, OnInit } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { SectionGroupService, GroupEditChange } from '@iqSharedComponentControls/Section/SectionGroup.service';
import { SectionGroupContainerService } from '@iqSharedComponentControls/Section/SectionGroupContainer.service';
import { SectionItemComponent } from '@iqSharedComponentControls/Section/Item/SectionItem.component';

/*
    This is a section grouping that allows for changing from edit to view a group of controls that use the SectionItemComponent.

    This adds a header text to the group
*/
@Component({
    selector: 'iq-section-group',
    templateUrl: './SectionGroup.component.html',
    styleUrls: ['./SectionGroup.component.scss'],
    providers: [SectionGroupService]
})
export class SectionGroupComponent implements AfterContentInit, OnDestroy, OnInit {

    private destroyed$: Subject<void> = new Subject();
    @ViewChild('focusDiv') private focusDiv: ElementRef;

    //  TODO: Change this to track by SectionGroupService like SectionGroupContainerService tracks the groups?
    //  ContentChildren will not know about section items buried deep inside other components...
    //  So it's not currently possible to do things like setting the first/last control in those cases.
    //  **BUT** Then we'll still have issues with sections being dyanmically changed - which happens alot in the
    //  ticket entry site info controls (by changing the dig site type).
    //  "descendants: true" option is needed here or this QueryList will not pick up any section items that are inside
    //  other elements - including inside regular divs!  As an example, that causes issues on the NY and DigSafe
    //  excavator section group that causes the office address section item to be considered the FIRST control in
    //  the group.  Which then causes a shift-tab to close the entire excavator group (and possibly also
    //  throws a consistency check error).
    @ContentChildren(SectionItemComponent, { descendants: true }) private sectionItems: QueryList<SectionItemComponent>;

    tabIndex: number = 0;
    headerFocused: boolean = false;
    mouseIn: boolean = false;
    private childFocused: boolean = false;
    private focusedFromClick: boolean = false;
    private focusedFromPageUpOrDown: boolean = false;
    private bringScreenToGroupIgnoringSettings: boolean = false;

    @Input() EditAllowed: boolean = null;
    @Input() ViewAllowed: boolean = null;
    @Input() CommandLinkAllowed: boolean = true;
    @Input() HideOpenClose: boolean = false;

    private openOnFocus: boolean = true;
    //When focused from keyboard nav, change the group to edit when focused. Clicking will always change the state of the group
    @Input() set AutoEditOnFocus(val: boolean) {
        this.openOnFocus = coerceBooleanProperty(val);
    }

    private closeonBlur: boolean = false;
    //When the group loses focus should it change to be non edit mode
    @Input() set AutoCloseOnBlur(val: boolean) {
        this.closeonBlur = coerceBooleanProperty(val);
    }

    private skipHeadersInEdit: boolean = false;
    //Should the headers be skipped when tabbing through them?  If there are no SectionItems marked to be focused the header will stay focused
    @Input() set SkipHeadersInEdit(val: boolean) {
        this.skipHeadersInEdit = coerceBooleanProperty(val);
    }

    private AlwaysSkipHeaders: boolean = false;
    //If we want to just always skip focusing the header set this to true
    @Input() set SkipHeaderFocus(val: boolean) {
        this.AlwaysSkipHeaders = coerceBooleanProperty(val);
        if (this.AlwaysSkipHeaders) {
            this.tabIndex = -1;
            this.SkipHeadersInEdit = true;//If we are skipping focus all together then set the 'SkipHeadersInEdit' property
        }
        else {
            this.tabIndex = 0;
            //Can't set the 'SkipHeadersInEdit' becuase it should be able to be set to whatever, even we're not skipping the headers
        }
    }

    private _order: number = null;
    //  The order that the page up and down functions should follow.  Set automatically based on the layout.
    //  If this needs to be specified differently (it was an @Input which was never used anyway), we can change this but need
    //  to make SectionGroupContainerService.RegisterSectionGroup() see that it's already set and then do a sort as it
    //  registers groups.
    set Order(val: number) {
        this._order = val;
    }
    get Order(): number {
        return this._order;
    }

    @Input() HeaderText: string = "";

    @Output() FocusChanged = new EventEmitter<boolean>();
    
    @Output() EditChanged = new EventEmitter<boolean>();

    _edit: boolean = false;     //  *** use SetEditFlag() to set this property so FocusChanged is always emitted correctly
    private SetEditFlag(edit: boolean): void {

        //Don't change if not allowed to
        if (this.EditAllowed === false && edit)
            return;
        if (this.ViewAllowed === false && !edit)
            return;

        const prev = this._edit;

        this._edit = edit;

        //  Only fire focus event if edit changed
        if (prev !== this._edit)
            this.EditChanged.emit(this._edit);
    }

    @Input()
    set Edit(val: boolean) {
        this.SetEditFlag(coerceBooleanProperty(val));
        if (this._edit && this._edit !== this.secGroupServ.groupEditChangeObserver.value.edit)
            this.secGroupServ.groupEditChangeObserver.next(new GroupEditChange(this._edit));
    }
    get Edit() {
        return this._edit;
    }

    //  An extra command (such as "add") shown in the header to the right of "edit" (if that is shown at all).
    @Input() CommandLink: string;
    @Output() OnCommandLink = new EventEmitter<boolean>();
    public OnCommandLinkClicked(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.OnCommandLink.next(true);
    }

    //  Use this (instead of *ngIf) to conditionally show/hide the section.
    //  Using *ngIf will change the ordering of the event handlers and mess up the tab ordering.
    private _Visible: boolean = true;
    @Input()
    set Visible(visible: boolean) {
        if (this._Visible !== visible) {
            this._Visible = visible;
            this.sectionGroupContainerService.GroupVisibilityChanged.next(this);
        }
    }
    get Visible(): boolean {
        return this._Visible;
    }

    constructor(private secGroupServ: SectionGroupService, private sectionGroupContainerService: SectionGroupContainerService) {
        //May want to pass in null just to create this class (used when sections are shown by conditions and need to have a "sub" nav menu)
        if (secGroupServ)
            secGroupServ.id = 1;//Set ID so that children know this isn't the global version of the service
    }

    private SetToCurrentGroup() {
        this.sectionGroupContainerService.SetCurrentGroup(this);
        this.GroupFocused();
    }

    private _sectionFocused: boolean = false;
    private GroupFocused() {
        if (!this._sectionFocused)
            this.FocusChanged.emit(true);

        this._sectionFocused = true;
    }

    private GroupLostFocus() {
        if (this._edit && this.closeonBlur) {
            setTimeout(() => {//set a timeout so there is time for the form controls to handle losing focus before we change the view
                //Close group when not the current focused one and set this groups items to not edit and tabable so it can be reopened
                this.secGroupServ.groupEditChangeObserver.next(new GroupEditChange(false));
                this.SetEditFlag(false);

                //Don't change the tab index if the control is set to always skip headers in tabing
                if (!this.AlwaysSkipHeaders)
                    this.tabIndex = 0;
            });
        }

        if (this._sectionFocused)
            this.FocusChanged.emit(false);

        this._sectionFocused = false;
    }
    

    private MakeActiveGroup() {
        let fromTabFocus: boolean = true;
        let focusFromPage: boolean = false;
        let fromClick: boolean = false;

        if (this.focusedFromClick) {
            fromTabFocus = false;
            fromClick = true;
            this.focusedFromClick = false;
        }
        if (this.focusedFromPageUpOrDown) {
            focusFromPage = true;
            this.focusedFromPageUpOrDown = false;
        }

        //Don't need to change anything if it's the active group and it's in edit mode, unless it was clicked on, then we need to flip it's edit state
        if (!fromClick && (this.sectionGroupContainerService.IsCurrentGroup(this) && this._edit))
            return;

        let focusFirst: boolean = this.skipHeadersInEdit && this.sectionGroupContainerService.ShouldFocusFirstControlWhenMakingGroupActive(this);
        let focusLast: boolean = !focusFirst && this.sectionGroupContainerService.ShouldFocusLastControlWhenMakingGroupActive(this);
        //Should only be caught here when a child control says to change to edit
        
        this.SetToCurrentGroup();

        //Notify this groups children to be the correct state
        let editValue: boolean = fromClick ? !this._edit : (fromTabFocus || focusFromPage) && !this._edit ? this.openOnFocus : this._edit;//If from a click, always change it.  If from keyboard only chang it if it is supposed to open on focus and it's not already in edit mode

        //This needs to come from the service because the pageup/down event happened on a different section group.
        if (this.sectionGroupContainerService.TabPreviousGroup) {
            focusFirst = true;
            focusLast = false;
            this.sectionGroupContainerService.TabPreviousGroup = false;
        }

        //We just want to focus the section, not change it (happens from side nav on admin pages)
        if (this.bringScreenToGroupIgnoringSettings) {
            this.bringScreenToGroupIgnoringSettings = false;
            editValue = this._edit;
            focusFirst = false;
            focusLast = false;
        }

        this.secGroupServ.groupEditChangeObserver.next(new GroupEditChange(editValue, focusFirst, focusLast));
        
        this.SetEditFlag(editValue);

        //Don't change the tab index if the control is set to always skip headers in tabing
        if (!this.AlwaysSkipHeaders)
            this.tabIndex = editValue && this.skipHeadersInEdit ? - 1 : 0;

        //Tell all the other groups that they are not the active focused one anymore
        this.sectionGroupContainerService.activeGroupObserver.next(this._order);
    }

    public Focus() {
        this.bringScreenToGroupIgnoringSettings = true
        this.focusDiv.nativeElement.focus();
    }

    //Sets focus while following focus rules of the group.  i.e. Focus first/last control, expanding by the set values, etc.
    //  If you just want to bring focus to the group and ignore those then call the Focus() method
    private SetFocus() {
        this.focusDiv.nativeElement.focus();
    }

    HeaderShiftTab() {
        if (this.sectionGroupContainerService.IsFirstGroup(this))
            this.GroupLostFocus();
    }
    OnClicked($event: MouseEvent): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        //  If in edit, make read only.  Else act as if they tabbed in to the section
        if (this._edit) {
            if (this.ViewAllowed === false)
                return;

            this.secGroupServ.groupEditChangeObserver.next(new GroupEditChange(false));
            this._edit = false;

            //Don't change the tab index if the control is set to always skip headers in tabing
            if (!this.AlwaysSkipHeaders)
                this.tabIndex = 0;

            this.Focus();
            return;
        }

        this.sectionGroupContainerService.SetCurrentGroup(null);    //  Unset current group which will cause us to always focus the first control

        this.focusedFromClick = true;
        if (this.headerFocused)//If already focused then we can't call focus on the event again, so just manually call it so it will make sure everything is setup right for the edit
            this.OnFocus();
        else
            //Have to call this because its from pageup or pagedown, need to focus according to tab rules
            this.SetFocus();

    }
    OnFocus() {
        this.childFocused = false;
        this.MakeActiveGroup();

        if (!this.childFocused)
            this.headerFocused = true;

        //If it's the first one, always emit it was focused because the user may have back tabbed then tabbed back in...in that case it will already be the current group so our other logic won't fire this event
        if (this.sectionGroupContainerService.IsFirstGroup(this))
            this.GroupFocused();
    }
    OnBlur() {
        this.headerFocused = false;
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.mouseIn = true;

    }
    @HostListener('mouseleave') onMouseLeave() {
        this.mouseIn = false;
    }
    @HostListener('keydown.PageUp', ['$event']) onPageUp($event: KeyboardEvent) {
        $event.preventDefault();
        $event.stopPropagation();
        this.sectionGroupContainerService.MovePreviousGroup();
    }
    @HostListener('keydown.PageDown', ['$event']) onPageDown($event: KeyboardEvent) {
        $event.preventDefault();
        $event.stopPropagation();
        this.sectionGroupContainerService.MoveNextGroup();
    }

    private setupObservers() {
        //Child said to change to edit mode
        this.secGroupServ.groupChildEditChangeObserver.pipe(takeUntil(this.destroyed$)).subscribe((s: boolean) => {
            if (s === null)//happens on the first subscription.  Nothing to do there.
                return;

            this.SetEditFlag(s);

            //Don't change the tab index if the control is set to always skip headers in tabing
            if (!this.AlwaysSkipHeaders)
                this.tabIndex = this._edit && this.skipHeadersInEdit ? -1 : 0;

            //A child said we need to be edit, so tell the other children to be edit too.
            this.secGroupServ.groupEditChangeObserver.next(new GroupEditChange(true));

            if (this.sectionGroupContainerService.IsCurrentGroup(this))
                return;

            //Should only be caught here when a child control says to change to edit
            this.SetToCurrentGroup();

            //Tell all the other groups that they are not the active focused one anymore
            //  Need a timeout on this or was getting consistency check errors if you do this:
            //  1) On FL form, enter something that is completely invalid in the work start
            //  2) tab
            //  3) Server responds with validation error and client tries to set focus back to the work start.
            //     But work start is the last control in the section so focus has already been set to
            //     the Work Information section.  When the Dates section (where the work start control is) is
            //     focused, a consistency check error is thrown back on the Work Information section!  Even happened
            //     with a very exaggerated delay after the Work Information section was fully focused.  So it's something
            //     about how this next statement is being handled when focusing to the Dates section.
            setTimeout(() => this.sectionGroupContainerService.activeGroupObserver.next(this._order));
        });
        this.secGroupServ.sectionContainerFocusLost.pipe(takeUntil(this.destroyed$)).subscribe(s => {
            if (s === null)//happens on the first subscription.  Nothing to do there.
                return;

            this.sectionGroupContainerService.lastGroupTabbedOut.next(true);

            if (!s.first) {
                this.GroupLostFocus();
            }
            else if (this.skipHeadersInEdit)
                this.GroupLostFocus();
        });

        //Called from pageup/pagedown
        this.sectionGroupContainerService.activeGroupObserver.pipe(takeUntil(this.destroyed$)).subscribe(activeGroupNum => {
            if (activeGroupNum === null)//happens on the first subscription.  Nothing to do there.
                return;

            if (this._order !== activeGroupNum) {
                this.headerFocused = false;

                this.GroupLostFocus();
                return;
            }

            this.focusedFromPageUpOrDown = true;

            //If already the active group, nothing to do (happens when you click the group or use the tab buttons because we need to inform the other groups that they aren't focused anymore)
            if (this.sectionGroupContainerService.IsCurrentGroup(this))
                return;

            //Have to call this because its from pageup or pagedown, need to focus according to tab rules
            this.SetFocus();
        });

        this.sectionGroupContainerService.sectionGroupFocused.pipe(takeUntil(this.destroyed$)).subscribe(order => {
            if (order === null)
                return;

            if (this._order === order) {
                this.SetToCurrentGroup();
            }
            else
                this.GroupLostFocus();
        });

        //Child control was focused automatically from the group tellng it's children to go to edit
        this.secGroupServ.groupChildFocusedOnAutoOpenObserver.pipe(takeUntil(this.destroyed$)).subscribe(s => {
            if (s === null)//Will happen when first subscribed
                return
            this.childFocused = s;
        });


        this.secGroupServ.sectionItemFocusedInEdit.pipe(takeUntil(this.destroyed$)).subscribe(s => {
            if (!s)
                return;

            if (!this.sectionGroupContainerService.IsCurrentGroup(this))
                this.sectionGroupContainerService.sectionGroupFocused.next(this._order);
        });

        this.secGroupServ.SectionItemVisibilityChanged.pipe(takeUntil(this.destroyed$)).subscribe(s => {
            this.SetFirstInContainerOnSectionItemsIfGroupIsLast();
            this.SetLastInContainerOnSectionItemsIfGroupIsLast();
        });
    }

    ngOnInit() {
        this.secGroupServ.EditAllowed = this.EditAllowed;
        if (this.EditAllowed === false && this.Edit)
            this.SetEditFlag(false);
        else if (this.ViewAllowed === false && !this.Edit)
            this.SetEditFlag(true);

    }
    ngAfterContentInit(): void {
        this.sectionGroupContainerService.RegisterSectionGroup(this);

        this.setupObservers();

        //  Note: Setting the first/last controls in container is now done by the SectionGroupContainer
        //  after the view has initialized.  Which is guaranteed to happen after all groups have been registered.
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();

        this.focusDiv = null;
        this.sectionItems = null;
    }

    private _GroupIsFirstInContainer: boolean = false;

    public GroupIsFirstInContainer(isFirst: boolean): void {
        this._GroupIsFirstInContainer = isFirst;

        if (this.sectionItems.length === 0)
            return;

        if (isFirst)
            this.SetFirstInContainerOnSectionItemsIfGroupIsLast();
        else {
            //  The list of section items could have changed due to visibility or dynamic changes.  So unset the flag from everything
            //  just to make sure.  Also allows us to unset the "first" flag if the entire group is not longer first.
            this.sectionItems.forEach(item => item._FirstControlInContainer = false);
        }
    }

    private SetFirstInContainerOnSectionItemsIfGroupIsLast(): void {
        if (!this._GroupIsFirstInContainer)
            return;

        if (this.sectionItems.length === 0)
            return;

        //  The visibility of section items could have changed.  So unset the flag from everything
        //  just to make sure it's not set on a section item that used to be visible.
        this.sectionItems.forEach(item => item._FirstControlInContainer = false);

        //  Find the first visible section
        const firstSection = this.sectionItems.find(item => item.Visible);
        if (firstSection)
            firstSection._FirstControlInContainer = true;
    }

    private _GroupIsLastInContainer: boolean = false;

    public GroupIsLastInContainer(isLast: boolean): void {
        this._GroupIsLastInContainer = isLast;

        if (isLast)
            this.SetLastInContainerOnSectionItemsIfGroupIsLast();
        else {
            //  The list of section items could have changed due to visibility or dynamic changes.  So unset the flag from everything
            //  just to make sure.  Also allows us to unset the "last" flag if the entire group is not longer last.
            this.sectionItems.forEach(item => item._LastControlInContainer = false);
        }
    }

    private SetLastInContainerOnSectionItemsIfGroupIsLast(): void {
        if (!this._GroupIsLastInContainer)
            return;

        if (this.sectionItems.length === 0)
            return;

        //  The visibility of section items could have changed.  So unset the flag from everything
        //  just to make sure it's not set on a section item that used to be visible.
        this.sectionItems.forEach(item => item._LastControlInContainer = false);

        //  Find the last visible section
        const lastSection = _.findLast(this.sectionItems.toArray(), item => item.Visible);
        if (lastSection)
            lastSection._LastControlInContainer = true;
    }

    public HasSectionItems(): boolean {
        return this.sectionItems.length > 0;
    }
}
