import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { SearchFilter, SearchFilterValue } from 'Models/Searching/SearchFilter.model';
import { SearchOrderBy } from 'Models/Searching/SearchOrderBy.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { take } from 'rxjs/operators';
import { SettingsService } from 'Services/SettingsService';

@Component({
    templateUrl: './TicketCancelDialog.component.html',
    styleUrls: ['./TicketCancelDialog.component.scss']
})
export class TicketCancelDialogComponent {
    public TicketNumber: string;
    public ReasonFormControl: UntypedFormControl;

    public MaxColumns: number = 0;
    public MaxRows: number = 0;
    public InputHint: string = "";

    public PromptForReplacedByTicketNumber: boolean = false;
    public LastTicketNumber: string = null;
    public ReplacedByTicketNumberFormControl: UntypedFormControl;
    public UseLastTicketCreatedFormControl: UntypedFormControl;
    public TicketNumberMaskOptions: any;

    constructor(private dialogRef: MatDialogRef<TicketCancelDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        settingsService: SettingsService, private _TicketService: TicketService)
    {
        this.TicketNumber = data.TicketNumber;

        this.ReasonFormControl = new UntypedFormControl(settingsService.CancelTicketReasonInitialValue);

        const limits = settingsService.CancelTicketReasonLimits;
        if (limits && limits.maxColumns) {
            this.MaxColumns = limits.maxColumns;
            this.MaxRows = limits.maxRows;
        }

        if (settingsService.CancelTicketPromptForReplacedByTicketNumber) {
            this.PromptForReplacedByTicketNumber = true;
            this.ReplacedByTicketNumberFormControl = new UntypedFormControl(null);
            this.UseLastTicketCreatedFormControl = new UntypedFormControl(false);
            this.TicketNumberMaskOptions = settingsService.TicketNumberMaskOptions;
            this.FindLastTicketCreated();
        }
    }

    public UseLastTicketCreatedChanged(): void {
        if (this.UseLastTicketCreatedFormControl.value)
            this.ReplacedByTicketNumberFormControl.disable();
        else
            this.ReplacedByTicketNumberFormControl.enable();
    }

    public CanCancel(): boolean {
        let reason = this.ReasonFormControl.value as string;
        if (reason)
            reason = reason.trim();
        if (!this.TicketNumber || !reason)
            return false;

        const ticketNumber = this.TicketNumber.trim();
        if ((ticketNumber.length <= 0) || (reason.length <= 0))
            return false;

        if (this.PromptForReplacedByTicketNumber) {
            //  This is not required.  But if anything is entered at all, should be considered invalid if not enough
            //  digits are entered for a full ticket number.
            let replacedByTicketNumber = this.UseLastTicketCreatedFormControl.value ? this.LastTicketNumber : this.ReplacedByTicketNumberFormControl.value;
            if (replacedByTicketNumber) {
                replacedByTicketNumber = replacedByTicketNumber.trim();
                if ((replacedByTicketNumber.length > 0) && (replacedByTicketNumber.length < this.TicketNumberMaskOptions.mask.length))
                    return false;
            }
        }

        return true;
    }

    public OnSave(): void {
        let reason = this.ReasonFormControl.value as string;
        reason = reason.trim();

        if (this.PromptForReplacedByTicketNumber) {
            let replacedByTicketNumber = this.UseLastTicketCreatedFormControl.value ? this.LastTicketNumber : this.ReplacedByTicketNumberFormControl.value;
            if (replacedByTicketNumber) {
                replacedByTicketNumber = replacedByTicketNumber.trim();
                reason = reason + "\r\n" + "REPLACED BY TICKET NUMBER: " + replacedByTicketNumber;
            }
        }

        this.dialogRef.close({ TicketNumber: this.TicketNumber, Reason: reason });
    }

    private FindLastTicketCreated(): void {
        const request = new SearchRequest();
        request.Columns = ["TicketNumber"];
        request.Filters = [
            new SearchFilter("AgentPersonID", SearchFilterOperatorEnum.CurrentUser, null),
            new SearchFilter("TakenEndDate", SearchFilterOperatorEnum.PastDaysFromToday, [new SearchFilterValue(0, null)]),
        ];
        request.OrderBy = [
            new SearchOrderBy("TakenEndDate", true)
        ]
        request.PageNum = 1;
        request.PageSize = 1;

        this._TicketService.GetList(request, false).pipe(take(1))
            .subscribe(response => {
                if (response && response.Items && (response.Items.length > 0))
                    this.LastTicketNumber = response.Items[0].TicketNumber;
            });
    }
}
