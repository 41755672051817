<ng-container *ngIf="SelectItemSearch">
    <iq-list-select-item-filter [filter]="SearchFilter" (valueChange)="FilterChanged($event)" [clearFilter]="ClearFilterEvent" [allowEmpty]="!IsValueRequired"
                                [SearchValues]="SearchColumn.filterOptions | async" [RestrictListFilterToSingleSelection]="SearchColumn.RestrictListFilterToSingleSelection"
                                [disabled]="IsDisabled"></iq-list-select-item-filter>
</ng-container>

<ng-container *ngIf="DateSearch">
    <iq-list-date-filter [filter]="SearchFilter" (valueChange)="FilterChanged($event)" [clearFilter]="ClearFilterEvent"
                            [allowEmpty]="!IsValueRequired" [disabled]="IsDisabled" [DateFilterConfig]="SearchColumn.DateFilterConfig"
                            [showFutureOptions]="SearchColumn.ShowFutureDateOptions" [DateOnly]="SearchColumn.DateOnly">
    </iq-list-date-filter>
</ng-container>

<ng-container *ngIf="FreeTextSearch">
    <iq-list-free-text-filter [filter]="SearchFilter" (valueChange)="FilterChanged($event)" [clearFilter]="ClearFilterEvent" [allowEmpty]="!IsValueRequired" [disabled]="IsDisabled" [minChars]="SearchColumn.minNumberOfCharsBeforeValid"></iq-list-free-text-filter>
</ng-container>

<ng-container *ngIf="AutoComplete">
    <iq-list-autocomplete-filter [filter]="SearchFilter" (valueChange)="FilterChanged($event)"
                                    [ResultDisplayValue]="AutocompleteResultDisplayValue" [ResultFilterValue]="AutocompleteResultFilterValue"
                                    [AutoCompleteSearchFunction]="AutoCompleteSearchFunction" [clearFilter]="ClearFilterEvent" [allowEmpty]="!IsValueRequired" [disabled]="IsDisabled"
                                    [minChars]="SearchColumn.minNumberOfCharsBeforeValid"
                                    [RestrictListFilterToSingleSelection]="SearchColumn.RestrictListFilterToSingleSelection"
                                    [SearchOnEmpty]="SearchColumn.AutoCompleteSearchOnEmpty"></iq-list-autocomplete-filter>
</ng-container>

<ng-container *ngIf="PersonSearch">
    <iq-list-agent-filter [filter]="SearchFilter" (valueChange)="FilterChanged($event)" [clearFilter]="ClearFilterEvent" [allowEmpty]="!IsValueRequired" [disabled]="IsDisabled"></iq-list-agent-filter>
</ng-container>

<ng-container *ngIf="NumberSearch">
    <iq-list-number-filter [filter]="SearchFilter" (valueChange)="FilterChanged($event)" [clearFilter]="ClearFilterEvent" [allowEmpty]="!IsValueRequired" [disabled]="IsDisabled"></iq-list-number-filter>
</ng-container>
