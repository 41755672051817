<div class="list-item" style="width: 100%;">
    <div class="column status-column">{{ResponseItem.Status}}</div>
    <ng-container *ngIf="ResponseItem.Event; else response">
        <div class="column date-column">{{ResponseItem.Event.EventDate | date: SettingsService.DateTimeFormat}}</div>
        <div class="column ticket-version-column">Version: {{ResponseItem.Event.TicketVersion}}</div>
        <div class="column event-message-column">{{ResponseItem.Event.Message}}</div>
    </ng-container>
    <ng-template #response>
        <div class="column date-column">
            <ng-container *ngIf="ResponseItem.Response.ResponseID">
                {{ResponseItem.Response.EnteredDate | date: SettingsService.DateTimeFormat}}
            </ng-container>
        </div>
        <div class="column service-area-column">
            <div class="text-overflow-2">{{ResponseItem.Response.ServiceAreaName}}</div>
            <div class="iq-caption text-overflow-1 gray-primary-text">{{ResponseItem.Response.ServiceAreaCode}}</div>
        </div>
        <div *ngIf="HaveUtilityTypes" class="column utility-type-column">{{ResponseItem.Response.UtilityTypeName}}</div>
        <div class="column response-column">
            <div *ngIf="ResponseItem.Response.ResponseCode">
                {{ResponseItem.Response.ResponseCode +": "+ResponseItem.Response.ResponseDescription}}
            </div>
            <div *ngIf="ResponseItem.Response.SuppressUntilDate">
                <span class="gray-primary-text">Suppress Until: </span>{{ResponseItem.Response.SuppressUntilDate | date: SettingsService.DateTimeFormat}}
            </div>
            <div *ngIf="ResponseItem.Response.DiscussionStatus" [ngClass]="{'red':ResponseItem.Response.DiscussionStatus.IsPending, 'green':!ResponseItem.Response.DiscussionStatus.IsPending}">
                * {{ResponseItem.Response.DiscussionStatus.Status}}
            </div>
            <ng-container *ngIf="ResponseItem.Response.Current && CanEnterResponse">
                <!-- This extra permission check is required becaase CanEnterResponse applies to the ticket in general. -->
                <!-- The user may have permission to create responses for 1 service area but could be view only on another! -->
                <div *iqHasPermission="TicketResponseCreatePermission for [ResponseItem.Response.ServiceAreaID]" class="link" (click)="AddNewResponse()">add new</div>
            </ng-container>
        </div>
        <div class="column entry-method-column">
            {{ResponseItem.Response.EntryMethod | ResponseEntryMethodEnum}}
            <span *ngIf="ResponseItem.Response.EnteredByPersonName">: {{ResponseItem.Response.EnteredByPersonName}}</span>
        </div>
        <div class="column comments-column">
            <div *ngIf="ResponseItem.Response.Respondant">
                <span class="gray-primary-text">Respondant: </span>{{ResponseItem.Response.Respondant}}
                <mat-divider *ngIf="ResponseItem.Response.Comment"></mat-divider>
            </div>
            <div *ngIf="ResponseItem.Response.Comment">{{ResponseItem.Response.Comment}}</div>
        </div>
    </ng-template>
</div>
