import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { EntityEnum } from 'Enums/EntityType.enum';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { TicketActionEnum } from 'Enums/TicketAction.enum';
import * as _ from 'lodash';
import { PagedListResponse } from 'Models/Base/PagedListResponse.model';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { TicketFunction } from 'Models/Configuration/TicketFunction.model';
import { TicketTypeOptionsForTicketEntry } from 'Models/Configuration/TicketTypeOptionsForTicketEntry.model';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { SearchColumnResponse } from 'Models/Searching/SearchColumnResponse.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { JSErrorRequest } from 'Models/System/JSErrorRequest.model';
import { AbandonTicketEditRequest } from 'Models/Tickets/AbandonTicketEditRequest.model';
import { AddNotesToTicketRequest } from "Models/Tickets/AddNotesToTicketRequest.model";
import { CopyTicketRequest } from 'Models/Tickets/CopyTicketRequest.model';
import { CreateAnotherTicketRequest } from 'Models/Tickets/CreateAnotherTicketRequest.model';
import { FieldChangeRequest } from 'Models/Tickets/FieldChangeRequest.model';
import { FieldChangeResponse } from 'Models/Tickets/FieldChangeResponse.model';
import { FindAffectedServiceAreasRequest } from 'Models/Tickets/FindAffectedServiceAreasRequest.model';
import { FindAvailableMeetingPeriodsRequest } from 'Models/Tickets/FindAvailableMeetingPeriodsRequest.model';
import { MeetingPeriodResponse } from 'Models/Tickets/MeetingPeriodResponse.model';
import { ResendTicketRequest } from 'Models/Tickets/ResendTicketRequest.model';
import { SearchAffectedServiceAreasRequest } from 'Models/Tickets/SearchAffectedServiceAreasRequest.model';
import { SendTicketToExcavatorRequest } from 'Models/Tickets/SendTicketToExcavatorRequest.model';
import { Ticket } from "Models/Tickets/Ticket.model";
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { TicketDashboardInfoRequest } from 'Models/Tickets/TicketDashboardInfoRequest.model';
import { TicketDashboardInfoResponse } from 'Models/Tickets/TicketDashboardInfoResponse.model';
import { TicketDeliveryItem } from 'Models/Tickets/TicketDeliveryItem.model';
import { TicketEditRequest } from 'Models/Tickets/TicketEditRequest.model';
import { TicketEntryConfigurationResponse } from "Models/Tickets/TicketEntryConfigurationResponse.model";
import { TicketEntryExcavatorInfoResponse } from 'Models/Tickets/TicketEntryExcavatorInfoResponse.model';
import { TicketEntryResponse } from 'Models/Tickets/TicketEntryResponse.model';
import { TicketRevisionItem } from 'Models/Tickets/TicketRevisionItem.model';
import { TicketServiceArea } from 'Models/Tickets/TicketServiceArea.model';
import { TicketVersionResponse } from 'Models/Tickets/TicketVersionResponse.model';
import { UpdateTicketCommentsRequest } from "Models/Tickets/UpdateTicketCommentsRequest.model";
import { VerifyTicketBeforeSaveRequest } from 'Models/Tickets/VerifyTicketBeforeSaveRequest.model';
import { VerifyTicketBeforeSaveResponse } from 'Models/Tickets/VerifyTicketBeforeSaveResponse.model';
import { ToastrService } from 'ngx-toastr';
import { MemberService } from 'Pages/Members/Services/Member.service';
import { RoleService } from 'Pages/RolesAndPermissions/Services/Role.service';
import { ServiceAreaService } from 'Pages/ServiceAreas/Services/ServiceArea.service';
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map, mergeMap } from 'rxjs/operators';
import { DeviceDetectorService } from "Services/DeviceDetector.service";
import { EnumService } from 'Services/Enum.service';
import { GeocodeService } from 'Services/GeocodeService';
import { MainMenuService } from 'Services/MainMenuService';
import { SettingsService } from 'Services/SettingsService';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { TicketDigSiteRuleService } from './TicketDigSiteRule.service';
import { TicketEntryOptionsService } from './TicketEntryOptions.service';

@Injectable({
    providedIn: 'root'
})
export class TicketService extends CRUDBaseService<Ticket> {
    protected apiPath: string = "Tickets";

    public ViewPermission: PermissionsEnum = PermissionsEnum.Ticket_View;
    public EditPermission: PermissionsEnum;
    public CreatePermission: PermissionsEnum = PermissionsEnum.Ticket_Create;
    public DeletePermission: PermissionsEnum;
    public CopyPermission: PermissionsEnum = PermissionsEnum.Ticket_Copy;

    constructor(private http: HttpClient, public SettingsService: SettingsService, public geocodeService: GeocodeService,
        protected services: CRUDServices, private _MainMenuService: MainMenuService, private _EnumService: EnumService,
        private _ServiceAreaService: ServiceAreaService, private _MemberService: MemberService,
        private _TicketEntryOptionsService: TicketEntryOptionsService, public ToastrService: ToastrService,
        private _RoleService: RoleService, private _Dialog: MatDialog, private _DeviceDetectorService: DeviceDetectorService)
    {
        super(services);
        
        this.InitTicketToolbar(null, false);
    }

    //  TODO: This is a BehaviorSubject so that we can initialize the form when this changes.
    //  That's probably not the best way to determine that.  Should just have 1 ticket stored here
    //  and trigger the form load some other way.
    public readonly Ticket: BehaviorSubject<Ticket> = new BehaviorSubject(null);

    //  True when the service area list in Ticket.ServiceAreas is dirty and needs to be looked up.
    //  i.e. Something has changed that affects how we determine the affected service areas (or we
    //  are editing/copying/working on a new ticket and have not fetched them yet).
    public readonly ServiceAreasAreDirty: BehaviorSubject<boolean> = new BehaviorSubject(true);

    //  Fired after we have fetched and received update date calculations.  If this is in response to
    //  a change in the TicketType, this event is also not fired until we have determined that we can
    //  change to that ticket type (i.e. showing and confirmation a disclaimer)
    //      - BUT ONLY IF the disclaimer is shown via EntryFormBase.CanChangeToTicketTypeID()!
    public readonly DatesRecalculated: EventEmitter<boolean> = new EventEmitter();

    //  These are configurations that do not change once we start editing (do not change based on ticket type or ticket function)
    private _TicketConfiguration: TicketEntryConfigurationResponse;
    public get TicketConfiguration(): TicketEntryConfigurationResponse {
        return this._TicketConfiguration;
    }

    //  These are options that change with the Ticket Function and Ticket Type.  It also holds the current
    //  Ticket Function and Ticket Type.
    //  This is exposed here as a convenience but anything should be able to get this using DI...
    public get TicketEntryOptionsService(): TicketEntryOptionsService {
        return this._TicketEntryOptionsService;
    }

    public get AllowedTicketFunctions(): TicketFunction[] {
        if (!this._TicketConfiguration || !this._TicketConfiguration.AllowedActions || !this._TicketConfiguration.AllowedActions.AllowedTicketEditFunctions)
            return [];
        return this._TicketConfiguration.AllowedActions.AllowedTicketEditFunctions;
    }

    public readonly ExcavatorInfo: BehaviorSubject<TicketEntryExcavatorInfoResponse> = new BehaviorSubject<TicketEntryExcavatorInfoResponse>(new TicketEntryExcavatorInfoResponse());
    public readonly PreviousTicketValues: BehaviorSubject<{ [key: string]: any; }> = new BehaviorSubject<{ [key: string]: any; }>({});

    public readonly Readonly: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public ViewURL: string;

    //  TODO: Don't reference this property - reference it from TicketEntryFormGroup.
    //  We need to move *ALL* data that is related to the ticket being viewed or edited out of this class.  This class should
    //  not being storing any state information at all.  It should only provide stateless services.
    //  The only exception may be for data related to the ticket list search where we need it to be shared between the list
    //  and details pages so that we can view the next/prev ticket.
    //  Otherwise, everything should be stored in TicketEntryFormGroup so that it is all self contained there and so that we
    //  don't have DI related issues with getting the wrong instance if there are multiple (which happens if we are viewing a ticket
    //  and then start a dialog-based ticket edit).
    //  Or maybe we just create another class to hold this stuff and track that instance inside TicketEntryFormGroup to try to
    //  make it more manageable (so we don't have EVERYTHING in TicketEntryFormGroup).
    //  But for the moment at least, TicketEntryFormGroup is exposing this property so that form controls get it from the correct instance.
    public readonly EditingTicket: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public CreateAnother: boolean = false;

    public readonly TicketAction: EventEmitter<{ action: TicketActionEnum, data: any, onActionAcknowledged: () => void }> = new EventEmitter();

    public readonly SelectedTabIndex: Subject<number> = new Subject();

    //  Tracks if we have sent the "responses viewed" message to the server yet.  This records a "responses viewed" event for excavators.
    private _RecordedResponsesViewedEvent: boolean = false;

    /**
     * Triggers a ticket action that can be handled by a child component.
     * @param action
     * @param data
     */
    public TriggerAction(action: TicketActionEnum, data: any, onActionAcknowledged: () => void): void {
        this.TicketAction.emit({ action: action, data: data, onActionAcknowledged: onActionAcknowledged });
    }

    public ViewTicket(ticketID: string, ticketFunctionForDialogEdit: TicketFunction = null): Observable<TicketEntryResponse> {
        this.LastViewableTicketIDorNumber = ticketID;

        return new Observable<TicketEntryResponse>(observer => {
            return this.http.get<TicketEntryResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/ViewTicket/" + ticketID)
                .subscribe(val => {
                    this.SetNextAndPreviousIDs(val.Ticket.ID);
                    val.StartDialogEditForTicketFunction = ticketFunctionForDialogEdit;
                    observer.next(val);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public ViewMostRecent(ticketID: string): Observable<TicketEntryResponse> {
        this.LastViewableTicketIDorNumber = ticketID;

        return new Observable<TicketEntryResponse>(observer => {
            return this.http.get<TicketEntryResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/ViewMostRecent/" + ticketID)
                .subscribe(val => {
                    this.SetNextAndPreviousIDs(val.Ticket.ID);
                    val.StartEdit = false;
                    observer.next(val);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public StartNewTicket(excavatorContactID: string = null): Observable<TicketEntryResponse> {
        return new Observable<TicketEntryResponse>(observer => {
            let url = this.SettingsService.ApiBaseUrl + "/Tickets/Entry/StartNewTicket";
            if (excavatorContactID)
                url += "/" + excavatorContactID;

            this.http.get<TicketEntryResponse>(url)
                .subscribe(val => {
                    //Clear the values for the next and previous buttons
                    this.ClearAllNextAndPreviousValues();
                    val.StartEdit = true;
                    observer.next(val);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    //  If locateTypeID provided, will force the LocateTypeID on the ticket returned to this value (used by FL to "create land ticket" after creating underwater).
    //  Set to null to keep LocateTypeID of ticket being copied.
    public CopyTicket(ticketNumber: string, locateTypeID: string): Observable<TicketEntryResponse> {
        this.LastViewableTicketIDorNumber = ticketNumber;

        return new Observable<TicketEntryResponse>(observer => {

            if (!ticketNumber)
            {
                observer.next(null);
                observer.complete();
                return;
            }

            const request = new CopyTicketRequest(ticketNumber, locateTypeID);

            this.http.post<TicketEntryResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/CopyTicket", request)
                .subscribe(val => {
                    //Clear the values for the next and previous buttons
                    this.ClearAllNextAndPreviousValues();

                    val.StartEdit = true;
                    observer.next(val);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public CreateAnotherTicket(ticketID: string): Observable<TicketEntryResponse> {
        this.LastViewableTicketIDorNumber = ticketID;

        return new Observable<TicketEntryResponse>(observer => {

            if (!ticketID) {
                observer.next(null);
                observer.complete();
                return;
            }

            const request = new CreateAnotherTicketRequest(ticketID);

            this.http.post<TicketEntryResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/CreateAnotherTicket", request)
                .subscribe(val => {
                    val.StartEdit = true;
                    observer.next(val);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public StartTicketEdit(ticketNumber: string, ticketFunctionID?: string): Observable<TicketEntryResponse> {
        //  How can this be null?
        if (!ticketNumber)
            return of(null);

        this.LastViewableTicketIDorNumber = ticketNumber;

        const request = new TicketEditRequest(ticketNumber, ticketFunctionID);
        return this.http.post<TicketEntryResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/StartTicketEdit", request).pipe(
            mergeMap(response => {
                //Clear the values for the next and previous buttons
                this.ClearAllNextAndPreviousValues();

                response.StartEdit = true;

                //  This will output null if there is a Dig Site Rule Error or the user chooses to not continue.
                //  The TicketRouteResolver will then navigate to the View route.
                return TicketDigSiteRuleService.ShowInvalidDigSiteRules(response, this._Dialog);
            })
        );
    }

    /**
     * Set the last Ticket.ID or Ticket.Number that we viewed or null to reset it.  This is used when
     * we discard and need to return to viewing a ticket.  We can't just go "back" because we may
     * have come from an edit/new (via "create another").
     * @param ticketIDorNumber
     */
    public LastViewableTicketIDorNumber: string = null;

    public ClearTicket(): void {
        this._TicketConfiguration = null;
        this._RecordedResponsesViewedEvent = false;

        this.Ticket.next(null);
        this.ServiceAreasAreDirty.next(false);
        this._TicketEntryOptionsService.TicketFunction = null;
        this._TicketEntryOptionsService.TicketType = null;
        this.ExcavatorInfo.next(new TicketEntryExcavatorInfoResponse());
        this.PreviousTicketValues.next({});
        this.geocodeService.Clear();
        this.ViewURL = null;

        this.InitTicketToolbar(null, false);
    }

    private InitTicketToolbar(ticketEntryResponse: TicketEntryResponse, forEditing: boolean): void{
        //  This needs to be a BehaviorSubject or get change detection error (may also work as bool if we use SetTimeout)
        this.Readonly.next(ticketEntryResponse && ticketEntryResponse.Readonly);

        this.EditingTicket.next(forEditing);
        this.CreateAnother = forEditing && ticketEntryResponse && ticketEntryResponse.CreateAnother;

        //  Only do this if not Phone - phone manages the top menu bar visibility itself.
        //  This needs to be set in a timeout or get change detection error
        setTimeout(() => {
            this._MainMenuService.forceClose = this.Readonly.value;
            this._MainMenuService.HideTopMenuBar = this.Readonly.value || this._DeviceDetectorService.IsPhone;
        });
    }

    public OnDateCalcDependentFieldChanged(changedPropertyName: string, dateCalcSeedDate: string, propertyValues: { [key: string]: any; }): Observable<FieldChangeResponse> {

        const request = new FieldChangeRequest(changedPropertyName, dateCalcSeedDate, propertyValues);

        return this.http.post<FieldChangeResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/DateFieldChanged", request);
    }

    public OnFieldStateTriggerPropertyChanged(changedPropertyName: string, propertyValues: { [key: string]: any; }): Observable<FieldChangeResponse> {

        const request = new FieldChangeRequest(changedPropertyName, undefined, propertyValues);

        return this.http.post<FieldChangeResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/FieldStateTriggerPropertyChanged", request);
    }

    public VerifyTicketBeforeSave(ticket: Ticket): Observable<VerifyTicketBeforeSaveResponse> {
        return new Observable<VerifyTicketBeforeSaveResponse>(observer => {
            this.PrepareTicketForPost(ticket, false);

            const request = new VerifyTicketBeforeSaveRequest(ticket);

            this.http.post<VerifyTicketBeforeSaveResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/VerifyTicketBeforeSave", request)
                .subscribe(response => {
                    //  If we need WillGenerateNewTicketNumber somewhere (like to display on the affected service area dialog),
                    //  can create a property for it in Ticket and stash it there.
                    this.SetAffectedServiceAreas(response.AffectedServiceAreas);
                    observer.next(response);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    private PrepareTicketForPost(ticket: Ticket, includeServiceAreas: boolean): void {
        //  ** Some data is maintained directly in the this.Ticket.value object because it's not mapped to fields in the TicketEntryFormGroup.
        //  The ticket that is passed in is constructed from the TicketEntryFormGroup control.
        //  So need to populate those collections from this.Ticket.value.
        //  ...and for Service Areas, strip out all the extra contact info stuff that we don't need to send to the server.

        if (includeServiceAreas) {
            ticket.ServiceAreas = _.map(this.Ticket.value.ServiceAreas, function (sa) {
                return new TicketServiceArea(sa.ServiceAreaID, sa.ManuallyAdded, sa.ServiceAreaType, sa.Suppressed, sa.SuppressedReason, sa.CustomBufferFt, sa.ScheduledInMeeting);
            });
        }

        ticket.Subcontractors = this.Ticket.value.Subcontractors;
        if (ticket.Subcontractors)
            ticket.Subcontractors.forEach(val => val.Subcontractor = null);//Clear out the subcontractor property.  We use it to switch between list ant to edit, but don't want to pass it on save

        ticket.AutoRemarks = this.Ticket.value.AutoRemarks;

        ticket.CreateMultipleTicketsForSegments = this.Ticket.value.CreateMultipleTicketsForSegments;
    }

    public ReleaseSuspendedTicket(ticketID: string): Observable<TicketEntryResponse> {
        return new Observable<TicketEntryResponse>(observer => {
            this.http.put<TicketEntryResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/ReleaseSuspendedTicket/" + ticketID, null)
                .subscribe(response => {
                    this.OnTicketEntryResponseReceived(response);
                    observer.next(response);
                    observer.complete();
                }, err => {
                    //  Most likely, the ticket has been updated by another user so there shouldn't be any extra need to log anything.
                    console.error("Error completing suspended ticket!", err);
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public SaveTicket(ticket: Ticket): Observable<TicketEntryResponse> {
        return new Observable<TicketEntryResponse>(observer => {
            this.PrepareTicketForPost(ticket, true);

            ticket.UpdateExcavatorContactLastVerifiedDate = this.ExcavatorInfo.value.UpdateExcavatorContactLastVerifiedDate;

            this.http.post<TicketEntryResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/SaveTicket", ticket)
                .subscribe(response => {
                    this.OnTicketEntryResponseReceived(response);
                    observer.next(response);
                    observer.complete();
                }, err => {
                    console.error("Error saving ticket!", err);

                    //  Log this to the server - unless it's one of our normal "warning" messages (like "Ticket already updated").
                    //  Also do not log status=500 errors - those are server errors and get logged by the server automatically.
                    if ((err.error?.IsWarning !== true) && (err.status !== 500)) {
                        //  I think network errors (on the client) are responsible for the duplicate
                        //  ticket issues.  The server gets the request and saves the ticket, but the client gets an error while waiting for
                        //  the response.  Need to know what kind of errors we are getting...
                        //  30 second delay is because we just failed - probably because of a temporary network issue on the client.
                        //  Give some time for it to resolve itself.
                        setTimeout(() => {
                            const request = new JSErrorRequest(window.location.href, "Error saving ticket (30 seconds ago): " + JSON.stringify(err), err.stack);
                            this.http.post(this.SettingsService.ApiBaseUrl + "/System/Logging/JSError", request)
                                .subscribe(response => console.log("Sent log report to server"));
                        }, 30000);
                    }

                    observer.error(err);
                    observer.complete();
                });
        });
    }

    /**
     * Updates the internal comments on a ticket (the TicketAncillary.Comments field).
     * @param ticketNumber
     * @param comments
     */
    public UpdateComments(ticketNumber: string, comments: string): Observable<any> {
        const request = new UpdateTicketCommentsRequest(ticketNumber, comments);
        return this.http.post<any>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/UpdateComments", request);
    }

    /**
     * Add Notes (as a TicketEvent) to a ticket.
     * @param ticketID
     * @param ticketNumber
     * @param notes
     * @param isPrivate
     */
    public AddNotesToTicket(ticketID: string, ticketNumber: string, notes: string, isPrivate: boolean): Observable<any> {
        const request = new AddNotesToTicketRequest(ticketID, ticketNumber, notes, isPrivate);
        return this.http.post<any>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/AddNotes", request);
    }

    public OnTicketEntryResponseReceived(response: TicketEntryResponse): void {
        const startTicketEdit = response.StartEdit;

        //  Need to clear the ticket to force it to update in case we are viewing the ticket and just did something that
        //  modified it - i.e. We were viewing a Suspended ticket and used the Complete function it.  That completes it
        //  and returns a new copy of the ticket (with new status and everything else that goes along with it).
        //  If we don't clear it, there's caching somewhere that is not refreshing the view with the new info.
        this.ClearTicket();

        this.InitTicketToolbar(response, startTicketEdit);   //  Always call this first because it sets the EditingTicket flag

        //  TODO: Maybe store the entire TicketEntryResponse and expose these other properties via getters?
        //  Some of these properties really do not need to be BehaviorSubjects either...
        this._TicketConfiguration = response.Configuration;
        this.Ticket.next(response.Ticket);
        this.ServiceAreasAreDirty.next(startTicketEdit);        //  Always dirty when editing!
        this._TicketEntryOptionsService.TicketFunction = response.TicketFunction;
        this.ExcavatorInfo.next(response.ExcavatorInfo ? response.ExcavatorInfo : new TicketEntryExcavatorInfoResponse());
        this._TicketEntryOptionsService.TicketType = response.TicketTypeOptions;
        this._TicketEntryOptionsService.HomeownerExcavatorCompanyTypeIDs = response.HomeownerExcavatorCompanyTypeIDs;
        this.PreviousTicketValues.next(response.PreviousTicketValues ? response.PreviousTicketValues : {});
        this.ViewURL = response.ViewURL;

        if (response.StartDialogEditForTicketFunction) {
            //  Set when a a ticket function is picked from the ... menu that has the RequireViewTicket flag set to true.
            //  Must trigger this to be handled by the TicketEntryFormBase because needs to be handled by TicketActionsService
            //  which also references TicketService and needs to open a dialog).
            //  setTimeout is needed to allow the TicketEntryForm to initialize (does not need any time - it happens in the next check cycle).
            setTimeout(() => this.TriggerAction(TicketActionEnum.DialogEdit, response.StartDialogEditForTicketFunction, null));
        }
    }

    /**
     * Finds the service areas affected by the dig site/ticket type/etc.  This should only be used when we need to display the
     * service areas BEFORE we are ready to save the ticket (on the map or on the service area tab on the right).  When we save
     * the ticket, VerifyTicketBeforeSave must be used because it factors in other things that are needed when building the
     * final affected service area list (like suppression).
     * @param digSiteGeometryJson
     * @param ticketTypeID
     * @param locateTypeID
     * @param nearEdgeOfRoad: Used by NY for their ServiceAreaTicketAssignmentFilter (does not assign service area if has matching value)
     * @param ticketNumber
     * @param forUpdateOfTicketID
     */
    public FindAffectedServiceAreas(digSiteGeometryJson: object, ticketTypeID: string, locateTypeID: string, nearEdgeOfRoad: string,
        ticketNumber: string, forUpdateOfTicketID: string): Observable<TicketServiceArea[]>
    {
        if (!this.ServiceAreasAreDirty.value) {
            //  Service area list is not dirty so can just return what we have
            return of(this.Ticket.value.ServiceAreas);
        }

        if (!digSiteGeometryJson) {
            this.SetAffectedServiceAreas([]);
            return of([]);
        }

        return new Observable<TicketServiceArea[]>(observer => {
            const request = new FindAffectedServiceAreasRequest(digSiteGeometryJson, ticketTypeID, locateTypeID, nearEdgeOfRoad, ticketNumber, forUpdateOfTicketID);

            this.http.post<TicketAffectedServiceAreaInfo[]>(this.SettingsService.ApiBaseUrl + "/Ticket/AffectedServiceArea/FindAffected", request)
                .subscribe(response => {
                    const saList = this.SetAffectedServiceAreas(response);

                    observer.next(saList);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    //  ** This same process is done in the api in CreateTicketServiceBase.SetAffectedServiceAreas()
    private SetAffectedServiceAreas(affectedServiceAreaInfoList: TicketAffectedServiceAreaInfo[]): TicketServiceArea[] {
        const saList = _.map(affectedServiceAreaInfoList, function (saInfo) {
            const tsa = new TicketServiceArea(saInfo.ID, saInfo.ManuallyAdded, saInfo.ServiceAreaType, saInfo.Suppressed, saInfo.SuppressedReason, saInfo.CustomBufferFt, false);
            tsa.ServiceAreaInfo = saInfo;
            return tsa;
        });

        //  Preserve any that have been manually added or have the ScheduledInMeeting flag set
        if (this.Ticket.value.ServiceAreas) {
            this.Ticket.value.ServiceAreas.forEach(tsa => {
                const sa = saList.find(sa => sa.ServiceAreaID === tsa.ServiceAreaID);

                if (!sa && tsa.ManuallyAdded && tsa.NotSaved) {
                    //  Must check the tsa.NotSaved flag here so that we can tell the difference between a manually added service area that
                    //  has been dropped by the affected service area lookup (which can happen if generating a new ticket number and service area is now inactive)
                    //  vs. a service area we have manually added during this ticket edit operation (and have not saved yet: add, back to ticket details, then save again).
                    saList.push(tsa);       //  Brings the ScheduledInMeeting flag along with it
                }
                else if (sa && tsa.ScheduledInMeeting)
                    sa.ScheduledInMeeting = true;           //  Preserve the ScheduledInMeeting flag
            });
        }

        this.Ticket.value.ServiceAreas = saList;
        this.ServiceAreasAreDirty.next(false);

        return saList;
    }

    /**
     * Searches for Service Areas by a search term or within a distance to the dig site.  Used when manually adding to a ticket.
     * @param digSiteGeometryJson
     * @param ticketTypeID
     * @param locateTypeID
     */
    public SearchAffectedServiceAreas(request: SearchAffectedServiceAreasRequest): Observable<PagedListResponse<TicketAffectedServiceAreaInfo>> {
        return new Observable<PagedListResponse<TicketAffectedServiceAreaInfo>>(observer => {
            this.http.post<PagedListResponse<TicketAffectedServiceAreaInfo>>(this.SettingsService.ApiBaseUrl + "/Ticket/AffectedServiceArea/Search", request)
                .subscribe(response => {
                    observer.next(response);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public FindMeetingTimesForServiceAreas(meetingDate: Date, meetingDurationMinutes: number, serviceAreaIDs: string[], state: string, county: string, forUpdateOfTicket: string): Observable<MeetingPeriodResponse[]> {
        return new Observable<MeetingPeriodResponse[]>(observer => {
            const request = new FindAvailableMeetingPeriodsRequest(meetingDate, meetingDurationMinutes, serviceAreaIDs, state, county, forUpdateOfTicket);

            this.http.post<MeetingPeriodResponse[]>(this.SettingsService.ApiBaseUrl + "/Ticket/AffectedServiceArea/FindMeetingTimes", request)
                .subscribe(response => {
                    observer.next(response);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public GetExcavatorInfoForSidePanel(excavatorContactID: string): void {
        if (!excavatorContactID) {
            //  Need to clear out the cached info when the excavator contact is not set or we may end up using
            //  the wrong information if we then type in a brand new excavator contact!
            this.ExcavatorInfo.next(new TicketEntryExcavatorInfoResponse());
            return;
        }

        const url = this.SettingsService.ApiBaseUrl + '/Tickets/Excavator/InfoForSelectedContact/' + excavatorContactID;

        this.http.get<TicketEntryExcavatorInfoResponse>(url)
            .subscribe(response => {
                this.ExcavatorInfo.next(response);
            });
    }

    public ResetExcavatorLastVerifiedDate(delay: boolean): void {
        this.ExcavatorInfo.value.MustVerifyExcavator = false;
        this.ExcavatorInfo.value.LastVerifiedDate = new Date();

        //  This value will be set in to the Ticket when we save.  The process that updates excavator info will then see it and
        //  reset/delay the date.  This makes sure that the date is only reset when we save so that any changes made to the info are
        //  saved along with the date reset.  Prior to doing this, we were making a separate api call to reset the date as soon as the link was clicked.
        //  1 = reset to now, 2 = delay [x] hours (as configured in OneCallCenterSettings)
        this.ExcavatorInfo.value.UpdateExcavatorContactLastVerifiedDate = delay ? 2 : 1;
    }

    public UpdateTicketAttachmentPublicFlag(ticketAttanchmentID: string, isPublic: boolean): Observable<void> {
        const url = this.SettingsService.ApiBaseUrl + '/Ticket/Attachments/SetPublic/' + ticketAttanchmentID + "/" + isPublic;
        return this.http.put<void>(url, null);
    }

    //  Gets the TicketTypeOptionsForTicketEntry for the Ticket Type and updates it into TicketService.TicketTypeOptions.
    //  The Observable returns the *PREVIOUS* options.  This method is called in response to the TicketTypeID changing.
    //  And once these options are fetched from the server, we will then test to make sure we can actually set that TicketType
    //  (show a disclaimer if necessary).  If we cannot set the TicketType, we will use the previous options to set the
    //  ticket type back to what it was before.
    //  If the ticketTypeID matches the current TicketTypeOptions or the ticketTypeID is null/empty, null will be returned as
    //  the previous options which should indicate that no additional checks should be done.
    public GetTicketTypeOptions(ticketTypeID: string): Observable<TicketTypeOptionsForTicketEntry> {
        const prevOptions = this._TicketEntryOptionsService.TicketType;

        if (!ticketTypeID) {
            this._TicketEntryOptionsService.TicketType = new TicketTypeOptionsForTicketEntry();
            return of(null);
        }
        if (ticketTypeID === this._TicketEntryOptionsService.TicketType.ID)
            return of(null);        //  Same as last so do nothing

        const url = this.SettingsService.ApiBaseUrl + '/Config/TicketTypes/TicketEntryOptions/' + ticketTypeID;

        return new Observable<TicketTypeOptionsForTicketEntry>(observer => {
            this.http.get<TicketTypeOptionsForTicketEntry>(url)
                .subscribe(response => {
                    this._TicketEntryOptionsService.TicketType = response;
                    observer.next(prevOptions);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public GetTicketDeliveries(): Observable<TicketDeliveryItem[]> {
        return this.http.get<TicketDeliveryItem[]>(this.SettingsService.ApiBaseUrl + "/Tickets/TicketDeliveries/" + this.Ticket.value.ID);
    }

    public ResendTicket(item: TicketDeliveryItem, billForResend: boolean = true): Observable<TicketDeliveryItem[]> {
        const request = new ResendTicketRequest(this.Ticket.value.ID, item.ServiceAreaID, item.MessageID, !billForResend);
        return this.http.post<TicketDeliveryItem[]>(this.SettingsService.ApiBaseUrl + "/Tickets/ResendTicket", request);
    }

    public GetTicketRevisions(): Observable<TicketRevisionItem[]> {
        return this.http.get<TicketRevisionItem[]>(this.SettingsService.ApiBaseUrl + "/Tickets/TicketRevisions/" + this.Ticket.value.ID);
    }

    public SendCopyToExcavator(ticketIDList: string[], excavatorContactID: string, emailAddressList: string[], faxNumber: string): void {
        if ((!emailAddressList || emailAddressList.length === 0) && !faxNumber)
            return;

        const url = this.SettingsService.ApiBaseUrl + '/Tickets/Excavator/SendTicket';
        const request = new SendTicketToExcavatorRequest(ticketIDList, excavatorContactID, emailAddressList, faxNumber);

        //  There's no return and don't need to wait for it
        this.http.post(url, request).subscribe();
    }

    /**
     *  Fetch Ticket Dashboard info.
     *  If impersonateExcavatorContactID is set, we are impersonating that Excavator Contact.
     *  If impersonatePersonID is set, we are impersonating a Person (i.e. a Service Area Contact).
     *  Only one of those can be non-null at a time.
     *  If both are null, we fetch the info for the current user.
     */
    public GetDashboardInfo(impersonateExcavatorContactID: string = undefined, impersonatePersonID: string = undefined): Observable<TicketDashboardInfoResponse> {
        const request = new TicketDashboardInfoRequest(impersonateExcavatorContactID, impersonatePersonID);
        return this.http.post<TicketDashboardInfoResponse>(this.SettingsService.ApiBaseUrl + "/" + this.apiPath + "/TicketDashboardInfo", request);
    }

    public ResponsesViewed(): void {
        if (!this.Ticket.value || !this.Ticket.value.TicketNumber || this.EditingTicket.value)
            return;
        if (!this.SettingsService.UsesPositiveResponse)
            return;
        if (this._RecordedResponsesViewedEvent)
            return;     //  Already called for this ticket

        //  This is fire and forget
        this.http.put(this.SettingsService.ApiBaseUrl + "/Ticket/Response/ResponsesViewed/" + this.Ticket.value.TicketNumber, null).subscribe();
        this._RecordedResponsesViewedEvent = true;
    }

    //  Abandon the ticket edit.  Tells the server to unlock the ticket and record a ticket event.
    public AbandonTicketEdit(ticketID: string, abandonReason?: string): void {
        const request = new AbandonTicketEditRequest(ticketID, abandonReason);
        this.http.put(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/AbandonTicketEdit", request).subscribe();
    }

    public FindTicketVersion(ticketNumber: string): Observable<TicketVersionResponse> {
        return new Observable<TicketVersionResponse>(observer => {
            return this.http.get<TicketVersionResponse>(this.SettingsService.ApiBaseUrl + "/Tickets/FindTicketVersion/" + ticketNumber)
                .subscribe(ticketVersion => {
                    observer.next(ticketVersion);
                    observer.complete();
                }, err => {
                    observer.next(null);
                    observer.complete();
                });
        });
    }

    //Override this to prevent the base funcitons from trying anything.  May want to move the logic in other methods to be in these for consistancy sake
    //CanPerformAction(action: 'View' | 'Create' | 'Edit' | 'Delete'): Observable<boolean> {
    //    console.error("Not implemented");
    //    return null;
    //}

    //GetList(request: SearchRequest): Observable<SearchResponse> {
    //    //console.error("Not implemented");
    //    //return null;

    //    return this.commonService.HttpClient.post<SearchResponse>(this.commonService.SettingsService.ApiBaseUrl + "/Tickets/Search", request);
    //    let temp = new SearchResponse();
    //    temp.TotalCount = 0;
    //    temp.Items = [];
    //    return of<SearchResponse>(temp);
    //}
    

    public DeleteMultiple(ids: number[] | string[], excludeIDs: boolean = false): Observable<boolean> {
        console.error("Not implemented");
        return null;
    }

    public Delete(id: number | string): Observable<Ticket> {
        console.error("Not implemented");
        return null;
    }

    public Get(id: number | string): Observable<Ticket> {
        console.error("Not implemented");
        return null;
    }

    //The caller needs to supply the subscribe so that it can do any logic that's needed
    public InsertOrUpdate(model: any): Observable<Ticket> {
        console.error("Not implemented");
        return null;
    }

    public AddCollectionToEntity(addToEntityID: string, propertyName: string, ids: string[], all: boolean = false, excludeIDs: boolean = false): Observable<any> | any {
        console.error("Not implemented");
        return null;
    }

    public RemoveCollectionFromEntity(removeFromEntityID: string, propertyName: string, ids: string[], all: boolean = false, excludeIDs: boolean = false): Observable<any> | any {
        console.error("Not implemented");
        return null;
    }
    
    public SaveProperty(propertyName: string, value: any, form: UntypedFormControl | UntypedFormGroup): void {
        console.error("Not implemented");
        return null;
    }

    //If you are just updating an ID property then you just need to pass in the value and not the model.  If you're inserting a new model (i.e. adding an address to the excavator company) then don't pass in the value, only pass in the model
    public SaveChildEntity(propertyName: string, value: string, model: Ticket | any, form: UntypedFormControl | UntypedFormGroup): void {
        console.error("Not implemented");
        return null;
    }

    protected HasSearchColumnsEndpoint: boolean = true;

    protected AddUIFunctionsToSearchColumn(response: SearchColumnResponse): SearchColumn {
        const col = super.AddUIFunctionsToSearchColumn(response);

        //  *** BEFORE HARDCODING PROPERTY NAMES IN HERE...
        //      Consider adding something into SearchColumnResponse to tell the UI how to handle the column!
        //      The server sends the available columns because it knows what they are.  So there's no reason it can't
        //      also send some information to tell the UI what type of column it is so that the UI knows how to handle it.
        switch (col.column) {
            case "Agent.Fullname":
            case "LockedByPerson.Fullname":
                col.usePersonSearch = true;
                break;

            case "AgentRole.Name":
                col.autoComplete = true;
                col.autocompleteResultDisplayValue = "Name";
                col.autoCompleteSearchFunction = (filter: SearchRequest) => {
                    filter.EntityType = EntityEnum.Role;
                    filter.Filters[0].PropertyName = "Name";
                    return this._RoleService.SearchForAutocomplete(filter);
                };
                break;

            case 'TicketNumber':
                col.minNumberOfCharsBeforeValid = this.SettingsService.TicketNumberSearchRequiredChars;
                col.filterOperator = SearchFilterOperatorEnum.StartsWith;
                break;

            case 'TicketFunction.Name':
                col.filterOptions = this._EnumService.TicketFunctions.pipe(map(types => types.sort((a, b) => a.Name.localeCompare(b.Name)).map(val => new SelectOption(val.ID, val.Name))));
                break;

            case 'DigSite.Intersections.CountyName':        //  old - replaced by Search.CountyName
            case 'Search.CountyName':
                this.ConfigureCountySearchColumn(col);
                break;

            case 'DigSite.Intersections.PlaceName':         //  old - replaced by Search.PlaceName
            case 'Search.PlaceName':
                this.ConfigurePlaceSearchColumn(col);
                break;

            case "ServiceAreaCodes":
                col.autoComplete = true;
                col.autocompleteResultDisplayValue = "Code";
                col.autoCompleteSearchFunction = (filter: SearchRequest) => {
                    filter.EntityType = EntityEnum.ServiceArea;
                    filter.Filters[0].PropertyName = "Name, Code";
                    return this._ServiceAreaService.SearchForAutocomplete(filter);
                };
                break;

            case "Damage.UtilityTypes":
                col.filterOperator = SearchFilterOperatorEnum.CustomArray;
                break;

            case "Stat_ResponsesReceivedRatio":
                //  If the TicketNumberStats_AllResponsesReceived column is included in the result columns, will use it to color this column red/green
                col.DynamicCssClass = (val, item) => !item.TicketNumberStats_AllResponsesReceived ? "" : item.TicketNumberStats_AllResponsesReceived === "Yes" ? "green" : "red";
                break;
        }

        return col;
    }

    protected CustomizeSearchFilters(filterColumns: SearchColumn[]): SearchColumn[] {
        //  Members are not shown as a column but Service Areas now are (as of 12/18/2020).
        //  But we do allow filtering on them.  So need to manually add the filters here.
        //  TODO: This should really be done by the api and then just include a flag on the returned column to indicate if
        //  it can be used as a column and/or filter...  (or an "exclude" from column or filter flag so the default is to use).

        //  This filter is still sent from the server even though it's only as a filter (not displayable).  Why do we need to add it again?
        const member = new SearchColumn(null, "Member", null, "MemberID");
        member.autoComplete = true;
        member.autocompleteResultDisplayValue = "Code";
        member.autoCompleteSearchFunction = (filter: SearchRequest) => {
            filter.EntityType = EntityEnum.Member;
            filter.Filters[0].PropertyName = "Name, Code";
            return this._MemberService.SearchForAutocomplete(filter);
        };

        filterColumns.push(member);
        return filterColumns;
    }

    public AddServiceAreaToExistingTicket(ticketIDs: string[], serviceAreaID: string) {
        const request = {
            TicketIDs: ticketIDs,
            ServiceAreaID: serviceAreaID
        };

        return this.http.post<any>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/AddServiceAreaToExistingTicket", request);
    }

    public TicketSavedConfirmationHtml(ticketID: string) {
        return this.http.get<string>(this.SettingsService.ApiBaseUrl + "/Tickets/Entry/TicketSavedConfirmationHtml/" + ticketID);
    }
    
}
