import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { ServiceAreaAssignmentFilterTypeEnum } from 'Enums/ServiceAreaAssignmentFilterType.enum';
import { NameValueUpdateRequest } from 'Models/Base/NameValueUpdateRequest.model';
import { PagedListResponse } from 'Models/Base/PagedListResponse.model';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { DeliveryRuleSendToPerson } from "Models/DeliveryRules/DeliveryRuleSendToPerson.model";
import { PersonServiceAreaContact } from 'Models/People/PersonServiceAreaContact.model';
import { Registration } from 'Models/Registrations/Registration.model';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { SearchResponse } from 'Models/Searching/SearchResponse.model';
import { ServiceArea } from 'Models/ServiceAreas/ServiceArea.model';
import { ServiceAreaAssignmentFilterInsertOrUpdateRequest } from "Models/ServiceAreas/ServiceAreaAssignmentFilterInsertOrUpdateRequest.model";
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';
import { EnumService } from 'Services/Enum.service';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { UIDateTimeFormat } from 'Shared/Utils/MaskFormats.model';

export class ServiceAreaSearchByHolidayRequest extends SearchRequest {
    HolidayID: string;
}

@Injectable({
    providedIn: 'root'
})
export class ServiceAreaService extends CRUDBaseService<ServiceArea> {
    protected apiPath: string = "Administration/ServiceArea";

    ViewPermission: PermissionsEnum = PermissionsEnum.ServiceArea_View;
    EditPermission: PermissionsEnum = PermissionsEnum.ServiceArea_Edit;
    CreatePermission: PermissionsEnum = PermissionsEnum.ServiceArea_Create;
    DeletePermission: PermissionsEnum = PermissionsEnum.ServiceArea_Delete;
    CopyPermission: PermissionsEnum = PermissionsEnum.ServiceArea_Copy;

    constructor(protected services: CRUDServices, private enumService: EnumService, private _AuthenticationService: AuthenticationService) {
        super(services);
    }

    CanPerformAction(action: 'View' | 'Create' | 'Edit' | 'Delete', itemID: string = null, propertyName: string = null): Observable<boolean> {
        switch (action) {
            case 'View':
                //Always need to look for it anywhere.  This is because we need to check permissions after it's fetched for
                //  the case like a MemberAdmin. We don't know if they can view this until it's been fetched and we know the service area's Member.
                //Also, for searching the server needs to figure out permissions
                return this.services.permissionService.CurrentUserHasPermission(this.ViewPermission, null, true);

            case 'Create':
                //Either change this method (everywhere) to take an item, in this case an inserting item, to check if the MemberID is one they have this permission
                //  on.  Or just check that they have it somewhere and let the server do a more detailed check
                return this.services.permissionService.CurrentUserHasPermission(this.CreatePermission, null, true);
            case 'Delete':
                //Either change this method (everywhere) to take an item, in this case an inserting item, to check if the MemberID is one they have this permission
                //  on.  Or just check that they have it somewhere and let the server do a more detailed check
                return this.services.permissionService.CurrentUserHasPermission(this.DeletePermission, null, true);
            case 'Edit':
                if ((propertyName === "ExcludeFromMCDRecommendations") || (propertyName === "DoNotApplyMCDRecommendations"))
                    return this.services.permissionService.CurrentUserHasPermission(PermissionsEnum.Registration_Create, null, true);

                //Either change this method (everywhere) to take an item, in this case an inserting item, to check if the MemberID is one they have this permission
                //  on.  Or just check that they have it somewhere and let the server do a more detailed check
                return this.services.permissionService.CurrentUserHasPermission(this.EditPermission, null, true);
        }
    }

    public GetRegistrations(serviceAreaID: string, approvedOnly: boolean): Observable<Registration[]> {
        return this.services.http.get<Registration[]>(this.services.settingsService.ApiBaseUrl + "/Administration/ServiceArea/Registrations/" + serviceAreaID + "/" + approvedOnly);
    }

    public RefreshRegistrations(serviceArea: ServiceArea, callback?: () => void): void {
        this.GetRegistrations(serviceArea.ID, false).subscribe(registrations => {
            serviceArea.Registrations = registrations;
            if (callback)
                callback();
        });
    }

    public VoiceCallContacts(serviceAreaID: string): Observable<PersonServiceAreaContact[]> {
        let url = this.services.settingsService.ApiBaseUrl + '/' + this.apiPath + '/VoiceCallContacts/' + serviceAreaID;
        return this.services.http.get<PersonServiceAreaContact[]>(url);
    }

    public SMSContacts(serviceAreaID: string): Observable<DeliveryRuleSendToPerson[]> {
        let url = this.services.settingsService.ApiBaseUrl + '/' + this.apiPath + '/SMSContacts/' + serviceAreaID;
        return this.services.http.get<DeliveryRuleSendToPerson[]>(url);
    }

    public DeleteAssignmentFilter(serviceAreaID: string, filterType: ServiceAreaAssignmentFilterTypeEnum): Observable<boolean> {
        return this.CanPerformAction('Edit').pipe(mergeMap(allowed => {
            return new Observable<boolean>(observer => {
                if (!allowed) {
                    this.services.toastrService.error("Permission Denied");
                    observer.error("Permission Denied");
                    observer.complete();
                    return;
                }

                this.services.http.delete(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + '/AssignmentFilter/' + serviceAreaID + '/' + filterType)
                    .subscribe(() => {
                        observer.next(true);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                    });
            });
        }));
    }

    /**
     * Sets a service area assignment filter that uses a single value.
     * @param serviceAreaID
     * @param filterType
     * @param filterValue
     */
    public SetAssignmentFilter(serviceAreaID: string, filterType: ServiceAreaAssignmentFilterTypeEnum, filterValue: string): Observable<boolean> {
        return this.CanPerformAction('Edit').pipe(mergeMap(allowed => {
            if (!allowed)
                return of(false);

            return this.UpdateAssignmentFilter(serviceAreaID, filterType, filterValue, null, null);
        }));
    }

    /**
     * Inserts/Deletes Service Area Assignment Filter records for an entire FieldConfiguration.  Any existing records not listed in fieldValueIDList
     * will be removed from the database.  So that list should contain the full set of configured fields.  If empty, all existing records will be deleted
     * (which turns off the filter).
     * @param serviceAreaID
     * @param filterType
     * @param fieldConfigurationID
     * @param fieldValueIDList
     */
    public SetAssignmentFilterFieldValues(serviceAreaID: string, filterType: ServiceAreaAssignmentFilterTypeEnum, fieldConfigurationID: string, fieldValueIDList: string[]): Observable<boolean> {
        return this.CanPerformAction('Edit').pipe(mergeMap(allowed => {
            if (!allowed)
                return of(false);

            return this.UpdateAssignmentFilter(serviceAreaID, filterType, null, fieldConfigurationID, fieldValueIDList);
        }));
    }

    private UpdateAssignmentFilter(serviceAreaID: string, filterType: ServiceAreaAssignmentFilterTypeEnum, filterValue: string, fieldConfigurationID: string, fieldValueIDList: string[]): Observable<boolean> {
        return new Observable<boolean>(observer => {
            const request = new ServiceAreaAssignmentFilterInsertOrUpdateRequest();
            request.ServiceAreaID = serviceAreaID;
            request.FilterType = filterType;
            request.FilterValue = filterValue;
            request.FieldConfigurationID = fieldConfigurationID;
            request.FieldValueIDList = fieldValueIDList;

            this.services.http.post(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + '/AssignmentFilter', request)
                .subscribe(() => {
                    observer.next(true);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public GeneratePositiveResponseCode(): Observable<string> {
        return new Observable<string>(observer => {
            //  This manually calls /Property api because the base does not handle updating the property
            //  if the object is returned from the server.
            const request = new NameValueUpdateRequest();
            request.ID = this.EntityID;
            request.Name = "PositiveResponseIdentificationCode";
            request.Value = null;
            request.ReturnUpdatedItem = true;

            this.services.http.put<ServiceArea>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + '/Property', request)
                .subscribe(data => {
                    observer.next(data.PositiveResponseIdentificationCode);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public FindByLocation(state: string, county: string = "", place: string = "") {

        return new Observable<any>(observer => {
            const request = {
                State: state,
                County: county,
                Place: place
            };

            this.services.http.post<ServiceArea>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + '/FindByLocation', request)
                .subscribe(data => {
                    observer.next(data);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public FindByUtilityTypes(utilityTypeIDs: string[]) {

        return new Observable<any>(observer => {

            this.services.http.post<ServiceArea>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + '/FindByUtilityTypes', utilityTypeIDs)
                .subscribe(data => {
                    observer.next(data);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }


    public GetAvailableSearchColumnsAndFilters(): Observable<{ columns: SearchColumn[], filters: SearchColumn[] }> {

        let columns = [new SearchColumn("Name", "Name", "Name", "Name"), new SearchColumn("Code", "Code", "Code", "Code")];

        const alwaysOpen = new SearchColumn("AlwaysOpen", "Open 24x7", "AlwaysOpen", "AlwaysOpen");
        alwaysOpen.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")]);
        alwaysOpen.DynamicCssClass = SearchColumn.BoolToRedGreen;
        columns.push(alwaysOpen);

        const isActive = new SearchColumn("IsActive", "Active", "IsActive", "IsActive");
        isActive.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")]);
        isActive.DynamicCssClass = SearchColumn.BoolToRedGreen;
        isActive.width = "10%";
        columns.push(isActive);

        const saType = new SearchColumn("ServiceAreaType", "Type", "ServiceAreaType", "ServiceAreaType");
        saType.filterOptions = this.enumService.ServiceAreaTypes;      //  This is filtered by the service using ICenterBiz.AllowedServiceAreaTypes
        saType.width = "10%";
        columns.push(saType);

        if (this.services.settingsService.UsesExtraordinaryCircumstances) {
            const extCircm = new SearchColumn("ExtraordinaryCircumstances", "Ex. Circum.", "ExtraordinaryCircumstances", "ExtraordinaryCircumstances");
            extCircm.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")]);
            extCircm.width = "10%";
            columns.push(extCircm);
        }

        if (this._AuthenticationService.CurrentUser?.OneCallCenterSettings) {
            const minCustomDigSiteBufferFt = coerceNumberProperty(this._AuthenticationService.CurrentUser.OneCallCenterSettings.ServiceArea_MinCustomDigSiteBufferFt);
            if (minCustomDigSiteBufferFt > 0) {
                const customBufferCol = new SearchColumn("CustomBufferFt", "Custom Buffer", "CustomBufferFt", "CustomBufferFt");
                customBufferCol.useNumberSearch = true;
                customBufferCol.width = "5rem";
                columns.push(customBufferCol);
            }
        }

        const hasUnapprovedRegCol = new SearchColumn("HasUnapprovedRegistration", "Has Unapproved Registration", "HasUnapprovedRegistration", "HasUnapprovedRegistration");
        hasUnapprovedRegCol.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")]);
        hasUnapprovedRegCol.width = "10%";
        columns.push(hasUnapprovedRegCol);

        const modifyDate = new SearchColumn("LastModifyDateTime", "Last Modified", "LastModifyDateTime", "LastModifyDateTime");
        modifyDate.useDateSearch = true;
        modifyDate.ShowFutureDateOptions = false;
        modifyDate.formatType = 'date';
        modifyDate.width = "15%";
        modifyDate.format = UIDateTimeFormat;
        columns.push(modifyDate);

        const memberName = new SearchColumn("Member_Name", "Member Name", "Member.Name", "Member.Name");
        columns.push(memberName);

        const memberCode = new SearchColumn("Member_Code", "Member Code", "Member.Code", "Member.Code");
        columns.push(memberCode);

        columns = columns.sort((a, b) => a.name.localeCompare(b.name));
        return of({ columns: columns, filters: columns });
    }

    public GetSearchByHolidayRequest(request: SearchRequest, holidayID: string): ServiceAreaSearchByHolidayRequest {
        const searchRequest: ServiceAreaSearchByHolidayRequest = new ServiceAreaSearchByHolidayRequest();
        searchRequest.Columns = request.Columns;
        searchRequest.EntityType = request.EntityType;
        searchRequest.Filters = request.Filters;
        searchRequest.LoadColumnsAndFilters = false;
        searchRequest.OrderBy = request.OrderBy;
        searchRequest.PageNum = request.PageNum;
        searchRequest.PageSize = request.PageSize;
        searchRequest.HolidayID = holidayID;

        return searchRequest;
    }

    public SearchByHoliday(request: SearchRequest, holidayID: string) {

        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            return new Observable<SearchResponse>(observer => {


                if (!allowed)//If they don't have permission then return an empty list, else do the search
                {
                    observer.next();
                    observer.complete();
                    return;     //  Exit now or execution will continue and still execute the api call!
                }

                const searchRequest = this.GetSearchByHolidayRequest(request, holidayID);

                this.services.http.post<SearchResponse>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/SearchByHoliday", searchRequest)
                    .subscribe(val => {
                        observer.next(val);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                            observer.complete();
                            return of<PagedListResponse<ServiceArea>>();
                    });
            });
        }));
    }
}
