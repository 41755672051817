<h1 mat-dialog-title class="add" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Add Affected Service Area</h1>
<mat-dialog-content fxLayout="column" style="flex-grow: 1">
    <div fxLayout fxFlex="nogrow">
        <mat-radio-group class="radio-group options" [(ngModel)]="SelectedOption" (change)="SearchOptionChanged()">
            <!-- DigSafe does not use County so it is hidden for them -->
            <mat-radio-button class="radio-button" color="primary" *ngFor="let opt of RadioOptions; index as i" [value]="i" [hidden]="(i==OPTION_COUNTY) && !SettingsService.UsesCountyInLocations">{{opt}}</mat-radio-button>
        </mat-radio-group>
        <div class="name-search-options" style="padding-left:20px">
            <mat-icon>search</mat-icon>
            <mat-form-field class="name-search-text">
                <input matInput [(ngModel)]="SearchTerm" (ngModelChange)="SearchTermChanged()" type="text" placeholder="Filter by Name or Code" (focus)="SwitchToSearchTerm()">
            </mat-form-field>
        </div>
    </div>

    <iq-select-list fxFlex>
        <div class="iq-list-header no-more-option">
            <div class="column-header lbp-column">
                Type
            </div>
            <div class="column-header service-area-column">
                Service Area
            </div>
            <div class="column-header utility-type-column">
                Utility Type(s)
            </div>
            <div class="column-header distance-column">
                Distance (ft)
            </div>
        </div>
        <div class="iq-list-select-items" style="display: flex">
            <!-- To enable virtual scroll: change the ngFor to cdkVirtualFor and uncomment a cdk-virtual-scroll-viewport element.
                    Also requires ScrollModule in Material.module.ts from @angular/cdk-experimental/scrolling
            -->
            <!--<cdk-virtual-scroll-viewport autosize>-->
            <!--<cdk-virtual-scroll-viewport [itemSize]="30">-->
            <iq-list-select-item *ngFor="let sa of ServiceAreaItems | async; trackBy: TrackBy" [checked]="sa.Selected" (changed)="ToggleSelected($event, sa)"
                                 class="list-item {{sa.ServiceAreaType.Name.toLowerCase()}}-background">
                <div class="lbp-column">
                    {{ sa.ServiceAreaType.Name }}
                </div>
                <div class="service-area-column">
                    <div class="text-overflow-2" matTooltip="{{ sa.Name }}">{{sa.Name}}</div>
                    <div class="iq-caption text-overflow-1" matTooltip="{{ sa.Code }}">Code: {{sa.Code}}</div>
                </div>
                <div class="text-overflow-3 utility-type-column" matTooltip="{{ sa.UtilityTypes }}">
                    {{ sa.UtilityTypes }}
                </div>
                <div class="distance-column">
                    {{ sa.DistanceToGeometryFt | number }}
                </div>
            </iq-list-select-item>
            <!--</cdk-virtual-scroll-viewport>-->
        </div>
    </iq-select-list>
</mat-dialog-content>
<mat-dialog-actions class="centered-dialog-buttons">
    <iq-icon-button button-class="Dialog" icon="Cancel" mat-dialog-close>Close</iq-icon-button>
    <iq-icon-button button-class="Dialog" icon="OK" [disabled]="!HaveSelections" (click)="AddToTicket()">{{AddButtonText}}</iq-icon-button>
</mat-dialog-actions>
