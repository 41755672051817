export class DialogModel {

    /**
     *  If true, sets focus to Action button.  Otherwise, defaults to whichever button is first.
     *  Must also set the "autoFocus" option in the dialog open parameters to false!
     */
    public DefaultToActionButton?: boolean = null;

    constructor(public Title: string = null,
        public Message: string = null,
        public ActionText: string = 'OK',       //Text used for the actionable button that returns some sort of a value (OK is not mixed case! https://www.writing-skills.com/ok-ok-okay-how-do-you-write-ok)
        public ConfirmationText: string = null, //Text used right above the buttons as one last confirmation
        public CancelText: string = 'Cancel',    //Text used if the Dialog type is a confirmational dialog to allow the user to cancel the action they started
        public ShowInPre: boolean = false       //Used to show the message in a pre tag instead of as HTML
    ) {
    }
}
