<busy-overlay [show]="isBusy" setParentPosition="false"></busy-overlay>
<div fxLayout="column" [formGroup]="group" style="width: 100%;">
    <div fxFlex>
        Confirm Login
    </div>
    <div *ngIf="ActionMessage != null" class="mat-body-1" style="padding: 15px 0;">
        {{ActionMessage}}
    </div>
    <mat-form-field fxFlex>
        <input matInput placeholder="Username" type="search" autocomplete="off" formControlName="Username" />
        <mat-error *ngIf="username.hasError('required')">required</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
        <input matInput placeholder="Code" type="search" autocomplete="off" formControlName="Code" (keyup.enter)="onConfirm()" />
        <mat-error *ngIf="code.hasError('required')">required</mat-error>
    </mat-form-field>

    <label class="mat-caption mat-error" style="padding-bottom: 10px;" *ngIf="_errorMessage">{{_errorMessage}}</label>

    <button mat-flat-button (click)="onConfirm()" color="primary" [disabled]="!group.valid || isBusy">
        Confirm
    </button>

    <div fxLayout fxLayoutGap="15px" style="padding: 20px 0;">
        <span fxFlex style="text-align: left;">
            <a class="link" style="text-align: right;" (click)="onResendCode()">Resend Code</a>
        </span>

        <span fxFlex style="text-align: right;">
            <a class="link" (keyup.enter)="onReturnToLogin()" (click)="onReturnToLogin()" tabindex="0">Return to Login</a>
        </span>
    </div>
</div>