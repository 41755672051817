import { OnDestroy, Output, Input, EventEmitter, Injectable, Directive } from "@angular/core";
import { PermissionsEnum } from "../../Enums/RolesAndPermissions/Permissions.enum";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";

@Injectable()//Can't be provided in root because this is actually a helper service to inject the services needed by the base class, it's not really a 'service'.
export class BaseHeaderClassService {
    constructor(public dialog: MatDialog, public router: Router, public toastr: ToastrService) { }
}

@Directive()
export abstract class BaseHeader<T extends any> implements OnDestroy {
    protected abstract CreatePermission: PermissionsEnum;
    protected abstract CreateNewDialog(): MatDialogRef<any, any>;
    protected abstract navigateOnSaveRoute: string;

    //Emit the new item in case we need to do anything with it.
    @Output() NewItem: EventEmitter<T> = new EventEmitter();

    protected destroyed = new Subject<void>();

    private lastSaved = null;

    constructor(protected services: BaseHeaderClassService) { }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    CreateNew() {
        const dialogRef = this.CreateNewDialog();

        if (dialogRef.componentInstance.itemSaved != null) {
            dialogRef.componentInstance.itemSaved.subscribe(val => {
                if (val) {
                    //Used here is only needed to refresh the list.  So just keep the last one so that when the dilaog is closed we know that
                    //  something has been saved and need to refresh the list.
                    this.lastSaved = val;
                }
            });
        }

        //If we want it to always navigate (even if they do a save and add another, then move this to the itemSaved observable above).
        //  I think we only want to navigate when they click 'Save' right now though
        dialogRef.beforeClosed().pipe(takeUntil(this.destroyed)).subscribe(val => {
            if (val) {
                this.services.router.navigate([this.navigateOnSaveRoute, val.ID]).then(val => {
                    if (val)
                        this.services.toastr.success('Viewing the new item');
                });
            }
            else if (this.lastSaved)//If something has been saved and we are canceling then emit this so the list knows to refresh
                this.NewItem.emit(this.lastSaved);
        })
    }
}
