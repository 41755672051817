import {delay, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';

import { Role } from 'Models/RolesAndPermissions/Role.model';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { RoleTypeEnum } from '../../../Enums/RolesAndPermissions/RoleType.enum';
import { ScheduledTaskService } from 'Pages/ScheduledTasks/Services/ScheduledTask.service';
import { ScheduledTaskTypeEnum } from 'Enums/ScheduledTaskType.enum';
import { SearchRequest } from '../../../Models/Searching/SearchRequest.model';
import { SearchResponse } from '../../../Models/Searching/SearchResponse.model';
import { PagedListResponse } from '../../../Models/Base/PagedListResponse.model';

@Injectable({
    providedIn: 'root'
})
export class RoleService extends CRUDBaseService<Role> {
    protected apiPath: string = "RolesAndPermissions/Role";

    ViewPermission: PermissionsEnum = PermissionsEnum.Role_View;//Needs to be overriden and set to the proper permission
    EditPermission: PermissionsEnum = PermissionsEnum.Role_Edit;//Needs to be overriden and set to the proper permission
    CreatePermission: PermissionsEnum = PermissionsEnum.Role_Create;//Needs to be overriden and set to the proper permission
    DeletePermission: PermissionsEnum = PermissionsEnum.Role_Delete;//Needs to be overriden and set to the proper permission
    CopyPermission: PermissionsEnum = PermissionsEnum.Role_Copy;//Needs to be overriden and set to the proper permission

    constructor(protected services: CRUDServices, private _ScheduledTaskService: ScheduledTaskService) {
        super(services);

    }

    /**
     * This is special because some things like announcements need to get this list to view/configure them. So this method will check if they have Permissions to view OneCallCenter_View
     * instead of Role_View
     * 
     * @param searchRequest
     */
    public GetListForAdminConfig(searchRequest: SearchRequest): Observable<SearchResponse> {

        return this.services.permissionService.CurrentUserHasPermission(PermissionsEnum.OneCallCenter_View, null, true).pipe(mergeMap(allowed => {
            return new Observable<SearchResponse>(observer => {

                if (!allowed)//If they don't have permission then return an empty list, else do the search
                {
                    observer.next();
                    observer.complete();
                    return;     //  Exit now or execution will continue and still execute the api call!
                }

                this.services.http.post<SearchResponse>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/search", searchRequest)
                    .subscribe(val => {

                        observer.next(val);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                            return of<PagedListResponse<Role>>();
                    });
            });
        }));
    }

    GetAll(type: RoleTypeEnum = null): Observable<Role[]> {
        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<Role[]>(new Array<Role>())
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            let url = this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/all";
            if (type != null)
                url = url + "/" + type;

            return this.services.http.get<Role[]>(url);
        }));
    }
    
    GetAllList(searchValue: string): Observable<Role[]> {

        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            return new Observable<Role[]>(observer => {

                if (!allowed)//If they don't have permission then return an empty list, else do the search
                {
                    observer.next();
                    observer.complete();
                }

                //let searchValue = searchRequest.Filters[0].Values[0];
                this.services.http.get<Role[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/List/" + searchValue)
                    .subscribe(val => {
                        observer.next(val);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                        return of<Role[]>();
                    });
            });

        }));
    }


    public CanCancelScheduledTask(role: Role): Observable<boolean> | boolean {
        if (!role || !this._ScheduledTaskService.CanCancel(role.ScheduledTask))
            return false;

        //  If user has permission to do the action that's being done, they also have permission to cancel it.
        switch (role.ScheduledTask.TaskType) {
            case ScheduledTaskTypeEnum.SendRegistrationInvites:
                return this.services.permissionService.CurrentUserHasPermission(PermissionsEnum.Role_Edit, [role.ID]);
            default:
                return false;   //  unhandled type???
        }
    }

    public CancelScheduledTask(role: Role): void {
        this._ScheduledTaskService.Cancel(role.ScheduledTask)
    }

    /**
     * Returns a list of the RoleTypeEnums of roles that can be added to a person that has the list of RoleTypes already.
     * @param otherRoleTypes
     */
    public RoleTypesThatCanCoexistWithRoleTypes(roleTypes: RoleTypeEnum[]): RoleTypeEnum[] {
        //  This assumes that this list does not already have any roles in it that cannot coexist with each other!
        if (!roleTypes || (roleTypes.length === 0))
            return null;

        if (roleTypes.some(rt => (rt === RoleTypeEnum.Admin) || (rt === RoleTypeEnum.Operator)))
            return [RoleTypeEnum.Admin, RoleTypeEnum.Operator];

        if (roleTypes.some(rt => (rt === RoleTypeEnum.Excavator) || (rt === RoleTypeEnum.ServiceArea)))
            return [RoleTypeEnum.Excavator, RoleTypeEnum.ServiceArea];

        if (roleTypes.some(rt => (rt === RoleTypeEnum.Regulator)))
            return [RoleTypeEnum.Regulator];

        return null;
    }
}
