import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { take } from 'rxjs';
import { SendCopyToComponent } from '../TicketSavedDialog/SendCopyTo/SendCopyTo.component';

@Component({
    templateUrl: './ConfirmReleaseSuspendedTicketDialog.component.html',
    styleUrls: ['./ConfirmReleaseSuspendedTicketDialog.component.scss']
})
export class ConfirmReleaseSuspendedTicketDialogComponent {
    public Ticket: Ticket;

    @ViewChild('sendCopyTo', { static: true })
    public _SendCopyTo: SendCopyToComponent;

    constructor(private dialogRef: MatDialogRef<ConfirmReleaseSuspendedTicketDialogComponent>, @Inject(MAT_DIALOG_DATA) data,
        private _TicketService: TicketService)
    {
        this.Ticket = data.Ticket;
    }

    public OnReleaseTicket(): void {
        this._TicketService.ReleaseSuspendedTicket(this.Ticket.ID).pipe(take(1)).subscribe(response => {
            if (response) {
                this._SendCopyTo.SendToExcavator();
                this.dialogRef.close(true);
            }
        });
    }

    public CanRelease(): boolean {
        return this._SendCopyTo.IsValid;
    }
}
