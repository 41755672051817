import { Location } from '@angular/common';
import { Directive, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { ICRUD } from '@iqModels/Interfaces/ICRUD.interface';
import { Subject, takeUntil } from 'rxjs';
import { BaseComponent } from 'Shared/Components/Forms/BaseComponent';

/*
 *  These are the services needed for this base.
 *  This way if we need to add or remove a new service we don't have to update all the components that extend this base
 */
@Injectable()
export class BaseEntityComponentServices {
    constructor(public route: ActivatedRoute, public router: Router, public Location: Location) { }
}

///Base class used to show an entity in the system.  This does not need to be used on each component used to make up the entity, just the component that brings all of them together.
//  i.e. it is used on the component to show the Excavtor Company (general info, offices, etc), but not on the component to show just the general info of the Company
@Directive()
export abstract class BaseEntityComponent<T> extends BaseComponent implements OnInit, OnDestroy {

    get nextID() { return this.entityService ? this.entityService.NextID : null; }
    get previousID() { return this.entityService ? this.entityService.PreviousID : null; }
    get previousNextDataStale() { return this.entityService ? this.entityService.IDsStale : null; }
    get selectedPosition() { return this.entityService ? this.entityService.SelectedPosition : null; }

    //protected EditForm: FormGroup;
    model: T = {} as T;

    //Needs to be true because this is needed in the ngOnInit
    @ViewChild(MatTabGroup, { static: true }) private _TabGroup: MatTabGroup;

    //Used to remember the tab by putting this in the URL.  Also used to pass in the tab info into the 'iq-next-previous-navigation' or anything else
    //  we would need it for to build the url properly
    tabKey: string;

    protected Destroyed: Subject<void> = new Subject();

    constructor(protected services: BaseEntityComponentServices, protected entityService: ICRUD<T> = null) {
        super();
    }

    ngOnDestroy() {
        this.Destroyed.next();
        this.Destroyed.complete();
    }

    ngOnInit() {
        this.services.route.data
            .pipe(takeUntil(this.Destroyed))
            .subscribe((data: { item: any }) => {
            if (data && data.item)
                this.SetModel(data.item);
        });

        //  These 2 handlers use a router fragment to save & restore the currently selected tab if you
        //  refresh the page or use the browser Back function to return to it.
        //  Can also route directly to a tab from another page by specifying the fragment
        //  directly like this: [routerLink]="'/servicearea/details/'+model.ServiceAreaID" fragment="tab5"
        //  Router state may be another way to remember stuff like this but using fragment so that we
        //  can do the direct route from another page.
        this.services.route.fragment
            .pipe(takeUntil(this.Destroyed))
            .subscribe(fragment => {
                //  Would have liked to use the MatTab.textLabel property to identity the tab, but...
                //  It's possible to use "@ViewChildren(MatTab)" to get all of the Tabs dynamically.
                //  But, that doesn't get populated until "AfterViewInit".  This fragment change gets triggered
                //  BEFORE that happens so the Tabs will never be available.  If we delay processing the fragment
                //  change (via a setTimeout or by setting a property that we then check in ngAfterViewInit),
                //  we can find the Tab dynamically but by the time that happens, the page has already rendered.
                //  So setting the TabGroup index shows a quick flash of animation as it changes to the new tab.
                //  Looks really bad.  Another option would be that the derived components need to provide
                //  a list of the tab names so that we can figure out the tab index.  Opted to just use a fragment
                //  name formatted as "tab[index]" so that we don't need to worry about any of that.
                if (fragment && fragment.startsWith("tab") && this._TabGroup) {
                    this.tabKey = fragment;

                    let index = parseInt(fragment.substring(3)) - 1;
                    if (index >= 0)
                        this._TabGroup.selectedIndex = index;
                }
            });


        if (this._TabGroup) {
            this._TabGroup.selectedIndexChange
                .pipe(takeUntil(this.Destroyed))
                .subscribe(index => {

                    //Store as a variable so we can use it to build a url and stay on the same tab.  Like when using the next/previous buttons we built.
                    this.tabKey = "tab" + (index + 1);

                    //  When the selected index of the Tab Group changes, update the fragment in the url.  This allows us to
                    //  come back to this tab if we reload the page or navigate back from another page.
                    const url = this.services.router.createUrlTree([], { relativeTo: this.services.route, fragment: this.tabKey }).toString();

                    //  This does not set the fragment in to the router!  Could not find a way to do that without causing the page to reload.
                    //  Even "skipLocationChange" option on navigate() when also setting "fragment" option did not work.
                    //  If we need to know the current fragment, can get the full url (with fragment) from Location.path(true) and then parse it from there.
                    this.services.Location.replaceState(url);       //  Changes the URL without affecting the browser url stack
                });
        }
    }

    //Logic to set the model when it is read loaded. i.e. from the api or passed in to the component
    abstract SetModel(model: T): void;

    //Logic to build the form that the page(s) will use
    //  This does not need to be abstract - it's not called by this class.  It really does not need
    //  to be defined here at all since it's entirely up to the derived class to call this method or not.
    protected BuildForm(): { [key: string]: AbstractControl } { return {}; }
}
