<div class="dialog-fill-height">
    <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Split in to multiple Tickets?</h1>
    <mat-dialog-content style="display:flex; flex-direction:column">
        <div style="display:flex; flex-grow:1" class="iq-ticket-entry-page">
            <div style="display: flex; flex-direction: column; flex-basis: 50%; overflow: auto; padding-right: 10px">
                <div style="color: red; font-size: x-large; font-weight: bold; padding-bottom: 10px">The total length of the dig area on this locate request is {{TotalLengthFt | number}} ft.</div>
                <div *ngIf="Data.DigSiteRule.Message" class="gray-border-color" style="border-bottom:1px solid; padding-bottom:10px" [innerHTML]="Data.DigSiteRule.Message | trustHtml"></div>
                <div class="gray-border-color" style="border-bottom:1px solid; padding-bottom:10px; padding-top:10px">
                    <ng-template dynamic-content></ng-template>
                </div>
                <div style="padding-top: 10px; display: flex; flex-direction: column">
                    <div style="display:flex">
                        <div class="col header"><div class="cell-content">Segment</div></div>
                        <div class="col header"><div class="cell-content">Footage</div></div>
                        <div class="col header"><div class="cell-content">Direction</div></div>
                        <div class="col header"><div class="cell-content"># Service Areas</div></div>
                    </div>
                    <div style="display:flex" *ngFor="let segment of Segments" [ngClass]="{'red': !segment.NumAffectedServiceAreas}">
                        <div class="col"><div class="cell-content">{{segment.Number}}</div></div>
                        <div class="col"><div class="cell-content">{{segment.LengthFt | number}}</div></div>
                        <div class="col"><div class="cell-content">{{segment.Direction | EnumDescription : DirectionEnum : DirectionEnumDescription}}</div></div>
                        <div class="col"><div class="cell-content">{{segment.NumAffectedServiceAreas}}</div></div>
                    </div>
                </div>
            </div>
            <div class="iq-ticket-entry-right-side">
                <ticket-digsite-map [TicketEntryForm]="TicketEntryForm" [ReadOnly]="true" style="height:100%"></ticket-digsite-map>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="centered-dialog-buttons">
        <iq-icon-button button-class="Dialog" icon="Cancel" [mat-dialog-close]="null">Back to Ticket</iq-icon-button>
        <iq-icon-button button-class="Dialog" icon="OK" [disabled]="!CanCreateMultipleTickets()" (click)="OnSaveMultipleTickets()">Split in to multiple Tickets</iq-icon-button>
        <iq-icon-button button-class="Dialog" icon="OK" [mat-dialog-close]="Data.VerifyInfo">Create single Ticket</iq-icon-button>
    </mat-dialog-actions>
</div>
