<busy-indicator [busyKey]="BusyKey"></busy-indicator>
<mat-vertical-stepper style="width: 100%;" [linear]="'true'" #stepper>
    <mat-step [stepControl]="PersonFormGroup">
        <div [formGroup]="PersonFormGroup" style="display:flex; flex-direction:column">
            <ng-template matStepLabel>Your information</ng-template>

            <ng-container *ngIf="HaveMultipleOneCalls">
                <p>Which 811 Center would you like to sign up for?</p>
                <mat-form-field>
                    <mat-select placeholder="Center" formControlName="OneCallCenter" [compareWith]="CompareSelectOptions" required>
                        <mat-option *ngFor="let item of OneCallCenters" [value]="item">
                            {{item.Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <p>
                We need some information about you.
                This will only be used to populate required information about you on Locate Requests that you create.
                Please verify all of the fields below.
            </p>

            <mat-form-field>
                <input matInput placeholder="First Name" UpperCase formControlName="FirstName" required>
                <mat-error iqValidationMessage [control]="FirstNameFormControl"></mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Last Name" UpperCase formControlName="LastName" required>
                <mat-error iqValidationMessage [control]="LastNameFormControl"></mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Email Address" type="email" UpperCase formControlName="EmailAddress" inputmode="email" required maxlength="{{FieldLengths.Email_EmailAddress}}" />
                <mat-error iqValidationMessage [control]="EmailAddressFormControl"></mat-error>
            </mat-form-field>
            <mat-form-field>
                <iq-phone iqAutoFocus placeholder="Phone number" formControlName="PhoneNumber" required [FieldLengths]="FieldLengths"></iq-phone>
                <mat-icon matSuffix>phone</mat-icon>
                <mat-error iqValidationMessage [control]="PhoneNumberFormControl"></mat-error>
            </mat-form-field>

            <!-- style="display:flex; flex-direction: column" -->
            <div class="buttons" style="align-self: flex-end">
                <iq-icon-button button-class="Button-Small" icon="Next" (click)="NextStep()">Next</iq-icon-button>
            </div>
        </div>
    </mat-step>

    <mat-step *ngIf="ExistingContact">
        <div>
            <ng-template matStepLabel>Existing user found</ng-template>

            <p>We found an existing account for your email address.  If this is you, we will attach your login to this record.</p>

            <div class="row">
                <label class="label-col gray-primary-text">Name:</label>
                <label UpperCase>{{ExistingContact.Name}}</label>
            </div>

            <div class="row">
                <label class="label-col gray-primary-text">Email address:</label>
                <label LowerCase>{{ExistingContact.EmailAddress}}</label>
            </div>
            <div class="row">
                <label class="label-col gray-primary-text">Phone number:</label>
                <label>{{ExistingContact.PrimaryPhoneNumber | phone}}</label>
            </div>

            <ng-container *ngIf="ExistingContact.ExcavatorOffices">
                <ng-container *ngFor="let office of ExistingContact.ExcavatorOffices">
                    <div class="row">
                        <label class="label-col gray-primary-text">Excavator company:</label>
                        <label UpperCase>{{office.ExcavatorOffice.ExcavatorCompany.Name}} <span class="gray-primary-text">({{office.ExcavatorOffice.ExcavatorCompany.CompanyID}})</span></label>
                    </div>
                    <div class="row">
                        <label class="label-col gray-primary-text">Excavator office:</label>
                        <label UpperCase>{{office.ExcavatorOffice.Name}} <span class="gray-primary-text">({{office.ExcavatorOffice.OfficeID}})</span></label>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="ExistingContact.Address">
                <div class="gray-primary-text">Homeowner/Property Address:</div>
                <div style="display:flex; flex-direction:column; padding-left:2em">
                    <label>{{ExistingContact.Address.Address1}}</label>
                    <div>
                        <label>{{ExistingContact.Address.City}}, </label>
                        <label>{{ExistingContact.Address.State}} </label>
                        <label>{{ExistingContact.Address.Zip}}</label>
                    </div>
                </div>
            </ng-container>

            <div class="buttons">
                <iq-icon-button button-class="Button-Small" icon="Previous" (click)="PreviousStep()">Back</iq-icon-button>
                <!--<a class="link" (click)="PreviousStep()">Back</a>-->
                <div style="padding:10px; display:flex; flex-direction:column">
                    <iq-icon-button style="padding-bottom:10px" button-class="Button-Small" icon="Cancel" (click)="ExistingContactIsNotUser()">This is not me</iq-icon-button>
                    <iq-icon-button button-class="Button-Small" icon="Save" [disabled]="IsSaving" (click)="CreateAccount(ExistingContact)">Yes, link my login to this record</iq-icon-button>
                </div>
            </div>
        </div>
    </mat-step>

    <ng-container *ngIf="!ExistingContact || IsExistingContactWrong">
        <!-- Steps to attempt to find an existing company/office/homeowner or create a brand new one -->
        <mat-step [completed]="FindMethod">
            <div>
                <ng-template matStepLabel>Search for existing account</ng-template>

                <div>
                    If you or someone else at your company have previously created Locate Requests, please choose a method to try to find your existing information.
                </div>

                <div class="buttons" style="flex-wrap: wrap; padding-top:0px">
                    <iq-icon-button *ngIf="!FieldLengths.ExcavatorCompany_CompanyID_Hidden" style="padding-top:10px" button-class="Button-Small" icon="Search" (click)="SetFindMethod(FIND_METHOD_COMPANY_ID)">Company ID</iq-icon-button>
                    <iq-icon-button *ngIf="AllowSearchByCompanyName" style="padding-top:10px" button-class="Button-Small" icon="Search" (click)="SetFindMethod(FIND_METHOD_COMPANY_NAME)">Company Name</iq-icon-button>
                    <iq-icon-button *ngIf="!FieldLengths.ExcavatorContact_ExcavatorID_Hidden" style="padding-top:10px" button-class="Button-Small" icon="Search" (click)="SetFindMethod(FIND_METHOD_EXCAVATOR_ID)">Excavator ID</iq-icon-button>
                    <iq-icon-button style="padding-top:10px" button-class="Button-Small" icon="Search" (click)="SetFindMethod(FIND_METHOD_TICKET_NUMBER)">Ticket Number</iq-icon-button>
                </div>

                <div style="padding-top:20px">
                    OR...  If you are new to this service, you can create a new account:
                </div>

                <div class="buttons">
                    <iq-icon-button button-class="Button-Small" icon="Previous" (click)="PreviousStep()">Back</iq-icon-button>
                    <iq-icon-button button-class="Button-Small" icon="New" (click)="SetFindMethod(FIND_METHOD_CREATE_NEW)">Create a new account</iq-icon-button>
                </div>
            </div>
        </mat-step>

        <!-- Find by Company ID.  May also require picking an office if the company has multiple -->
        <mat-step [stepControl]="AttachToExcavatorCompanyFormControl" *ngIf="FindMethod === FIND_METHOD_COMPANY_ID">
            <div>
                <ng-template matStepLabel>Find by Company ID</ng-template>

                <div style="display:flex">
                    <mat-form-field style="width:8em">
                        <input iqAutoFocus matInput placeholder="Company ID" type="text" maxLength="9" autocomplete="off" [formControl]="CompanyIDFormControl" required />
                        <mat-error iqValidationMessage [control]="CompanyIDFormControl"></mat-error>
                    </mat-form-field>
                    <div class="buttons" style="align-items:baseline; padding-left:2em">
                        <iq-icon-button button-class="Button-Small" icon="Search" (click)="SearchByCompanyID()">Find Company</iq-icon-button>
                    </div>
                </div>

                <div *ngIf="AttachToExcavatorCompanyFormControl.value?.ID">
                    <p>The search returned the Company:</p>
                    <label><b>{{AttachToExcavatorCompanyFormControl.value?.Name}}</b></label>
                    <p>If you wish to create locate requests for this company, click Next to continue.</p>
                </div>

                <p *ngIf="SearchReturnedNotFound">A Company with that ID was not found.  Please verify the information you entered or contact the one call center to complete your registration.</p>

                <div class="buttons">
                    <iq-icon-button button-class="Button-Small" icon="Previous" (click)="PreviousStep()">Back</iq-icon-button>
                    <iq-icon-button button-class="Button-Small" icon="Next" (click)="NextStep()" [disabled]="!AttachToExcavatorCompanyFormControl.valid">Next</iq-icon-button>
                </div>
            </div>
        </mat-step>

        <!-- Find by Company Name.  May also require picking an office if the company has multiple -->
        <mat-step [stepControl]="AttachToExcavatorCompanyFormControl" *ngIf="FindMethod === FIND_METHOD_COMPANY_NAME">
            <div>
                <ng-template matStepLabel>Find by Company Name</ng-template>

                <div style="display:flex; flex-direction: column">
                    <iq-find-company (CompanySelected)="CompanyNameSelected($event)" [OneCallCenterCode]="OneCallCenterFormControl.value.Code"></iq-find-company>
                </div>

                <div *ngIf="AttachToExcavatorCompanyFormControl.value?.ID">
                    <p>The search returned the Company:</p>
                    <label><b>{{AttachToExcavatorCompanyFormControl.value?.Name}}</b></label>
                    <p>If you wish to create locate requests for this company, click Next to continue.</p>
                </div>

                <p *ngIf="SearchReturnedNotFound">A Company with that ID was not found.  Please verify the information you entered or contact the one call center to complete your registration.</p>

                <div class="buttons">
                    <iq-icon-button button-class="Button-Small" icon="Previous" (click)="PreviousStep()">Back</iq-icon-button>
                    <iq-icon-button button-class="Button-Small" icon="Next" (click)="NextStep()" [disabled]="!AttachToExcavatorCompanyFormControl.valid">Next</iq-icon-button>
                </div>
            </div>
        </mat-step>

        <mat-step [stepControl]="AttachToExcavatorOfficeFormControl" *ngIf="AvailableOffices && AvailableOffices.length > 1">
            <div style="display:flex; flex-direction: column">
                <ng-template matStepLabel>Excavator Office</ng-template>

                <p>Your company has multiple offices.  Please pick the one for which you primarily work.</p>

                <mat-form-field>
                    <mat-select #officeSelect placeholder="Office" [formControl]="AttachToExcavatorOfficeFormControl" [compareWith]="CompareOfficeSelectOptions" required>
                        <mat-select-trigger>
                            {{ officeSelect.value?.Name }}
                        </mat-select-trigger>
                        <mat-option *ngFor="let availOffice of AvailableOffices" [value]="availOffice">
                            <div style="display:flex">
                                <div style="flex: 0 0 40%; overflow: hidden; text-overflow: ellipsis">{{availOffice.Name}}</div>
                                <div style="color: gray; overflow: hidden; text-overflow: ellipsis">{{availOffice.FormattedAddress}}</div>
                            </div>
                        </mat-option>
                    </mat-select>
                    <mat-error iqValidationMessage [control]="AttachToExcavatorOfficeFormControl"></mat-error>
                </mat-form-field>

                <div class="buttons">
                    <iq-icon-button button-class="Button-Small" icon="Previous" (click)="PreviousStep()">Back</iq-icon-button>
                    <iq-icon-button button-class="Button-Small" icon="Next" (click)="NextStep()" [disabled]="!AttachToExcavatorOfficeFormControl.valid">Next</iq-icon-button>
                </div>
            </div>
        </mat-step>

        <mat-step *ngIf="FindMethod === FIND_METHOD_EXCAVATOR_ID">
            <div>
                <ng-template matStepLabel>Find by Excavator ID</ng-template>

                <div style="display:flex">
                    <mat-form-field style="width:8em">
                        <input iqAutoFocus matInput placeholder="Excavator ID" type="text" maxLength="9" autocomplete="off" [formControl]="ExcavatorIDFormControl" required />
                        <mat-error iqValidationMessage [control]="ExcavatorIDFormControl"></mat-error>
                    </mat-form-field>
                    <div class="buttons" style="align-items:baseline; padding-left:2em">
                        <iq-icon-button button-class="Button-Small" icon="Search" (click)="SearchByExcavatorID()">Find Excavator</iq-icon-button>
                    </div>
                </div>

                <p *ngIf="SearchReturnedNotFound">An Excavator with that ID was not found.  Please verify the information you entered or contact the one call center to complete your registration.</p>

                <div *ngIf="AttachToExcavatorContact?.ID">
                    <p>The search returned Excavator:</p>
                    <label><b>{{AttachToExcavatorContact.Name}}</b></label>
                    <ng-container *ngIf="AttachToExcavatorContact.HasLogin">
                        <p style="color:red">This Excavator already has a login in our system.</p>
                        <p>If this is you, please go back to the Login page and log in with your existing username/password or contact the One Call Center for assistance in recovering your account.</p>
                    </ng-container>
                    <ng-container *ngIf="!AttachToExcavatorContact.HasLogin">
                        <p>If this is you, we will attach your login to this record.</p>
                    </ng-container>
                    <div class="buttons" style="flex-wrap:wrap; padding-top:0px">
                        <!-- wrap & padding needed for iphone -->
                        <iq-icon-button style="padding-top:10px" button-class="Button-Small" icon="Cancel" (click)="AttachToExcavatorContact = null; PreviousStep()">This is not me</iq-icon-button>
                        <iq-icon-button *ngIf="!AttachToExcavatorContact.HasLogin" style="padding-top:10px" button-class="Button-Small" icon="Save" [disabled]="IsSaving" (click)="CreateAccount(AttachToExcavatorContact)">Yes, link my login to this record</iq-icon-button>
                    </div>
                </div>
            </div>
        </mat-step>

        <mat-step [completed]="PreviousTicketVerified" *ngIf="FindMethod === FIND_METHOD_TICKET_NUMBER">
            <div>
                <ng-template matStepLabel>Find by Ticket number</ng-template>

                <div style="display:flex">
                    <!-- max-width needed here for iphone -->
                    <mat-form-field style="max-width:48%">
                        <input iqAutoFocus matInput placeholder="Ticket Number" type="search" autocomplete="off" [formControl]="PreviousTicketNumberFormControl" [textMask]="TicketNumberMaskOptions" />
                        <mat-error iqValidationMessage [control]="PreviousTicketNumberFormControl"></mat-error>
                    </mat-form-field>
                    <mat-form-field style="padding-left: 20px; max-width: 48%">
                        <iq-phone placeholder="Phone number" [formControl]="PreviousTicketPhoneNumberFormControl" [FieldLengths]="FieldLengths"></iq-phone>
                        <mat-icon matSuffix>phone</mat-icon>
                        <mat-error iqValidationMessage [control]="PreviousTicketPhoneNumberFormControl"></mat-error>
                    </mat-form-field>
                </div>

                <div class="buttons" style="padding-top:0px">
                    <iq-icon-button button-class="Button-Small" icon="Search" (click)="SearchByPreviousTicket()" [disabled]="!PreviousTicketNumberFormControl.valid || !PreviousTicketPhoneNumberFormControl.valid">Find Ticket</iq-icon-button>
                </div>

                <p *ngIf="SearchReturnedNotFound">That Ticket and Phone Number do not match a ticket in our system.  Please verify the information you entered or contact the one call center to complete your registration.</p>

                <div *ngIf="AttachToExcavatorContact">
                    <p>The Excavator Contact listed on that ticket is:</p>
                    <label><b>{{AttachToExcavatorContact.Name}}</b></label>
                    <ng-container *ngIf="AttachToExcavatorCompanyFormControl.value">
                        <label> with <b>{{AttachToExcavatorCompanyFormControl.value.Name}}</b></label>
                    </ng-container>
                    <ng-container *ngIf="AttachToExcavatorContact.HasLogin">
                        <p style="color:red">This Excavator already has a login in our system.</p>
                        <p>If this is you, please go back to the Login page and log in with your existing username/password or contact the One Call Center for assistance in recovering your account.</p>
                    </ng-container>
                    <ng-container *ngIf="!AttachToExcavatorContact.HasLogin">
                        <p>If this is you, we will attach your login to this record.</p>
                    </ng-container>
                    <div class="buttons" style="flex-wrap:wrap; padding-top:0px">
                        <!-- If "This is not me", we switch to offer creating an account for the company if there is one (not a homeowner). -->
                        <!-- If no company, we go "Back" so user can either use a different method or use the "create account" option. -->
                        <!-- wrap & padding needed for iphone -->
                        <iq-icon-button *ngIf="AttachToExcavatorCompanyFormControl.value" style="padding-top:10px" button-class="Button-Small" icon="Cancel" (click)="AttachToExcavatorContact = null">This is not me</iq-icon-button>
                        <iq-icon-button *ngIf="!AttachToExcavatorCompanyFormControl.value" style="padding-top:10px" button-class="Button-Small" icon="Cancel" (click)="PreviousStep()">This is not me</iq-icon-button>
                        <iq-icon-button *ngIf="!AttachToExcavatorContact.HasLogin" style="padding-top:10px" button-class="Button-Small" icon="Save" [disabled]="IsSaving" (click)="CreateAccount(AttachToExcavatorContact)">Yes, link my login to this record</iq-icon-button>
                    </div>
                </div>

                <div *ngIf="!AttachToExcavatorContact && AttachToExcavatorCompanyFormControl.value?.ID">
                    <p>The Excavator Company listed on that ticket is:</p>
                    <label><b>{{AttachToExcavatorCompanyFormControl.value?.Name}}</b></label>
                    <p>If you wish to create locate requests for this company, click Next to continue.</p>
                    <div class="buttons">
                        <iq-icon-button button-class="Button-Small" icon="Previous" (click)="PreviousStep()">Back</iq-icon-button>
                        <iq-icon-button button-class="Button-Small" icon="Next" [disabled]="!PreviousTicketVerified" (click)="NextStep()">Next</iq-icon-button>
                    </div>
                </div>
            </div>
        </mat-step>
    </ng-container>

    <ng-container *ngIf="FindMethod === FIND_METHOD_CREATE_NEW">
        <!-- Steps for creating a brand new Excavator or Homeowner -->
        <mat-step>
            <div>
                <ng-template matStepLabel>
                    Account type:
                    <ng-container *ngIf="SelectedProjectType; else projectNotSelected">
                        <span><b>{{SelectedProjectType.Name}}</b></span>
                    </ng-container>
                    <ng-template #projectNotSelected>
                        <span *ngIf="AccountTypeIsExcavator === false"><b>Homeowner/Property Owner</b></span>
                        <span *ngIf="AccountTypeIsExcavator === true"><b>Excavator</b></span>
                    </ng-template>
                </ng-template>

                <p>Please select an account type:</p>
                <div style="display:flex; flex-flow: row wrap; place-content: center space-between">
                    <button class="link" mat-button [color]="'primary'" (click)="SetAccountType(true)">Excavator</button>
                    <button class="link" mat-button [color]="'primary'" (click)="SetAccountType(false)">Homeowner/Property Owner</button>
                    <ng-container *ngFor="let project of ProjectTypes">
                        <button class="link" mat-button [color]="'primary'" (click)="ProjectTypeSelected(project)">{{project.Name}}</button>
                    </ng-container>
                </div>

                <div class="buttons">
                    <iq-icon-button button-class="Button-Small" icon="Previous" (click)="PreviousStep()">Back</iq-icon-button>
                </div>
            </div>
        </mat-step>

        <!-- Create new Excavator -->
        <mat-step [stepControl]="NewExcavatorFormGroup" *ngIf="AccountTypeIsExcavator === true">
            <div style="display:flex; flex-direction: column" [formGroup]="NewExcavatorFormGroup">
                <ng-template matStepLabel>New Company</ng-template>

                <p>Please enter the following information about your Company:</p>

                <mat-form-field>
                    <input iqAutoFocus matInput placeholder="Company Name" type="search" autocomplete="off" UpperCase formControlName="ExcavatorCompanyName" required maxlength="{{FieldLengths.ExcavatorCompany_Name}}" />
                    <mat-error iqValidationMessage [control]="NewExcavatorCompanyNameFormControl"></mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-select placeholder="Company Type" formControlName="CompanyType" [compareWith]="CompareSelectOptions" required>
                        <mat-option *ngFor="let type of CompanyTypes | async" [value]="type">
                            {{type.Name}}
                        </mat-option>
                    </mat-select>
                    <mat-error iqValidationMessage [control]="NewExcavatorCompanyTypeFormControl"></mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="NewExcavatorIndustryTypeFormControl">
                    <mat-select placeholder="Industry Type" formControlName="IndustryType" [compareWith]="CompareSelectOptions" iq-material-select-clear>
                        <mat-option *ngFor="let type of IndustryTypes | async" [value]="type">
                            {{type.Name}}
                        </mat-option>
                    </mat-select>
                    <mat-error iqValidationMessage [control]="NewExcavatorIndustryTypeFormControl"></mat-error>
                </mat-form-field>

                <address [Edit]="'true'" [group]="NewExcavatorOfficeAddressFormControl" [FieldLengths]="FieldLengths"></address>

                <div class="buttons">
                    <iq-icon-button button-class="Button-Small" icon="Previous" (click)="PreviousStep()">Back</iq-icon-button>
                    <iq-icon-button button-class="Button-Small" icon="Next" (click)="NextStep()">Next</iq-icon-button>
                </div>
            </div>
        </mat-step>

        <ng-container *ngIf="AccountTypeIsExcavator === false">
            <!-- Create new Homeowner -->
            <mat-step [stepControl]="NewHomeownerAddressFormGroup">
                <div>
                    <ng-template matStepLabel>Address</ng-template>

                    <p>Please provide the property address:</p>
                    <address [Edit]="'true'" [group]="NewHomeownerAddressFormGroup" [FieldLengths]="FieldLengths"></address>

                    <div class="buttons">
                        <iq-icon-button button-class="Button-Small" icon="Previous" (click)="PreviousStep()">Back</iq-icon-button>
                        <iq-icon-button button-class="Button-Small" icon="Next" (click)="NextStep()">Next</iq-icon-button>
                    </div>
                </div>
            </mat-step>
        </ng-container>
    </ng-container>

    <!-- final confirmation step -->
    <mat-step *ngIf="CanCreateAccount">
        <div>
            <ng-template matStepLabel>Create Account Review</ng-template>

            <p>Please review the summary of the information below.  If it is correct, click 'Create Account' to finish registration.</p>

            <div class="row">
                <label class="label-col gray-primary-text">First name:</label>
                <label UpperCase>{{FirstNameFormControl.value}}</label>
            </div>
            <div class="row">
                <label class="label-col gray-primary-text">Last name:</label>
                <label UpperCase>{{LastNameFormControl.value}}</label>
            </div>
            <div class="row">
                <label class="label-col gray-primary-text">Email address:</label>
                <label LowerCase>{{EmailAddressFormControl.value}}</label>
            </div>
            <div class="row">
                <label class="label-col gray-primary-text">Phone number:</label>
                <label >{{PhoneNumberFormControl.value | phone}}</label>
            </div>

            <ng-container *ngIf="AttachToExcavatorCompanyFormControl?.value">
                <!-- Attaching to an existing Company/Office (i.e. Company ID/Ticket Number search)-->
                <div class="row">
                    <label class="label-col gray-primary-text">Excavator Company:</label>
                    <label UpperCase>{{AttachToExcavatorCompanyFormControl.value.Name}}</label>
                </div>

                <ng-container *ngIf="AttachToExcavatorOfficeFormControl.value">
                    <div class="row">
                        <label class="label-col gray-primary-text">Excavator Office:</label>
                        <label UpperCase>{{AttachToExcavatorOfficeFormControl.value.Name}}</label>
                    </div>
                    <div class="row" *ngIf="AttachToExcavatorOfficeFormControl.value.FormattedAddress" style="padding-left:2em">
                        {{AttachToExcavatorOfficeFormControl.value.FormattedAddress}}
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="FindMethod === FIND_METHOD_CREATE_NEW">
                <ng-container *ngIf="AccountTypeIsExcavator === true">
                    <div class="row">
                        <label class="label-col gray-primary-text">Excavator Company:</label>
                        <label UpperCase>{{NewExcavatorCompanyNameFormControl.value}}</label>
                    </div>

                    <div class="row">
                        <label class="label-col gray-primary-text">Excavator Address:</label>
                        <address [CanEdit]="false" [group]="NewExcavatorOfficeAddressFormControl" [FieldLengths]="FieldLengths"></address>
                    </div>
                </ng-container>
                <ng-container *ngIf="AccountTypeIsExcavator === false">
                    <div class="row">
                        <label class="label-col gray-primary-text">Address:</label>
                        <address [CanEdit]="false" [group]="NewHomeownerAddressFormGroup" [FieldLengths]="FieldLengths"></address>
                    </div>
                </ng-container>
            </ng-container>

            <div class="buttons">
                <iq-icon-button button-class="Button-Small" icon="Previous" (click)="PreviousStep()">Back</iq-icon-button>
                <iq-icon-button button-class="Button-Small" icon="Save" [disabled]="IsSaving" (click)="CreateAccount()">Create Account</iq-icon-button>
            </div>
        </div>
    </mat-step>
</mat-vertical-stepper>
