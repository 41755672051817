<busy-overlay [show]="isBusy" setParentPosition="false"></busy-overlay>
<div fxLayout="column" style="width: 100%; height: 95%;">
    <label fxFlex class="mat-caption" style="padding-top: 15px;">
        You will get an email at the address provided for verification. 
    </label>
    
    <iq-aws-cognito-create-login fxFlex [PasswordRequirements]="PasswordRequirements" 
        (IsValid)="onValueIsValid($event)"></iq-aws-cognito-create-login>

    <label fxFlex class="mat-caption mat-error" style="padding-bottom: 10px;" *ngIf="_errorMessage">{{_errorMessage}}</label>
    
    <button mat-flat-button (click)="onSignUp()" color="primary" [disabled]="!groupValid || isBusy">
        Submit
    </button>
    
    <div style="text-align: right; padding-top: 20px">
        Already have a login?
        <a class="link" (keyup.enter)="onReturnToLogin()" (click)="onReturnToLogin()" tabindex="0">click here</a>
    </div>
</div>
