import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { MapTooltipsInteraction } from "../Interactions/MapTooltipsInteraction";
import * as ol from 'ol';

export class ShowTooltipsTool {

    private _TooltipsInteraction: MapTooltipsInteraction;
    public get Interaction(): MapTooltipsInteraction {
        return this._TooltipsInteraction;
    }

    constructor(map: ol.Map, private _MapToolService: MapToolService) {
        this._TooltipsInteraction = new MapTooltipsInteraction(_MapToolService);

        this._TooltipsInteraction.setActive(localStorage.getItem("hideMapTooltips") === null);
    }

    public OnDestroy(): any {
        this._TooltipsInteraction.setMap(null);
        return null;
    }

    public IsActive(): boolean {
        return this._TooltipsInteraction.getActive();
    }
}
