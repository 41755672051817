<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Update Alternate Username
</h2>
<mat-dialog-content fxLayout="column" class="mat-typography" [formGroup]="group" style="overflow: auto; min-height: 150px;">
    <busy-overlay [show]="isBusy"></busy-overlay>

    <ng-container *ngIf="isSignedIn; else noLoggedIn">
        <p fxFlex class="mat-body-1"> 
            <span *ngIf="preferredUsername.value != null">
                You have an alternate username of <b>{{currentPreferredUsername}}</b>.  If you want to change the 
                alternate username you can do so below.  
            </span>
            <ng-template #noAlternateSetText>
                If you would like to set an alternate username please do so below.
            </ng-template>
            <br/>
            *If you set an altername username you will be able to login with both your username AND the alternate you set here.
        </p>

        <mat-form-field fxFlex>
            <input matInput placeholder="Alternate Username" type="search" autocomplete="off" formControlName="PreferredUsername"
                (keyup.enter)="onChangePreferredUsername()" autofocus />
            <mat-error iq-aws-cognito-validator [control]="preferredUsername"></mat-error>
        </mat-form-field>        
    </ng-container>
    <ng-template #noLoggedIn>
        <label class="mat-caption mat-error">No logged in user, this should not be shown</label>
    </ng-template>
    
    <label class="mat-caption mat-error" style="text-align: right;" *ngIf="_errorMessage">{{_errorMessage}}</label>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-button (click)="onChangePreferredUsername()" color="primary" [disabled]="!group.valid || isBusy">
        Confirm
    </button>
</mat-dialog-actions>