import { MatDialog } from '@angular/material/dialog';
import { VerifyTicketBeforeSaveResponse } from '@iqModels/Tickets/VerifyTicketBeforeSaveResponse.model';
import { DigSiteRuleActionEnum } from 'Enums/DigSiteRules/DigSiteRuleAction.enum';
import { DigSiteRuleValidationErrorResponse } from 'Models/Errors/DigSiteRuleValidationErrorResponse.model';
import { IResponseWithDigSiteRules } from 'Models/Tickets/IResponseWithDigSiteRules.interface';
import { map, Observable, of } from 'rxjs';
import { ConfirmationDialogComponent } from 'Shared/Components/Controls/Dialog/Confirmation/ConfirmationDialog.component';
import { InformationDialogComponent } from 'Shared/Components/Controls/Dialog/Information/InformationDialog.component';
import { StringUtils } from 'Shared/Utils/StringUtils';
import { CreateMultipleTicketsDialogComponent, CreateMultipleTicketsDialogData } from '../Details/Components/CreateMultipleTicketsDialog/CreateMultipleTicketsDialog.component';
import { TicketEntryFormGroup } from '../Details/Components/InputControls/TicketEntryFormGroup';

export class TicketDigSiteRuleService {

    public static ShowInvalidDigSiteRules<T extends IResponseWithDigSiteRules>(response: T, dialog: MatDialog): Observable<T> {
        if (response?.InvalidDigSiteRules) {
            //  If we have errors, we show the error dialog.
            //  ** When finished, the observable outputs null so the save process is aborted
            if (response.InvalidDigSiteRules.some(r => r.DigSiteRuleAction === DigSiteRuleActionEnum.ShowError))
                return TicketDigSiteRuleService.ShowDigSiteRuleErrors(response.InvalidDigSiteRules, dialog).pipe(map(() => null));

            //  Otherwise, show warnings.  That dialog allows the user to continue or cancel
            if (response.InvalidDigSiteRules.some(r => r.DigSiteRuleAction === DigSiteRuleActionEnum.ShowMessage))
                return TicketDigSiteRuleService.ShowDigSiteRuleWarnings(response.InvalidDigSiteRules, dialog, null).pipe(map(val => val ? response : null));
        }

        return of(response);        //  No invalid dig site rules;
    }

    private static ShowDigSiteRuleErrors(invalidDigSiteRules: DigSiteRuleValidationErrorResponse[], dialog: MatDialog): Observable<any> {
        let message: string = "";
        invalidDigSiteRules.forEach(r => {
            if (r.DigSiteRuleAction === DigSiteRuleActionEnum.ShowError)
                message = StringUtils.AppendToString(message, r.Message, "</br></br>");
        });

        return dialog
            .open(InformationDialogComponent, {
                panelClass: 'iq-warn',
                data: {
                    Title: "Invalid Dig Site!",
                    Message: message,
                    ActionText: "OK"
                }
            }).afterClosed();
    }

    private static ShowDigSiteRuleWarnings(invalidDigSiteRules: DigSiteRuleValidationErrorResponse[], dialog: MatDialog, afterAction: () => void): Observable<any> {
        let message: string = "";
        invalidDigSiteRules.forEach(r => {
            if (r.DigSiteRuleAction === DigSiteRuleActionEnum.ShowMessage)
                message = StringUtils.AppendToString(message, r.Message, "</br></br>");
        });

        return dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    Title: "Warning!",
                    Message: message,
                    ConfirmationText: "Continue anyway?",
                    ActionText: "Yes",
                    CancelText: "No"
                }
            })
            .afterClosed();
    }

    public static ShowCreateMultipleTicketsDialog(verifyResponse: VerifyTicketBeforeSaveResponse,
        ticketEntryFormGroup: TicketEntryFormGroup, dialog: MatDialog): Observable<VerifyTicketBeforeSaveResponse>
    {
        if (verifyResponse?.InvalidDigSiteRules) {
            const showMultipleTicketDigSiteRule = verifyResponse.InvalidDigSiteRules.find(r => r.DigSiteRuleAction === DigSiteRuleActionEnum.CreateMultipleTickets);

            if (showMultipleTicketDigSiteRule) {
                //  Returns the verifyResponse if creating a ticket (when creating multiple, will return an UPDATED
                //  verify response) or null to abort saving.
                return dialog
                    .open(CreateMultipleTicketsDialogComponent, {
                        minWidth: "95%",
                        height: "90%",
                        data: new CreateMultipleTicketsDialogData(ticketEntryFormGroup, verifyResponse, showMultipleTicketDigSiteRule),
                        autoFocus: false        //  To prevent the dialog from focusing on the first input control - it interfers with dialog setting focus itself!
                    })
                    .afterClosed();
            }
        }

        //  Nothing to show
        return of(verifyResponse);
    }
}

