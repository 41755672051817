<mat-chip-list #chipList>
    <ng-container *ngFor="let value of values">
        <!-- changed this to allow removing even if it's required and removing would leave no items. -->
        <!-- originally had this condition: [removable]="!isDisabled && (allowEmpty || values.length > 1)" -->
        <!-- same condition was also on the mat-icon -->
        <!-- ...and same check is also in BaseFilterItem.remove()... -->
        <!-- That's just confusing to the user if you start with an empty list, add something, and then can't remove it unless you add something else first. -->
        <!-- The proper way to handle that is to just check to see if it's empty when saving -->
        <mat-chip *ngIf="!value.HideInUI" [selectable]="true" [removable]="!isDisabled" (removed)="remove(value)" [disabled]="isDisabled">
            {{value.DisplayValue}}
            <mat-icon matChipRemove *ngIf="!isDisabled">cancel</mat-icon>
        </mat-chip>
    </ng-container>
</mat-chip-list>

<mat-form-field style="padding-top: 10px; width: 100%">

    <input autocomplete="off" type="search" placeholder="Search Text..." UpperCase [formControl]="autoCompleteControl" [matAutocomplete]="auto" matInput
           [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false"
           (matChipInputTokenEnd)="Add($event)" iqAutoFocus/>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="AutoCompleteResultSelected($event)" autoActiveFirstOption panelWidth="auto">
        <mat-option *ngFor="let option of autoCompleteControl.Results | async" [value]="option" style="line-height: 15px;">
            <div fxLayout="column" fxLayoutAlign="center start">
                <label>{{ option.Code || option.Name }}</label>
                <label *ngIf="option.Code" class="iq-caption">Name: {{ option.Name }}</label>
            </div>
        </mat-option>
    </mat-autocomplete>
    <mat-hint>
        <ng-container *ngIf="minChars && minChars > 0; else addToFilterHintText">
            Min {{minChars}} characters required
        </ng-container>
    </mat-hint>
</mat-form-field>

<div *ngIf="minChars && minChars > 0" class="iq-caption gray-primary-text">
    <ng-content *ngTemplateOutlet="addToFilterHintText"></ng-content>
</div>

<ng-template #addToFilterHintText>
    {{addTextToFilterHintText}}
</ng-template>
