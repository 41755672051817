import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class SectionItemService {

    public id: number = null;

    public hasLastControl: boolean = false;

    //fired when the Item has multiple controls making up the edit and the last one is tabbed out if
    public lastControlTabForward: BehaviorSubject<boolean> = new BehaviorSubject(null);

    constructor() {
    }


}
