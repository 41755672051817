import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { SectionGroupComponent } from './Group/SectionGroup.component';
import * as _ from 'lodash';

@Injectable()
export class SectionGroupContainerService {
    //Used to tell the SectionGroup that there is a SectionContainer
    public id: number = null;

    //fired when page up or page down is pressed to switch groups
    public readonly activeGroupObserver: BehaviorSubject<number> = new BehaviorSubject(null);

    public readonly lastGroupTabbedOut: Subject<boolean> = new Subject();

    //fired when a group is focused to make sure we fire focus events correctly (ATM only fired when the a group is in edit mode and a section item gets focus.  The other ways are already handled, but may be able to be refactored, but may not)
    public readonly sectionGroupFocused: BehaviorSubject<number> = new BehaviorSubject(null);

    public readonly GroupVisibilityChanged: Subject<SectionGroupComponent> = new Subject();

    public TabPreviousGroup: boolean = false;

    /**
     *  The SectionGroup objects inside the container - in layout order.  Added via RegisterSectionGroup()
     */
    private _SectionGroupObjects: SectionGroupComponent[] = [];
    private _CurrentGroupOrderNumber?: number = null;

    private _Destroyed: Subject<void> = new Subject();

    constructor() {
        this.GroupVisibilityChanged
            .pipe(takeUntil(this._Destroyed))
            .subscribe(g => this.SetFirstLastGroupsInContainer());
    }

    //  Called by each group in it's ngAfterContentInit section.  This happens in layout order.
    public RegisterSectionGroup(group: SectionGroupComponent): void {
        //  ** 4/18/2022: Checking "EditAllowed" instead of "HasSectionItems()" because NY's Site Info section
        //  is not finding any SectionItemComponents at all.  So requiring HasSectionItems was causeing page up/down
        //  to not work for that section (it gets skipped).  It seems to have something to do with there
        //  only being the SiteInfoSectionFull component in it - the @ContentChildren for "sectionItems"
        //  in the SectionGroupComponent does not find them.
        //  EditAllowed can be set to false on sections that do not allow any editing to cause the entire section
        //  to prevent tabbing (i.e.SC's Damage project when the user is an Excavator user).
        if (group.EditAllowed === false)
            return;

        //  Orders are automatically determined.  If we ever need to support different orders, we can
        //  make group.Order an Input.  Then just need to see that it's already set here and then do a sort on the Orders
        //  down below.
        group.Order = this._SectionGroupObjects.length + 1;

        //  Each group calls this method from it's ngAfterContentInit.  Which happens in layout order.  If for some reason,
        //  the order needs to be different (or somehow it's not called in layout order), the group can specify the Order it
        //  wants.  We can then add the group into our collection and then sort it by the order.
        this._SectionGroupObjects.push(group);

        //  If we ever need to support orders other than automatically determined, sort this._SectionGroupObjects here
    }

    public OnDestroy(): void {
        this._SectionGroupObjects = [];

        this._Destroyed.next();
        this._Destroyed.complete();
    }

    public MoveFirstGroup() {
        const firstGroup = this.GetFirstGroup();
        if (!firstGroup)
            return;

        this.activeGroupObserver.next(firstGroup.Order)
    }

    public MoveNextGroup() {
        this.TabPreviousGroup = false;

        let foundStartingGroup: boolean = false;

        const nextGroup = _.find(this._SectionGroupObjects, group => {
            if (foundStartingGroup && group.Visible) 
                return true;
            else if (group.Order == this._CurrentGroupOrderNumber)
                foundStartingGroup = true;

            return false;
        });

        if (nextGroup)
            this.activeGroupObserver.next(nextGroup.Order);
    }

    public MovePreviousGroup() {
        this.TabPreviousGroup = true;

        let foundStartingGroup: boolean = false;

        const prevGroup = _.findLast(this._SectionGroupObjects, group => {
            if (foundStartingGroup && group.Visible)
                return true;
            else if (group.Order == this._CurrentGroupOrderNumber)
                foundStartingGroup = true;

            return false;
        });

        if (prevGroup)
            this.activeGroupObserver.next(prevGroup.Order);
    }

    //  Pass in null to set no current group
    public SetCurrentGroup(group: SectionGroupComponent): void {
        if (group)
            this._CurrentGroupOrderNumber = group.Order;
        else
            this._CurrentGroupOrderNumber = null;
    }

    public IsCurrentGroup(group: SectionGroupComponent): boolean {
        return this._CurrentGroupOrderNumber === group.Order;
    }

    public IsFirstGroup(group: SectionGroupComponent): boolean {
        return (group === this.GetFirstGroup());
    }

    public GetFirstGroup(): SectionGroupComponent {
        return _.find(this._SectionGroupObjects, group => group.Visible);
    }

    public IsLastGroup(group: SectionGroupComponent): boolean {
        return (group === this.GetLastGroup());
    }

    public GetLastGroup(): SectionGroupComponent {
        return _.findLast(this._SectionGroupObjects, group => group.Visible);
    }

    //  Called when the given group is about to be made active.  Returns true if the first section item in the
    //  group should be focused.  Takes into account which group is currently active to determine the direction.
    public ShouldFocusFirstControlWhenMakingGroupActive(group: SectionGroupComponent): boolean {
        if (this._CurrentGroupOrderNumber === null)
            return true;        //  No current group so first control is always focused (happens when clicking on header)

        if ((this.IsCurrentGroup(group)) && this.IsFirstGroup(group))
            return true;        //  This group is the current group and it's the first group???  Not sure how this happens but it was a condition before this logic was moved in here...

        return this.GroupIsAfterCurrentGroup(group);        //  group comes after current group so we are moving forward in the layout
    }

    //  Called when the given group is about to be made active.  Returns true if the first section item in the
    //  group should be focused.  Takes into account which group is currently active to determine the direction.
    public ShouldFocusLastControlWhenMakingGroupActive(group: SectionGroupComponent): boolean {
        if (this._CurrentGroupOrderNumber === null)
            return false;        //  No current group so FIRST control is always focused - NOT LAST

        if ((this.IsCurrentGroup(group)) && this.IsLastGroup(group))
            return true;        //  This group is the current group and it's the last group???  Not sure how this happens but it was a condition before this logic was moved in here...

        return this.GroupIsBeforeCurrentGroup(group);       //  group comes before current group so we are moving backwards in the layout
    }

    public GroupIsAfterCurrentGroup(group: SectionGroupComponent): boolean {
        if (this._CurrentGroupOrderNumber === null)
            return true;        //  No current group so...?

        return group.Order > this._CurrentGroupOrderNumber;
    }

    public GroupIsBeforeCurrentGroup(group: SectionGroupComponent): boolean {
        if (this._CurrentGroupOrderNumber == null)
            return false;        //  No current group so...?

        return group.Order < this._CurrentGroupOrderNumber;
    }

    public SetFirstLastGroupsInContainer(): void {
        //  This is called after the view has finished initializing (by SectionGroupContainer component)
        //  and also if any group visibilities change.  It will find the first and last group in the container
        //  and then tell EVERY group to update those flags in itself.
        //  This makes sure that group visibility changes will unset the flags if the first/last group is
        //  no longer the first/last VISIBLE group.

        const firstGroup = this.GetFirstGroup();
        const lastGroup = this.GetLastGroup();

        this._SectionGroupObjects.forEach(group => {
            group.GroupIsFirstInContainer(group === firstGroup);
            group.GroupIsLastInContainer(group === lastGroup);
        });
    }
}
