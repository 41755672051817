import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EntryFieldValidationErrorSourceEnum } from "Enums/EntryFieldValidationErrorSource.enum";
import _ from 'lodash';
import { EntryFieldDropdownItem } from 'Models/EntryFields/EntryFieldDropdownItem.model';
import { PropertyValidationErrorResponse } from 'Models/Errors/PropertyValidationErrorResponse.model';
import { BehaviorSubject } from 'rxjs';
import { EntryFormControl } from './EntryFormControl';

/**
  * Base class for shared functionality when using configurable Entry Fields (i.e. Ticket and Project entry).
  */
export class EntryFormGroupBase extends UntypedFormGroup {
    constructor(controls: { [key: string]: AbstractControl }) {
        super(controls);
    }

    public IsEditing: BehaviorSubject<boolean>;

    public readonly ShowValidationErrors: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Recursively clear all validation errors of the given source from the entire FormGroup.
     * @param source: If null, clears validation errors of all types.
     */
    public ClearAllValidationErrors(source: EntryFieldValidationErrorSourceEnum) {
        EntryFormGroupBase.ClearValidationErrorsFromAllFormFields(this, source);
    }

    /**
     * Recursively clear all validation errors of the given source.
     * @param source: If null, clears validation errors of all types.
     * @param formGroup: The starting FormGroup.  Will clear all controls and child FormGroups.
     */
    public static ClearValidationErrorsFromAllFormFields(formGroup: UntypedFormGroup, source: EntryFieldValidationErrorSourceEnum) {
        const controls = formGroup.controls;

        for (const name in controls) {
            const control = controls[name];

            if (control instanceof EntryFormControl) {
                const teFormControl = control;
                if (source === null)
                    teFormControl.ClearAllValidationErrors();
                else
                    teFormControl.ClearValidationErrorOfSource(source);
            } else if (control instanceof UntypedFormArray) {
                const fa = control;
                fa.controls.forEach((value, index) => {
                    this.ClearValidationErrorsFromAllFormFields(value as UntypedFormGroup, source);
                });
            } else if (control instanceof UntypedFormGroup)
                this.ClearValidationErrorsFromAllFormFields(control, source);
        }
    }

    //  Touch each FormControl so that the mat-form-field's show their errors.
    public static ValidateAllFormFields(formGroup: UntypedFormGroup, propertyNames: string[], prefix: string) {
        const controls = formGroup.controls;

        for (const name in controls) {
            const control = controls[name];

            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
                if (control.invalid)
                    propertyNames.push(prefix + name);
            } else if (control instanceof UntypedFormArray) {
                const fa = control;
                fa.controls.forEach((value, index) => {
                    this.ValidateAllFormFields(value as UntypedFormGroup, propertyNames, prefix + name + '.' + index.toString() + '.');
                });
            } else if (control instanceof UntypedFormGroup)
                this.ValidateAllFormFields(control, propertyNames, prefix + name + '.');
        }
    }

    public GetDropdownItemsForControl(propertyName: string): EntryFieldDropdownItem[] {
        const control = this.get(propertyName) as EntryFormControl;
        return this.GetDropdownItemsForFormControl(control);
    }

    //  Always use this method to get the DropdownItems - it will handle the DropdownItems not being set
    //  when viewing a ticket (so that we always show the current value).
    public GetDropdownItemsForFormControl(control: AbstractControl): EntryFieldDropdownItem[] {
        const formControl = control as EntryFormControl;

        if (formControl?.FieldConfiguration?.DropdownItems)
            return formControl.FieldConfiguration.DropdownItems;

        //  If we don't have dropdown items and we are not editing, just return a single item with the current
        //  value.  This is so that a dropdown with FieldValues does not need to send the entire list of
        //  values when viewing an entity.  Can save a lot on the payload if there are lots of items in these dropdowns...
        if (!this.IsEditing.value && formControl?.value && formControl.FieldConfiguration) {
            //  And must store this in the DropdownItems for the next time or this will cause angular to detect a change every single time
            //  and will cause an infinite change detection loop!  Causes the page to hang.
            formControl.FieldConfiguration.DropdownItems = [new EntryFieldDropdownItem(formControl.value, formControl.value)];
            return formControl.FieldConfiguration.DropdownItems;
        }

        return [];
    }

    public GetDropdownDisplayValueFromForm(propertyName: string): string {
        const formControl = this.get(propertyName) as EntryFormControl;
        return this.GetDropdownDisplayValueForFormControl(formControl);
    }

    public GetDropdownDisplayValueForFormControl(formControl: EntryFormControl): string {
        const selectedValue = (formControl && (formControl.value !== undefined)) ? formControl.value : '';

        return this.GetDropdownDisplayValue(formControl, selectedValue);
    }

    public GetDropdownDisplayValueForControlAndValue(propertyName: string, selectedValue: any): string {
        return this.GetDropdownDisplayValue(this.get(propertyName) as EntryFormControl, selectedValue);
    }

    public GetDropdownDisplayValue(formControl: EntryFormControl, selectedValue: any): string {
        if (!formControl)
            return '';

        if ((selectedValue === null) || (selectedValue === undefined) || (selectedValue === ''))
            return '';

        //  Use this method to get the DropdownItems which will handle dropdowns not set when readonly
        const dropdownItems = this.GetDropdownItemsForFormControl(formControl);
        const selectedItem = dropdownItems.find(i => i.Value === selectedValue);
        if (!selectedItem)
            return '';

        return selectedItem.Name;
    }

    public GetDropdownValueForName(formControl: EntryFormControl, name: any): string {
        if (!formControl)
            return null;

        if ((name === null) || (name === undefined) || (name === ''))
            return '';

        //  Use this method to get the DropdownItems which will handle dropdowns not set when readonly
        const dropdownItems = this.GetDropdownItemsForFormControl(formControl);
        const selectedItem = dropdownItems.find(i => i.Name === name);
        if (!selectedItem)
            return null;

        return selectedItem.Value;
    }

    public IsReadOnly(propertyNameOrFormControl: string | EntryFormControl): boolean {
        let formControl: EntryFormControl;

        if (propertyNameOrFormControl instanceof EntryFormControl)
            formControl = propertyNameOrFormControl;
        else
            formControl = this.get(propertyNameOrFormControl) as EntryFormControl;

        if (!formControl || !formControl.FieldConfiguration)
            return true;        //  ???

        return formControl.FieldConfiguration.ReadOnly;
    }

    protected DisableProperties(propertyList: string[], disable: boolean): void {
        //  Never allow changing the disabed state if we're viewing the ticket - all fields will be disabled and
        //  must stay that way.
        if (!this.IsEditing.value)
            return;

        _.forEach(propertyList, prop => {
            //  Don't allow a readonly field to become enabled!  But still need to call DisableControl with the proper
            //  "disable" value so that the validators are updated correctly.
            //  * If we don't do all of this, can lead to toggling a readonly field between disabled & not disabled.
            //      If this is an excavator, a bunch of the excavator fields are readonly - picking a different contact in
            //      the side panel toggles the company type which can then ENABLE the fields that are supposed to be readonly!
            //  * And if we don't always call with the disabled forced to false for a readonly field, the validators
            //      are not set right so a readonly field that is required will always be shown as invalid (i.e. try to "Update"
            //      a FL homeowner ticket - the company name will always be invalid!)
            const formControl = this.get(prop) as EntryFormControl;
            if (formControl && formControl.FieldConfiguration)
                this.DisableControl(formControl, disable || formControl.FieldConfiguration.ReadOnly);
        });
    }

    public DisableControl(control: AbstractControl, disable: boolean): void {
        if (!control || (control.disabled === disable))
            return;

        //  Disabled fields are styled via this css ".mat-form-field-disabled" in TicketDetails.component.scss.
        //  Disabled fields are also ignored by the FormControl validators (so will not be required).
        if (disable) {
            //  Must remove any existing validation errors or they will continue to display!
            //  And must do that *BEFORE* disabling the control or it will not have any effect.
            control.setErrors(null);
            control.disable({ onlySelf: true, emitEvent: false });
        } else {
            control.enable({ onlySelf: true, emitEvent: false });

            //  After enabling, also need to re-evaluate the field so that any validation errors get displayed correctly.
            //  But only markAsTouched if we're already showing validation errors
            if (this.ShowValidationErrors.value)
                control.markAsTouched({ onlySelf: true });      //  this causes validation error to be displayed

            //  Added "emitEvent:false" on 4/27/2020 because if we allow the event to fire, it will emit a valueChanges
            //  event even though the value didn't change!  That causes issues with the NY event handlers for OnWorkTypeChanged:
            //  Trying to change from Demolition ticket type (which forced DEMOLITION to be set in to Work Type) triggers a change
            //  on Work Type which then sees that Work Type = DEMOLITION which then sets the Ticket Type back to Demolition!
            //  That then caused validation errors to not always show so also needed to change TicketEntryFieldWrapper to call
            //  CheckIfInvalid() when the TicketEntryFormGroup.ShowValidationErrors changes.  Which is a more reliable way to make
            //  sure the form controls all update themselves when they should.
            control.updateValueAndValidity({ emitEvent: false });
        }
    }

    /**
     * Set property validation errors into the cooresponding form controls.  Returns a list of Property Names
     * that have validation errors.
     * @param validationErrors
     */
    public SetPropertyValidationErrors(validationErrors: PropertyValidationErrorResponse[]): string[] {
        if (!validationErrors)
            return null;

        const propertyNames: string[] = [];

        validationErrors.forEach(err => {
            const control = this.get(err.PropertyName) as EntryFormControl;

            //  The control may not be found if it's not on the main form (such as a subcontractor field)
            if (control)
                control.SetValidationError(EntryFieldValidationErrorSourceEnum.Server, err.ShortMessage);

            propertyNames.push(err.PropertyName);
        });

        return propertyNames;
    }

    /**
     * Sets a property value for a ticket field without emitting a change event.
     * @param propertyName
     * @param propertyValue
     */
    public UpdateSinglePropertyValue(propertyName: string, propertyValue: any): void {
        const formControl = this.get(propertyName) as EntryFormControl;
        if (!formControl)
            return;

        formControl.patchValue(propertyValue, { emitEvent: false });

        //  Any time we set a property value, also clear out any ticket validation errors (i.e. server or client added errors).
        formControl.ClearAllValidationErrors();
    }

    protected SetupShortcuts(): void {
    }

    /**
     * Called when a Shortcut key is triggered in a ticket entry field.
     * Should return true if the key has been handled (and should be ignored from input).
     * This is called by EnterField directive.
     * @param propertyName
     * @param key
     * @param formControl
     */
    public OnShortcutKey(propertyName: string, key: string, formControl: EntryFormControl): boolean {
        return false;
    }

    public IsFormControlVisible(propertyNameOrFormControl: string | EntryFormControl): boolean {
        let formControl: EntryFormControl;

        if (propertyNameOrFormControl instanceof EntryFormControl)
            formControl = propertyNameOrFormControl;
        else
            formControl = this.get(propertyNameOrFormControl) as EntryFormControl;
        if (!formControl)
            return false;

        return !formControl.FieldConfiguration || (formControl.FieldConfiguration.Hidden !== true);
    }

    public IsOneOfFormControlsVisible(propertyNameList: string[]): boolean {
        return _.some(propertyNameList, propertyName => this.IsFormControlVisible(propertyName));
    }
}
