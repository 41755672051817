<mat-chip-list #chipList>
    <ng-container *ngFor="let value of values">
        <!-- set HideInUI to true in the SearchFilterValue if the filter is required/auto configured and the
        user should not see it or modify it. -->
        <mat-chip *ngIf="!value.HideInUI" [selectable]="true" [removable]="!isDisabled && (allowEmpty || values.length > 1)" (removed)="remove(value)" [disabled]="isDisabled">
            {{value.DisplayValue}}
            <mat-icon matChipRemove *ngIf="!isDisabled && (allowEmpty || values.length > 1)">cancel</mat-icon>
        </mat-chip>
    </ng-container>
</mat-chip-list>
<mat-form-field style="padding-top: 10px; min-width: 300px;">
    <input placeholder="Search Text..." UpperCase [disabled]="isDisabled" matInput style="width:100%" autocomplete="off" type="search"
           [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
           (matChipInputTokenEnd)="add($event)" iqAutoFocus>
    <mat-hint>
        <ng-container *ngIf="minChars && minChars > 0; else addToFilterHintText">
            Min {{minChars}} characters required
        </ng-container>
    </mat-hint>
</mat-form-field>
<div *ngIf="minChars && minChars > 0" class="iq-caption gray-primary-text">
    <ng-content *ngTemplateOutlet="addToFilterHintText"></ng-content>
</div>

<ng-template #addToFilterHintText>
    {{addTextToFilterHintText}}
</ng-template>
