import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { JSErrorRequest } from 'Models/System/JSErrorRequest.model';
import moment, { Moment } from 'moment';
import { SettingsService } from 'Services/SettingsService';

//  https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c
//  https://netbasal.com/angular-2-custom-exception-handler-1bcbc45c3230

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler {
    private _LastErrorMessage: string;
    private _LastErrorStack: string;
    private _LastErrorDate: Moment;

    constructor(private injector: Injector) {
        super();
    }

    public handleError(error): void {
        if ((navigator as any).IsUnsupportedEdgeBrowser) {
            //  This is an unsupported version of Edge (not Chrome Edge).  Which is throwing some kind of SyntaxError from different parts of the
            //  app and not giving any information about where.  Started happening with Angular 13 so could be something in Angular.
            //  MS has cut off support for it.  So these users are on their own.  But don't send these logs to our server because...we don't care...
            if ((error.message === "SyntaxError") || (error.message === "InternalError")) {
                console.error(error);
                console.error("This version of Edge is not supported - error is being ignored");
                return;
            }
        }

        //  If the error is a chunk load failed error, we need to reload the page.
        //  This happens if a module is fetched from the browser cache that is outdated (and thus
        //  references modules that no longer exist).  Reloading the page may not be the most graceful
        //  way to hande this, but it gets the app up and running correctly.
        //  Error looks like "Uncaught (in promise): ChunkLoadError: Loading chunk 15 failed."
        //  https://medium.com/@kamrankhatti/angular-lazy-routes-loading-chunk-failed-42b16c22a377
        //  Seems like the error may have changed (doesn't have numeric in the 'Loading chunk [] failed' part) so also just
        //  looking for the string "ChunkLoadError: Loading chunk"
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        const errMessage = error.message ?? error;
        if (chunkFailedMessage.test(errMessage) || (errMessage.indexOf("ChunkLoadError: Loading chunk") !== -1)) {
            window.location.reload();
            return;
        }

        //  There is a bug in nGrid when using rxjs7: https://github.com/shlomiassaf/ngrid/pull/238
        //  Ignore those errors
        if ((errMessage.indexOf("Cannot read properties of undefined (reading 'prev')") != -1)) {
            console.warn("Ignoring error caused by known issue with nGrid:", errMessage);
            return;
        }

        this.SendErrorToServer(error, this.injector.get(HttpClient), this.injector.get(SettingsService));

        //  Rethrow the error or it will get swallowed and cause the error to be handled over and over again in an infite loop!
        console.error(error);
        throw error;
    }

    public SendErrorToServer(error: any, httpClient: HttpClient, settingsService: SettingsService): void {
        //  Ignore http errors - those are handled in the ApiInterceptor.  Could probably handle them here if we want
        //  to centralize all error handling in 1 place...
        //  For now though, we only want the ones that we need to log.
        if (error instanceof HttpErrorResponse)
            return;

        //  Note: Looked at using stacktrace.js (which was mentioned in one of the links above) to get more
        //  info about the stack trace.  But it doesn't actually give anything else that's not already there.
        //  And it messes up the line numbers!
        //  If want to try, just add the package ("yarn add stacktrace-js") then add import like this: import * as StackTrace from 'stacktrace-js';
        //  Then this command will use it to get the stackframes:
        //StackTrace.fromError(error, { offline: true }).then(stackframes => {
        //    console.log("stackframes=", stackframes);
        //});

        if (error.message
            && ((error.message.indexOf("Failed to execute 'appendChild' on 'Node'") >= 0) || (error.message.indexOf("The operation would yield an incorrect node tree") >= 0))
            && error.stack && (error.stack.indexOf("_adjustParentForFullscreenChange") >= 0))
        {
            //  This error is caused if we are showing a dialog and then a component inside the dialog makes itself fullscreen.
            //  Happens on the Verify Location dialog if the user clicks the fullscreen button on the map.
            //  There doesn't seem to be any way to prevent the error but everything also works perfectly fine.
            //  The closest known issue in Angular is: https://github.com/angular/components/issues/10679
            //  but not sure that's entirely related other than a comment at the bottom showing the exact error we see.
            //  So seems to be safe to just ignore the error so it doesn't get logged on the server.
            console.warn("Ignoring error caused by fullscreen change");
            return;
        }

        if ((window.location.href.indexOf("/googleResponse") > 0) && error.message
            && (
                (error.message.indexOf("_handleAuthResponse") > 0)
                || (error.message.indexOf("Cannot read properties of undefined (reading 'accessToken')") > 0)
                || (error.message.indexOf("Cannot read property 'accessToken' of undefined") > 0)
                || ((error.message.indexOf("undefined is not an object") > 0) && (error.message.indexOf("accessToken") > 0))
            )) {
            //  Amplify has issues with it's error handling during a Google login.  Do not log it.  We show the user a warning
            //  on the Pages\Authentication\GoogleResponsePage.  It's usually because the user does not have a first + last name.
            return;
        }

        if (error.message && (error.message.indexOf("null is not an object (evaluating ") >= 0) && (error.message.indexOf(".canvas')") >= 0)) {
            //  This is happening on only Safari/Mac.  It's something inside OpenLayers that we can't do anything about.
            //  It's not causing any issues for the client so just ignore it.
            return;
        }

        if (error.message && (error.message.indexOf("The quota has been exceeded") >= 0)) {
            //  Happens if local storage is full.  This is a client browser issue.  And Safari/iOS has a low limit.
            //  AwsAmpliphy has issues handling it correctly but we trap it where we can and show a more meaningful error.
            return;
        }

        //  Don't send repeat error messages.  An error in the html template could cause this to spam the server.
        if (this.IsDuplicateError(error))
            return;

        GlobalErrorHandler.LogError(error, httpClient, settingsService);
    }

    private IsDuplicateError(error: any): boolean {
        const now = moment();
        const errorMessage = error.message ? error.message : JSON.stringify(error);

        if (this._LastErrorDate && this._LastErrorMessage) {
            if ((moment(this._LastErrorDate).add(5, "minutes") > now) && (this._LastErrorMessage === errorMessage))
                return true;
        }

        this._LastErrorDate = now;
        this._LastErrorMessage = errorMessage;
        this._LastErrorStack = error.stack;
        return false;
    }

    public static LogError(error: any, httpClient: HttpClient, settingsService: SettingsService): void {
        //  If the error does not contain the message or stack properties, it was probably created with
        //      throw "boom!"
        //  ** DO NOT USE THAT FORM OF THROW, do this:
        //      throw new Error("boom!");
        //  That will give the proper stack trace so we know where it came from.
        const request = new JSErrorRequest(window.location.href, error.message ? error.message : JSON.stringify(error), error.stack);

        httpClient.post(settingsService.ApiBaseUrl + "/System/Logging/JSError", request)
            .subscribe(response => console.log("Sent log report to server"));
    }

}
