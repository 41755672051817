import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TicketActionEnum } from 'Enums/TicketAction.enum';
import { TicketFunctionActionEnum } from 'Enums/TicketFunctionAction.enum';
import { TicketStatusEnum } from 'Enums/TicketStatus.enum';
import { TicketFunction } from 'Models/Configuration/TicketFunction.model';
import { CancelTicketRequest } from 'Models/Tickets/CancelTicketRequest.model';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketEntryAllowedTicketActions } from 'Models/Tickets/TicketEntryAllowedTicketActions';
import { TicketEntryResponse } from 'Models/Tickets/TicketEntryResponse.model';
import { TicketFunctionDialogData } from 'Models/Tickets/TicketFunctionDialogData.model';
import { VoidTicketRequest } from "Models/Tickets/VoidTicketRequest.model";
import { Observable, of } from "rxjs";
import { take } from 'rxjs/operators';
import { ComponentLookupRegistry } from 'Services/ComponentLookup.service';
import { SettingsService } from 'Services/SettingsService';
import { ConfirmationDialogComponent } from 'Shared/Components/Controls/Dialog/Confirmation/ConfirmationDialog.component';
import { InformationDialogComponent } from 'Shared/Components/Controls/Dialog/Information/InformationDialog.component';
import { DialogModel } from 'Shared/Components/Controls/Dialog/Models/Dialog.model';
import { ConfirmReleaseSuspendedTicketDialogComponent } from '../Details/Components/ConfirmReleaseSuspendedTicketDialog/ConfirmReleaseSuspendedTicketDialog.component';
import { ConfirmVoidTicketDialogComponent, ConfirmVoidTicketDialogResponse } from '../Details/Components/ConfirmVoidTicketDialog/ConfirmVoidTicketDialog.component';
import { TicketEntryFormGroup } from '../Details/Components/InputControls/TicketEntryFormGroup';
import { TicketCancelDialogComponent } from '../Details/Components/TicketCancelDialog/TicketCancelDialog.component';
import { TicketCanceledConfirmationDialogComponent } from '../Details/Components/TicketCancelDialog/TicketCanceledConfirmation/TicketCanceledConfirmationDialog.component';
import { TicketService } from './TicketService';

//  Need this scoped to the TicketsModule because it uses a dialog (to cancel tickets) that is in this module.
//  If we use "angular providedIn: 'root'", it will fail to load TicketCancelDialogComponent
//  See: https://github.com/angular/components/issues/8473#issuecomment-415237247
//  This service has no state anyway so we don't need a singleton of it...
@Injectable()
export class TicketActionsService {
    private _FormIsBusy: boolean = false;

    constructor(private _HttpClient: HttpClient, private _SettingsService: SettingsService, private _Dialog: MatDialog,
        private _Router: Router, private _TicketService: TicketService) {
    }

    public GetAllowedTicketActionsForTicket(ticketID: string): Observable<TicketEntryAllowedTicketActions> {
        return this._HttpClient.get<TicketEntryAllowedTicketActions>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/AllowedTicketActions/" + ticketID);
    }

    //  Can be Ticket.ID or Ticket.TicketNumber.
    //  If TicketNumber, will view the most recent version.
    public ViewTicket(idOrTicketNumber: string): void {
        //  The api controller handles a TicketID or a TicketNumber (which will find the most recent version)
        this._Router.navigate(["/tickets/view/" + idOrTicketNumber]);
    }

    public ViewMostRecent(idOrTicketNumber: string): void {
        //  The api controller handles a TicketID or a TicketNumber (which will find the most recent version)
        this._Router.navigate(["/tickets/mostrecent/" + idOrTicketNumber]);
    }

    public DoTicketFunction(ticketNumber: string, ticketFunction: TicketFunction, fromTicketDetails: boolean,
        onTicketSaved: () => void, ticketEntryForm: TicketEntryFormGroup): void
    {
        switch (ticketFunction.Action) {
            case TicketFunctionActionEnum.Edit:
                if (!this.StartTicketFunctionDialogComponent(ticketNumber, ticketFunction, fromTicketDetails, onTicketSaved, ticketEntryForm))
                    this._Router.navigate(["/tickets/edit/" + ticketNumber + "/" + ticketFunction.ID]);
                break;
            case TicketFunctionActionEnum.Cancel:
                //  If coming from ticket list and !RequireViewTicket, navigate to "dialogedit" route to show the ticket.
                //  That will then initiate the ticket edit from the ticket details page (so the user can see the ticket behind the dialog).
                if (fromTicketDetails || !ticketFunction.RequireViewTicket)
                    this.ConfirmCancelTicket(ticketNumber, ticketFunction);
                else
                    this.ViewTicketAndStartTicketEditDialog(ticketNumber, ticketFunction);
                break;
            case TicketFunctionActionEnum.AddComments:
                //  This (at least currently) requires a TicketFunction DialogComponent.
                if (!this.StartTicketFunctionDialogComponent(ticketNumber, ticketFunction, fromTicketDetails, onTicketSaved, ticketEntryForm))
                    throw new Error("Ticket Function dialog component not registered for ticket function: " + ticketFunction.Name);
                break;
            default:
                throw new Error("Unhandled TicketFunctionAction");
        }
    }

    private StartTicketFunctionDialogComponent(ticketNumber: string, ticketFunction: TicketFunction, fromTicketDetails: boolean,
        onTicketSaved: () => void, ticketEntryForm: TicketEntryFormGroup): boolean
    {
        const classRef = this.GetTicketFunctionDialogComponent(ticketFunction);
        if (!classRef)
            return false;

        //  If coming from ticket list and !RequireViewTicket, navigate to "dialogedit" route to show the ticket.
        //  That will then initiate the ticket edit from the ticket details page (so the user can see the ticket behind the dialog).
        if (fromTicketDetails || !ticketFunction.RequireViewTicket)
            this.OpenTicketFunctionDialog(classRef, ticketNumber, ticketFunction, fromTicketDetails, onTicketSaved, ticketEntryForm);
        else
            this.ViewTicketAndStartTicketEditDialog(ticketNumber, ticketFunction);

        return true;
    }

    private ViewTicketAndStartTicketEditDialog(ticketNumber: string, ticketFunction: TicketFunction): void {
        //  Navigates to the view ticket page and passes the ticketFunction in the "state".  The TicketRouteResolver can then read it
        //  and use it to start the ticket edit dialog.  Done this way (as opposed to creating a dedicated route) so that the
        //  URL is still the "view ticket" URL so refreshing does not start it again.  Do not want that to happen in case the
        //  user already did it and saved the edit.
        this._Router.navigate(["/tickets/view/" + ticketNumber], { state: { 'TicketFunction': ticketFunction } });
    }

    /**
     * Dynamically finds a Dialog Component and returns the type if it finds it.  To register a component,
     * decorate it with @ComponentLookup("key") with the key formatted as [occCode]-TicketFunction-[ticket function name]
     * @param ticketFunction
     */
    private GetTicketFunctionDialogComponent(ticketFunction: TicketFunction): any {
        const componentKey = this._SettingsService.CurrentOneCallCenterCode + "-TicketFunction-" + ticketFunction.Name;
        const classRef = ComponentLookupRegistry.get(componentKey);
        return classRef;
    }

    private OpenTicketFunctionDialog(classRef: any, ticketNumber: string, ticketFunction: TicketFunction,
        fromTicketDetails: boolean, onTicketSaved: () => void, ticketEntryForm: TicketEntryFormGroup): void
    {
        //  Don't allow if already busy - can happen if the network is really slow and user repeatedly clicks on the button!
        if (this._FormIsBusy)
            return;
        this._FormIsBusy = true;

        this._TicketService.StartTicketEdit(ticketNumber, ticketFunction.ID)
            .subscribe(response => {
                //  End this now (don't wait until dialog closes) because we don't really know what the dialog is going to do.
                //  For all we know, it could call back in to something here to save that is going to re-check _FormIsBusy...
                //  The dialog is opening so that should block the user from clicking on something else (or if they click outside, will
                //  cause dialog to immediately close and they will have to start over).
                this._FormIsBusy = false;

                if (!response?.StartEdit)
                    return;     //  Dig Site Rules were an Error or user chose to not continue

                this._Dialog
                    .open(classRef, {
                        data: new TicketFunctionDialogData(response, fromTicketDetails, onTicketSaved, ticketEntryForm),
                        //  If this creates a smaller dialog than needed, add width to the content and the dialog will expand to fit
                    }).afterClosed().pipe(take(1)).subscribe(dialogResult => {
                        //  If false or undefined returned, the user canceled/ESC'd the dialog.  So need to unlock the ticket.
                        if (!dialogResult)
                            this._TicketService.AbandonTicketEdit(response.Ticket.ID);
                    })
            }, () => this._FormIsBusy = false);
    }

    public ConfirmCancelTicket(ticketNumber: string, ticketFunction: TicketFunction): void {
        //  Don't allow if already busy - can happen if the network is really slow and user repeatedly clicks on the button!
        if (this._FormIsBusy)
            return;
        this._FormIsBusy = true;

        //  Start a ticket edit so that we immediately do all of the checks to see if we can (still) do the cancel - because
        //  that could change if the user was sitting on the page for a while.  Also locks the ticket so another user can't
        //  start an edit while we are canceling.
        this._TicketService.StartTicketEdit(ticketNumber, ticketFunction.ID)
            .subscribe(editResponse => {
                this._Dialog
                    .open(TicketCancelDialogComponent, {
                        data: {
                            TicketNumber: ticketNumber
                        },
                        minWidth: "55em",       // Needed to make the textarea row/col limit for DigSafe (75 chars/per row - all "A"'s need this width)
                    })
                    .afterClosed()
                    .subscribe(data => {
                        if (data) {
                            this.CancelTicket(data.TicketNumber, data.Reason)
                                .subscribe(cancelResponse => {
                                    this._FormIsBusy = false;
                                    if (cancelResponse) {
                                        //  Shows "ticket canceled" message and allows sending a copy to the excavator
                                        this._Dialog.open(TicketCanceledConfirmationDialogComponent, { data: cancelResponse.Ticket });
                                    }
                                }, () => this._FormIsBusy = false);
                        } else {
                            this._FormIsBusy = false;
                            this._TicketService.AbandonTicketEdit(editResponse.Ticket.ID);
                        }
                    });
            }, () => this._FormIsBusy = false);
    }

    private CancelTicket(ticketNumber: string, reason: string): Observable<TicketEntryResponse> {
        if (!ticketNumber || !reason)
            return of(null);

        return new Observable<TicketEntryResponse>(observer => {
            const request = new CancelTicketRequest(ticketNumber, reason);

            this._HttpClient.post<TicketEntryResponse>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/CancelTicket", request)
                .subscribe(response => {
                    this._TicketService.OnTicketEntryResponseReceived(response);
                    this._Router.navigate(["/tickets/view/" + response.Ticket.ID]);
                    observer.next(response);
                    observer.complete();
                }, err => {
                    observer.next(null);
                    observer.complete();
                });
        });
    }

    public EditOrResumeTicket(ticketNumber: string): void {
        this._Router.navigate(["/tickets/edit/" + ticketNumber]);
    }

    public CopyTicket(ticketNumber: string): void {
        this._Router.navigate(["/tickets/copy/" + ticketNumber]);
    }

    public ConfirmReleaseSuspendedTicket(ticket: Ticket): void {
        //  This dialog calls the ReleaseSuspendedTicket api directly because it then also needs to call SendTicket (to send excavator email).
        //  And they need to be called in sequence with SendTicket only being called if the ticket is successfully completed.
        this._Dialog
            .open(ConfirmReleaseSuspendedTicketDialogComponent, {
                data: {
                    Ticket: ticket
                },
                disableClose: true
            }).afterClosed().subscribe((val) => {
                if (val) {
                    //  Suspended ticket was successfully completed
                    this._Router.navigate(["/tickets/view/" + ticket.ID]);
                }
            });
    }

    public ConfirmVoidTicket(ticketID: string): void {
        this._Dialog.open(ConfirmVoidTicketDialogComponent).afterClosed()
            .subscribe((result: ConfirmVoidTicketDialogResponse) => {
                if (result?.Discard) {
                    const request = new VoidTicketRequest(ticketID, result.SendVoidedNotification);
                    this._HttpClient.post<TicketEntryResponse>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/VoidTicket", request)
                        .subscribe(response => {
                            if (response) {
                                this._TicketService.OnTicketEntryResponseReceived(response);
                                this._Router.navigate(["/tickets/view/" + response.Ticket.ID]);
                            }
                        });
                }
            });
    }

    public UnlockTicket(ticketID: string): Observable<TicketEntryAllowedTicketActions> {
        return this._HttpClient.put<TicketEntryAllowedTicketActions>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/UnlockTicket/" + ticketID, null);
    }

    public QATicket(ticketID: string): Observable<any> {
        return this._HttpClient.put<any>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/QATicket/" + ticketID, null);
    }

    public SetQAFlaggedForReview(ticketID: string, flaggedForReview: boolean): Observable<any> {
        return this._HttpClient.put<any>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/QAFlagForReview/" + ticketID + "/" + flaggedForReview, null);
    }

    public MarkWorkCompleted(ticketID: string, workComplete: boolean): Observable<any> {
        return this._HttpClient.put<any>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/MarkComplete/" + ticketID + "/" + workComplete, null);
    }

    //  This is called when editing a ticket (by enumerating over the dropdown items for the Status field).
    //  These always go through the full save process (with Incomplete & Void skipping some things but still calling the normal SaveTicket)
    public SaveWithStatus(ticketEntryForm: TicketEntryFormGroup, status: TicketStatusEnum): void {
        //  This keeps the Save links disabled while we process the action.  Some of them (or at least
        //  saving - and fetching affected service areas) may take longer than the double-click guard.
        //  This keeps the user from being able to click the link again which can cause us to create
        //  multiple affected service area dialogs!
        if (this._FormIsBusy)
            return;
        this._FormIsBusy = true;

        switch (status) {
            case TicketStatusEnum.Released:
                this.TriggerSaveWithStatus(ticketEntryForm, status);     //  No confirmation prompt needed - the complete process handles all that
                break;
            case TicketStatusEnum.Incomplete: {
                const incompleteMessage = this._SettingsService.IncompleteWarningMessage();
                this.ShowConfirmStatusDialog(ticketEntryForm, "Incomplete Ticket?", incompleteMessage, "Incomplete",
                    "Would you still like to save this as an Incomplete Ticket?", status);
                break;
            }
            case TicketStatusEnum.Suspended:
                this.ShowConfirmStatusDialog(ticketEntryForm, "Suspend Ticket?",
                    "<span style='color:red'>This will save the Ticket but not send it to the affected Service Areas until it has been reviewed by the One Call Center.</span>",
                    "Suspend", "Would you still like to save this as a Suspended Ticket?", status);
                break;
            case TicketStatusEnum.Void:
                this.ShowConfirmVoidTicket(ticketEntryForm);
                break;
        }
    }

    private ShowConfirmStatusDialog(ticketEntryForm: TicketEntryFormGroup, title: string, message: string, actionText: string,
                                    confirmText: string, status: TicketStatusEnum): void {
        this._Dialog
            .open(ConfirmationDialogComponent, {
                data: new DialogModel(title, message, actionText, confirmText),
                disableClose: true
            }).afterClosed().subscribe((val) => {
                if (val)
                    this.TriggerSaveWithStatus(ticketEntryForm, status);
                else
                    this._FormIsBusy = false;
            });
    }

    private ShowConfirmVoidTicket(ticketEntryForm: TicketEntryFormGroup): void {
        this._Dialog.open(ConfirmVoidTicketDialogComponent).afterClosed()
            .subscribe((result: ConfirmVoidTicketDialogResponse) => {
                if (result?.Discard) {
                    ticketEntryForm.get("Status").setValue(TicketStatusEnum.Void);
                    //  TicketEntryFormBase.OnTicketAction() expects an object here with Reason and SendVoidedNotification properties
                    this._TicketService.TriggerAction(TicketActionEnum.SaveIncompleteOrVoid, result, () => this.OnSaveActionAcknowledgedOrCompleted());
                }
                else
                    this._FormIsBusy = false;
            });
    }

    private TriggerSaveWithStatus(ticketEntryForm: TicketEntryFormGroup, status: TicketStatusEnum): void {
        ticketEntryForm.get("Status").setValue(status);
        this._TicketService.TriggerAction(TicketActionEnum.SaveTicket, null, () => this.OnSaveActionAcknowledgedOrCompleted());
    }

    //  Called after we have triggered TicketService.TriggerAction and the action has been processed enough
    //  to either have completed or shown a dialog.  Used to know when it's ok to re-enable the links on the form
    //  to prevent the user from being able to trigger them multiple times.  Even though there is a double click
    //  guard on them, if we show a dialog (such as the affected service area dialog) that can take more than the
    //  timeout used by the click guard, the user can (and *DOES*) click on the link again.  That can cause us
    //  to create multiple affected service area dialogs.  Which then allows the user to save the same ticket
    //  multiple times.
    private OnSaveActionAcknowledgedOrCompleted(): void {
        this._FormIsBusy = false;
    }

    public DiscardTicket(): void {
        //  Go "back" to the previous viewable ticket.  We track that in TicketService when we initiate the different routes
        //  that could be used as a "viewable" Ticket.ID or Ticket.Number.  The navigation will trigger the route guard that
        //  will prompt the user about discarding changes.  If user confirms, they will be navigated to this ticket.
        //  We can just do "window.history.go(-1)" because if the user did something like a new ticket followed by
        //  "create another", going back will leave them on "new" again.  In that situation, this method will leave them on
        //  the ticket that was last saved.
        //window.history.go(-1);

        if (this._TicketService.LastViewableTicketIDorNumber)
            this._Router.navigate(["/tickets/view/" + this._TicketService.LastViewableTicketIDorNumber])
        else
            this._Router.navigate(["/tickets"]);        //  If no viewable last ticket, will go to default ticket page
    }

    public static LockedByMessage(allowedActions: TicketEntryAllowedTicketActions): string {
        if (!allowedActions.LockedByAnotherUser)
            return null;

        if (allowedActions.LockedByUserName && allowedActions.LockedByUserName !== "")
            return "* LOCKED BY " + allowedActions.LockedByUserName + " *";

        return "* LOCKED *";
    }
}
