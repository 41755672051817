<div fxLayout="column">
    <mat-radio-button [value]="FilterOptions.None" [checked]="selectedOption === FilterOptions.None" [disabled]="isDisabled || !allowEmpty" color="primary" (change)="selectedOptionChange($event)" name="AgentRadioGroup">All</mat-radio-button>
    <mat-radio-button [value]="FilterOptions.Self" [checked]="selectedOption === FilterOptions.Self" [disabled]="isDisabled" color="primary" (change)="selectedOptionChange($event)" name="AgentRadioGroup">Myself</mat-radio-button>
    <mat-radio-button [value]="FilterOptions.SpecificPeople" [checked]="selectedOption === FilterOptions.SpecificPeople" [disabled]="isDisabled" color="primary" (change)="selectedOptionChange($event)" name="AgentRadioGroup">Specific People</mat-radio-button>

    <!--Has to be outside of the mat-radio-group or it will clear out the selected values if you use the mouse to select them..Don't know why..So if we add another one we need to get rid of the group tag and just use the name properties and fix up the .ts to se the 'selectedOption' properly-->
    <mat-chip-list #autoChipList>
        <mat-chip *ngFor="let value of autoCompleteValues" [selectable]="true" [removable]="!isDisabled && (allowEmpty || autoCompleteValues.length > 1)" [disabled]="selectedOption != FilterOptions.SpecificPeople || isDisabled" (removed)="removeAutoComplete(value)" (keydown.delete)="removeAutoComplete(value)">
            {{value.DisplayValue}}
            <mat-icon matChipRemove *ngIf="selectedOption === FilterOptions.SpecificPeople && !isDisabled && (allowEmpty || autoCompleteValues.length > 1)">cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>
    <mat-form-field style="padding-top: 10px; min-width: 300px;" (click)="inputClicked(FilterOptions.SpecificPeople)">
        <input iqDisableAutofill type="text" placeholder="Search people..." UpperCase [formControl]="autoCompleteControl" [matAutocomplete]="auto" matInput [iqFocus]="autocompleteFocusEvent" />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="AutoCompleteResultSelected($event)" autoActiveFirstOption>
            <mat-option *ngFor="let option of autoCompleteControl.Results | async" [value]="option">
                {{option.Name}}
            </mat-option>
        </mat-autocomplete>
        <mat-hint>Select a result to add it to the filter</mat-hint>
    </mat-form-field>

    <mat-radio-button [value]="FilterOptions.NameContains" [checked]="selectedOption === FilterOptions.NameContains" [disabled]="isDisabled" color="primary" (change)="selectedOptionChange($event)" name="AgentRadioGroup">Name Contains</mat-radio-button>
    <mat-chip-list>
        <mat-chip *ngFor="let value of values" [selectable]="true" [removable]="!isDisabled && (allowEmpty || values.length > 1)" [disabled]="selectedOption != FilterOptions.NameContains || isDisabled" (removed)="remove(value)">
            {{value.DisplayValue}}
            <mat-icon matChipRemove *ngIf="selectedOption === FilterOptions.NameContains && !isDisabled && (allowEmpty || values.length > 1)">cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>
    <mat-form-field style="padding-top: 10px; min-width: 300px;" (click)="inputClicked(FilterOptions.NameContains)">
        <input iqDisableAutofill placeholder="Search Text..." UpperCase [formControl]="containsControl" matInput [iqFocus]="containsFocusEvent"
               (keydown.enter)="add()" autocomplete="off" type="search"><!--(keydown.tab)="add()"-->
        <mat-hint>{{addTextToFilterHintText}}</mat-hint>
    </mat-form-field>
</div>
