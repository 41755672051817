//  This is not working on the phone routes.  I'm sure I broke something when I refactored it all in 1/2023
//  which involved removing all of the phone specific routes (which are partly what tells angular how to do the route animations).
//  But even putting the animations directly on the phone components does not work as Angular says they should.
//  These guides show how to do it.  And the router outlet configs it talks about are still in the PhoneRootComponent
//  (which were originally in app.component).
//  The animations are not terribly important so not worrying about it.  Flyouts and MatTabs still animate.
//  https://medium.com/ngconf/animating-angular-route-transitions-ef02b871cc30
//  https://medium.com/angular-in-depth/routing-animation-in-angular-d9d4ff663e88
import { trigger, state, style, transition, animate, query, animateChild, group } from '@angular/animations';

//  To get a component flyout to animate correctly:
//  1) Put this in the component css: :host { height: 100 %; position: fixed; }
//     "position" was important in a couple of the phone fly-outs (but not all) for some reason.
//  2) Define the animation in the components.ts like this:
//          @HostBinding('@slideInFromRight') animation = 'enter';
//     Using this method instead of defining in the .html template causes the close animation to execute automatically
//     any time the overlay is detached (so there is no need for a timeout delay when closing).

export const slideInFromRight = trigger('slideInFromRight', [
    state('void, exit', style({ transform: 'none' })),//, display: 'none'
    state('enter', style({ transform: 'none' })),
    transition('* => enter', [
        style({ transform: 'translateX(100%)' }),
        animate('350ms ease-in', style({ transform: 'translateX(0%)' }))
    ]),
    transition('* => void, * => exit', [
        animate('350ms ease-out', style({ transform: 'translateX(100%)' }))
    ])
]);

export const slideInFromLeft = trigger('slideInFromLeft', [
    state('void, exit', style({ transform: 'none' })),//, display: 'none'
    state('enter', style({ transform: 'none' })),
    transition('* => enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('350ms ease-in', style({ transform: 'translateX(0%)' }))
    ]),
    transition('* => void, * => exit', [
        animate('350ms ease-out', style({ transform: 'translateX(-100%)' }))
    ])
]);

export const slideInFromBottom = trigger('slideInFromBottom', [
    state('void, exit', style({ transform: 'none', display: 'none' })),
    state('enter', style({ transform: 'none', 'opacity': '1' })),
    transition('* => enter', [
        style({ transform: 'translateY(50%)' }),//Start with this style
        animate('350ms ease-in-out', style({ transform: 'translateY(0%)', 'opacity': '.5' }))
    ]),
    //Do no animation on exit.  Just go right to not showing it
    //transition('enter => void, enter => exit', [
    //    //style({ transform: 'translateY(10%)', 'max-height': '10%' }),
    //    //animate('125ms linear', style({ transform: 'translateY(10%)', 'opacity': '.5', 'max-height': '10px' }))
    //    //animate('750ms linear', style({ transform: 'translateY(10%)', 'opacity': '.5' }))
    //])
]);

export const slideInFromTop = trigger('slideInFromTop', [
    state('void, exit', style({ transform: 'none', display: 'none' })),
    state('enter', style({ transform: 'none', 'opacity': '1' })),
    transition('* => enter', [
        style({ transform: 'translateY(-50%)', display: '' }),//Start at this style
        animate('350ms ease-in-out', style({ transform: 'translateY(0%)', 'opacity': '.5' }))
    ]),
    //Do no animation on exit.  Just go right to not showing it
    //transition('enter => void, enter => exit', [
    //    //style({ transform: 'translateY(-10%)', 'max-height': '-10%' }),
    //    //animate('125ms linear', style({ transform: 'translateY(-10%)', 'opacity': '.5', 'max-height': '10px' }))
    //    //animate('750ms linear', style({ transform: 'translateY(-10%)', 'opacity': '.5' }))
    //])
]);

export const fadeInAnimation = trigger('enterTrigger', [
    state('fadeIn', style({
        opacity: '1'
    })),

    transition('void => *',
        [style({ opacity: '0' }), animate('700ms')]
    )
]);




export const PhoneAnimationTriggers = {
    DefaultAnimation: 'DefaultAnimation',
    SlideInFromLeft: 'SlideInFromLeft',
    SlideInFromRight: 'SlideInFromRight'
}

export const phoneRouteAnimations =
    trigger('phoneRouteAnimations', [
        transition('SlideInFromLeft => SlideInFromRight', [
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    bottom: '0',
                    left: '0'
                })
            ]),
            query(':enter', [
                style({ left: '100%' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({ right: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ left: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ]),

        transition('SlideInFromRight => SlideInFromLeft', [
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    bottom: '0',
                    left: '0'
                })
            ]),
            query(':enter', [
                style({ right: '100%' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({ left: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ right: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ]),


        //Default transition. If nothing set then the page will fade in
        transition('* => *', [
            style({ opacity: '0' }), animate('700ms')
        ]),
    ]);
