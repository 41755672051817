import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Guid } from '@iqSharedUtils/Guid';
import { QAStatusEnum, QAStatusEnumDescription } from 'Enums/QAStatus.enum';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { IEntity } from 'Models/Interfaces/IEntity.interface';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { SearchFilter, SearchFilterValue } from 'Models/Searching/SearchFilter.model';
import { SearchOrderBy } from 'Models/Searching/SearchOrderBy.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { ToastrService } from 'ngx-toastr';
import { AddPositiveResponseData } from 'Pages/Tickets/Responses/AddPositiveResponse/AddPositiveResponse.component';
import { TicketResponseServiceAreaListDialog, TicketResponseServiceAreaListDialogData } from 'Pages/Tickets/Responses/Dialogs/TicketResponseServiceAreaListDialog/TicketResponseServiceAreaListDialog.component';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { PrintingService } from 'Services/Printing.service';
import { SettingsService } from 'Services/SettingsService';
import { BaseListDisplayPageClass, BaseListDisplayPageClassService } from 'Shared/BaseClasses/BaseListDisplayPage.class';
import { CRUDBaseService } from 'Shared/BaseServices/CRUDBase.service';
import { AddPositiveResponseDialog } from '../../Responses/AddPositiveResponse/Dialog/AddPositiveResponseDialog.component';
import { TicketActionsService } from '../../Services/TicketActions.service';

export enum TicketListListActions {
    AddPositiveResponse = 50,//Need to make sure these don't overlap the base actions on the BaseListDisplayPageClass
    ViewPositiveResponses = 51,
    ViewAllRelated = 52,
    PrintTicketText = 53
}

/**
 *  This class is used as a base class where we need a ticket list.  We have 2 cases where we need to show lists of tickets
 *  from different sources - Tickets and TicketResponses.  Each needs to call their own search api (via either TicketService
 *  or TicketResponseService).  But they both need all of the same "ticket actions" (the ... menu).  So this class handles all
 *  of that as well as the html layout.
 *  ***** A derived class should reference TicketListBase.component.html and TicketListBase.component.scss
 * */
@Directive()
export abstract class DesktopTicketListBase extends BaseListDisplayPageClass {    

    disableFilters: boolean = false;

    //  Set filters here that should be included when doing a search by Ticket Number.  Otherwise, no other filters are included.
    //  That is normally correct except, for example, if the list is only supposed to show the "current" record or something like that.
    //  Not including that filter would cause multiple rows which would not make sense based on the columns being used.
    //  Specifically needed on the Service Area user ticket dashboard for the very first tab (which shows the Current response only);
    public FiltersRequiredForTicketNumberSearch: SearchFilter[];

    constructor(crudService: CRUDBaseService<IEntity>, protected baseServices: BaseListDisplayPageClassService, private _TicketActionsService: TicketActionsService,
        private settingService: SettingsService, private toastr: ToastrService, private _PrintingService: PrintingService) {
        super(crudService, baseServices);
    }

    UpdateSearchFilters() {
        this.SetDefaultOrderAndFilterItems();
        this.filter.filterChange.next(true);
    }

    ClearFilters(fireEvent: boolean = true) {
        if (this.SearchFilter) {
            this.filter.ColumnFilters = this.baseServices.listFilterService.copyFilters(this.SearchFilter);
            this.filter.filterString = null;
            this.filter.filterTextValue = null;

            if (fireEvent)
                this.filter.filterChange.next(true);
        }
        else
            super.ClearFilters(fireEvent);
    }

    ClearSort(fireEvent: boolean = true) {
        
        if (this.SearchOrderBy) {
            this.filter.OrderBy = this.baseServices.listFilterService.copyOrderBys(this.SearchOrderBy);

            if (fireEvent)
                this.filter.filterChange.next(false);
        }
        else
            super.ClearSort(fireEvent);
    }

    minCharsDefaultSearch = this.settingService.TicketNumberSearchRequiredChars;

    defaultOrderBy = [new SearchOrderBy("TakenEndDate", true)];

    //  TODO: This is terrible!  The queries created in TicketDashboardService should just always specify what is required in the
    //  RequiredFilters property of each query!  Changed them to do this for the phone filtering but left this here just in case.
    @Input() requiredFilters: string[] = ["TakenEndDate", "TakenStartDate", "WorkStartDate", "ExpiresDate", "ResponseDueDate"];
        
    protected GetMainFilterPropertiesFilters(value: string): SearchFilter {
        return new SearchFilter("TicketNumber", SearchFilterOperatorEnum.StartsWith, [new SearchFilterValue(value.trim(), value.trim())], false, true);//Trim this since it'a an equals, so a space at begin or end will return nothing
    }

    public ActionList: SelectOption[] = [];
    private _PrevMatMenu: MatMenu = null;

    public MenuOpened(item: any, menu: MatMenu): void {
        this.ActionList = [];

        //  We track the previous MatMenu so that we can force it closed when another menu is opened.  If we don't do this, the user can use
        //  the keyboard to open up multiple menus at once!
        //  1) Open a menu
        //  2) tab twice - this will cause focus to be in the "..." button of the next row
        //  3) Hit the space or enter keys - new menu is opened in addition to the previous.
        //  ** This is also the reason why we are fetching the allowed actions via the (menuOpened) event.  Previously, we were doing that as an async list
        //  on the *ngFor".  But when an additional menu was opened, it caused BOTH menus to alternate refreshing it's menu list which then spammed
        //  the api until it exhausted all available postgres database connections!
        if (this._PrevMatMenu && (this._PrevMatMenu !== menu))
            this._PrevMatMenu.closed.next("click");
        this._PrevMatMenu = menu;

        this._TicketActionsService.GetAllowedTicketActionsForTicket(item.ID).pipe(take(1))
            .subscribe(allowedActions => {
                let list: SelectOption[] = [];

                list.push(new SelectOption({ OnClick: (item: any) => { this._TicketActionsService.ViewTicket(item.ID) } }, "View Ticket"));

                if (item.IsLast === "No")
                    list.push(new SelectOption({ OnClick: (item: any) => { this._TicketActionsService.ViewMostRecent(item.TicketNumber) } }, "View Most Recent Version"));

                if (item.ParentTicketID || (item.IsLast === "No"))
                    list.push(new SelectOption(TicketListListActions.ViewAllRelated, "Find All Related"));

                list.push(null);        //  Makes a horizontal line

                if (allowedActions) {
                    let needDivider = false;
                    if (this.settingService.UsesPositiveResponse) {
                        if (allowedActions.CanAddResponses) {
                            list.push(new SelectOption(TicketListListActions.AddPositiveResponse, "Add Response", "note_add"));
                            needDivider = true;
                        }
                        if (allowedActions.CanViewResponses) {
                            const onClick = (item: any) => {
                                this.services.dialog.open(TicketResponseServiceAreaListDialog, {
                                    data: new TicketResponseServiceAreaListDialogData(allowedActions, item),
                                    width: '90%',
                                    maxWidth: '90%',
                                    height: '90%'
                                });
                            }

                            list.push(new SelectOption({ OnClick: onClick }, "View Responses", "description"));
                            needDivider = true;
                        }
                    }
                    if (allowedActions.CanMarkCompleted) {
                        const onClick = (item: any) => {
                            this._TicketActionsService.MarkWorkCompleted(item.ID, (item.IsWorkComplete === "No"))
                                .subscribe(() => {
                                    //  IsWorkComplete has been toggled.  Update it here in case it's visible.
                                    item.IsWorkComplete = item.IsWorkComplete === "No" ? "Yes" : "No";
                                });
                        };
                        list.push(new SelectOption({ OnClick: onClick }, item.IsWorkComplete === "Yes" ? "Work Not Completed" : "Work Completed", "done"));
                        needDivider = true;
                    }
                    if (needDivider)
                        list.push(null);        //  Makes a horizontal line

                    if (allowedActions.CanUnlock) {
                        const onClick = (item: any) => {
                            this._TicketActionsService.UnlockTicket(item.ID)
                                .subscribe(() => {
                                    //  Reset these to reflect the change that was just made.
                                    item.LockedDate = null;
                                    item.LockedByPerson_Fullname = null;
                                });
                        };
                        let unlockText = "Unlock";
                        if (allowedActions.LockedByUserName && allowedActions.LockedByUserName !== "")
                            unlockText += " (locked by " + allowedActions.LockedByUserName + ")";
                        list.push(new SelectOption({ OnClick: onClick }, unlockText));
                    } else if (allowedActions.LockedByAnotherUser)
                        list.push(new SelectOption('', TicketActionsService.LockedByMessage(allowedActions)));

                    allowedActions.AllowedTicketEditFunctions.forEach(tf => {
                        const onClick = (item: any) => { this._TicketActionsService.DoTicketFunction(item.TicketNumber, tf, false, () => this.refreshSearch(), null) };
                        list.push(new SelectOption({ OnClick: onClick }, tf.Name));
                    });

                    if (allowedActions.CanCopy) {
                        const onClick = (item: any) => { this._TicketActionsService.CopyTicket(item.TicketNumber) };
                        list.push(new SelectOption({ OnClick: onClick }, "Copy"));
                    }

                    if (allowedActions.CanEditSuspend) {
                        const onClick = (item: any) => { this._TicketActionsService.EditOrResumeTicket(item.TicketNumber) };
                        list.push(new SelectOption({ OnClick: onClick }, "Edit"));
                    }

                    if (allowedActions.CanResumeIncomplete) {
                        const onClick = (item: any) => { this._TicketActionsService.EditOrResumeTicket(item.TicketNumber) };
                        list.push(new SelectOption({ OnClick: onClick }, "Resume"));
                    }

                    if (allowedActions.CanQueueForQA) {
                        const onClick = (item: any) => {
                            this._TicketActionsService.QATicket(item.ID)
                                .subscribe(() => {
                                    //  Update this in case it's being displayed in the list
                                    item.QAStatus = QAStatusEnumDescription[QAStatusEnum[QAStatusEnum.ManuallyQueued]];
                                });
                        };
                        list.push(new SelectOption({ OnClick: onClick }, "Queue for QA"));
                    }

                    if (allowedActions.CanPrint) {
                        //  Only add horizontal line if the last item is not one too!  (happens when can view ticket but not do any edit actions)
                        if ((list.length > 0) && (list[list.length-1] !== null))
                            list.push(null);
                        list.push(new SelectOption(TicketListListActions.PrintTicketText, "Print Text", "print"));
                    }
                }

                if (!list[list.length - 1])
                    list = list.splice(0, list.length - 1);

                this.ActionList = list;
            });
    }

    protected SetActionList(): Observable<SelectOption[]> {
        return of([]);
    }

    public viewAllRelated: string;
    protected SpecialAction(action: SelectOption, listItem: any = null) {
        if (action.Value === TicketListListActions.AddPositiveResponse)
            this.AddPositiveResponse(listItem);
        else if (action.Value === TicketListListActions.ViewAllRelated) {
            this.viewAllRelated = listItem.TicketLinkID;
            this.refreshSearch();
        }
        else if (action.Value === TicketListListActions.PrintTicketText)
            this._PrintingService.PrintDocument("Ticket", "tickets", ["print", "text", listItem.ID]);
        else if (action.Value.OnClick)
            action.Value.OnClick(listItem);
    }

    removeRelatedSearch() {
        this.viewAllRelated = null;
        this.refreshSearch();
    }
    ClearSortAndFilters() {
        this.viewAllRelated = null;
        super.ClearSortAndFilters();
    }
    filterChange(filters: SearchFilter[]) {
        this.viewAllRelated = null;
        
        for (let i = 0; i < filters.length; i++) {
            const f = this.MassageAgentFilter(filters[i]);
            if (f.PropertyName !== filters[i].PropertyName) {
                filters[i] = f;
            }
        }

        super.filterChange(filters);
    }

    private AddPositiveResponse(listItem: any): void {
        const data = new AddPositiveResponseData(listItem.TicketNumber, listItem.TicketTypeID);

        //  These properties are used to limit the Service Area(s) and Utility Type to the item in the list.
        //  If we don't do this and the user is linked to multiple service areas, it will let him pick any service area.
        //  And if the ticket/service area has multiple utility types, the same.
        //  Which means the user may pick a service area/utility type that does not match the current list item.
        //  And then we can't correcly set the Response Code that they picked in to the list or remove the item if the response is now complete.
        const haveServiceAreaNameColumn = this.DisplayedColumns.some(c => c.returnPropertyName === "ServiceArea_Name");
        if (haveServiceAreaNameColumn) {
            //  Would be better if we had the IDs for these instead of the names, but this is what we have on the Service Area web user dashboards
            //  and didn't want to add more columns. Not even sure why we are showing the service area name here instead of code...
            //  If we ever change that to code, will break this.
            data.LimitToServiceAreaName = listItem["ServiceArea_Name"];

            //  Note that on the service area web user dashboard, this column is dynamic bases on whether or not any of the users service areas
            //  use Response by Utility Type.
            if (this.DisplayedColumns.some(c => c.returnPropertyName === "UtilityType_Name")) {
                const utilityTypeName = listItem["UtilityType_Name"];
                if (utilityTypeName && utilityTypeName !== "")
                    data.LimitToUtilityTypeName = utilityTypeName;
            }
        }

        //  This is called after a response has been saved.  Which we use to update the current response in the list
        //  if it is currently being displayed.
        data.OnResponseSaved = (savedServiceAreaInfo) => {
            //  If the list contains a service area code name, then we also limited the "add response" dialog to that name.  So we can also check to see
            //  if the list contains a column for the Response or if it is filtering on "HaveCompletedResponse" (and we just entered a complete response).
            if (haveServiceAreaNameColumn) {
                //  If the list also has the UtilityType, that has also been limited.  So it should be safe to just use the first item in CurrentResponses.
                const savedResponse = (savedServiceAreaInfo.CurrentResponses && savedServiceAreaInfo.CurrentResponses.length > 0) ? savedServiceAreaInfo.CurrentResponses[0] : null;
                if (savedResponse) {
                    if (this.DisplayedColumns.some(c => c.returnPropertyName === "Response_Code"))
                        listItem["Response_Code"] = savedResponse.ResponseCode;

                    //  If the response is complete and we're filtering on HaveCompletedResponse, remove it from the list.
                    //  This function will refresh the list if necessary but allows a number of items to be removed without refreshing to
                    //  avoid spamming the server.
                    //  Not sure how, but there seem to be times when SearchFilter is not defined yet - maybe on the ticket list or if column not initialized yet?
                    if (!savedResponse.ResponseIsNotComplete && this.SearchFilter && this.SearchFilter.some(f => f.PropertyName === "HaveCompletedResponse"))
                        this.DeleteItem(listItem);
                }
            }
        };

        this.services.dialog.open(AddPositiveResponseDialog, {
            data: data,
            minWidth: '45%',
            width: '550px',
            maxWidth: '550px'
        });
    }

    //By default do a contains for all columns.  Override this if you have a column that needs something different
    protected GetSearchFilterObject(column: SearchColumn, filter: SearchFilter) {
        if ((column.filterColumn === "AgentPersonID") || (column.filterColumn === "LockedByPersonID")) {
            return this.MassageAgentFilter(filter);
        }

        return filter;
    }

    //  TODO: This should not need to be manually handled like this.  If we configure the column with the "usePersonSearch" flag,
    //  we should also give it the column name to use when doing a text filter.  Then *THE SEARCH COMPONENT* should handle using either the ID filter
    //  name or the text column filter name!  Otherwise, we have to add this handling in every individual list that searches by people!
    private MassageAgentFilter(filter: SearchFilter) {
        //Only do this on contains because if done for 'Myself' then the filter.value won't be a GUID because the server has to get the personID to use so they can save the filter
        if (filter.PropertyName === "AgentPersonID" && filter.Operator === SearchFilterOperatorEnum.Contains && !Guid.isGuid(filter.Values[0].FilterValue))
            return new SearchFilter("Agent.Fullname", filter.Operator, filter.Values);
        else if (filter.PropertyName === "Agent.Fullname" && (filter.Operator === SearchFilterOperatorEnum.CurrentUser || Guid.isGuid(filter.Values[0].FilterValue)))
            return new SearchFilter("AgentPersonID", filter.Operator, filter.Values);
        else if (filter.PropertyName === "LockedByPersonID" && filter.Operator === SearchFilterOperatorEnum.Contains && !Guid.isGuid(filter.Values[0].FilterValue))
            return new SearchFilter("LockedByPerson.Fullname", filter.Operator, filter.Values);
        else if (filter.PropertyName === "LockedByPerson.Fullname" && (filter.Operator === SearchFilterOperatorEnum.CurrentUser || Guid.isGuid(filter.Values[0].FilterValue)))
            return new SearchFilter("LockedByPersonID", filter.Operator, filter.Values);

        return filter;
    }

    protected ApplySearchFilters() {
        const request: SearchRequest = super.ApplySearchFilters();

        //If filtering on ticket number from the quick search then only use that value and disable all other filtering
        const ticketNumber = request.Filters ? request.Filters.find(f => f.QuickTextSearch) : null;
        if (this.viewAllRelated) {
            const filter = new SearchFilter("TicketLinkID", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(this.viewAllRelated, this.viewAllRelated)]);
            request.Filters = [filter];
        }
        else if (ticketNumber) {
            request.Filters = [ticketNumber];

            if (this.FiltersRequiredForTicketNumberSearch)
                request.Filters = request.Filters.concat(this.FiltersRequiredForTicketNumberSearch);
        }

        //Need to do this in a timeout so we don't get errors about it changing after being checked if it's being set from navigation page load
        setTimeout(() => this.disableFilters = coerceBooleanProperty(this.viewAllRelated || ticketNumber));

        //Always say to load these. Tickets have to have both columns and filters set, so tell the server to always try to get them (the server will only load them if they weren't passed in the call).
        //  Can't do this in the base list class because not every list uses the stored columns and filters.
        //  This is tricky in tickets because you can search by the number in the details, and it needs to keep that ticket number search when coming back to
        //  the list.  But then also allow the user to clear it out and still have a valid search (dates set, etc).  The issues are if they refresh the page on the ticket details, then do a
        //  ticket number search, we have to know what the filter should be when they come back to the list and clear out the ticket number search (highlight and delete from the quick search text field).
        request.LoadColumnsAndFilters = true;

        return request;
    }
}
