<div style="width: 100%; display: flex; flex-direction: column; align-items: center;"><!--Needed because of the changes in the whole site styling that removed the scroll bar on the main page in app.component.html.  If this div isn't here then the mat-card won't grow to fill the content-->
    <mat-card style="max-width: 680px; position: relative;">
        <busy-indicator [busyKey]="busyKey"></busy-indicator>
        <mat-card-title>
            <span *ngIf="OptionPicked === 'self register'; else NotRegistered">
                New User Registration
            </span>
            <ng-template #NotRegistered>
                Login not Registered
            </ng-template>
        </mat-card-title>
        <mat-card-content>
            <ng-template #errorTemplate>
                <div class="api-error">
                    Error on server.
                </div>
            </ng-template>
            <ng-container *ngIf="!apiError; else errorTemplate">
                <ng-container *ngIf="!currentUser.LoginDisabled; else loginDisabledTemplate">

                    <ng-container *ngIf="userPersonID != null; else loginNotLinked">
                        <div>You are currently linked to a person in the system already.  You shouldn't be here.</div>
                    </ng-container>

                    <ng-template #loginNotLinked>
                        <div *ngIf="currentUser.IsSupportUser">
                            <div>
                                <p>I see you are an Irth user, but you are not linked to a person in the system!  Would you like to create a person in this system?</p>
                                <p>If not feel free to navigate away from this page, just know you may experience errors in certain areas.</p>
                                <p>Your user will be created with the following info:</p>
                                <mat-form-field>
                                    <input matInput UpperCase placeholder="First Name" type="text" [(ngModel)]="firstName" />
                                    <mat-hint>*required</mat-hint>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput UpperCase placeholder="Last Name" type="text" [(ngModel)]="lastName" />
                                    <mat-hint>*required</mat-hint>
                                </mat-form-field>

                                <!--<div>
                                    First Name: <b>{{currentUser.FirstName}}</b>
                                </div>
                                <div>
                                    Last Name: <b>{{currentUser.LastName}}</b>
                                </div>-->
                            </div>
                            <button mat-button style="margin-top:10px" color="primary" [disabled]="firstName == null || lastName == null" (click)="OnCreateForAdmin()">Create Person</button>
                        </div>
                        <div *ngIf="!currentUser.IsSupportUser">
                            <ng-container *ngIf="!OptionPicked">
                                <p>
                                    Your login has been created but is not currently registered in Exactix.
                                </p>
                                <p>
                                    To resolve this issue, please pick the option that best describes your situation:
                                </p>
                                <!--<div fxLayout="column" fxLayoutAlign="space-around center">
                                    <button mat-button [color]="'primary'" (click)="OptionPicked = 'have invite'">I received an invitation email from the One Call Center.</button>
                                    <button mat-button [color]="'primary'" (click)="OptionPicked = 'not received'">The One Call Center sent me a registration invite but I have not received it.</button>
                                    <button mat-button [color]="'primary'" (click)="OptionPicked = 'self register'">I have not received an invitation but I need to create a ticket.</button>
                                    <button mat-button [color]="'primary'" (click)="OptionPicked = 'idk'">None of the above.</button>
                                </div>-->


                                <ul>
                                    <li>
                                        <div class="link" (click)="OptionPicked = 'have invite'">I received an invitation email from the One Call Center.</div>
                                    </li>
                                    <li>
                                        <div class="link" (click)="OptionPicked = 'not received'">The One Call Center sent me a registration invite but I have not received it.</div>
                                    </li>
                                    <li>
                                        <div class="link" (click)="OptionPicked = 'self register'">I have not received an invitation but I need to create a ticket.</div>
                                    </li>
                                    <li>
                                        <div class="link" (click)="OptionPicked = 'self register'">I started creating a new account but did not complete it.</div>
                                    </li>
                                    <li>
                                        <div class="link" (click)="OptionPicked = 'idk'">None of the above.</div>
                                    </li>
                                </ul>

                            </ng-container>

                            <ng-container *ngIf="OptionPicked === 'have invite'">
                                Please leave this browser window open, return to the invitation email, and click the link it contains to finish the registration process.
                            </ng-container>

                            <ng-container *ngIf="OptionPicked === 'not received'">
                                <p>Please leave this browser window open and check your spam folder.</p>
                                <p *ngIf="EmailFrom">Look for an email sent from '<b>{{EmailFrom}}</b>' with a subject of '<b>User Registration for {{OneCallCenterName}}</b>'</p>
                                <p>If you find the email, just click the link in the email to complete the registration process.</p>
                                <p>If you cannot find the email, please contact the One Call Center.</p>
                            </ng-container>

                            <ng-container *ngIf="OptionPicked === 'self register'">
                                <ng-container *ngIf="CanSelfRegister; else noSelfRegisterRole">
                                    <iq-new-user-registration-user-details></iq-new-user-registration-user-details>
                                </ng-container>
                                <ng-template #noSelfRegisterRole>
                                    The One Call is not currently allowing self registration.  Please contact the One Call Center for assistance.
                                </ng-template>
                            </ng-container>

                            <ng-container *ngIf="OptionPicked === 'idk'">
                                <p>Please leave this browser window open and contact the One Call Center for assistance.</p>
                                <p>If the One Call Center sends you a "registration invite" email, you can click on the link in the email to complete the registration process.</p>
                                <p *ngIf="EmailFrom">Look for an email sent from '<b>{{EmailFrom}}</b>' with a subject of '<b>User Registration for {{OneCallCenterName}}</b>'</p>
                            </ng-container>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-template #loginDisabledTemplate>
                    <div>Your login is currently disabled in the system.  Please contact your One Call Center.</div>
                </ng-template>

            </ng-container>
        </mat-card-content>
    </mat-card>
</div>
