import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval, merge } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

//  Service to handle checking for Software Updates using the built-in Angular Service Worker.
//  Service Worker links:
//      https://angular.io/guide/service-worker-intro
//      Checking for updates:
//          https://medium.com/@martindzejky/service-workers-angular-3c1551f0c203
//      How to run Service Worker in local dev: https://stackoverflow.com/questions/55905172/how-to-run-service-worker-locally-with-angular
//      Setup/configuration:
//          https://dev.to/maxaboxi/setting-up-service-worker-to-an-existing-angular-project-f35
//      api request caching: https://christianlydemann.com/how-to-cache-http-requests-in-an-angular-pwa/
//      Issue with rollbacks not being detected:
//          https://github.com/angular/angular/issues/24338

//  Notes/Gotchas about Service Workers:
//      https://webdesign.tutsplus.com/tutorials/5-essential-tips-for-service-worker-development--cms-31424
//      1) Doing "Empty Cache and Hard Reload" will bypass the Service Worker completely!  Nothing can be done about that - just use the normal reload/refresh.
//      2) Rolling back to a previous build is not going to work.  The ServiceWorker will not downgrade due to how it checks previous version/timstamps.
//         The only way to force it to happen is to change something (like the timestamp) in the ngsw.json file.
//         If we need to allow for this, we could add an option to the SPA docker to pass in a value for the timestamp and have the nginx
//              container re-write the ngsw.json file with that value.  Or have it write the build number in to that file (we can re-tag the docker image to
//              match a new build / tag).
//         Or can just re-build what we are trying to roll back to...
//         Or in a pinch, could ssh in to each of the running SPA containers after they have been rolled back and edit that file on each one.

//  How to test on local machine:
//      1) Install angular-http-server:
//              yarn global add angular-http-server
//      2) Manually build the app.  This will place the build results in the "dist" folder.
//              ng build --configuration production
//         * To speed up the build, you can (temporarily) change .browserslistrc to just contain "last 2 chrome versions"
//           (which will turn off the ES5 bundle generation).
//      3) Serve the app using angular-http-server:
//              cd dist
//              angular-http-server -p 4200 dist
//      4) Open the browser console and refresh the app.  The network tab should show a bunch of stuff about the Service Worker
//         in the Size column (and also show an icon )
//         And in the Application tab, you should see the Service Worker is registered and active.
//      5) Stop serving the app.  Pages for any of the angular bundles that have already been downloaded are now cached by
//         the Service Worker.  You can refresh the browser and the app will still work!
//      6) Rebuild the app and serve it again (#2 and #3).  The app should detect the new version and
//         automatically load it on the next navigation.
//         * May want to temporarily change the refresh interval in the constructor below to speed up the detection for testing.
//  * Yes, the only way to properly test this is to manually build and serve like this.  Can use the steps below to trick parts of
//      it for faster local testing but it will not trigger updates (because the data to determine that is stored in "ngsw.json" which
//      is built during the production build process not *NOT* built when doing an "ng serve").
//  * To easily test switching and watch the upgrade process: Build the app, rename the "dist" folder to something else, make some kind
//      of visible change to the app, build the app again.  You now have 2 "dist" folders.  Serve one of them, load the web app, stop it,
//      serve the other one.  Can repeat that back and forth between the 2 folders.
//      * Can also edit the ngsw.json file in the dist folder and increment the timestamp at the top.  Updates are triggered any time that
//        timestamp is GREATER than the cached value.
//
//  * As of Chrome 89, they changed how they detect if a PWA can be used offline.  And if not, as of Chrome 93 it will no longer be installable!
//      As of 3/14/2021, there is a known Angular issue about this: https://github.com/angular/angular/issues/41085
//      The workaround is to set the "start_url" in manifest.webmanifest to "/index.html".  And then the index.html also needs to be prefetched
//      (which is configured in ngws-config.json).

@Injectable({
    providedIn: 'root'
})
export class AppUpdateService {

    public UpdateIsAvailable: boolean = false;

    //  The current version of the SPA.  This comes from environment.Build which is built in to the docker container
    //  when it is built.
    public CurrentVersion: string = environment.Build;

    //  The last x-exactix-spa-ver header value received from an Api call.  The nginx/spa docker adds this to all api
    //  calls.  Any time that version differs from our environment.Build version, we know our version is out of date
    //  and we need to check for updates (which will then result in fetching the same build that the nginx docker is
    //  currently serving).
    private _LastSPAVersion: string = null;

    constructor(appRef: ApplicationRef, private _Updates: SwUpdate) {
        if (this._Updates.isEnabled)
            this.Init(appRef);
    }

    /**
     * Called by the ApiInterceptor with the value of the "x-exactix-spa-ver" header.  This contains the SPA build
     * number.  When running in Kubernetes, nginx adds this to all api responses.  If running in debug on a dev machine,
     * a value of "1.?.???" is set in UserSessionCookieMiddleware (so can change that if testing the update detection).
     * @param spaVersion
     */
    public SetSPAVersion(spaVersion: string): void {
        if (!spaVersion)
            return;

        //  The way this is being done could be done better if we compile the Angular app with the build number in the environment.
        //  Think we can do that but need to figure out the

        if (this._LastSPAVersion !== spaVersion)
            console.warn("AppUpdateService.SetSPAVersion:", spaVersion);

        //  Only trigger a .checkForUpdate() if we already had a version and now it's different.  This prevents the very
        //  first api call from triggering a check.  We do the initial check in Init() because we then also know it's safe to
        //  activate an update immediately if one is available.
        const changed = this._LastSPAVersion && (this._LastSPAVersion !== spaVersion);
        this._LastSPAVersion = spaVersion;

        //  ** If this is not working, check the status of the service worker cache using a url like this:
        //      https://dev.exactix811.com/ngsw/state
        //  Had issues with /assets/amplifyConfig.json causing a hash mismatch error.  That file is dynamically built so it must be excluded
        //  from the assets group in ngsw-config.json or the ngsw.json file that is built during the build will contain a hash of the
        //  file BEFORE it is modified (which happens inside the docker when the docker starts).
        //  ** Had an issue on 12/31/2020 caused by that file not being excluded due to the ordering of the files listed in the "files"!!
        //     Apparently, files that are excluded must come after the inclusion rule!
        if (changed && this._Updates.isEnabled)
            this._Updates.checkForUpdate();
    }

    private Init(appRef: ApplicationRef): void {
        //  Do an initial check for updates.  Otherwise, if there is an update available, the ServiceWorker has loaded
        //  the previously cached version and will NOT load the update until .activateUpdate() is triggered.
        this._Updates.checkForUpdate().then(() => {
            if (this.UpdateIsAvailable) {
                //  Have an update available.  And since we only Init() when the app is initializing, it should be safe to just
                //  activate it now.
                this.ActivateUpdateNow();
            }
        });

        //  If configured, this will check for updates periodicaly in the background.
        //  But the way we are detecting this from the spa changing (via a header added by the nginx docker), it ended up not
        //  being necessary - so it is currently disabled by default (in the environment settings).
        //  When we push out a new build, the client will know about the update as soon as it has issued it's first api call.
        if (environment.AppUpdateRefreshIntervalSeconds > 0) {
            // Allow the app to stabilize first, before starting polling for updates with `interval()`.
            const appIsStable = appRef.isStable.pipe(first(isStable => isStable === true));

            const refreshInterval = interval(environment.AppUpdateRefreshIntervalSeconds * 1000);        //  Default is 5 minutes

            merge(appIsStable, refreshInterval)
                .subscribe(async () => await this._Updates.checkForUpdate());
        }

        //  Track if an update is required.  When we navigate to a route that has the AuthenticationGuardService, it will trigger
        //  activating the update as it's navigating via a call to ActivateUpdateIfAvailable();
        //  As of Angular 13, the "available" observable is deprecated.  Now need to use versionUpdates and filter like below.
        //  This mimics the old behavior of the "available" observable.  Could probably simplify this but didn't have time to test it.
        this._Updates.versionUpdates.pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
            map(evt => ({
                type: 'UPDATE_AVAILABLE',
                current: evt.currentVersion,
                available: evt.latestVersion,
            })))
            //this._Updates.available.subscribe(event => {
            .subscribe(event => {
                console.warn("Update is available!", event, this._Updates);
                this.UpdateIsAvailable = true;
            });
    }

    /**
     * Activate the update and navigate the browser to the url (which will reload the page to that url with the
     * new update applied).
     * @param url
     * @returns true if update is available and is being applied, false if no update
     */
    public ActivateUpdateIfAvailable(url: string): boolean {
        if (!this.UpdateIsAvailable)
            return false;

        this._Updates.activateUpdate().then(() => {
            location.href = url;
        });
        return true;
    }

    /**
     * If an update is available, activate it now by reloading the current page.
     * */
    public ActivateUpdateNow(): void {
        if (this.UpdateIsAvailable)
            window.location.reload();
    }
}
