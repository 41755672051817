<mat-icon *ngIf="filtered" style="color: initial;">
    filter_list
</mat-icon>
<div style="overflow: hidden; text-overflow: ellipsis;">
    <ng-content></ng-content>
</div>
<mat-icon *ngIf="aToZChecked" style="color: initial;">
    arrow_upward
</mat-icon>
<mat-icon *ngIf="zToAChecked" style="color: initial;">
    arrow_downward
</mat-icon>
<ng-template #actions>
    
    <div class="iq-column-header-options mat-elevation-z7" cdkDrag cdkDragRootElement=".cdk-overlay-pane" [@dialogContainer]="isOpen ? 'enter' : 'exit'">
        <h2 *ngIf="Title != null" class="mat-dialog-title" cdkDragHandle>{{Title}}</h2>
        <div class="iq-column-header-options-body">
            <ng-container *ngIf="SearchColumn.CanSort" >
                <div style="display:flex; flex-shrink: 0;">
                    <mat-checkbox [(ngModel)]="aToZChecked" color="primary" (change)="ToggleSortAToZ($event)">Sort Ascending</mat-checkbox>
                    <!-- not shown if "SelectItemSearch" because that list has it's own clear now -->
                    <div style="flex-grow: 1; text-align: right; min-width: 80px;" *ngIf="!SelectItemSearch && (filtered || aToZChecked || zToAChecked) && !isValueRequired">
                        <span class="link" (click)="ClearSortAndFiltersInternal()">clear</span>
                    </div>
                </div>
                <mat-checkbox [(ngModel)]="zToAChecked" color="primary" (change)="ToggleSortZToA($event)">Sort Descending</mat-checkbox>
            </ng-container>

            <ng-container *ngIf="SearchColumn.CanFilter">
                <label style="padding: 10px 0 5px">Limit by...</label>

                <label *ngIf="SearchColumn.disabledOptionsMessage && isValueRequired" class="iq-caption red">*{{SearchColumn.disabledOptionsMessage}}</label>

                <iq-list-filter-container [SearchFilter]="filter" [SearchColumn]="SearchColumn" [IsDisabled]="isDisabled" [IsValueRequired]="isValueRequired"
                                          [ClearFilter]="clearFilterEvent" (FilterChanged)="filterChanged($event)"></iq-list-filter-container>
            </ng-container>
        </div>
    </div>
</ng-template>
