
export class TicketTypeOptionsForTicketEntry {
    public ID: string;
    public Name: string;

    public MustVerifyLBPs: boolean;
    public MustVerifyLocation: boolean;

    public CanScheduleMeeting: boolean;
    public CanManuallyAddServiceAreas: boolean;

    public TicketTypePickedDisclaimerName?: string;
    public TicketTypePickedDisclaimerText?: string;         //  This allows html - but usage depends on value of TicketTypePickedDisclaimerName

    public TicketCreatedDisclaimerText?: string;            //  This allows html

    public BackgroundColor?: string;
    public FontColor?: string;

    //  These are the names of the Contact Types used for the Ticket Type - displayed as column headers in the service area list.
    //  Names come from ICenterBiz.ContactTypeName* methods because different One Calls use different methods for handling
    //  the contact types (and may not even want the column headers to vary from the defaults).
    public MainContactTypeName: string;
    public AltContactTypeName: string;
    public EmergContactTypeName: string;

    public ClipDigSiteToCounty: boolean;
    public ClipDigSiteToPlace: boolean;

    public RecalculateServiceAreas: boolean;
}
