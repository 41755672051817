import { Dictionary } from "@iqSharedUtils/Dictionary";
import { MapToolsEnum } from "Enums/MapTools.enum";
import { PermissionsEnum } from "Enums/RolesAndPermissions/Permissions.enum";
import { FieldLengths } from "Models/Configuration/FieldLengths.model";
import { OneCallCenterSettings } from "Models/Configuration/OneCallCenterSettings.model";
import { LinkedServerSetting } from "./LinkedServerSetting.model";
import { MapLayer } from "Models/Configuration/Maps/MapLayer.model";
import { LatLonBounds } from "Models/Maps/LatLonBounds.model";
import { NameIDEntity } from "Models/Configuration/NameIDEntity.model";

export class AppUser {
    public ID: string;
    public FirstName: string;
    public LastName: string;
    public FullName: string;

    public EmailAddress: string;

    public Username: string;
    public IsExternalLogin: boolean;

    public LoginDisabled: boolean;

    /**
     *  True if the user is a SuperAdmin user.  This is a user that gets all permissions in the system.
     */
    public IsSuperAdminUser: boolean;

    /**
     *  True if the user is a Support user.  This is a user that gets read-only permissions in the system.
     *  This is always true if IsSuperAdminUser is true.
     */
    public IsSupportUser: boolean;

    //  This is also set to true if the user is a Support or Super Admin user
    public IsLocalUser: boolean;

    public IsExcavatorUser: boolean;       //  DO NOT USE THIS WHEN CHECKING TO SEE IF THEY CAN DO SOMETHING TO A TICKET!  User could be in multiple roles!
    public IsRegulatorUser: boolean;

    /**
     * If set, date range filters will be limited to this number of days.  i.e. Only allow users to search
     * for a max of 180 days of tickets at a time.  If the user is in multiple rows, will use the smallest
     * (non-null) value.  Null means not limited.
      */
    public MaxDaysInDateRangeQueries?: number;

    /**
    * The name to use for the "Projects" entity.  If there is exactly 1 ProjectType configured, this will be that name.
    * If there are multiple, this will be "Projects".  If there are none (and Projects should be completely hidden),
    * this will be null.
    */
    public ProjectsName: string;

    public CurrentOneCallCenterCode: string;
    public CurrentOneCallCenterID: string;

    public CurrentOneCallCenterName: string;

    public ConfiguredLandingPage: string;

    /**
     *  The list of servers/one calls that the user can switch to.  Could be another database on the local system
     *  or a remote server.
     */
    public LinkedServers: LinkedServerSetting[];

    //  TODO: This should not be here.  The allowed map tools could be configured differently for different roles - especially
    //  between a ticket creation role and a service area role (where one applies to ticket map tools and the other to
    //  registration map tools).  Need to move this in to the TicketConfiguration & RegistrationMapParams.
    public AllowedMapTools: MapToolsEnum[];

    //  Could also generically define this as type "{ [key: string]: string }".  But mapped it to a class so we don't have
    //  to reference by a string and to get better type mappings of the settings.
    public OneCallCenterSettings: OneCallCenterSettings;

    public Permissions: PermissionsEnum[];

    public EntityPermissions: Dictionary<PermissionsEnum[]>;

    public MapLayers: MapLayer[];

    public MapBounds: LatLonBounds;

    //  ** If we end up needing more than just field lengths (maybe "required"?), may want to consider adding it
    //  to this property (make a class instead of just using "int").
    //  Could also generically define this as type "{ [key: string]: number }".  But mapped it to a class forbetter type mappings of the values.
    public FieldLengths: FieldLengths;

    public TermsAndConditions: string;

    //  True if this user has reports available to view (and they have the required permission)
    public HaveReports: boolean;
    public HaveSchedulableReports: boolean;

    public EmergencyContactPageContactTypes: NameIDEntity[];
}
