import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GeocodeRequest } from '@iqModels/Maps/GeocodeRequest.model';
import { StreetVerifyRequest } from '@iqModels/Maps/StreetVerifyRequest.model';
import { StreetVerifyResponse } from '@iqModels/Maps/StreetVerifyResponse.model';
import { DigsiteEnteredTypeEnum } from 'Enums/DigsiteEnteredType.enum';
import { DigSiteIntersectionItemTypeEnum } from 'Enums/DigSiteIntersectionItemType.enum';
import { DigSiteStreetItemTypeEnum } from 'Enums/DigSiteStreetItemType.enum';
import { GeocodeTypeEnum, GeocodeTypeEnumDescriptions } from 'Enums/GeocodeType.enum';
import * as _ from 'lodash';
import { DigSite } from 'Models/DigSites/DigSite.model';
import { DigSiteIntersection } from 'Models/DigSites/DigSiteIntersection.model';
import { DigSiteStreet } from 'Models/DigSites/DigSiteStreet.model';
import { BestZoomExtentsRequest } from 'Models/Maps/BestZoomExtentsRequest.model';
import { GeocodeResponse } from 'Models/Maps/GeocodeResponse.model';
import { LatLonBounds } from 'Models/Maps/LatLonBounds.model';
import { LocationValidationError } from 'Models/Maps/LocationValidationError.model';
import { ProcessManualDigSiteRequest } from "Models/Maps/ProcessManualDigSiteRequest.model";
import { SplitGeocodeRequest } from 'Models/Maps/SplitGeocodeRequest.model';
import { SplitGeocodeItemResponse } from 'Models/Maps/SplitGeocodeItemResponse.model';
import { TicketEntryOptionsService } from 'Pages/Tickets/Services/TicketEntryOptions.service';
import { Observable, of } from "rxjs";
import { SettingsService } from 'Services/SettingsService';

@Injectable({
    providedIn: 'root'
})
export class GeocodeService {

    //  The last Dig Site geocode request & response so that we can avoid re-geocoding if no changes since the last time.
    private _LastDigSiteGeocodeRequest: GeocodeRequest;
    public get LastDigSiteGeocodeRequest(): GeocodeRequest { return this._LastDigSiteGeocodeRequest }

    private _LastDigSiteGeocodeResponse: GeocodeResponse;

    //  The last Near Street geocode request & response so that we can avoid re-geocoding if no changes since the last time.
    private _LastNearStreetGeocodeRequest: GeocodeRequest;
    private _LastNearStreetGeocodeResponse: GeocodeResponse;

    private _LastFindBestZoomExtentsRequest: BestZoomExtentsRequest;
    private _LastFindBestZoomExtentsResponse: LatLonBounds;

    constructor(private http: HttpClient, private _SettingsService: SettingsService, private _TicketEntryOptionsService: TicketEntryOptionsService) { }

    public Clear() {
        this._LastDigSiteGeocodeRequest = null;
        this._LastDigSiteGeocodeResponse = null;

        this._LastNearStreetGeocodeRequest = null;
        this._LastNearStreetGeocodeResponse = null;

        this._LastFindBestZoomExtentsRequest = null;
        this._LastFindBestZoomExtentsResponse = null;
    }

    public GetGeocodeTypeDescription(geocodeType: GeocodeTypeEnum): string {
        if (geocodeType === GeocodeTypeEnum.Place)
            return this._SettingsService.PlaceNameLabel;

        return GeocodeTypeEnumDescriptions[GeocodeTypeEnum[geocodeType].toString()]
    }

    /**
     *  Set the Last Geocode Request so that we will not re-geocode this same location.
     */
    public SetLastGeocodeRequest(geocodeType: GeocodeTypeEnum, digSite: DigSite, ticketTypeID: string, geometry: object, bufferFt: number,
        unbufferedGeometry: object, refreshValidationErrors: boolean): void
    {
        this.Clear();

        //  Initialize the internal last Request/Response to match the ticket values.  This is done when we need to keep the current
        //  dig site and do not need to re-geocode it (i.e. to keep the geocode type already picked - user could have changed it - or to keep
        //  the current digsite in case the map landbase has changed).

        this._LastDigSiteGeocodeRequest = new GeocodeRequest(digSite, geocodeType, ticketTypeID);

        //  refreshValidationErrors should (normally) be set to true so that validation errors will still be fetched.
        //  That allows us to keep the current dig site but still refresh those validation errors.
        this._LastDigSiteGeocodeResponse = new GeocodeResponse(geocodeType, geometry, bufferFt, unbufferedGeometry);
        this._LastDigSiteGeocodeResponse.RefreshValidationErrors = refreshValidationErrors;

        this._LastNearStreetGeocodeRequest = null;
        this._LastNearStreetGeocodeResponse = null;
    }

    //  TODO: The server is now checking to see if the street component fields are null when saving a ticket.
    //        Maybe don't need to do this any more?  But would still want to wipe out the component fields - the server
    //        only does that if the Name is not set (and that's how it should be - so that we don't wipe out the components
    //        if they were set by selecting an item in the autocomplete).
    /**
     * Verifies the street and updates the returned values into the form
     * @param state
     * @param countyName
     * @param placeName
     * @param searchValue
     * @param formGroup
     * @param interPath
     * @param streetPath
     * @param isNearStreet
     * @param setCallerPlaceInsteadOfPlace: If true sets the CallerPlaceName field instead of PlaceName
     */
    public VerifyEnteredStreet(state: string, countyName: string, placeName: string, searchValue: string, formGroup: UntypedFormGroup,
        interPath: string, streetPath: string, isNearStreet: boolean,
        setCallerPlaceInsteadOfPlace: boolean = false): Observable<StreetVerifyResponse>
    {
        if (!searchValue)
            return of(null);

        return new Observable<StreetVerifyResponse>(observer => {

            const request = new StreetVerifyRequest();
            request.State = state;
            request.CountyName = countyName;
            request.PlaceName = placeName;
            request.EnteredStreet = searchValue;

            //  Before making the call, set all of the component fields to null/undefined.  If the api call fails due to a client
            //  network connection error, it is retried with a delay.  This makes sure the fields are properly erased in case
            //  it is still being retried when the user clicks Save.  If they are empty when the ticket is saved, the server will
            //  parse out the entered street and populate them.
            //  TODO: Should probably have a "busy" flag we can expose from here to prevent Saving when we have an active api call...
            this.SetStreetComponentsFromResponse(null, isNearStreet, formGroup, streetPath);

            const url = this._SettingsService.ApiBaseUrl + "/Maps/Street/VerifyEnteredStreet";
            this.http.post<StreetVerifyResponse>(url, request).subscribe(
                response => {
                    if (!isNearStreet) {
                        //  The PlaceName could be populated if we found a place name in the searchValue.  If not, this will
                        //  contain the placeName in the search request or null if there was none.
                        //  interPath could be null/empty if the formGroup we were given *IS* the intersection
                        const placeNamePath = (interPath ? interPath + "." : "") + (setCallerPlaceInsteadOfPlace ? "CallerPlace" : "PlaceName");

                        //  As of 3/7/2021 only doing this if the place field is empty.  Otherwise, if there is a problem parsing
                        //  the entered street and we incorrectly decide there is a place name, it will populate with something
                        //  completely wrong.  Don't think users even know they can enter the place name here to help with searching
                        //  so it's very unlikely they intended to enter one anyway (and did not find a match in the street data either).
                        const placeName = formGroup.get(placeNamePath).value;
                        if (!placeName || (placeName === ""))
                            this.SetFormValueIfDifferent(formGroup, placeNamePath, response.PlaceName);
                    }

                    this.SetStreetComponentsFromResponse(response, isNearStreet, formGroup, streetPath);

                    observer.next(response);
                    observer.complete();
                },
                err => {
                    //  If this fails, we have already wiped out the component fields so that we don't keep them populated with
                    //  incorrect values (or possibly values from a copied ticket!).  When saving the ticket, the server will see
                    //  the values are null and will parse the entered street to populate them.
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    /*
     *  Set the street components.  If response = null, will set all values to null.  The server will then see that when the ticket is
     *  saved and will build them.
     */
    private SetStreetComponentsFromResponse(response: StreetVerifyResponse, isNearStreet: boolean, formGroup: UntypedFormGroup, streetPath: string): void {
        //  response.EnteredStreet will be set to the exact searchValue that was passed to the server.  *BUT* if
        //  we found a place name at the end of it and returned that value in response.PlaceName, that text (and any
        //  whitespace / non alpha chars) will be removed from the end of it.
        if (isNearStreet) {
            if (response?.EnteredStreet)
                this.SetFormValueIfDifferent(formGroup, streetPath + ".NearStreetText", response.EnteredStreet);
        }
        else {
            this.SetFormValueIfDifferent(formGroup, streetPath + ".LastVerifiedEnteredStreetAddress", response?.EnteredStreet);

            //  Don't let this field get erased if the api call failed!
            if (response?.EnteredStreet)
                this.SetFormValueIfDifferent(formGroup, streetPath + ".EnteredStreetAddress", response.EnteredStreet);

            this.SetFormValueIfDifferent(formGroup, streetPath + ".FromAddress", response?.FromAddress);
            this.SetFormValueIfDifferent(formGroup, streetPath + ".ToAddress", response?.ToAddress);
        }

        this.SetFormValueIfDifferent(formGroup, streetPath + ".Prefix", response?.DirectionPrefix);
        this.SetFormValueIfDifferent(formGroup, streetPath + ".Name", response?.StreetName);
        this.SetFormValueIfDifferent(formGroup, streetPath + ".StreetType", response?.StreetType);
        this.SetFormValueIfDifferent(formGroup, streetPath + ".Suffix", response?.DirectionSuffix);
    }

    /**
     * Set the value in the formControl if the value is different.  Doing this avoids triggering all kinds
     * of change & status event notifications that get fired even if the value is the same!
     * @param formControl
     * @param newValue
     */
    private SetFormValueIfDifferent(formGroup: UntypedFormGroup, formControlPath: string, newValue: any): void {
        const formControl = formGroup.get(formControlPath);
        if (formControl && (formControl.value !== newValue))
            formControl.setValue(newValue);
    }

    private _PendingGeocodeRequest: GeocodeRequest;
    private _PendingGeocodeObservable: Observable<GeocodeResponse>;
    /**
     * Checks the given digsite against the last geocoded digsite (or the one given to InitializeDigSite()
     * if no other geocode has been done).  If different, re-geocodes and updates the GeocodeGeoJson
     * observable (which is monitored by the map).
     * @param digsite
     */
    public GeocodeDigSiteIfChanged(currentGeocodeType: GeocodeTypeEnum, digsite: DigSite, ticketTypeID: string,
        requestedGeocodeType: GeocodeTypeEnum = null, forProjectTypeID: string = null): Observable<GeocodeResponse>
    {
        return new Observable<GeocodeResponse>(observer => {
            //  These properties get added to the dig site when we fetch it from the form.  Don't send it to the server!
            //  But need to stash them away in prevResponse in case this is a manual digsite - we will need them if we need to validate
            //  the digsite properties.
            const prevResponse = new GeocodeResponse(GeocodeTypeEnum.Manual, digsite.GeometryJson, digsite.BufferFt, digsite.UnbufferedGeometryJson);
            this.RemovePropertiesFromDigsiteNotNeededForGeocoding(digsite);

            const request = new GeocodeRequest(digsite, requestedGeocodeType, ticketTypeID, forProjectTypeID);

            const digSitesAndPropertiesAreEqualToLastRequest = this._LastDigSiteGeocodeRequest
                && this.DigSitesAreEqual(digsite, this._LastDigSiteGeocodeRequest.Digsite)
                && (ticketTypeID === this._LastDigSiteGeocodeRequest.TicketTypeID);

            if (this._LastDigSiteGeocodeResponse)
                this._LastDigSiteGeocodeResponse.IsPreviousResult = true;

            const me = this;
            const setResult = function (returnRequest: GeocodeRequest, returnResponse: GeocodeResponse) {
                if (!returnRequest)
                    returnRequest = request;        //  Should not happen, but just in case... (the handling for Manual looks suspicious)
                if (!returnResponse) {
                    //  Always return an object so that if it's called again with the same request, we return it with the IsPreviousResult
                    //  flag set so the caller knows it's a repeat (to avoid firing additional change event).
                    returnResponse = new GeocodeResponse(null, null, null, null)
                }

                me._LastDigSiteGeocodeRequest = returnRequest;
                me._LastDigSiteGeocodeResponse = returnResponse;
                observer.next(returnResponse);
                observer.complete();
            };

            const setResultToLastResponse = () => {
                if (!this._LastDigSiteGeocodeResponse.RefreshValidationErrors)
                    setResult(this._LastDigSiteGeocodeRequest, this._LastDigSiteGeocodeResponse);
                else {
                    const newResponse = this._LastDigSiteGeocodeResponse.Clone();       //  clone because this is async and something else could change the global value
                    //  Have a previous response but we need to refresh the validation errors.
                    this.http.post<LocationValidationError[]>(this._SettingsService.ApiBaseUrl + "/Maps/Geocoder/ValidateDigSite", request)
                        .subscribe(
                            validationErrors => {
                                //const response = new GeocodeResponse(GeocodeTypeEnum.Manual, digsite.GeometryJson, digsite.BufferFt, digsite.UnbufferedGeometryJson);
                                newResponse.ValidationErrors = validationErrors;
                                setResult(request, newResponse);
                            }, err => {
                                console.error("Error validating digsite", err);
                                observer.error(err);
                                observer.complete();
                            }
                        );
                }
            }

            if (!this.CanGeocodeDigsite(digsite)) {
                if (digSitesAndPropertiesAreEqualToLastRequest)
                    setResultToLastResponse();
                else
                    setResult(request, null);
                return;
            }

            //  If the current GeocodeType is Manual, do not re-geocode unless requestedGeocodeType has been set to something.
            //  If it's set to something, the user is forcing this via the Notify By dropdown in the header.  Otherwise, we are
            //  geocoding in response to a change in the dig site - in which case, we do not want to wipe out a manually drawn
            //  dig site if we have one.
            if ((currentGeocodeType === GeocodeTypeEnum.Manual) && (requestedGeocodeType === null)) {
                if (digSitesAndPropertiesAreEqualToLastRequest && this._LastDigSiteGeocodeResponse) {
                    //  No changes to the dig site properties and we have a previous response.  So return the same thing.
                    setResultToLastResponse();
                    return;
                }

                //  If the place/county has changed and we clip to the place/county, we need to reset the digsite completely
                //  and force a "find best" geocode.  Fix up the request and fall-through to allow the geocode to be done
                //  against the current digsite properties.
                if (!this.PlaceOrCountyChangeRequiresFindBestGeocode(request)) {
                    //  If we get here, this is either the first time being called after initializing (for an edit/copy) or the dig site
                    //  properties have changed.  Since we have a manual, there is nothing to geocode.  But we still need to
                    //  call the server to fetch any ticket location validation errors/warnings.
                    this.http.post<LocationValidationError[]>(this._SettingsService.ApiBaseUrl + "/Maps/Geocoder/ValidateDigSite", request)
                        .subscribe(
                            validationErrors => {
                                //const response = new GeocodeResponse(GeocodeTypeEnum.Manual, digsite.GeometryJson, digsite.BufferFt, digsite.UnbufferedGeometryJson);
                                prevResponse.ValidationErrors = validationErrors;
                                setResult(request, prevResponse);
                            }, err => {
                                console.error("Error validating digsite", err);
                                observer.error(err);
                                observer.complete();
                            }
                        );
                    return;
                }
            }

            //  NA is used for "find best" so don't let that trigger keeping the previous!
            if (digSitesAndPropertiesAreEqualToLastRequest && this._LastDigSiteGeocodeResponse
                && ((requestedGeocodeType === null) || ((requestedGeocodeType !== GeocodeTypeEnum.NA) && (requestedGeocodeType === this._LastDigSiteGeocodeRequest.RequestedGeocodeType))))
            {
                //  Digsite is the same as the last geocode and we are not forcing a re-geocode
                //  to either find best (requestedGeocodeType === null) or a different geocode than what we got before.
                //  So just output the last response
                setResultToLastResponse();
                return;
            }

            if (this._PendingGeocodeRequest && this._PendingGeocodeRequest && this.DigSitesAreEqual(digsite, this._PendingGeocodeRequest.Digsite)
                && (ticketTypeID === this._PendingGeocodeRequest.TicketTypeID)) {
                //  This request is the same as a pending request that has not completed yet!  This can happen if there is a delay
                //  (of more than 300ms - because that's the debounce time in TicketEntryFormGroup.RegisterFieldChangeHandlers())
                //  with the previous request and focus is lost on the street name field.  That triggers a change which then triggers
                //  a geocode.  Just complete the observable which will cause the waiting task to just do nothing.
                //  Subscribe to the existing request so that we return the same result here.
                //  This is important if there is a pending geocode and we save the ticket - the verify before save triggers a geocode and
                //  needs to complete normally in order for the save to continue.  This is triggered immediately by DigSafe's
                //  Add Service Area function (which is a dialog edit that immediately triggers a save ticket so that the
                //  affected service area dialog is immediately shown).
                console.log("Waiting on pending geocode request for same location");
            }
            else {
                this._PendingGeocodeRequest = request;
                this._PendingGeocodeObservable = this.http.post<GeocodeResponse>(this._SettingsService.ApiBaseUrl + "/Maps/Geocoder/GeocodeDigSite", request);
            }

            this._PendingGeocodeObservable
                .subscribe(
                    response => {
                        this._PendingGeocodeRequest = null;
                        setResult(request, response);
                    }, err => {
                        this._PendingGeocodeRequest = null;
                        if (err.status === 404) {
                            //  Not found - still set response so it's cleared from the map.
                            setResult(request, null);
                        } else {
                            console.error("Error fetching geocode for dig site", err);
                            observer.error(err);
                            observer.complete();
                        }
                    }
                );
        });
    }

    private PlaceOrCountyChangeRequiresFindBestGeocode(request: GeocodeRequest): boolean {
        if (!this._LastDigSiteGeocodeRequest)
            return false;       //  Don't think this is possible...

        //  Currently deciding if we do this or not only if we also clip to the county/place (and the cooresponding value changes).
        //  That covers this for IN/KY (who reported the issue) without causing a big change in functionality for a OneCall that
        //  does not clip (which may only be AZ and NY at this point...  FL clips to County and DigSafe clips to Place).
        //  If clipping is done and the value changes, we definitely need to do this - because the manual digsite we already have will
        //  definitely not be in the new county/place).  So the only question may be if someone like AZ wants us to re-geocode on
        //  county change even though they don't clip.
        //  ClipDigSiteToCounty should not be enabled if One Call does not use county (DigSafe)!
        const clipToCounty = this._TicketEntryOptionsService.ClipDigSiteToCounty && this._SettingsService.UsesCountyInLocations ;
        const clipToPlace = this._TicketEntryOptionsService.ClipDigSiteToPlace;
        if (!clipToCounty && !clipToPlace)
            return false;       //  Not configured to do any clipping so don't need to check anything else

        //  Only compare against intersection1.  This matches what happens in DigSiteMap.OnSaveManualDigsite.
        const inter1 = request.Digsite.Intersections[DigSiteIntersectionItemTypeEnum[DigSiteIntersectionItemTypeEnum.Inter1]];
        const lastInter1 = this._LastDigSiteGeocodeRequest.Digsite.Intersections[DigSiteIntersectionItemTypeEnum[DigSiteIntersectionItemTypeEnum.Inter1]];

        if (clipToCounty || clipToPlace) {
            if (inter1.State !== lastInter1.State)
                return true;

            if (this._SettingsService.UsesCountyInLocations && (inter1.CountyName !== lastInter1.CountyName))
                return true;
        }

        if (clipToPlace && (inter1.PlaceName !== lastInter1.PlaceName))
            return true;

        return false;
    }

    private DigSitesAreEqual(digsite1: DigSite, digsite2: DigSite): boolean {
        //  For some reason, even though we delete some extra properties that we don't want/need to send to the server,
        //  those properties get put back (as null values) when we store the digsite into _LastDigSiteGeocodeRequest!
        //  So manually comparing just the properties in the DigSite that need to be checked.

        //  digsite1 is always expected to not be null...  So if digsite2 is null, they are not equal
        if (!digsite1 || !digsite2)
            return false;

        if (digsite1.DigsiteEnteredType !== digsite2.DigsiteEnteredType)
            return false;

        if (digsite1.FootprintAmount !== digsite2.FootprintAmount)
            return false;
        if (digsite1.FootprintUnits !== digsite2.FootprintUnits)
            return false;

        if (digsite1.BothSidesOfStreet !== digsite2.BothSidesOfStreet)
            return false;       //  Affects buffer size of NY parcels

        if ((digsite1.Latitude !== digsite2.Latitude) || (digsite1.Longitude !== digsite2.Longitude))
            return false;

        //  ** 1/8/2021: Changed to not check for changes using _.isEqual.  There are properties inside the
        //      Intersection that do not affect the geocode.  Specifically, the Caller Place.  And IN has special searching
        //      that is done for the dropdown list on their caller place.  So we can't have it re-geocoding as they
        //      enter the caller place - it serves no purpose and also wipes out the dropdown list and rebuilds it as they type...
        //  Use isEqual from lodash library to do comparision - handles equality checks on attributes.
        //  And for this to work, both objects must have been created from the FormGroup so that all
        //  optional/nullable fields are set consistently (the api may exclude those).
        //return _.isEqual(digsite1.Intersections, digsite2.Intersections);

        for (const intersectionType in DigSiteIntersectionItemTypeEnum) {
            if (!isNaN(parseInt(intersectionType)))
                continue;   //  TS enumerates the strings keys and also the numeric value...  We want the string key
            const intersection1 = digsite1.Intersections[intersectionType];
            const intersection2 = digsite2.Intersections[intersectionType];
            if (!this.DigSiteIntersectionsAreEqual(intersection1, intersection2))
                return false;
        }

        //  TODO: Tabbing through the street fields is triggering this to be checked.  Should figure out why.
        //  The field value must be getting set in to the FormControl - even when the value is the same...
        //  Should stop that from happening since it's just wasting CPU in the browser.
        //console.warn("DigSitesAreEqual: no changes");
        return true;
    }

    private DigSiteIntersectionsAreEqual(intersection1: DigSiteIntersection, intersection2: DigSiteIntersection): boolean {
        if (coerceBooleanProperty(intersection1) !== coerceBooleanProperty(intersection2))
            return false;       //  1 is null and the other is not
        if (!intersection1 && !intersection2)
            return true;        //  both null

        if (intersection1.State !== intersection2.State)
            return false;
        if (intersection1.CountyName !== intersection2.CountyName)
            return false;
        if (intersection1.PlaceName !== intersection2.PlaceName)
            return false;

        for (const streetType in DigSiteStreetItemTypeEnum) {
            if (!isNaN(parseInt(streetType)))
                continue;   //  TS enumerates the strings keys and also the numeric value...  We want the string key
            const street1 = intersection1.Streets[streetType];
            const street2 = intersection2.Streets[streetType];
            if (!this.DigSiteStreetsAreEqual(street1, street2))
                return false;
        }

        //  TODO: Offsets are not currently used so not bothering with them.

        return true;
    }

    private DigSiteStreetsAreEqual(street1: DigSiteStreet, street2: DigSiteStreet): boolean {
        if (coerceBooleanProperty(street1) !== coerceBooleanProperty(street2))
            return false;       //  1 is null and the other is not
        if (!street1 && !street2)
            return true;        //  both null

        if (street1.EnteredStreetAddress !== street2.EnteredStreetAddress)
            return false;

        //  Not checking these fields any more.  We only need the EnteredStreetAddress to do the geocode.
        //  Letting it parse it out itself so that we don't have to rely on the VerifyStreetAddress being called
        //  (which can having timing problems when we are saving if focus is in a dig site field that has been changed).
        //if (street1.FromAddress !== street2.FromAddress)
        //    return false;
        //if (street1.ToAddress !== street2.ToAddress)
        //    return false;

        //if (street1.Prefix !== street2.Prefix)
        //    return false;
        //if (street1.Name !== street2.Name)
        //    return false;
        //if (street1.StreetType !== street2.StreetType)
        //    return false;
        //if (street1.Suffix !== street2.Suffix)
        //    return false;

        return true;
    }

    /**
     * Remove properties from the DigSite that we do not need to send to the server for geocoding
     * @param digsite
     */
    private RemovePropertiesFromDigsiteNotNeededForGeocoding(digsite: DigSite): void {
        if (digsite.GeometryJson)
            delete digsite.GeometryJson;
        if (digsite.UnbufferedGeometryJson)
            delete digsite.UnbufferedGeometryJson;
        if (digsite.BufferFt)
            delete digsite.BufferFt;    //  Don't send this either - it's calculated on the server from the 2 footprint fields and then this value is returned to us in the result

        if (digsite.FootprintAmount === null)
            delete digsite.FootprintAmount;
        if (digsite.FootprintUnits === null)
            delete digsite.FootprintUnits;

        if (digsite.Latitude === null)
            delete digsite.Latitude;
        if (digsite.Longitude === null)
            delete digsite.Longitude;

        if (digsite.BothSidesOfStreet === null)
            delete digsite.BothSidesOfStreet;
        if (digsite.NearEdgeOfRoad === null)
            delete digsite.NearEdgeOfRoad;

        if (digsite.Intersections) {
            for (const intersectionType in digsite.Intersections) {
                const intersection = digsite.Intersections[intersectionType];

                delete intersection.Corner;
                delete intersection.Offsets;

                for (const streetType in intersection.Streets) {
                    const street = intersection.Streets[streetType];

                    if (!street.EnteredStreetAddress)
                        delete intersection.Streets[streetType];
                    else {
                        delete street.SideOfStreet;

                        //  If the EnteredStreetAddress does not match the LastVerifiedEnteredStreetAddress then the user has entered
                        //  something (without picking from the autocomplete dropdown) and we have not made the call to the
                        //  VerifyEnteredStreet api yet.  So we need to wipe out the individual component fields since those
                        //  are not for the EnteredStreetAddress.  That will see that there is no street.Name value and it will
                        //  parse EnteredStreetAddress to set the components - which is exactly what VerifyEnteredStreet would do.
                        //  This is necessary to handle when the user has entered something and then immediately clicks on the save
                        //  button - that causes a geocode to be done immediately (and before VerifyEnteredStreet has a chance to be called).
                        //  And it still allows us to keep the component fields when the user picks from an autocomplete which is
                        //  necessary to handle weird street component values (or, the unlikely event that our address parser
                        //  doesn't parse the value correctly).
                        if (street.EnteredStreetAddress !== street.LastVerifiedEnteredStreetAddress) {
                            delete street.FromAddress;
                            delete street.ToAddress;
                            delete street.Prefix;
                            delete street.Name;
                            delete street.StreetType;
                            delete street.Suffix;
                        }

                        delete street.LastVerifiedEnteredStreetAddress;
                    }
                }

                //  Never remove the first intersection - it's needed for place/county geocode!
                if ((Object.keys(intersection.Streets).length === 0) && (intersectionType !== "Inter1"))
                    delete digsite.Intersections[intersectionType];
            }
        }
    }

    /*
     *  Process the manual digsite.  This will clip it against the county/place if necessary and also
     *  compute the NearRailroad if necessary.
     */
    public ProcessManualGeocode(ticketTypeID: string, state: string, county: string, place: string, geoJson: object, bufferFt: number, unbufferedGeoJson: object): Observable<GeocodeResponse> {
        const request = new ProcessManualDigSiteRequest(ticketTypeID, state, county, place, geoJson, bufferFt, unbufferedGeoJson);

        return this.http.post<GeocodeResponse>(this._SettingsService.ApiBaseUrl + "/Maps/Geocoder/ProcessManualGeocode", request);
    }

    /**
     * Checks the given digsite against the last geocoded digsie (or the one given to InitializeDigSite()
     * if no other geocode has been done).  If different, re-geocodes and updates the GeocodeGeoJson
     * observable (which is monitored by the map).
     * @param nearStreetText
     * @param digsiteEnteredType
     * @param digsiteInter1
     */
    GeocodeNearStreetIfChanged(nearStreetText: string, digsiteEnteredType: DigsiteEnteredTypeEnum, digsiteInter1: DigSiteIntersection, forProjectTypeID?: string): Observable<GeocodeResponse> {
        return new Observable<GeocodeResponse>(observer => {
            const me = this;
            const setResult = function (request: GeocodeRequest, response: GeocodeResponse) {
                me._LastNearStreetGeocodeRequest = request;
                me._LastNearStreetGeocodeResponse = response;

                observer.next(response);
                observer.complete();
            };

            if (!this.CanGeocodeNearStreet(nearStreetText, digsiteEnteredType, digsiteInter1)) {
                setResult(null, null);
                return;
            }

            //  Construct a DigSite to be used to geocode
            const digsite: DigSite = {
                DigsiteEnteredType: DigsiteEnteredTypeEnum.Street,
                Intersections: {
                    Inter1: {
                        ItemType: DigSiteIntersectionItemTypeEnum.Inter1,
                        State: digsiteInter1.State,
                        CountyName: digsiteInter1.CountyName,
                        PlaceName: digsiteInter1.PlaceName,
                        Streets: {
                            Street: {
                                ItemType: DigSiteStreetItemTypeEnum.Street,
                                EnteredStreetAddress: nearStreetText
                            }
                        }
                    }
                }
            };

            //  Use isEqual from lodash library to do comparision - handles equality checks on attributes.
            //  And for this to work, both objects must have been created from the FormGroup so that all
            //  optional/nullable fields are set consistently (the api may exclude those).
            if (this._LastNearStreetGeocodeResponse && _.isEqual(digsite, this._LastNearStreetGeocodeRequest.Digsite)) {
                //  Digsite is the same as the last geocode so just output the last response
                setResult(this._LastNearStreetGeocodeRequest, this._LastNearStreetGeocodeResponse);
                return;
            }

            //  Include adjacent places here in case the near street is outside the current place.
            //  i.e. FL, Volusia, Debary, Gardenia Ave - Near st = Hummingbird St.  It's very close...
            //  ** Set "ForMapSearch" which will ignore the users allowed geocode types - needed for users in single address
            //  type roles that are not allowed to do street lookups.
            const request = new GeocodeRequest(digsite, GeocodeTypeEnum.Street, undefined, forProjectTypeID, false, true, undefined, true);

            this.http.post<GeocodeResponse>(this._SettingsService.ApiBaseUrl + "/Maps/Geocoder/GeocodeDigSite", request)
                .subscribe(
                    response => {
                        setResult(request, response);
                    }, err => {
                        if (err.status === 404)
                            setResult(request, null);       //  Not found - still set response so it's cleared from the map and distance is recalculated
                        else {
                            console.error("Error fetching geocode for near street", err);
                            observer.error(err);
                            observer.complete();
                        }

                        setResult(request, null);
                    }
                );
        });
    }

    public SetManualDigsite(geometryJson: object, bufferFt: number, unbufferedGeometry: object): void {
        this._LastDigSiteGeocodeResponse = new GeocodeResponse(GeocodeTypeEnum.Manual, geometryJson, bufferFt, unbufferedGeometry);
    }

    private CanGeocodeDigsite(digsite: DigSite): boolean {
        if (!digsite)
            return false;

        if (digsite.Latitude && digsite.Longitude)
            return true;        //  Nothing else needed for lat/lon

        if (!digsite.Intersections || !digsite.Intersections.Inter1)
            return false;

        const inter1 = digsite.Intersections.Inter1;
        if (!inter1.State)
            return false;

        if (this._SettingsService.UsesCountyInLocations && !inter1.CountyName)
            return false;

        return true;
    }

    private CanGeocodeNearStreet(nearStreetText: string, digsiteEnteredType: DigsiteEnteredTypeEnum, digsiteInter1: DigSiteIntersection): boolean {
        if (!nearStreetText || _.isEmpty(nearStreetText))
            return false;

        //  Do not geocode the near street unless it's a Street or Lat/Lon dig site type.  Those are the only
        //  ones that allow entering a Near Street.
        //  But...that could potentially be different depending on the One Call.  If that happens, will need to figure
        //  out another way to handle this.
        //  This is necessary to prevent the Near Street from continuing to display if you enter a street + near street
        //  then change the digsite entered type to one that does not allow a near street.
        if ((digsiteEnteredType !== DigsiteEnteredTypeEnum.Street) && (digsiteEnteredType !== DigsiteEnteredTypeEnum.LatLon))
            return false;

        //  Near street geocode is only done to street (no fallback) so must have State/County/Place
        //  from intersection 1 of dig site
        if (!digsiteInter1 || _.isEmpty(digsiteInter1.State) || _.isEmpty(digsiteInter1.PlaceName))
            return false;

        if (this._SettingsService.UsesCountyInLocations && _.isEmpty(digsiteInter1.CountyName))
            return false;

        return true;
    }

    public FindBestZoomExtents(state: string, countyName: string, placeName: string): Observable<LatLonBounds> {
        //  Because sometimes the values come in as null and sometimes as undefined.  This keeps the comparisons easier
        //  and then won't send the values to the server if the value is undefined.
        if (!state)
            state = undefined;
        if (!countyName)
            countyName = undefined;
        if (!placeName)
            placeName = undefined;

        if (this._LastFindBestZoomExtentsRequest && (this._LastFindBestZoomExtentsRequest.State === state)
            && (this._LastFindBestZoomExtentsRequest.CountyName === countyName) && (this._LastFindBestZoomExtentsRequest.PlaceName === placeName))
        {
            return of(this._LastFindBestZoomExtentsResponse);
        }

        const request = new BestZoomExtentsRequest(state, countyName, placeName);

        return new Observable<LatLonBounds>(observer => {
            this.http.post<LatLonBounds>(this._SettingsService.ApiBaseUrl + "/Maps/Geocoder/FindBestZoomExtents", request)
                .subscribe(
                    response => {
                        this._LastFindBestZoomExtentsRequest = request;
                        this._LastFindBestZoomExtentsResponse = response;
                        observer.next(response);
                        observer.complete();
                    }, () => {
                        //  On error, return null and caller will position to full extents
                        observer.next(null);
                        observer.complete();
                    }
                );
        });
    }

    public SplitDigSite(lengthFt: number, digsite: DigSite, ticketTypeID: string): Observable<SplitGeocodeItemResponse[]> {
        const inter1 = digsite.Intersections["Inter1"];

        const request = new SplitGeocodeRequest(lengthFt, digsite.UnbufferedGeometryJson, digsite.BufferFt, ticketTypeID, inter1.State, inter1.CountyName, inter1.PlaceName);
        const url = this._SettingsService.ApiBaseUrl + "/Maps/Geocoder/SplitDigSite";
        return this.http.post<SplitGeocodeItemResponse[]>(url, request);
    }
}
