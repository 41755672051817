<div style="width: 100vw; height: 100%; background-color: white">
    <div style="display: flex; flex-direction: column">
        <mat-toolbar class="iq-display-1">
            <div class="iq-phone-header-left">
                <iq-phone-button Text="Cancel" (click)="Close()"></iq-phone-button>
            </div>
            <div class="iq-phone-header-center">Add Comment</div>
            <div class="iq-phone-header-right">
                <iq-phone-button Text="Save" (click)="Save()" [disabled]="!addComponent.CanSave"></iq-phone-button>
            </div>
        </mat-toolbar>

        <iq-ticket-add-excavator-comment #addComponent style="padding:10px" [Data]="Data"></iq-ticket-add-excavator-comment>
    </div>
</div>
