
export enum SearchFilterOperatorEnum {
    //  If multiple values given, evaluates as "is one of" - i.e. (prop = val1) or (prop = val2) or (prop = val3) etc...
    Contains = 0,               //  Only "contains" for strings, other types will automatically do Equals
    Equals = 1,
    NotEqual = 2,
    IsNotNull = 3,
    IsNull = 4,
    StartsWith = 5,

    GreaterThan = 10,
    GreaterThanOrEqual = 11,

    LessThan = 20,
    LessThanOrEqual = 21,

    // Evaluated as value1 <= value < value2
    Between = 30,

    //  Using Between is also valid for a single date, but this is a much clearer way to indicate this is what we want.
    //  And it was needed by the Report parameter prompting to tell the difference between a date range parameter and a single date.
    SingleDate = 31,            

    Yesterday = 40,
    PastDaysFromToday = 41,     //  Past: Days before (and including) today.  Originally, value of 0 was used as Today.  Now have separate enum for this for clarity but both still work.
    PastHoursFromNow = 42,      //  Past: Hours before current time
    LastWeek = 43,              //  Last Week (last Sunday to last Saturday)
    LastMonth = 44,
    Tomorrow = 45,              //  Future
    DaysFromToday = 46,         //  Future: Days after (and including) today
    HoursFromNow = 47,          //  Future: Hours after current time
    BusinessDaysBeforeAndAfterToday = 48,
    Today = 49,                             //  Can also use PastDaysFromToday w/value=0 but this was added for clarity and to help with allowing Today on query report configs
    DaysBeforeAndNotIncludingToday = 50,    //  Past: Days before (and *NOT* including) today
    DaysAfterAndNotIncludingToday = 51,     //  Future: Days after (and *NOT* including) today

    CurrentUser = 55,

    GeometryIntersects = 60,    //  Geometry filter

    IsOneOf = 70,               //  A single FilterValue can contain an enumerable list of values (used to roll up  multiple values in to a single filter)

    //  These custom filter operators are used with a custom filter (defined in the repository MapSearchFilterByProperty method).
    //  Allows us to write a filter function without needing to do some kind of standard operator against a property name.
    CustomArray = 100,          //  Send the search value directly to the function as an array
    CustomOr = 101,             //  Each value in the search value list generates an expression.  Each expression is or'd together.
    CustomAnd = 102,            //  Each value in the search value list generates an expression.  Each expression is and'd together.
    CustomCondition = 103       //  A custom built condition that has no parameters
}

export enum SearchFilterOperatorEnumDescriptions {
    NotEqual = "Not equal",
    IsNotNull = "Is not null",
    IsNull = "Is null",
    StartsWith = "Starts with",

    GreaterThan = "Greater than",
    GreaterThanOrEqual = "Greater than or equal",

    LessThan = "Less than",
    LessThanOrEqual = "Less than or equal",

    PastDaysFromToday = "Days before (and including) today",
    PastHoursFromNow = "Hours before the current time",
    LastWeek = "Last week",
    LastMonth = "Last month",
    DaysFromToday = "Days after (and including) today",
    HoursFromNow = "Hours after the current time",
    BusinessDaysBeforeAndAfterToday = "Business days before and after today",
    Today = "Today",
    DaysBeforeAndNotIncludingToday = "Days before (and not including) today",
    DaysAfterAndNotIncludingToday = "Days after (and not including) today",

    CurrentUser = "Current User",

    IsOneOf = "One of"
}
