import { formatDate } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FeatureItemResponse } from '@iqModels/Maps/FeatureItemResponse.model';
import * as _ from 'lodash';
import { TicketMapItem } from 'Models/Tickets/TicketMapItem.model';
import * as ol from 'ol';
import { Coordinate } from 'ol/coordinate';
import { EventsKey } from 'ol/events';
import { Extent } from 'ol/extent';
import { unByKey } from 'ol/Observable';
import { TicketsLayer } from 'Pages/Tickets/TicketMapViewer/Layers/TicketsLayer';
import { TicketMapViewerService } from 'Pages/Tickets/TicketMapViewer/Services/TicketMapViewer.service';
import { Observable, of } from 'rxjs';
import { CommonService } from 'Services/CommonService';
import { MapSearchService } from 'Services/MapSearchService';
import { MapBaseComponent } from 'Shared/Components/Maps/MapBase.component';

@Component({
    templateUrl: './TicketMapViewer.component.html',
    styleUrls: ['./TicketMapViewer.component.scss'],
    providers: [ CommonService ]
})
export class TicketMapViewerComponent extends MapBaseComponent implements OnDestroy {
    public get CurrentStateAbbreviation(): string { return null; }
    public get CurrentCountyName(): string { return null; }
    public get MapSearchFilterBounds(): Extent { return null; }

    private _TicketsLayer: TicketsLayer;

    private _CachedMapItems: { [TicketID: string]: TicketMapItem } = {};

    private _PointerMoveEventsKey: EventsKey;
    private _ClickEventsKey: EventsKey;

    constructor(commonService: CommonService, mapSearchService: MapSearchService, private _TicketMapViewerService: TicketMapViewerService) {
        super(commonService, mapSearchService);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this._PointerMoveEventsKey) {
            unByKey(this._PointerMoveEventsKey);
            this._PointerMoveEventsKey = null;
        }
        if (this._ClickEventsKey) {
            unByKey(this._ClickEventsKey);
            this._ClickEventsKey = null;
        }
    }

    public ShowTicketsNearMe(): void {
        this.Clear();

        const lastZoomMethod = localStorage.getItem("ticketDashboardZoomMethod");
        if (lastZoomMethod === "tickets")
            this.ZoomToTicketExtents();
        else
            this.PositionToCurrentLocation();       //  Defaults to location if not set

        //let testCoord = [-111.9618, 33.3445];       //  AZ
        //let testCoord = [-81.3108, 28.8755];       //  FL
        //const coord = ol.proj.transform(testCoord, MapConstants.LATLON_PROJECTION, MapConstants.MAP_PROJECTION);
        //setTimeout(() => this.ZoomToCoordinate(coord, 17));
    }

    public PositionToCurrentLocation(): void {
        this.SetLastZoomMethod("location");
        super.PositionToCurrentLocation();
    }

    protected HandleCurrentPositionError(error: GeolocationPositionError): void {
        //  This will show the default dialog
        super.HandleCurrentPositionError(error);

        this.ZoomToTicketExtents();
    }

    protected HandleCurrentPositionNotInMapBounds(): void {
        //  Default is to zoom to full extents - try to zoom to tickets first
        this.ZoomToTicketExtents();
    }

    public ZoomToTicketExtents(): void {
        this.SetLastZoomMethod("tickets");
        this._TicketMapViewerService.Extents().subscribe(extents => this.ZoomToLatLonBounds(extents), () => this.ZoomToFullMapExtents());
    }

    private SetLastZoomMethod(method: string): void {
        try {
            localStorage.setItem("ticketDashboardZoomMethod", method);
        } catch { }
    }

    //  TODO: Add method to accept a ticket SearchRequest.
    //      1) Clear the current cached info
    //      2) Increment some kind of query ID that is used in the tile URL - so that we can refresh the map tiles
    //      3) Send the SearchRequest in the map tile requests.

    private Clear(): void {
        this._CachedMapItems = {};
    }

    protected OnMapInitialized(map: ol.Map): boolean {
        const ticketTileURL = this.CommonService.SettingsService.ApiBaseUrl + "/Maps/Tiles/Tickets/{z}/{x}/{y}";
        this._TicketsLayer = new TicketsLayer(map, ticketTileURL, this.CommonService.AuthenticationService);

        this._PointerMoveEventsKey = map.on('pointermove', evt => this.OnPointerMove(evt));
        this._ClickEventsKey = map.on('click', evt => this.OnClick(evt));

        return true;            //  Tells base to not position to default extents
    }

    protected GetBestFitExtents(): Extent {
        return null;
    }

    protected OnPointerMove(evt: any): void {
        this._TicketsLayer.SelectedTicketID = this.FindSelectedTicketIDAtPixel(evt.pixel);
    }

    protected OnClick(evt: any): void {
        this._TicketsLayer.SelectedTicketID = this.FindSelectedTicketIDAtPixel(evt.pixel);
    }

    //  This is called when the context menu is displayed if IsContextMenuDirty is set to true.
    //  So can rebuild the menu by setting that property.
    protected BuildContextMenuItems(): any[] {
        const contextMenuItems = super.BuildContextMenuItems();

        //  TODO: Add option to view ticket from here?

        return contextMenuItems;
    }

    private FindSelectedTicketIDAtPixel(pixel: Coordinate): string {
        //  Find the ID (or one of them) that is being hovered over.  Set that ID into the TicketsLayer.
        //  If it changes, it will trigger a change on the layer to redraw it.  Which will cause any features
        //  with the same ID to be highlighted.  This works better for a MVT tile layer because the features may
        //  be clipped randomly.  So this will re-style ALL of the features for that ID no matter how they were clipped.
        let selectedTicketID: string = null;
        let keepCurrentID: boolean = false;

        this.Map.forEachFeatureAtPixel(pixel, (feature/*, layer*/) => {
            const id = feature.getProperties()["ID"];

            //  If we find the currently selected ID, keep it.  This keeps things from flashing when moving the mouse
            //  slightly when the features are chopped and overlapping with another dig site.
            if (id === this._TicketsLayer.SelectedTicketID) {
                keepCurrentID = true;
                return true;        //  This tells forEachFeatureAtPixel to stop iterating
            }
            else
                selectedTicketID = id;
        }, { hitTolerance: 5 });

        return keepCurrentID ? this._TicketsLayer.SelectedTicketID : selectedTicketID;
    }

    public GetAdditionalMapFeaturesForPopup(pixel: Coordinate, selectionBox: Extent): Observable<{ Features: FeatureItemResponse[], Exclusive: boolean }> {
        return new Observable<{ Features: FeatureItemResponse[], Exclusive: boolean }>(observer => {
            this.GetAllMapItemsAtPixel(pixel)
                .subscribe(mapItems => {
                    if (!mapItems || (mapItems.length === 0))
                        observer.next({ Features: [], Exclusive: false });
                    else {
                        const totalItems = mapItems.length;

                        mapItems = _.sortBy(mapItems, m => m.ExpiresDate);
                        mapItems = _.take(mapItems, 5);
                        const featureItems = mapItems
                            .map(m => {
                                const featureName = m.TicketNumber + '-v' + m.Version
                                    + ', Responses: ' + m.NumResponsesReceived + '/' + m.NumResponsesExpected
                                    + ', Expires: ' + formatDate(m.ExpiresDate, this.CommonService.SettingsService.DateTimeFormat, "en-US");

                                return new FeatureItemResponse("Ticket", featureName, null);
                            });

                        if (totalItems > 5)
                            featureItems.push(new FeatureItemResponse("Ticket", "...plus " + (totalItems - 5) + " more tickets", null));

                        observer.next({ Features: featureItems, Exclusive: false });
                    }
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    private GetAllMapItemsAtPixel(pixel: Coordinate): Observable<TicketMapItem[]> {
        const mapItems: TicketMapItem[] = [];
        const missingTicketIDs: string[] = [];

        this.Map.forEachFeatureAtPixel(pixel, (feature/*, layer*/) => {
            const ticketID = feature.getProperties()["ID"];
            if (ticketID) {
                const item = this._CachedMapItems[ticketID];
                if (item && (mapItems.indexOf(item) === -1))
                    mapItems.push(item);
                else if (!item && (missingTicketIDs.indexOf(ticketID) === -1))
                    missingTicketIDs.push(ticketID);
            }
        }, { hitTolerance: 5 });

        if (missingTicketIDs.length === 0)
            return of(mapItems);

        return new Observable<TicketMapItem[]>(observer => {
            this._TicketMapViewerService.MapItems(missingTicketIDs)
                .subscribe(fetchedItems => {
                    fetchedItems.forEach(mi => {
                        this._CachedMapItems[mi.ID] = mi;
                        mapItems.push(mi);
                    });

                    observer.next(mapItems);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }
}
