import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MapToolService } from "../MapToolService";
import { MapBrowserEvent, Map } from "ol";
import { Pointer } from "ol/interaction";
import ol_Overlay_Popup from "ol-ext/overlay/Popup";
import { EventsKey } from "ol/events";
import { unByKey } from "ol/Observable";

//  Extends an OpenLayers Pointer interaction
export class MapTooltipsInteraction extends Pointer {

    private _FetchFeatures: Subject<MapBrowserEvent<any>> = new Subject();

    private _PopupOverlay: any;     //  type = ol.Overlay.Popup, but there are no typescript mappings currently available for this

    private _Enabled: boolean = true;
    private _MapIsMoving: boolean = false;

    private _FetchFeaturesSubscription: Subscription;
    private _MapMoveStartEventsKey: EventsKey = null;
    private _MapMoveEndEventsKey: EventsKey = null;
    private _MouseOutHandler: () => void;

    constructor(public MapToolService: MapToolService) {
        super();
    }

    public setMap(map: Map): void {
        if (this._FetchFeaturesSubscription) {
            this._FetchFeaturesSubscription.unsubscribe();
            this._FetchFeaturesSubscription = null;
        }

        if (this._MouseOutHandler && this.getMap()) {
            this.getMap().getViewport().removeEventListener("mouseout", this._MouseOutHandler);
            this._MouseOutHandler = null;
        }

        if (this._MapMoveStartEventsKey) {
            unByKey(this._MapMoveStartEventsKey);
            this._MapMoveStartEventsKey = null;
        }

        if (this._MapMoveEndEventsKey) {
            unByKey(this._MapMoveEndEventsKey);
            this._MapMoveEndEventsKey = null;
        }

        if (this._PopupOverlay && this.getMap()) {
            this.getMap().removeOverlay(this._PopupOverlay);
            this._PopupOverlay = null;
        }

        super.setMap(map);

        if (!map) {
            this.handleMoveEvent = null;
            return;     //  being destroyed
        }

        this.handleMoveEvent = (evt: MapBrowserEvent<any>) => this.OnMouseMoveEvent(evt);

        this._FetchFeaturesSubscription = this._FetchFeatures
            .pipe(debounceTime(800))
            .subscribe(evt => this.OnFetchFeatures(evt));

        this._PopupOverlay = new ol_Overlay_Popup(
            {
                popupClass: "default anim", //"tooltips", "warning" "black" "default", "tips", "shadow",
                closeBox: false,
                positioning: 'auto',
                autoPan: true,
                autoPanAnimation: { duration: 250 }
            });
        map.addOverlay(this._PopupOverlay);

        //  Need to track this so that we can prevent the tooltip from showing when we mouse OUT of the map
        this._MouseOutHandler = () => {
            //  Set null into this to overwrite whatever the last value was so we know to do nothing
            this._FetchFeatures.next(null);
        }
        map.getViewport().addEventListener("mouseout", this._MouseOutHandler);

        //  Cancel and stop monitoring when the map is moving.
        this._MapMoveStartEventsKey = map.on('movestart', () => {
            this._MapIsMoving = true;
            this.CancelPending();         //  Previous event could be for a position that is no longer in the currently displayed view!
        });
        this._MapMoveEndEventsKey = map.on('moveend', () => {
            this._MapIsMoving = false;
            this.CancelPending();         //  Previous event could be for a position that is no longer in the currently displayed view!
        });
    }

    /**
     *  Call this method to cancel any pending (waiting for delay) tooltips.
     * */
    public CancelPending(): void {
        this._FetchFeatures.next(null);
    }

    public Enabled(enabled: boolean): void {
        this._Enabled = enabled;
    }

    private OnMouseMoveEvent(evt: MapBrowserEvent<any>): void {
        this.ClearPopupTimeout();

        if (this._PopupOverlay) {
            this._PopupOverlay.hide();
            this._FetchFeatures.next(evt);
        }
    }

    private _DismissPopupTimeout: NodeJS.Timeout = null;

    private ClearPopupTimeout(): void {
        if (!this._DismissPopupTimeout)
            return;

        clearTimeout(this._DismissPopupTimeout);
        this._DismissPopupTimeout = null;
    }

    private OnFetchFeatures(evt: MapBrowserEvent<any>): void {
        if (!evt)
            return;     //  happened when we "mouseout" of the map.

        if (!this._Enabled || this._MapIsMoving)
            return;     //  Ignore if disabled or the map is in the process of moving (otherwise, this will reposition the map!)

        //  Don't show if near the edges of the view - it causes confusion if the user is trying to click on a button
        const frameState: any = evt.frameState;     //  b/c "size" property is not in type definitions...
        if ((evt.pixel[0] < 45) || (evt.pixel[1] < 45) || (evt.pixel[0] > (frameState.size[0] - 45)) || (evt.pixel[1] > (frameState.size[1] - 45))) {
            return;
        }

        if (!evt.coordinate || (evt.coordinate.length !== 2))
            return;     //  Don't know why, but sometimes get invalid coordinate here which will cause a JS error inside ol-ext when calling .show()

        this.MapToolService.MapSearchService.CreatePopupContentForLocation(evt.pixel, evt.coordinate, evt.frameState.viewState.resolution, this.getMap().getView().getZoom())
            .subscribe(content => {
                if (content) {
                    this.ClearPopupTimeout();

                    try {
                        if (this._PopupOverlay)
                            this._PopupOverlay.show(evt.coordinate, content);
                    } catch {
                        //  This is somehow throwing an error sometimes due to it (internally) getting an invalid coordinate.  So just ignore exceptions.
                    }

                    this._DismissPopupTimeout = setTimeout(() => {
                        if (this._PopupOverlay)
                            this._PopupOverlay.hide();
                        this._DismissPopupTimeout = null;
                    }, 5000);
                }
            });
    }
}
