import { Component, TemplateRef, Input, ContentChild, Output, EventEmitter, TrackByFunction, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { NgForOfContext } from '@angular/common';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { Element } from '@angular/compiler';

//  TODO: This component is just a wrapper for the ngx-virtual-scroller component.
//  All it adds is a loading spinner.  Is this necessary?  Most of the places where this is used are not
//  fetching additional rows from the api - they load everything at once and then this is used to make the
//  rendering more efficient.  So the loading should never be done in those cases and is probably what
//  is causing the service area list to be so jerky when scrolling.
@Component({
  selector: 'iq-virtual-scroll',
  templateUrl: './VirtualScroll.component.html',
  styleUrls: ['./VirtualScroll.component.css']
})
export class VirtualScrollComponent {
  
  /** The data that builds the templates within the virtual scroll. This is the same data that you'd pass to ngFor. It's important to note that when this data has changed, then the entire virtual scroll is refreshed. */
  @Input() Items: any[];
  @Input() TrackByFn: TrackByFunction<any> = (index, item) => { return index };
  /** Are new items being loaded */
  @Input() LoadingItems: boolean = false;
  /** When scrolling fire the event to load new when this number of items are left to be shown.  By default it's 10 */
  @Input() ItemBuffer: number = 10;


  /** ngx-virtual-scroller pass through options https://github.com/rintoj/ngx-virtual-scroller */

  /** The number of elements to be rendered above & below the current container's viewport. Increase this if enableUnequalChildrenSizes isn't working well enough. */
  @Input() bufferAmount: number;
  
  /** How often in milliseconds to check if virtual-scroller (or parentScroll) has been resized. If resized, it'll call Refresh() method */
  @Input() checkResizeInterval: number = 1000;//ngx default
  
  /** Predicate of syntax (item1:any, item2:any)=>boolean which is used when items array is modified to determine which items have been changed (determines if cached child size measurements need to be refreshed or not for enableUnequalChildrenSizes). */
  //@Input() compareItems: (item1:any, item2:any)=>boolean;

  /** If you want to use the "unequal size" children feature. This is not perfect, but hopefully "close-enough" for most situations. */
  @Input() enableUnequalChildrenSizes: boolean = true;//We ovrride this to be true, because most of our use cases that's what we want

  /** Disables full-app Angular ChangeDetection while scrolling, which can give a performance boost. Requires developer to manually execute change detection on any components which may have changed. USE WITH CAUTION - Read the "Performance" section below. */
  @Input() executeRefreshOutsideAngularZone: boolean = false;//ngx default
  
  /** Whether the scrollbars should be vertical or horizontal. */
  @Input() horizontal: boolean = false;//ngx default

  /** Set to false if you want to prevent ngx-virtual-scroller from automatically changing the overflow style setting of the parentScroll element to 'scroll'. */
  @Input() modifyOverflowStyleOfParentScroll: boolean = true;//ngx default

  /** Element (or window), which will have scrollbar. This element must be one of the parents of virtual-scroller */
  @Input() parentScroll: Element | Window;

  /** Set to true if you want horizontal slider to support right to left script (RTL). */
  //@Input() RTL: boolean = false;//ngx default

  /** How many pixels to ignore during resize check if virtual-scroller (or parentScroll) are only resized by a very small amount. */
  @Input() resizeBypassRefreshThreshold: number = 5;//ngx default

  /** The time in milliseconds for the scroll animation to run for. 0 will completely disable the tween/animation. */
  @Input() scrollAnimationTime: number = 750;//ngx default

  /** Milliseconds to delay refreshing viewport if user is scrolling quickly (for performance reasons). 
   * 
   * This API is meant as a quick band-aid fix for performance issues. Please read the performace section here https://github.com/rintoj/ngx-virtual-scroller.
   * 
   * If both Debounce & Throttling are set, debounce takes precedence.
  */
  @Input() scrollDebounceTime: number = 0;//ngx default // = 10?

  /** Milliseconds to delay refreshing viewport if user is scrolling quickly (for performance reasons). 
   * 
   * This API is meant as a quick band-aid fix for performance issues. Please read the performace section here https://github.com/rintoj/ngx-virtual-scroller.
   * 
   * If both Debounce & Throttling are set, debounce takes precedence.
  */
  @Input() scrollThrottlingTime: number = 0;//ngx default // = 10;

  /** If you want to override the auto-calculated scrollbar height. This is used to determine the dimensions of the viewable area when calculating the number of items to render. */
  @Input() scrollbarHeight: number;

  /** If you want to override the auto-calculated scrollbar width. This is used to determine the dimensions of the viewable area when calculating the number of items to render. */
  @Input() scrollbarWidth: number;

  /** The hard-coded height of the item template's cell to use if rendering via Angular Universal/Server-Side-Rendering */
  @Input() ssrChildHeight: number;

  /** The hard-coded width of the item template's cell to use if rendering via Angular Universal/Server-Side-Rendering */
  @Input() ssrChildWidth: number;

  /** The hard-coded visible height of the virtual-scroller (or [parentScroll]) to use if rendering via Angular Universal/Server-Side-Rendering. */
  @Input() ssrViewportHeight: number = 1080;//ngx default

  /** The hard-coded visible width of the virtual-scroller (or [parentScroll]) to use if rendering via Angular Universal/Server-Side-Rendering. */
  @Input() ssrViewportWidth: number = 1920;//ngx default

  /** Set to true if you use a striped table. In this case, the rows will be added/removed two by two to keep the strips consistent. */
  @Input() stripedTable: boolean = false;//ngx default

  /** Translate is faster in many scenarios because it can use GPU acceleration, but it can be slower if your scroll container or child elements don't use any transitions or opacity. More importantly, translate creates a new "containing block" which breaks position:fixed because it'll be relative to the transform rather than the window. If you're experiencing issues with position:fixed on your child elements, turn this flag on. */
  @Input() useMarginInsteadOfTranslate: boolean = false;//ngx default
  


  @Output() LoadMoreItems: EventEmitter<any> = new EventEmitter()

  private destroyed$: Subject<void> = new Subject();

  @ContentChild(TemplateRef, {static: true}) itemTemplate: TemplateRef<NgForOfContext<any>>;
  @ViewChild(VirtualScrollerComponent, {static: true}) private virtualScroller: VirtualScrollerComponent;

  constructor() {

  }

  ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
  }

  fetchNextPage($event) {
      //Only fire the event to get the next page if we are showing the last items in the list.
      if (this.LoadingItems || !this.Items || $event.endIndex < (this.Items.length - this.ItemBuffer)) {
          return;
      }

      this.LoadMoreItems.emit($event);
  }

  scrollToIndex(index:number, alignToBeginning:boolean = true, additionalOffset:number = 0, animationMilliseconds:number = undefined, animationCompletedCallback:()=>void = undefined){
    this.virtualScroller.scrollToIndex(index, alignToBeginning, additionalOffset, animationMilliseconds, animationCompletedCallback);
  }

  get maxScrollPosition() { 
    return this.virtualScroller.viewPortInfo.maxScrollPosition; 
  }
  
  scrollToPosition(scrollPosition:number, animationMilliseconds:number = undefined, animationCompletedCallback: ()=>void = undefined){
    this.virtualScroller.scrollToPosition(scrollPosition, animationMilliseconds, animationCompletedCallback);
  }
  scrollInto(item:any, alignToBeginning:boolean = true, additionalOffset:number = 0, animationMilliseconds:number = undefined, animationCompletedCallback:()=>void = undefined){
    this.virtualScroller.scrollInto(item, alignToBeginning, additionalOffset, animationMilliseconds, animationCompletedCallback);
  }

  refresh(){
    this.virtualScroller.refresh();
  }

  invalidateAllCachedMeasurements(){
    this.virtualScroller.invalidateAllCachedMeasurements();
  }
  invalidateCachedMeasurementAtIndex(index: number){
    this.virtualScroller.invalidateCachedMeasurementAtIndex(index);
  }
  invalidateCachedMeasurementForItem(item: any){
    this.virtualScroller.invalidateCachedMeasurementForItem(item);
  }
}
