import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { tap, finalize, map, concatMap, catchError, retry, retryWhen, delay } from 'rxjs/operators';

import { AuthenticationService } from 'Services/AuthenticationService';
import { SettingsService } from 'Services/SettingsService';
import { BusyService } from 'Services/BusyService';
import { ToastrService } from 'ngx-toastr';
import { AppUpdateService } from 'Services/AppUpdateService';

//  About HttpInterceptor: https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8

@Injectable({
    providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
    private _ActiveApiCalls: number = 0;

    constructor(private injector: Injector, private authenticationService: AuthenticationService, private busyService: BusyService,
        private _AppUpdateService: AppUpdateService)
    { }

    //  ***
    //  To bypass this interceptor for specific api calls, construct the HttpClient using an HttpBackend object.
    //  See: https://stackoverflow.com/questions/46469349/how-to-make-an-angular-module-to-ignore-http-interceptor-added-in-a-core-module/49013534#49013534
    //  ***

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //  Can't inject these.  For some reason, something is triggering a cyclic dependency error.
        //  https://github.com/angular/angular/issues/18224
        //  But getting it via the injector works!
        const settingsService = this.injector.get(SettingsService);

        let headerObserv = of<HttpRequest<any>>(request.clone({
            withCredentials: true
        }));

        //  Add our authorization token to all requests to our api.
        if (request.url.startsWith(settingsService.ApiBaseUrl)) {
            headerObserv = this.authenticationService.getAuthorizationHeaderValueObservable().pipe(
                map(token => {
                    let headers = new HttpHeaders();

                    //  Don't set the Content-Type if we're uploading a file - that is done as a multi-part form
                    //  And don't check using "endsWith" - we add a url parameter when uploading registration shapefiles.
                    if (request.url.indexOf("/UploadFile") < 0)
                        headers = headers.set('Content-Type', 'application/json');

                    //  This header is required in order to bypass the ServiceWorker - which causes problems with the FormData of /UploadFile.
                    //  https://stackoverflow.com/questions/56413685/form-data-not-always-send-in-chrome-when-uploading-file-with-angular-7
                    //  Requests that go through the service worker are also *NOT* cancelable!
                    //  This seems to be because under the covers, the service worker uses a new "fetch" api to do it's http call.
                    //  And that currently has no way to abort!  One of many issues about it: https://github.com/w3c/ServiceWorker/issues/592
                    //  That specifically affects the /search api which we cancel when we issue another request.  The server catches
                    //  the cancellation and also cancels the db query.  This is needed to prevent big queries from piling up in the
                    //  database and can easily happen if the user is quickly flipping through pages or changing the filters.
                    //  We don't currently need any of our api requests to go through the service worker and that seems to cause
                    //  more harm than good.  So we are currently bypassing *ALL* /api requests right now.
                    //  If we want to enable it for some, we will need to still bypass for /UploadFiles and all /search calls
                    //  (as well as any others that may be cancelable...)
                    headers = headers.set('ngsw-bypass', 'true');

                    headers = headers.append('Accept', 'application/json');

                    if (token)
                        headers = headers.append('Authorization', token);

                    return request.clone({
                        headers: headers,
                        withCredentials: true
                    });
                }));
        }
        else if (request.url.indexOf(".storage.googleapis.com/") > 1) {
            //  Calls to Google Storage must not include credentials or the CORS checks will fail
            headerObserv = of<HttpRequest<any>>(request.clone({
                withCredentials: false
            }));
        }

        //  Track the number of active calls in case multiple are active at once.  If we don't do this, we will stop the indicator when
        //  the first one completes even though there are more that are still active.
        this._ActiveApiCalls++;
        this.busyService.showGeneral();

        return headerObserv.pipe(concatMap(newReq => {
            const MAX_RETRIES = 3;
            const DELAY_MS = 1000;
            const BACKOFF_MS = 1000;

            return next.handle(newReq)
                .pipe(
                    //  Retry local network errors.  See: https://medium.com/angular-in-depth/retry-failed-http-requests-in-angular-f5959d486294
                    //  and https://stackoverflow.com/questions/51905963/retry-http-requests-in-angular-6
                    retryWhen((errors: Observable<any>) => {
                        return errors
                            .pipe(
                                concatMap((error, numRetries) => {
                                    if (this.CanRetry(error.status)) {
                                        if (numRetries < MAX_RETRIES) {
                                            //  Retry this call with a delay
                                            console.warn("ApiInterceptor: Retrying failed http call", error, numRetries);
                                            const backoffTime = DELAY_MS + (numRetries * BACKOFF_MS);
                                            return of(error).pipe(delay(backoffTime));
                                        } else {
                                            const toastr = this.injector.get(ToastrService);
                                            toastr.error("Failed to contact server, please check your network connection.", null, { disableTimeOut: true });
                                        }
                                    }

                                    //  This will cause us to not retry
                                    return throwError(error);
                                })
                            )
                    }),
                    tap(
                        (event: HttpEvent<any>) => {
                            //  Must use this first parameter of tap() so that we get the event.  The 3rd param (called for "complete")
                            //  does not include that.
                            //console.warn("ApiInterceptor: tap", request.url, event);
                            if (event instanceof HttpResponse) {
                                //  If the SPA version has changed, this will trigger a check for update so the SPA knows there is an update to itself.
                                this._AppUpdateService.SetSPAVersion(event.headers.get("x-exactix-spa-ver"));
                            }
                        },
                        (err: any) => {
                            //  Must catch errors using tap() instead of catchError() because catchError() will eat the exception and
                            //  cause it to not be handled by any other error handling attached to the observable (and also prevent
                            //  GlobalErrorHandler from catching it - but it doesn't do anything with an http error).
                            if (err instanceof HttpErrorResponse)
                                this.HandleHttpError(err);
                        }),
                    finalize(() => {
                        this._ActiveApiCalls--;
                        if (this._ActiveApiCalls < 0)
                            this._ActiveApiCalls = 0;           //  Should not be possible
                        if (this._ActiveApiCalls <= 0)
                            this.busyService.hideGeneral();     //  Don't hide unless all are completed
                    })
                )
        }));
    }

    private CanRetry(httpStatusCode: number): boolean {
        switch (httpStatusCode) {
            case 0:         //  Client failed to send completely - temporary network issue on client
            case 400:       //  Bad Request - should not happen, possible the request was corrupted?  Or could be dns lookup timeout/failure.
            case 429:       //  Too Many Requests - don't think we can trigger this (but maybe from geoserver?  but it doesn't go through angular interceptor...)
            case 502:       //  Bad Gateway - can happen if part of the system is down (nginx proxy can't forward request or ingress down).  Hopefully resolves itself quickly!
            case 504:       //  Gateway Timeout - can happen due to a dns lookup timeout
                return true;
        }

        return false;
    }

    private HandleHttpError(err: HttpErrorResponse): void {
        //  ngx-toastr: https://github.com/scttcper/ngx-toastr
        const toastr = this.injector.get(ToastrService);
        switch (err.status) {
            case 401:
                //  Got unauthorized response.  Mark the user as not logged in and redirect to the login page.
                //  Also see the example here if we can/want to do some kind of automatic reauthentication: https://danielk.tech/home/angular-retry-an-http-request
                this.authenticationService.logout(true);
                break;
            case 403:
                //  Permission denied
                toastr.error("Permission Denied");
                break;
            case 500:
                //  Server error.  This (should) include a message that should be displayed to the user.
                if (err.error && err.error.Message) {
                    if (err.error.IsWarning)
                        toastr.warning(err.error.Message);
                    else
                        toastr.error(err.error.Message);
                }
                break;
            case 503:
                //  System Unavailable - this is triggered by the Api using the SystemUnavailableException exception
                //  (when the system is in maintenance mode).
                //  Not sure this is the best http status code to use for this.  Couldn't this be returned by something in the middle?
                //  Like Amazon Cognito refreshing the auth token?  Or a proxy server that is having issues?
                //  If so, those errors will cause the user to get logged out when they really shouldn't.
                //  ** May want to use a different status code (but I did not see a good alternative) or maybe return something else in the
                //  response message that we can use to verify that it actually came from our api and not something in between.
                //  This is handled in the api in UserSessionCookieMiddleware.HandleExceptionAsync() - search for HttpStatusCode.ServiceUnavailable
                this.authenticationService.logout(true);
                toastr.error(err.error.Message);
        }
    }
}
