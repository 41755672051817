import { NgModule } from '@angular/core';
import { ValidationMessageDirective } from 'Shared/Components/Forms/Validation/ValidationMessage.directive';
import { AutoFocusDirective } from 'Shared/Directives/AutoFocus.directive';
import { DateEditorDirective } from 'Shared/Directives/DateEditor.directive';
import { DisableAutofillDirective } from 'Shared/Directives/DisableAutofill.directive';
import { DragAndDropFileDirective } from 'Shared/Directives/DragAndDropFile.directive';
import { FieldLengthDirective } from 'Shared/Directives/FieldLength.directive';
import { FocusDirective } from 'Shared/Directives/Focus.directive';
import { HasPermissionDirective } from 'Shared/Directives/HasPermission.directive';
import { LowerCaseTextDirective } from 'Shared/Directives/Lowercase.directive';
import { PreventDoubleClickDirective } from 'Shared/Directives/PreventDoubleClick.directive';
import { PreventFocusStealOnClickMapOptionDirective } from 'Shared/Directives/PreventFocusStealOnClickMapOption.directive';
import { TextareaAutosizeDirective } from 'Shared/Directives/TextAreaAutoSize.directive';
import { TextareaInputLimiterDirective } from 'Shared/Directives/TextAreaInputLimiter.directive';
import { UpperCaseTextDirective } from 'Shared/Directives/Uppercase.directive';
import { SearchListDirective } from '../Directives/SearchList.directive';
import { AutocompleteSelectOnBlurDirective } from '../Directives/AutocompleteSelectOnBlur.directive';

@NgModule({
    exports: [
        ValidationMessageDirective,
        FocusDirective, AutoFocusDirective, FieldLengthDirective, DisableAutofillDirective,
        HasPermissionDirective,
        UpperCaseTextDirective, LowerCaseTextDirective,
        TextareaAutosizeDirective, TextareaInputLimiterDirective,
        PreventFocusStealOnClickMapOptionDirective, PreventDoubleClickDirective,
        DragAndDropFileDirective,
        SearchListDirective,
        DateEditorDirective,
        AutocompleteSelectOnBlurDirective
    ],
    declarations: [
        ValidationMessageDirective,

        FocusDirective, AutoFocusDirective, FieldLengthDirective, DisableAutofillDirective,
        HasPermissionDirective,
        UpperCaseTextDirective, LowerCaseTextDirective,
        TextareaAutosizeDirective, TextareaInputLimiterDirective,
        PreventFocusStealOnClickMapOptionDirective, PreventDoubleClickDirective,
        DragAndDropFileDirective,
        SearchListDirective,
        DateEditorDirective,
        AutocompleteSelectOnBlurDirective
    ]
})
export class UtilsModule { }
