import { Location } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppUser } from 'Models/Security/AppUser.model';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'Services/AuthenticationService';
import { SettingsService } from 'Services/SettingsService';
import { DeviceDetectorService } from './DeviceDetector.service';

/**
 * Collection of services we tend to need all over the place.  Makes it much easier to get them injected and
 * pass them down to base classes.
 * Don't go overboard here!  Only include what we really need all the time!
 *
 * To use this service, list it in the "providers" section of a @Component and then add it to that components
 * constructor.  By listing it in the @Components providers, an instance will be created for THAT component
 * as opposed to creating a single instance for the entire application.  That is necessary because some of the
 * classes we are injecting here are not actually services and must be scoped to the component.
 * See: https://blog.thoughtram.io/angular/2015/05/18/dependency-injection-in-angular-2.html#transient-dependencies-and-child-injectors
 */
@Injectable()
export class CommonService {

    constructor(public HttpClient: HttpClient, public SettingsService: SettingsService,
        public ActivatedRoute: ActivatedRoute, public FormBuilder: UntypedFormBuilder, public Location: Location,
        public Dialog: MatDialog, public ToastrService: ToastrService,
        public AuthenticationService: AuthenticationService, public DeviceDetectorService: DeviceDetectorService) {
    }

    public get CurrentUser(): AppUser {
        return this.AuthenticationService.CurrentUser;
    }
}
