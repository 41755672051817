export class SelectOption {

    ///Only needed if used in a multi select list.
    //  This way we can set the inital value and just manage when it changes and not have to constantly check the list to know if it should be selected.
    /// Currently it only changes when we do other actions like saving, so we can manage this value ourselves.
    Selected: boolean;

    //Will allow us to make an item disabled dynamically
    Disabled: boolean;

    constructor(public Value: any, public Name: string, public iconName: any = null) {

    }
}

