<iq-view-list style="height:100%; overflow:auto">
    <div style="display:flex">
        <div style="flex-grow:1"></div>
        <iq-icon-button *ngIf="CanEnterResponse" icon="New" button-class="Button-Small" (click)="AddNewResponse()">Add Response</iq-icon-button>
    </div>

    <div class="iq-list-items" style="padding-bottom:unset">
        <div class="iq-list-header no-more-option" fxLayout>
            <ng-container *ngFor="let col of DisplayedColumns">
                <ng-container *ngIf="col.canSearchAndFilter; else normalHeaderTemplate">
                    <iq-column-header class="link column {{col.class}}" [fxFlex]="col.width" [Title]="col.name"
                                      [ClearSortAndFilters]="_clearSortAndFilter" [StoredFiltersKey]="StoredFiltersKey" [isDisabled]="disableFilters"
                                      [SearchColumn]="col" (change)="ColumnSortFilterChange(col, $event)">
                        {{col.name}}
                    </iq-column-header>
                </ng-container>
                <ng-template #normalHeaderTemplate>
                    <label class="column-header {{col.class}}" [fxFlex]="col.width">
                        {{col.name}}
                    </label>
                </ng-template>
            </ng-container>
        </div>

        <iq-list-item *ngFor="let item of ServiceAreas" [ngClass]="{'no-response-background': !item.CurrentResponses || item.CurrentResponses.length===0 || (!item.CurrentResponses[0].ResponseID && item.CurrentResponses[0].ResponseRequired)}">
            <ng-container *ngFor="let col of DisplayedColumns" [ngSwitch]="col.returnPropertyName">
                <div *ngSwitchCase="'ServiceArea'" class="column {{col.class}}" fxLayout="column" [fxFlex]="col.width">
                    <div class="text-overflow-2">{{item.Name}}</div>
                    <div class="iq-caption text-overflow-1 gray-primary-text">{{item.Code}}</div>
                </div>

                <div *ngSwitchCase="'CurrentResponses'" class="column {{col.class}}" fxLayout="column" [fxFlex]="col.width">
                    <ng-container *ngFor="let response of item.CurrentResponses; last as isLast">
                        <div *ngIf="response.UtilityTypeName">
                            <label class="iq-caption gray-primary-text">Utility Type:</label>
                            <span style="font-weight: bold">{{response.UtilityTypeName}}:</span>
                        </div>
                        <div *ngIf="!response.ResponseCode && !response.ResponseRequired">
                            Response not required
                        </div>
                        <div *ngIf="response.ResponseCode" style="padding-bottom: 5px;">
                            <div>{{response.ResponseCode}} - {{response.ResponseDescription}}</div>
                            <div>
                                <label class="iq-caption gray-primary-text">Date:</label>
                                <span>{{response.EnteredDate | date: SettingsService.DateTimeFormat}}</span>
                            </div>
                            <div *ngIf="response.Respondant">
                                <label class="iq-caption gray-primary-text">Respondant:</label>
                                <span>{{response.Respondant}}</span>
                            </div>
                            <div *ngIf="response.Comment">
                                <label class="iq-caption gray-primary-text">Comment:</label>
                                <span>{{response.Comment}}</span>
                            </div>
                            <div>
                                <span class="iq-caption gray-primary-text">Entered via {{response.EntryMethod | ResponseEntryMethodEnum}}</span>
                                <span *ngIf="response.EnteredByPersonName">: {{response.EnteredByPersonName}}</span>
                            </div>
                        </div>
                        <mat-divider style="margin-left: 25px;" *ngIf="!isLast"></mat-divider>
                        <div *ngIf="item.CurrentResponses && item.CurrentResponses.length > 0" style="display:flex">
                            <div *ngIf="item.CurrentResponses[0].ResponseID" class="link" (click)="ViewServiceAreaResponses(item)" style="padding-right:2em">view all</div>
                            <ng-container *ngIf="CanEnterResponse">
                                <div *iqHasPermission="EnterResponsesPermission for [item.ID]" class="link" (click)="AddNewResponse(item)">add new</div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>

                <div *ngSwitchCase="'ExcavatorComments'" class="column {{col.class}}" fxLayout fxLayoutGap="10px" [fxFlex]="col.width">
                    <div fxFlex fxLayout="column">
                        <ng-container *ngFor="let response of item.ExcavatorComments; last as isLast">
                            <label style="white-space: pre-wrap; padding: 5px 0; word-break: break-all;">{{response.Message}}</label>
                            <mat-divider style="margin-left: 25px;" *ngIf="!isLast"></mat-divider>
                        </ng-container>
                    </div>
                </div>

                <div *ngSwitchCase="'Discussions'" class="column {{col.class}}" fxLayout="column" fxLayoutGap="10px" [fxFlex]="col.width">
                    <iq-ticket-response-discussion-list [ServiceArea]="item" [ServiceAreaList]="ServiceAreas" (Changed)="OnDiscussionChanged()"></iq-ticket-response-discussion-list>
                </div>

                <label *ngSwitchDefault class="column {{col.class}}" [fxFlex]="col.width">
                    {{item[col.returnPropertyName] | dynamicFormat : col.formatType : col.format}}
                </label>
            </ng-container>
        </iq-list-item>
    </div>
</iq-view-list>
