<busy-indicator [busyKey]="busyKey"></busy-indicator>
<div *ngIf="apiError">
    <div class="paged-list-api-error">
        Error fetching list from server.
    </div>
</div>
<iq-view-list>
    <iq-list-filter-section>
        <div class="search-header">
            <div class="name-search-options">
                <mat-icon>search</mat-icon>
                <mat-form-field class="name-search-text" style="width: 200px;">
                    <input matInput [(ngModel)]="filter.filterString" (ngModelChange)="applyFilter($event)" autocomplete="off" type="search"
                           placeholder="Filter by Ticket Number">
                    <mat-hint *ngIf="minCharsDefaultSearch && minCharsDefaultSearch > 0 && !disableFilters">Min {{minCharsDefaultSearch}} characters required</mat-hint>
                    <mat-hint *ngIf="disableFilters">*All other filters are disabled</mat-hint>
                </mat-form-field>
            </div>
            <label *ngIf="viewAllRelated" class="link" (keydown.enter)="removeRelatedSearch()" (keydown.space)="removeRelatedSearch()" tabindex="0" (click)="removeRelatedSearch()">Viewing related tickets.  Go back to the last filter</label>
            <label *ngIf="!viewAllRelated" class="link" (click)="ClearSortAndFilters()">reset</label>
            <div class="paging-controls">
                <div>
                    <pagination-controls [id]="PaginatorID" (pageChange)="pageChanged($event)"></pagination-controls>
                    <div *ngIf="resultsLength > MaxRowsForPaging" class="paging-limited iq-caption gray-primary-text">* paging limited to {{MaxRowsForPaging | number}} results</div>
                </div>
                <mat-form-field floatLabel="always" class="num-per-page-selector">
                    <mat-select placeholder="Items per Page" [(ngModel)]="defaultPageSize" (selectionChange)="pageSizeChanged()">
                        <mat-option *ngFor="let item of pageSizeOptions" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </iq-list-filter-section>
    <iq-list-action-section>
        <!--<mat-checkbox color="primary" [indeterminate]="!allSelected && selectedItems.length!==0" (change)="allSelectedChange($event)" [checked]="allSelected" style="padding-right: 5px;"></mat-checkbox>-->
        <!--<label class="selected-label">{{selectedCount}} Selected</label>-->

        <ng-container *ngIf="ShowColumnSelector">
            <iq-column-header-chooser [availableColumns]="AvailableColumns" [EntityType]="EntityType" [AllowSaving]="true" (change)="columnChange($event)"></iq-column-header-chooser>
        </ng-container>

        <ng-container *ngIf="ShowFilterSelector">
            <iq-list-filter-builder [availableColumns]="AvailableFilterColumns" [requiredColumnPropertyNames]="requiredFilters"
                                    [EntityType]="EntityType" [AllowSaving]="true" [disableAllFilters]="disableFilters"
                                    [NewDefaultFilter]="NewDefaultFilter" (change)="filterChange($event)"></iq-list-filter-builder>
        </ng-container>

        <button mat-icon-button matTooltip="Export to csv" (click)="exportAsCsv()">
            <fa-icon [icon]="exportImage"></fa-icon>
        </button>

        <button class="iq-display-2" mat-icon-button matTooltip="Refresh" (click)="refreshSearch()">
            <mat-icon>refresh</mat-icon>
        </button>

        <label class="results-text">Results: {{resultsLength | number}}</label>
    </iq-list-action-section>

    
    <div class="iq-list-items" #iqListItems>
        <div class="iq-list-header" style="padding: 0 10px 0 90px">
            <ng-container *ngFor="let col of DisplayedColumns">
                <ng-container *ngIf="col.canSearchAndFilter; else normalHeaderTemplate">
                    <iq-column-header class="link column-fixed {{col.class}}" [fxFlex]="col.width" [Title]="col.name"
                                      [ClearSortAndFilters]="_clearSortAndFilter" [StoredFiltersKey]="StoredFiltersKey" [isDisabled]="disableFilters"
                                      [SearchColumn]="col" (change)="ColumnSortFilterChange(col, $event)"
                                      [requiredColumnPropertyNames]="requiredFilters">
                        {{col.name}}
                    </iq-column-header>
                </ng-container>
                <ng-template #normalHeaderTemplate>
                    <label class="link column-fixed {{col.class}}" [fxFlex]="col.width">
                        {{col.name}}
                    </label>
                </ng-template>
            </ng-container>
        </div>

        <!-- this was used to color the entire row -->
        <!-- [ngStyle]="{'background-color': item.TicketType_BackgroundColor, 'color': item.TicketType_FontColor}" -->
        <iq-list-item *ngFor="let item of items | async | paginate: { itemsPerPage: defaultPageSize,
                                                      currentPage: currentPage, id: PaginatorID,
                                                      totalItems: MaxRowsForPaging }; trackBy: TrackBy">
            <div style="width: 45px" fxLayout fxLayoutAlign="flex-start center" class="iq-display-2">
                <button mat-icon-button [matMenuTriggerFor]="filter" (menuOpened)="MenuOpened(item, filter)" style="top: -3px;" class="iq-display-2" [iqFocus]="focusActionMenuEvents.Item(item.ID)">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-icon *ngIf="item.IsLast === 'No'" matTooltip="Ticket has been updated to a new Ticket Number or Version" class="red" style="transform: rotate(90deg); font-weight: 900;position: relative; left: -10px;">chevron_left</mat-icon>
                <mat-icon *ngIf="item.IsLast === 'Yes' && item.ParentTicketID" matTooltip="Ticket is the most recent version" class="green" style="transform: rotate(90deg); font-weight: 900;position: relative; left: -10px;">first_page</mat-icon>
            </div>
            <div style="padding-left:10px; height:40px" matTooltip="{{item.TicketType_Name}}">
                <div style="width:25px; height:100%" [ngStyle]="{'background-color': item.TicketType_BackgroundColor }"></div>
            </div>
            <mat-menu #filter="matMenu">
                <ng-template matMenuContent>
                    <div fxLayout="column">
                        <ng-container *ngFor="let action of ActionList">
                            <button *ngIf="action" matMenuItem mat-button (click)="ActionClicked(action, item)" [disabled]="action.Disabled">
                                <span fxLayout fxLayoutAlign="flex-start center">
                                    <mat-icon style="padding-right:10px">{{action.iconName}}</mat-icon>
                                    {{action.Name}}
                                </span>
                            </button>
                            <div *ngIf="!action" class="hr"></div>
                        </ng-container>
                    </div>
                </ng-template>
            </mat-menu>
            <div class="no-focus-outline" style="flex:1 0 0">
                <a style="display:flex; flex-grow:1; align-items:center; color:initial; text-decoration:none;" routerLink="/tickets/view/{{item.ID}}">
                    <ng-container *ngFor="let col of DisplayedColumns" [ngSwitch]="col.returnPropertyName">
                        <label *ngSwitchCase="'TicketNumber'" class="column-fixed {{col.class}}" [fxFlex]="col.width">
                            <ng-container *ngIf="item.HideTicketNumber === 'Yes'; else elseTicketNumber">
                                N/A
                            </ng-container>
                            <ng-template #elseTicketNumber>
                                {{item[col.returnPropertyName] | dynamicFormat : col.formatType : col.format}}
                            </ng-template>
                        </label>
                        <label *ngSwitchDefault class="column-fixed {{col.class}} {{col.DynamicCssClass(item[col.returnPropertyName], item)}}" [fxFlex]="col.width">
                            {{item[col.returnPropertyName] | dynamicFormat : col.formatType : col.format}}
                        </label>
                    </ng-container>
                </a>
            </div>
        </iq-list-item>
    </div>
</iq-view-list>
