<div style="display: flex; align-items: center">
    <mat-icon class="iq-display-2" style="flex-grow: 0; width: 20px; height: 20px;">search</mat-icon>
    <mat-form-field style="flex: 1 1 auto;">
        <input matInput autocomplete="off" type="search" placeholder="Filter by Ticket Number" [formControl]="ticketNumberControl" />
        <mat-hint *ngIf="minCharsDefaultSearch && minCharsDefaultSearch > 0">Min {{minCharsDefaultSearch}} characters required</mat-hint>
    </mat-form-field>

    <div style="min-width: 10px; display: flex; flex-direction: column; align-items:center; padding: 0 12px;">
        <label class="iq-caption gray-primary-text">Results</label>
        <label>{{totalResults}}</label>
    </div>
</div>

<iq-virtual-scroll [Items]="Items" [LoadingItems]="loading" (LoadMoreItems)="FetchNextPage()">
    <ng-template let-item let-last="last">
        <ticket-list-item [View]="Config.ListItemView" [Item]="item" [SearchColumns]="AvailableSearchColumns"></ticket-list-item>
    </ng-template>
</iq-virtual-scroll>
