<div fxLayout="column" [formGroup]="group" style="height: 100%; overflow: auto;">
    <mat-form-field fxFlex>
        <input matInput placeholder="Email Address" type="text" formControlName="email" />
        <mat-error *ngIf="emailControl.hasError('required')">required</mat-error>
        <mat-error *ngIf="emailControl.hasError('pattern')">format not valid</mat-error>
    </mat-form-field>

    <mat-form-field fxFlex *ngIf="ShowVerifyEmail">
        <input matInput placeholder="Confirm Email Address" type="text" formControlName="confirmEmail" />
        <mat-error *ngIf="confirmEmailControl.hasError('required')">required</mat-error>
        <!--we don't care about the pattern on the confirming email, because the other one has it-->
        <mat-error *ngIf="confirmEmailControl.hasError('equalValue')">emails must match</mat-error>
    </mat-form-field>

    <div style="display:flex; flex-direction:column; padding-bottom:20px; padding-top:10px">
        <mat-checkbox fxFlex color="primary" [checked]="UsernameSameAsEmail" (change)="UsernameSameAsEmailChanged()" >My email will be my username</mat-checkbox>
        <mat-form-field fxFlex *ngIf="!UsernameSameAsEmail" style="padding-left:2em" >
            <input matInput placeholder="Username" type="text" formControlName="username" />
            <mat-error iq-aws-cognito-validator [control]="UsernameFormControl"></mat-error>
        </mat-form-field>
    </div>

    <mat-form-field fxFlex>
        <input matInput placeholder="First Name" type="text" formControlName="given_name" />
        <mat-error *ngIf="givenNameControl.hasError('required')">required</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
        <input matInput placeholder="Last Name" type="text" formControlName="family_name" />
        <mat-error *ngIf="familyNameControl.hasError('required')">required</mat-error>
    </mat-form-field>

    <div class="mat-caption" *ngIf="PasswordRequirements != null">
        <p>Password requirements:</p>
        <ul>
            <li>Must be at least {{PasswordRequirements.MinCharacters}} characters</li>
            <li *ngIf="PasswordRequirements.RequireAnUppercaseCharacter">Must have an uppercase character</li>
            <li *ngIf="PasswordRequirements.RequireALowercaseCharacter">Must have a lowercase character</li>
            <li *ngIf="PasswordRequirements.RequireNumbers">Must have a number</li>
            <li *ngIf="PasswordRequirements.RequireASpecialCharacter">Must have a special character ($ * . ? - ! @ #
                %
                &)</li>
        </ul>
    </div>
    <iq-aws-cognito-password-input fxFlex [FormControl]="passwordControl"></iq-aws-cognito-password-input>

    <iq-aws-cognito-password-input fxFlex Placeholder="Confirm Password" [FormControl]="confirmPasswordControl" blockPaste></iq-aws-cognito-password-input>
</div>
