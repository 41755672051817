//  Contains components that should be included everywhere
//  See: https://angular.io/guide/ngmodule-faq#sharedmodule

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountyAutocompleteOptionComponent } from 'Shared/Components/Autocomplete/County/CountyAutocompleteOption.component';
import { PlaceAutocompleteOptionComponent } from 'Shared/Components/Autocomplete/Place/PlaceAutocompleteOption.component';
import { StreetAutocompleteOptionComponent } from 'Shared/Components/Autocomplete/Street/StreetAutocompleteOption.component';
import { IntersectingStreetAutocompleteOptionComponent } from 'Shared/Components/Autocomplete/IntersectingStreet/IntersectingStreetAutocompleteOption.component';
import { ExcavatorCompanyAutocompleteOptionComponent } from 'Shared/Components/Autocomplete/ExcavatorCompany/ExcavatorCompanyAutocompleteOption.component';
import { ExcavatorCompanyNameAutocompleteOptionComponent } from './ExcavatorCompany/ExcavatorCompanyNameAutocompleteOption.component';
import { ExcavatorOfficeAutocompleteOptionComponent } from 'Shared/Components/Autocomplete/ExcavatorOffice/ExcavatorOfficeAutocompleteOption.component';
import { ExcavatorContactAutocompleteOptionComponent } from 'Shared/Components/Autocomplete/ExcavatorContact/ExcavatorContactAutocompleteOption.component';
import { SiteContactAutocompleteOptionComponent } from 'Shared/Components/Autocomplete/SiteContact/SiteContactAutocompleteOption.component';
import { IQPipesModule } from '../../Pipes/Pipes.module';
import { MemberNameAutocompleteOptionComponent } from './Member/MemberNameAutocompleteOption.component';
import { ZipCodeAutocompleteOptionComponent } from 'Shared/Components/Autocomplete/ZipCode/ZipCodeAutocompleteOption.component';

@NgModule({
    imports: [
        CommonModule,
        IQPipesModule
    ],
    exports: [
        CountyAutocompleteOptionComponent,
        ExcavatorCompanyAutocompleteOptionComponent, ExcavatorCompanyNameAutocompleteOptionComponent,
        ExcavatorContactAutocompleteOptionComponent,
        ExcavatorOfficeAutocompleteOptionComponent,
        MemberNameAutocompleteOptionComponent,
        IntersectingStreetAutocompleteOptionComponent,
        PlaceAutocompleteOptionComponent,
        SiteContactAutocompleteOptionComponent,
        StreetAutocompleteOptionComponent,
        ZipCodeAutocompleteOptionComponent
    ],
    
    declarations: [
        CountyAutocompleteOptionComponent,
        ExcavatorCompanyAutocompleteOptionComponent, ExcavatorCompanyNameAutocompleteOptionComponent,
        ExcavatorContactAutocompleteOptionComponent,
        ExcavatorOfficeAutocompleteOptionComponent,
        MemberNameAutocompleteOptionComponent,
        IntersectingStreetAutocompleteOptionComponent,
        PlaceAutocompleteOptionComponent,
        SiteContactAutocompleteOptionComponent,
        StreetAutocompleteOptionComponent,
        ZipCodeAutocompleteOptionComponent
    ]
})

export class AutocompleteTemplatesModule { }
