import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchAffectedServiceAreasRequest } from '@iqModels/Tickets/SearchAffectedServiceAreasRequest.model';
import { TicketAffectedServiceAreaInfo } from '@iqModels/Tickets/TicketAffectedServiceAreaInfo.model';
import { DigSiteIntersectionItemTypeEnum } from 'Enums/DigSiteIntersectionItemType.enum';
import * as _ from 'lodash';
import { DebouncedFunc } from 'lodash';
import { DigSite } from 'Models/DigSites/DigSite.model';
import { TicketEntryFormGroup } from 'Pages/Tickets/Details/Components/InputControls/TicketEntryFormGroup';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from 'Services/SettingsService';

export class ManuallyAddServiceAreaDialogData {
    public ExistingServiceAreaIDs: string[];
    public LimitToServiceAreaIDs?: string[];
    public TicketEntryForm: TicketEntryFormGroup;
    public AddButtonText: string = "Add to Ticket";
}

@Component({
    selector: 'manually-add-service-area-dialog',
    templateUrl: './ManuallyAddServiceAreaDialog.component.html',
    styleUrls: ['./ManuallyAddServiceAreaDialog.component.scss']
})
export class ManuallyAddServiceAreaDialog {
    public HaveSelections: boolean = false;

    public SelectedOption: number = 0;

    //  This is tweaked in the constructor so if this list is changed, check there too.
    public RadioOptions: string[] = ['1000 ft', '2000 ft', '5000 ft', 'Place', 'County', 'Search Name/Code'];
    private readonly OPTION_1000FT = 0;
    private readonly OPTION_2000FT = 1;
    private readonly OPTION_5000FT = 2;
    private readonly OPTION_PLACE = 3;
    public readonly OPTION_COUNTY = 4;
    private readonly OPTION_SEARCH = 5;

    public SearchTerm: string;

    public AddButtonText: string;

    public ServiceAreaItems: BehaviorSubject<TicketAffectedServiceAreaInfo[]> = new BehaviorSubject<TicketAffectedServiceAreaInfo[]>([]);

    private _SelectedServiceAreas: TicketAffectedServiceAreaInfo[] = [];

    constructor(private _TicketService: TicketService, public SettingsService: SettingsService,
        private _DialogRef: MatDialogRef<ManuallyAddServiceAreaDialog>,
        @Inject(MAT_DIALOG_DATA) private _DialogData: ManuallyAddServiceAreaDialogData)
    {

        this.AddButtonText = this._DialogData.AddButtonText;

        this.SearchAffectedServiceAreas();  //  Initial search will be done against the first search option (a distance option)

        this.RadioOptions[this.OPTION_PLACE] = SettingsService.PlaceNameLabel;      //  DigSafe calls it Municipality
    }

    //  Improves performance of very large results (County search in AZ can return several hundred!)
    public TrackBy(index, sa) {
        return sa.ID;
    }

    public SearchOptionChanged(): void {
        this._DebouncedSearch.cancel();     //  Cancel pending search term so that this one will fire immediately

        if ((this.SelectedOption === this.OPTION_SEARCH) && _.isEmpty(this.SearchTerm)) {
            //  When switching to search term search and we don't have a search term, just clear - don't do empty search
            this.ServiceAreaItems.next([]);
            this.ClearSelections();
        }
        else
            this.SearchAffectedServiceAreas();
    }

    public SwitchToSearchTerm(): void {
        this.SelectedOption = this.OPTION_SEARCH;
        this.SearchOptionChanged();
    }

    private SearchAffectedServiceAreas(): void {
        const request = this.BuildSearchRequest();
        if (!request)
            return;

        this._TicketService.SearchAffectedServiceAreas(request)
            .subscribe(results => {
                this.ServiceAreaItems.next(results.Items);
                this.ClearSelections();
            });
    }

    private ClearSelections(): void {
        this._SelectedServiceAreas = [];
        this.HaveSelections = false;
    }

    private BuildSearchRequest(): SearchAffectedServiceAreasRequest {
        //  TODO: May need to make this pageable.
        //  Right now, all results are returned for the distance, county, and place searches.  Initially expected these to
        //  not be obnoxiously large.  But they can be in AZ!  The server does the search very quickly but it takes forever
        //  to render the results in the browser due to the number of DOM elements.  In-memory page would help dramatically.
        //  SearchTerm search respects the page parameter of the search request if they are set (which we are leaving at
        //  defaults so it's always returning top 20 right now).

        const ticketTypeID = this._DialogData.TicketEntryForm.get("TicketTypeID").value;
        const locateTypeID = this._DialogData.TicketEntryForm.get("LocateTypeID").value;

        const digsite = (this._DialogData.TicketEntryForm.get("DigSite") as UntypedFormGroup).getRawValue() as DigSite;//  This includes properties that are disabled.  Otherwise (for a FormGroup), they get excluded!
        const inter1 = digsite.Intersections[DigSiteIntersectionItemTypeEnum[DigSiteIntersectionItemTypeEnum.Inter1]];

        switch (this.SelectedOption) {
            case this.OPTION_1000FT:
                return SearchAffectedServiceAreasRequest.CreateDistanceToGeomRequest(1000, digsite.GeometryJson, ticketTypeID, locateTypeID, this._DialogData.ExistingServiceAreaIDs, this._DialogData.LimitToServiceAreaIDs);
            case this.OPTION_2000FT:
                return SearchAffectedServiceAreasRequest.CreateDistanceToGeomRequest(2000, digsite.GeometryJson, ticketTypeID, locateTypeID, this._DialogData.ExistingServiceAreaIDs, this._DialogData.LimitToServiceAreaIDs);
            case this.OPTION_5000FT:
                return SearchAffectedServiceAreasRequest.CreateDistanceToGeomRequest(5000, digsite.GeometryJson, ticketTypeID, locateTypeID, this._DialogData.ExistingServiceAreaIDs, this._DialogData.LimitToServiceAreaIDs);
            case this.OPTION_PLACE:
                return SearchAffectedServiceAreasRequest.CreatePlaceRequest(inter1.State, inter1.CountyName, inter1.PlaceName, ticketTypeID, locateTypeID, this._DialogData.ExistingServiceAreaIDs, this._DialogData.LimitToServiceAreaIDs, digsite.GeometryJson);
            case this.OPTION_COUNTY:
                return SearchAffectedServiceAreasRequest.CreateCountyRequest(inter1.State, inter1.CountyName, ticketTypeID, locateTypeID, this._DialogData.ExistingServiceAreaIDs, this._DialogData.LimitToServiceAreaIDs, digsite.GeometryJson);
            case this.OPTION_SEARCH:
                return SearchAffectedServiceAreasRequest.CreateSearchTermRequest(this.SearchTerm, ticketTypeID, locateTypeID, this._DialogData.ExistingServiceAreaIDs, this._DialogData.LimitToServiceAreaIDs, digsite.GeometryJson);
        }
    }

    private _DebouncedSearch: DebouncedFunc<() => void> = _.debounce(this.SearchAffectedServiceAreas, 200);

    public SearchTermChanged(): void {
        this._DebouncedSearch();        //  debounced like this so that we can debounce just typing in the search term and cancel it if we change the search option
    }

    public ToggleSelected(selected: boolean, item: any) {
        if (selected)
            this._SelectedServiceAreas.push(item);
        else
            this._SelectedServiceAreas.splice(this._SelectedServiceAreas.findIndex(val => val.ID === item.ServiceAreaID), 1);

        this.HaveSelections = (this._SelectedServiceAreas.length > 0);
    }

    public AddToTicket(): void {
        this._DialogRef.close(this._SelectedServiceAreas);
    }
}
