import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { iQSignInComponent } from './LoginControls/sign-in/sign-in.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { iQResetPasswordComponent } from './LoginControls/reset-password/reset-password.component';
import { iQAuthContainerComponent, iqAwsSignOutTemplate, iqAwsSignInTemplate, iqAwsResetPasswordTemplate, iqAwsCreateNewUserTemplate, iqAwsConfirmUserTemplate, iqAwsNewPasswordTemplate } from './LoginControls/auth-container/auth-container.component';
import { iQLogoutComponent } from './LoginControls/logout/logout.component';
import { iQSignUpComponent } from './LoginControls/sign-up/sign-up.component';
import { iQConfirmUserComponent } from './LoginControls/confirm-user/confirm-user.component';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { iQAwsCognitoValidatorDirective } from './validator.directive';
import { BusyOverlayComponent } from './busy-overlay/busy-overlay.component';
import { iQNewPasswordComponent as iQNewPasswordComponent } from './LoginControls/new-password/new-password.component';
import { iQAdminCreateLoginDialogComponent } from './ManageAccount/Admin/admin-create-login-dialog/admin-create-login-dialog.component';
import { iQAdminChangeEmailDailogComponent as iQAdminChangeEmailDialogComponent } from './ManageAccount/Admin/admin-change-email-dialog/admin-change-email-dialog.component';
import { iQAdminForcePasswordResetDialogComponent } from './ManageAccount/Admin/admin-force-password-reset-dialog/admin-force-password-reset-dialog.component';
import { iQAdminSetPasswordDialogComponent } from './ManageAccount/Admin/admin-set-password-dialog/admin-set-password-dialog.component';
import { iQChangeVerifyEmailDialogComponent } from './ManageAccount/Self/change-verify-email-dialog/change-verify-email-dialog.component';
import { iQChangeEmailComponent } from './ManageAccount/Self/change-email/change-email.component';
import { iQCreateLoginSharedComponent } from './SharedControls/create-login/create-login.component';
import { iQChangePasswordDialogComponent } from './ManageAccount/Self/change-password-dialog/change-password-dialog.component';
import { iQChangePasswordComponent } from './ManageAccount/Self/change-password/change-password.component';
import { IqAwsSafeUrlPipe } from './safe-url.pipe';
import { iQChangeAlternateUsernameDialogComponent } from './ManageAccount/Self/change-alternate-username-dialog/change-alternate-username-dialog.component';
import { iQChangeAlternateUsernameComponent } from './ManageAccount/Self/change-alternate-username/change-alternate-username.component';
import { BlockPasteDirective } from './block-paste.directive';
import { iQAlternateLoginSubIdDialogComponent } from './LinkAccount/get-alternate-login-sub-id-dialog/get-alternate-login-sub-id-dialog.component';
import { iQAlternateLoginSubIdComponent } from './LinkAccount/get-alternate-login-sub-id/get-alternate-login-sub-id.component';
import { PasswordInputComponent } from './SharedControls/password-input/password-input.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VerifyAlternateLoginDialogComponent } from './LinkAccount/verify-alternate-login-dialog/verify-alternate-login-dialog.component';

@NgModule({
    declarations: [
        iQSignInComponent,
        iQResetPasswordComponent,
        iQAuthContainerComponent,
        iQLogoutComponent,
        iQSignUpComponent,
        iQConfirmUserComponent,
        iQAwsCognitoValidatorDirective,
        BusyOverlayComponent,
        iQNewPasswordComponent,
        iQAdminCreateLoginDialogComponent,
        iQAdminChangeEmailDialogComponent,
        iQAdminForcePasswordResetDialogComponent,
        iQAdminSetPasswordDialogComponent,
        iQChangeVerifyEmailDialogComponent,
        iQChangeEmailComponent,
        iQCreateLoginSharedComponent,
        iQChangePasswordDialogComponent,
        iQChangePasswordComponent,
        iQChangeAlternateUsernameDialogComponent,
        iQChangeAlternateUsernameComponent,
        iQAlternateLoginSubIdDialogComponent,
        iQAlternateLoginSubIdComponent,
        //Directives to let apps importing this library to override the default views
        iqAwsSignInTemplate,
        iqAwsSignOutTemplate,
        iqAwsResetPasswordTemplate,
        iqAwsCreateNewUserTemplate,
        iqAwsConfirmUserTemplate,
        iqAwsNewPasswordTemplate,
        IqAwsSafeUrlPipe,
        //Curreently don't exort this...It would be nice to get this moved to a common libray
        BlockPasteDirective,
        PasswordInputComponent,
        VerifyAlternateLoginDialogComponent
    ],
    imports: [
        FlexLayoutModule,
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        DragDropModule,
        FontAwesomeModule
    ],
    exports: [
        iQSignInComponent,
        iQResetPasswordComponent,
        iQAuthContainerComponent,
        iQLogoutComponent,
        iQSignUpComponent,
        iQConfirmUserComponent,
        iQNewPasswordComponent,
        //Export this so that it can be used as a view with logic added to it in other apps
        iQCreateLoginSharedComponent,
        iQChangeEmailComponent,
        iQChangePasswordComponent,
        iQChangeAlternateUsernameComponent,
        iQAwsCognitoValidatorDirective,
        iQAlternateLoginSubIdComponent,
        //Export so the apps using this can have it and not have to create one.
        PasswordInputComponent,
        //Export this so that it can be used if the importing app doesn't have anything...Not ideal, but don't have time to setup a library for these type of controls right now.
        BusyOverlayComponent,
        //Directives to let apps importing this library to override the default views
        iqAwsSignInTemplate,
        iqAwsSignOutTemplate,
        iqAwsResetPasswordTemplate,
        iqAwsCreateNewUserTemplate,
        iqAwsConfirmUserTemplate,
        iqAwsNewPasswordTemplate,
        IqAwsSafeUrlPipe //Export this so that it can be used in an app so they don't have to make one to sanatize the sso iframe url
    ]
})
export class IqAwsCognitoModule {
    constructor(@Optional() @SkipSelf() parentModule?: IqAwsCognitoModule) {

    }

    static forRoot(snackBarConfig: MatSnackBarConfig<any> = { horizontalPosition: 'right', verticalPosition: 'top', duration: 4000 }
    ): ModuleWithProviders<IqAwsCognitoModule> {

        return {
            ngModule: IqAwsCognitoModule,
            providers: [
                { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: snackBarConfig }
            ]
        };
    }

}
