<busy-overlay [show]="isBusy" setParentPosition="false"></busy-overlay>
<div fxLayout="column" [formGroup]="group" style="width: 100%;">
    <div fxFlex>
        Reset your Password
    </div>
    <p fxFlex *ngIf="!waitingForCode; else waitingMessage" class="mat-body-1">
        <span *ngIf="!forgotUsername">An email with a code to complete the process will be sent to the address associated with the username you enter.</span>
        <span *ngIf="forgotUsername">An email with the username and a code to complete the process will be sent to the email address you enter.</span>
    </p>
    <ng-template #waitingMessage>
        <p fxFlex class="mat-body-1">
            {{ActionMessage}}
        </p>
    </ng-template>
    <mat-form-field fxFlex *ngIf="!forgotUsername" style="padding-bottom:20px">
        <input matInput placeholder="Username" type="text" formControlName="Username" (keyup.enter)="onSendEmail()" />
        <mat-error iq-aws-cognito-validator [control]="username"></mat-error>
        <mat-hint align="end">* typically, your email address</mat-hint>
    </mat-form-field>

    <mat-form-field fxFlex *ngIf="forgotUsername" style="padding-bottom:20px">
        <input matInput placeholder="Email" type="text" formControlName="Email" (keyup.enter)="onSendUsernameEmail()" />
        <mat-error iq-aws-cognito-validator [control]="email"></mat-error>
    </mat-form-field>

    <ng-container *ngIf="waitingForCode">

        <div class="mat-caption" *ngIf="PasswordRequirements != null">
            <p>Password requirements:</p>
            <ul>
                <li>Must be at least {{PasswordRequirements.MinCharacters}} characters</li>
                <li *ngIf="PasswordRequirements.RequireAnUppercaseCharacter">Must have an uppercase character</li>
                <li *ngIf="PasswordRequirements.RequireALowercaseCharacter">Must have a lowercase character</li>
                <li *ngIf="PasswordRequirements.RequireNumbers">Must have a number</li>
                <li *ngIf="PasswordRequirements.RequireASpecialCharacter">
                    Must have a special character ($ * . ? - ! @ # % &)
                </li>
            </ul>
        </div>

        <iq-aws-cognito-password-input fxFlex [FormControl]="password" Placeholder="New Password" (keyup.enter)="onSubmitPasswordChange()"></iq-aws-cognito-password-input>

        <iq-aws-cognito-password-input fxFlex [FormControl]="confirmPassword" Placeholder="Confirm New Password" (keyup.enter)="onSubmitPasswordChange()" blockPaste></iq-aws-cognito-password-input>

        <mat-form-field fxFlex>
            <input matInput placeholder="Code" type="search" autocomplete="off" formControlName="Code" (keyup.enter)="onSubmitPasswordChange()" />
            <mat-error *ngIf="code.hasError('required')">required</mat-error>
        </mat-form-field>
    </ng-container>

    <label class="mat-caption mat-error" style="padding-bottom: 10px;" *ngIf="_errorMessage">{{_errorMessage}}</label>

    <div fxFlex fxLayout="column">
        <button mat-flat-button *ngIf="!waitingForCode && !forgotUsername" (click)="onSendEmail()" color="primary" [disabled]="!username.valid || isBusy">
            Send Code
        </button>

        <button mat-flat-button (click)="onSubmitPasswordChange()" color="primary" [disabled]="!group.valid || isBusy" *ngIf="waitingForCode">
            Reset Password
        </button>

        <button mat-flat-button *ngIf="forgotUsername" (click)="onSendUsernameEmail()" color="primary" [disabled]="!email.valid || isBusy">
            Send Email
        </button>
    </div>

    <div fxLayout="row" fxLayoutGap="15px" style="padding: 20px 0;">
        <span fxFlex style="text-align: left;" *ngIf="!waitingForCode && !forgotUsername">
            <a class="link" (keyup.enter)="onForgotUsername()" (click)="onForgotUsername()" tabindex="0">Forgot username?</a>
        </span>
        <span fxFlex style="text-align: left;" *ngIf="waitingForCode">
            <a class="link" (keyup.enter)="onSendEmail()" (click)="onSendEmail()">Resend code</a>
        </span>

        <span fxFlex style="text-align: right;">
            <a class="link" (keyup.enter)="onReturnToLogin()" (click)="onReturnToLogin()" tabindex="0">Return to Login</a>
        </span>
    </div>
</div>
