<div class="home-card-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-card fxFlex ngClass.lt-sm="card-full-width">
        <busy-indicator [busyKey]="busyKey"></busy-indicator>
            <mat-card-content fxLayout="row wrap" fxLayout.lt-md="column-reverse">
                <div fxFlex="50%" ngStyle.lt-md="border-top: solid gray 1px; padding-top: 12px; border-right: none; padding-right: unset" style="border-right: solid gray 1px; padding-right: 12px" fxLayout="column" fxLayoutAlign="center center">
                    <p>Searching for a ticket?
                        <button mat-button color="primary" routerLink="/findTicketByNumberAndPhone">Search here</button>
                    </p>
                    <p *ngIf="ShowEmergencyContactsPage">
                        <button mat-button color="primary" routerLink="/emergencyContacts">View Emergency Contacts</button>
                    </p>
                </div>
                <div fxFlex="50%" ngStyle.lt-md="padding-bottom:12px;" style="padding-left:12px;" fxLayout="column" fxLayoutAlign="center center">
                    <iq-aws-cognito [StartingStep]="1" style="width: 100%; max-width: 375px;"
                                    [AllowCreateLogin]="CanSelfRegister"
                                    [PasswordRequirements]="passwordRequirements"
                                    [HideLogout]="false"
                                    [FederatedLoginReturnState]="federatedLoginReturnState">
                        <ng-template iqAwsResetPasswordTemplate>
                            <auth-forgot-password [PasswordRequirements]="passwordRequirements"></auth-forgot-password>
                        </ng-template>
                    </iq-aws-cognito>
                </div>
            </mat-card-content>
    </mat-card>

    <mat-card fxFlex ngClass.lt-sm="card-full-width">
        <iq-announcements-carousel forLogin></iq-announcements-carousel>
    </mat-card>
</div>
