import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'Services/SettingsService';
import { take, map, tap } from 'rxjs/operators';
import { EntityBase } from 'Models/Base/EntityBase.model';
import { Form } from 'Models/Configuration/Form.model';

export enum TicketHelpUserType {
    LocalUser = 1,
    SelfRegistrationRole = 2,
    WebUser = 4
}

export class TicketHelpSectionData extends EntityBase {

    FormSectionID: string;
    OneCallCenterID: string;


    UserType: TicketHelpUserType;

    Html: string = null;

    LastModifyDateTime: Date;

    UpdatedBy: string;

    
}

@Injectable({
    providedIn: 'root'
})
export class TicketHelpService {

    constructor(private http: HttpClient, private settingsService: SettingsService) {
    }

    ClearCache() {
        this._formHelp = null;
    }

    InsertOrUpdate(item: TicketHelpSectionData) {
        //Clear out all the cached items when one is changed.  That way it will reload from the server as needed
        return this.http.post(this.settingsService.ApiBaseUrl + "/Config/FormSectionHelp", item);
    }
    Delete(id: string): Observable<TicketHelpSectionData> {
        //Clear out all the cached items when one is deleted.  That way it will reload from the server as needed
        return this.http.delete<TicketHelpSectionData>(this.settingsService.ApiBaseUrl + "/Config/FormSectionHelp/" + id);
    }

    private _formHelp: Form[];
    GetFormSections(getCached = false): Observable<Form[]> {
        if (getCached && this._formHelp)
            return of(this._formHelp);

        return this.http.get<Form[]>(this.settingsService.ApiBaseUrl + "/Config/Forms/GetHelp").pipe(tap(val => {
            if (val && val.length > 0)
                this._formHelp = val;
        }));
    }
}
