<mat-radio-group style="display:flex; flex-direction: column; overflow: hidden" [(ngModel)]="SelectedDateOption" (change)="OnSelectedDateOptionChanged()" [disabled]="isDisabled">
    <mat-radio-button *ngIf="allowEmpty" style="padding-bottom:20px" [value]="FilterOptions.None" color="primary">All Dates</mat-radio-button>

    <mat-radio-button *ngIf="DateFilterConfig.AllowToday" style="padding-bottom:20px" [value]="FilterOptions.Today" color="primary">Today</mat-radio-button>

    <mat-radio-button *ngIf="DateFilterConfig.AllowYesterday" style="padding-bottom:20px" [value]="FilterOptions.Yesterday" color="primary">Yesterday</mat-radio-button>

    <mat-radio-button *ngIf="DateFilterConfig.AllowLastWeek" style="padding-bottom:20px" [value]="FilterOptions.LastWeek" color="primary">Last Week (last Sunday to last Saturday)</mat-radio-button>

    <mat-radio-button *ngIf="DateFilterConfig.AllowLastMonth" style="padding-bottom:20px" [value]="FilterOptions.LastMonth" color="primary">Last Month</mat-radio-button>

    <div *ngIf="DateFilterConfig.AllowPastDaysFromToday" style="display: flex; align-items: center">
        <mat-radio-button [value]="FilterOptions.PastDaysFromToday" color="primary">Days before (and including) today:</mat-radio-button>
        <mat-form-field class="input-control" (click)="OnInputClicked(FilterOptions.PastDaysFromToday)">
            <input matInput placeholder="Days" type="number" min="1" max="{{MaxDays}}" [iqFocus]="PastDaysFocusEvent"
                   [formControl]="PastDaysFormControl" autocomplete="off" />
            <mat-hint *ngIf="MaxDays">Max {{MaxDays}}</mat-hint>
            <mat-error iqValidationMessage [control]="PastDaysFormControl"></mat-error><!--I don't think there will ever be an error....-->
        </mat-form-field>
    </div>
    <div *ngIf="DateFilterConfig.AllowDaysBeforeAndNotIncludingToday" style="display: flex; align-items: center">
        <mat-radio-button [value]="FilterOptions.DaysBeforeAndNotIncludingToday" color="primary">Days before (and NOT including) today:</mat-radio-button>
        <mat-form-field class="input-control" (click)="OnInputClicked(FilterOptions.DaysBeforeAndNotIncludingToday)">
            <input matInput placeholder="Days" type="number" min="1" max="{{MaxDays}}" [iqFocus]="DaysBeforeAndNotIncludingTodayFocusEvent"
                   [formControl]="DaysBeforeAndNotIncludingTodayFormControl" autocomplete="off" />
            <mat-hint *ngIf="MaxDays">Max {{MaxDays}}</mat-hint>
            <mat-error iqValidationMessage [control]="DaysBeforeAndNotIncludingTodayFormControl"></mat-error><!--I don't think there will ever be an error....-->
        </mat-form-field>
    </div>

    <div *ngIf="DateFilterConfig.AllowPastHoursFromNow" style="display: flex; align-items: center">
        <mat-radio-button [value]="FilterOptions.PastHoursFromNow" color="primary">Hours before current time:</mat-radio-button>
        <mat-form-field class="input-control" (click)="OnInputClicked(FilterOptions.PastHoursFromNow)">
            <input matInput placeholder="Hours" type="number" min="1" max="{{MAX_HOURS}}" [iqFocus]="PastHoursFocusEvent"
                   [formControl]="PastHoursFormControl" autocomplete="off" />
            <mat-hint>Max {{MAX_HOURS}}</mat-hint>
            <mat-error iqValidationMessage [control]="PastHoursFormControl"></mat-error><!--I don't think there will ever be an error....-->
        </mat-form-field>
    </div>

    <div *ngIf="DateFilterConfig.AllowSingleDate" style="display: flex; align-items: center">
        <mat-radio-button [value]="FilterOptions.SingleDate" color="primary">Single Date:</mat-radio-button>
        <mat-form-field class="date-input-control" (click)="OnInputClicked(FilterOptions.SingleDate); pickerSingle.open()">
            <input iqDisableAutofill matInput [matDatepicker]="pickerSingle" placeholder="Date" (dateChange)="OnSingleDateChanged()" [formControl]="SingleDateFormControl" />
            <mat-datepicker-toggle matSuffix [for]="pickerSingle"></mat-datepicker-toggle>
            <mat-datepicker type="date" #pickerSingle></mat-datepicker>
            <mat-hint>(mm/dd/yyyy)</mat-hint>
            <mat-error iqValidationMessage [control]="SingleDateFormControl"></mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="DateFilterConfig.AllowDateRange" style="display: flex; align-items: center">
        <mat-radio-button [value]="FilterOptions.DateRange" color="primary">Date Range:</mat-radio-button>
        <mat-form-field class="date-input-control" (click)="OnInputClicked(FilterOptions.DateRange); pickerRangeStart.open()">
            <input iqDisableAutofill matInput [matDatepicker]="pickerRangeStart" placeholder="Start Date" (dateChange)="OnDateRangeChanged()" [formControl]="DateRangeStartFormControl" />
            <mat-datepicker-toggle matSuffix [for]="pickerRangeStart"></mat-datepicker-toggle>
            <mat-datepicker type="date" #pickerRangeStart></mat-datepicker>
            <mat-hint>(mm/dd/yyyy)</mat-hint>
            <mat-error iqValidationMessage [control]="DateRangeStartFormControl"></mat-error>
        </mat-form-field>
        <mat-form-field class="date-input-control" (click)="OnInputClicked(FilterOptions.DateRange); pickerRangeEnd.open()">
            <input iqDisableAutofill matInput [matDatepicker]="pickerRangeEnd" placeholder="End Date" (dateChange)="OnDateRangeChanged()" [min]="DateRangeStartFormControl.value" [formControl]="DateRangeEndFormControl" />
            <mat-datepicker-toggle matSuffix [for]="pickerRangeEnd"></mat-datepicker-toggle>
            <mat-datepicker type="date" #pickerRangeEnd></mat-datepicker>
            <mat-hint>(mm/dd/yyyy)</mat-hint>
            <mat-error iqValidationMessage [control]="DateRangeEndFormControl"></mat-error>
        </mat-form-field>
    </div>

    <mat-radio-button *ngIf="DateFilterConfig.AllowTomorrow" style="padding-bottom:20px" [value]="FilterOptions.Tomorrow" color="primary">Tomorrow</mat-radio-button>

    <div *ngIf="DateFilterConfig.AllowDaysFromToday">
        <div style="display: flex; align-items: center">
            <mat-radio-button [value]="FilterOptions.DaysFromToday" color="primary">Days after (and including) today:</mat-radio-button>
            <mat-form-field class="input-control" (click)="OnInputClicked(FilterOptions.DaysFromToday)">
                <input matInput placeholder="Days" type="number" min="1" max="{{MaxDays}}" [iqFocus]="NextDaysFocusEvent"
                       [formControl]="NextDaysFormControl" autocomplete="off" />
                <mat-hint *ngIf="MaxDays">Max {{MaxDays}}</mat-hint>
                <mat-error iqValidationMessage [control]="NextDaysFormControl"></mat-error><!--I don't think there will ever be an error....-->
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="DateFilterConfig.AllowDaysAfterAndNotIncludingToday">
        <div style="display: flex; align-items: center">
            <mat-radio-button [value]="FilterOptions.DaysAfterAndNotIncludingToday" color="primary">Days after (and NOT including) today:</mat-radio-button>
            <mat-form-field class="input-control" (click)="OnInputClicked(FilterOptions.DaysAfterAndNotIncludingToday)">
                <input matInput placeholder="Days" type="number" min="1" max="{{MaxDays}}" [iqFocus]="DaysAfterAndNotIncludingTodayFocusEvent"
                       [formControl]="DaysAfterAndNotIncludingTodayFormControl" autocomplete="off" />
                <mat-hint *ngIf="MaxDays">Max {{MaxDays}}</mat-hint>
                <mat-error iqValidationMessage [control]="DaysAfterAndNotIncludingTodayFormControl"></mat-error><!--I don't think there will ever be an error....-->
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="DateFilterConfig.AllowHoursFromNow">
        <div *ngIf="!DateOnly" style="display: flex; align-items: center">
            <mat-radio-button [value]="FilterOptions.HoursFromNow" color="primary">Hours after current time:</mat-radio-button>
            <mat-form-field class="input-control" (click)="OnInputClicked(FilterOptions.HoursFromNow)">
                <input matInput placeholder="Hours" type="number" min="1" max="{{MAX_HOURS}}" [iqFocus]="NextHoursFocusEvent"
                       [formControl]="NextHoursFormControl" autocomplete="off" />
                <mat-hint>Max {{MAX_HOURS}}</mat-hint>
                <mat-error iqValidationMessage [control]="NextHoursFormControl"></mat-error><!--I don't think there will ever be an error....-->
            </mat-form-field>
        </div>
    </div>
</mat-radio-group>
