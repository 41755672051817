<div style="display:flex; flex-direction: column; flex-grow:1">
    <div style="display:flex; justify-content:center; padding: 10px 0px 10px 0px">
        <div style="padding-right:2em">Zoom to:</div>
        <div class="link" style="padding-right:2em" title="Zoom to your current location" (click)="PositionToCurrentLocation()">Current Location</div>
        <div class="link" title="Zoom to the bounds of your Tickets" (click)="ZoomToTicketExtents()">Tickets</div>
    </div>
    <div #MapHost id="MapHost" class="map" [ngClass]="{'map-search-visible': MapSearchIsVisible}">
        <iq-map-search-button></iq-map-search-button>
    </div>
</div>
