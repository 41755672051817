<div *ngIf="IsLoading; else itemsLoaded" style="padding:10px">
    Loading ticket responses...
</div>
<ng-template #itemsLoaded>
    <div style="padding:10px; height:100%; display:flex; flex-direction:column; flex-grow:1; overflow:hidden">
        <div style="padding-bottom:20px; display:flex; flex-wrap:wrap; column-gap:5em; row-gap:10px">
            <div style="display:flex">
                <div class="gray-primary-text" style="padding-right:10px">Show:</div>
                <mat-radio-group aria-label="Options:" [(ngModel)]="FilterOption" (change)="FilterChanged($event)">
                    <mat-radio-button color="primary" [value]="2">Current Only ({{NumCurrent}})</mat-radio-button>
                    <mat-radio-button color="primary" [value]="3">No Response ({{NumNoResponse}})</mat-radio-button>
                    <mat-radio-button color="primary" [value]="1">All ({{NumResponses}})</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="NumEvents > 0">
                <mat-checkbox color="primary" [(ngModel)]="ShowEvents" (change)="ShowEventsChanged($event)">Include events ({{NumEvents}})</mat-checkbox>
            </div>
            <div *ngIf="!CommonService.DeviceDetectorService.IsPhone" style="flex-grow:1; display:flex; justify-content:flex-end">
                <ticket-work-complete [AllowedActions]="AllowedActions" [Ticket]="Ticket"></ticket-work-complete>
                <iq-icon-button *ngIf="CanEnterResponse" style="padding-left:2em" icon="New" button-class="Button-Small" (click)="AddNewResponse()">Add Response</iq-icon-button>
            </div>
        </div>
        <div *ngIf="CommonService.DeviceDetectorService.IsPhone" style="display:flex" class="hide-if-empty">
            <ticket-work-complete [AllowedActions]="AllowedActions" [Ticket]="Ticket" style="padding-bottom:20px" class="hide-if-empty"></ticket-work-complete>
        </div>
        <div class="view iq-list-items" style="height:100%; overflow:auto">
            <desktop-ticket-response-row-header *ngIf="!CommonService.DeviceDetectorService.IsPhone" [HaveUtilityTypes]="HaveUtilityTypes"></desktop-ticket-response-row-header>

            <ng-container *ngFor="let item of FilteredResponseHistory">
                <div *ngIf="!CommonService.DeviceDetectorService.IsPhone" class="row" [ngClass]="{'no-response-background': item.Response && !item.Response.ResponseCode && item.Response.ResponseRequired, 'event-background': item.Event}">
                    <desktop-ticket-response-row [ResponseItem]="item" [Ticket]="Ticket"
                                                 [HaveUtilityTypes]="HaveUtilityTypes" [CanEnterResponse]="CanEnterResponse"
                                                 (Refresh)="Refresh()"></desktop-ticket-response-row>
                </div>
                <phone-ticket-response-row *ngIf="CommonService.DeviceDetectorService.IsPhone" [ResponseItem]="item" [Ticket]="Ticket"
                                           [HaveUtilityTypes]="HaveUtilityTypes" [CanEnterResponse]="CanEnterResponse"
                                           (Refresh)="Refresh()"></phone-ticket-response-row>
            </ng-container>
        </div>
    </div>
</ng-template>
