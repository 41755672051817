import { Injectable, EventEmitter } from '@angular/core';
import { TicketFunction } from 'Models/Configuration/TicketFunction.model';
import { TicketTypeOptionsForTicketEntry } from 'Models/Configuration/TicketTypeOptionsForTicketEntry.model';
import { TicketFunctionActionEnum } from 'Enums/TicketFunctionAction.enum';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

/**
 *  This service combines settings on the current Ticket Function and Ticket Type and exposes
 *  the various ticket entry options.  Some of the options can be configured on the
 *  Ticket Function (for normal One Calls) and/or on the Ticket Type (AZ).  This service should be used
 *  to resolve all of that.
 * */
@Injectable({
    providedIn: 'root'
})
export class TicketEntryOptionsService {

    private _TicketFunction: TicketFunction;
    private _TicketType: TicketTypeOptionsForTicketEntry;
    private _HomeownerExcavatorCompanyTypeIDs: string[];

    /**
     *  get/set the current TicketFunction.  Setting to null/undefined will clear the ticket function
     *  (to an empty TicketFunction object).
     */
    get TicketFunction(): TicketFunction { return this._TicketFunction; }
    set TicketFunction(ticketFunction: TicketFunction) {
        this._TicketFunction = ticketFunction;
        if (!this._TicketFunction)
            this._TicketFunction = new TicketFunction();
        this.OptionsChanged.next();
    }

    /**
     *  get/set the current TicketType.  Setting to null/undefined will clear the ticket type
     *  (to an empty TicketTypeOptionsForTicketEntry object).
     */
    get TicketType(): TicketTypeOptionsForTicketEntry { return this._TicketType; }
    set TicketType(ticketType: TicketTypeOptionsForTicketEntry) {
        this._TicketType = ticketType;
        if (!this._TicketType)
            this._TicketType = new TicketTypeOptionsForTicketEntry();
        this.OptionsChanged.next();
    }

    set HomeownerExcavatorCompanyTypeIDs(ids: string[]) {
        this._HomeownerExcavatorCompanyTypeIDs = ids;
    }

    /**
     *  Called any time the Ticket Function or Ticket Type are changed.
     */
    public readonly OptionsChanged: EventEmitter<void> = new EventEmitter();

    constructor() {
    }

    get CanUploadAttachments(): boolean {
        return coerceBooleanProperty(this._TicketFunction.CanUploadAttachments);
    }

    get MustVerifyLocation(): boolean {
        return this.FirstValue(this._TicketFunction.MustVerifyLocation, this._TicketType.MustVerifyLocation);
    }

    get MustVerifyLBPs(): boolean {
        return this._TicketType.MustVerifyLBPs;
    }

    get CanScheduleMeeting(): boolean {
        return this._TicketType.CanScheduleMeeting && !this._TicketFunction.PreventScheduledMeetingChange;
    }

    get TicketCreatedDisclaimerText(): string {
        return this._TicketType.TicketCreatedDisclaimerText;
    }

    get CanManuallyAddServiceAreas(): boolean {
        return this.FirstValue(this._TicketFunction.CanManuallyAddServiceAreas, this._TicketType.CanManuallyAddServiceAreas);
    }

    get CanRemoveManuallyAddedServiceAreas(): boolean {
        //  Ticket Type does not have an option for this.  If CanRemoveManuallyAddedServiceAreas is set, remove is implied.
        return this.FirstValue(this._TicketFunction.CanRemoveManuallyAddedServiceAreas, this._TicketType.CanManuallyAddServiceAreas);
    }

    get BackgroundColor(): string {
        return this._TicketType.BackgroundColor;
    }

    get FontColor(): string {
        return this._TicketType.FontColor;
    }

    public get MainContactTypeName(): string {
        return this._TicketType.MainContactTypeName;
    }
    public get AltContactTypeName(): string {
        return this._TicketType.AltContactTypeName;
    }
    public get EmergContactTypeName(): string {
        return this._TicketType.EmergContactTypeName;
    }

    public get ClipDigSiteToCounty(): boolean {
        return this._TicketType && this._TicketType.ClipDigSiteToCounty;
    }

    public get ClipDigSiteToPlace(): boolean {
        return this._TicketType && this._TicketType.ClipDigSiteToPlace;
    }

    private FirstValue(value1: boolean, value2: boolean): boolean {
        if ((value1 !== undefined) && (value1 !== null))
            return value1;
        return value2;
    }

    public IsHomeownerExcavatorCompanyType(excavatorCompanyTypeID: string): boolean {
        return excavatorCompanyTypeID && this._HomeownerExcavatorCompanyTypeIDs && this._HomeownerExcavatorCompanyTypeIDs.some(id => id === excavatorCompanyTypeID);
    }
}
