import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormComponentBase } from '../../form-component-base';
import { IqAwsValidationClass, controlsEqualValidator, validateUsername } from '../../validation';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { PasswordRequirements } from '../../models';
import { IqAwsCognitoService } from '../../Services/iq-aws-cognito.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'iq-aws-cognito-create-login',
    templateUrl: './create-login.component.html',
    styleUrls: ['./create-login.component.css']
})
export class iQCreateLoginSharedComponent extends FormComponentBase {

    private _passwordRequirements: PasswordRequirements;
    @Input() set PasswordRequirements(val: PasswordRequirements) {
        const defaultRequirements = new PasswordRequirements();
        this._passwordRequirements = { ...defaultRequirements, ...val };
    }
    get PasswordRequirements() {
        return this._passwordRequirements;
    }

    @Input() ShowVerifyEmail: boolean = true;

    @Input() Email: string;
    @Input() Username: string;
    @Input() GivenName: string;
    @Input() FamilyName: string;

    public UsernameSameAsEmail: boolean = true;

    @Output() IsValid: EventEmitter<boolean> = new EventEmitter();

    private _isValid: boolean = false;

    public UsernameFormControl: UntypedFormControl;
    //  TODO: Stop using this pattern!  It causes the page to have to find the control every time it checks for changes -
    //  which can be hundreds of times when it's referenced in the html template!
    //  Store the FormControl into a (typed!) public property like doing above - it's way more efficient.
    get passwordControl() { return this.group.get("password"); }
    get confirmPasswordControl() { return this.group.get("confirmPassword"); }
    get emailControl() { return this.group.get("email"); }
    get confirmEmailControl() { return this.group.get("confirmEmail"); }
    get givenNameControl() { return this.group.get("given_name"); }
    get familyNameControl() { return this.group.get("family_name"); }

    BuildForm(): UntypedFormGroup {
        const validators = [controlsEqualValidator('password', 'confirmPassword')];
        if (this.ShowVerifyEmail)
            validators.push(controlsEqualValidator('email', 'confirmEmail'));

        this.UsernameFormControl = new UntypedFormControl(this.Username);      //  Validator set in SetUsernameValidator()

        const form = new UntypedFormGroup({
            username: this.UsernameFormControl,
            given_name: new UntypedFormControl(this.GivenName, [Validators.required]),
            family_name: new UntypedFormControl(this.FamilyName, [Validators.required]),
            email: new UntypedFormControl(this.Email, [Validators.required, Validators.pattern(IqAwsValidationClass.emailPattern)]),
            confirmEmail: new UntypedFormControl(this.Email, [Validators.required]),
            password: new UntypedFormControl(null, [Validators.required]),
            confirmPassword: new UntypedFormControl(null, [Validators.required])
        }, validators);

        this.SetUsernameValidator();

        form.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
            const formValid = this.group.valid;

            if (this._isValid !== formValid) {
                this._isValid = formValid;
                this.IsValid.next(formValid);
            }
        });

        return form;
    }

    constructor(protected awsCognitoService: IqAwsCognitoService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();

        this.SetPasswordRequirements(this.passwordControl, this.PasswordRequirements);
    }

    GetValue(): any {
        if (!this.group.valid)
            return null;

        const user = this.group.value;
        //Clear this out so we don't try to send it on the signup call to cognito
        user.confirmPassword = null;
        user.confirmEmail = null;

        if (this.UsernameSameAsEmail)
            user.username = user.email;

        return user;
    }

    public UsernameSameAsEmailChanged(): void {
        this.UsernameSameAsEmail = !this.UsernameSameAsEmail;
        this.SetUsernameValidator();
    }

    private SetUsernameValidator(): void {
        if (this.UsernameSameAsEmail)
            this.UsernameFormControl.clearValidators();
        else
            this.UsernameFormControl.setValidators([Validators.required, validateUsername]);
        this.UsernameFormControl.updateValueAndValidity();
    }

    // onReturnToLogin() {
    //   this.awsCognitoService.setAuthState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
    // }

    // onSignUp() {
    //   if (!this.group.valid) {
    //     return;
    //   }

    //   this.isBusy = true;
    //   const user = this.group.value;
    //   //Clear this out so we don't try to send it on the signup call to cognito
    //   user.confirmPassword = null;
    //   user.confirmEmail = null;

    //   this.CreateLoginSubmitted.next(user);

    //   // this.awsCognitoService.CreateNewUser(user).pipe(take(1), finalize(() => this.isBusy = false))
    //   //   .subscribe(null, err => this.SetError(err));
    // }
}
