<div *ngIf="Model.ActionName" class="action" >
    <div>{{Model.ActionName}}</div>
</div>
<div *ngIf="!Model.ActionName" class="item" style="display:flex; white-space:normal">
    <!-- todo: if One Call doesn't use CompanyID, need to hide it.  Can't do it based on Model.CompanyID because results may be rolled up.  Will need one call setting -->
    <div class="gray-primary-text" style="min-width:6ch">{{Model.CompanyID}}</div>
    <div style="padding-left:10px; padding-right:10px">
        <div>{{Model.CompanyName}}<span *ngIf="Model.OfficeName">&nbsp;-&nbsp;{{Model.OfficeName}}</span></div>
        <div *ngIf="ShowOfficePhone">{{Model.OfficePhone | phone}}</div>
    </div>
    <div *ngIf="!this.Model.ExcavatorCompanyID" style="flex-grow:1; text-align:end; color:red; padding-left:3em">
        * Create New Company
    </div>
    <div *ngIf="this.Model.ExcavatorCompanyID" class="gray-primary-text" style="flex-grow:1; text-align:end">
        <div *ngIf="Description">{{Description}}</div>
        <div *ngIf="Model.ContactName">
            {{Model.ContactName}}
            <span *ngIf="Model.ExcavatorID">&nbsp;({{Model.ExcavatorID}})</span>
        </div>
        <div *ngIf="Model.Address">{{FormattedAddress}}</div>
    </div>
</div>
