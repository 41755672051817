<div class="dialog-fill-height">
    <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Responses for Ticket {{Data.Ticket.TicketNumber}}</h1>
    <mat-dialog-content style="display:flex; flex-direction:column; overflow:hidden">
        <div style="padding-bottom:10px; padding-left:10px; display:flex">
            <!--<span class="gray-primary-text" style="padding-right:20px">Show:</span>-->
            <mat-radio-group aria-label="Options:" [(ngModel)]="ViewOption">
                <mat-radio-button color="primary" [value]="1">Current/Most Recent Response for each Service Area</mat-radio-button>
                <mat-radio-button style="padding-left:20px" color="primary" [value]="2">Show all Responses and Ticket Events</mat-radio-button>
            </mat-radio-group>
            <div style="flex-grow:1"></div>
            <ticket-work-complete [AllowedActions]="Data.AllowedActions" [Ticket]="Data.Ticket"></ticket-work-complete>
            <iq-icon-button *ngIf="CanEnterResponse" style="padding-left:2em" icon="New" button-class="Button-Small" (click)="AddNewResponse()">Add Response</iq-icon-button>
        </div>
        <!-- Positive Response is not enabled inside either of these because it's just easier to keep it inside this dialog. -->
        <!-- Prevents having to deal with adding in 1 and then making the other one update (other than making the history-list always refresh itself. -->
        <ticket-response-service-area-list [hidden]="ViewOption !== 1" [ServiceAreas]="ServiceAreas" [Ticket]="Data.Ticket"
                                                    [IsExcavatorOfTicket]="IsExcavatorOfTicket" (NeedsDiscussionValue)="NeedsDiscussionValue($event)"></ticket-response-service-area-list>
        <!-- We were previously just hiding the history list so that it would only fetch the list once.  Since the component above can add responses,
             we can't do that any more. It's query is very efficient though.  -->
        <ticket-response-history-list *ngIf="ViewOption === 2" [Ticket]="Data.Ticket"></ticket-response-history-list>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: space-between;">
        <div style="display:flex">
            <div style="display:flex">
                <div style="height:20px; width:20px; margin-right:10px" class="no-response-background"></div>
                <div>No Response Posted - Excavator must check job site for marks</div>
            </div>
            <div *ngIf="ViewOption === 2" style="display:flex; margin-left:40px">
                <div style="height:20px; width:20px; margin-right:10px" class="event-background"></div>
                <div>Ticket Entry Event</div>
            </div>
        </div>
        <div style="display:flex">
            <iq-icon-button *ngIf="IsExcavatorOfTicket && CenterUsesExcavatorComments" button-class="Dialog" icon="New" (click)="AddExcavatorComment()" style="margin-right:50px">Add Comments</iq-icon-button>

            <mat-checkbox *ngIf="NeedsExcavationDate || NeedsMarkingDelayResponse" color="primary" (change)="IgnoreRequired($event)"
                          style="margin-right:50px; align-self:center">I choose not to provide required answers right now</mat-checkbox>

            <iq-icon-button button-class="Dialog" icon="Close" [disabled]="!IsValid && !IgnoreRequiredDiscussionResponses" (click)="dialogRef.close()" iqAutoFocus>Done</iq-icon-button>
        </div>
    </mat-dialog-actions>
</div>
