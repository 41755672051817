import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatDatepicker } from '@matheo/datepicker';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { SearchFilterValue } from 'Models/Searching/SearchFilter.model';
import { SearchFilterDateConfig } from 'Models/Searching/SearchFilterDateConfig.model';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';
import { CustomValidationError } from 'Shared/Components/Forms/Validation/CustomValidationError';
import { APIDateFormat, APIDateTimeFormat } from 'Shared/Utils/MaskFormats.model';
import { BaseFilterItem } from '../BaseFilterItem';

enum DateFilterOptions {
    None = 0,
    Today,
    Yesterday,
    SingleDate,
    DateRange,
    PastDaysFromToday,//Past
    PastHoursFromNow,//Past
    LastWeek,//Meaning last sunday through last saturday
    LastMonth,
    Tomorrow,//Future
    DaysFromToday,//Future
    HoursFromNow,//Future
    DaysBeforeAndNotIncludingToday,  //  Past
    DaysAfterAndNotIncludingToday   //  Future
}

@Component({
    selector: 'iq-list-date-filter',
    templateUrl: './ListDateFilter.component.html',
    styleUrls: ['./ListDateFilter.component.scss']
})
export class ListDateFilterComponent extends BaseFilterItem implements OnInit {

    //  TODO: Move this in to SearchFilterDateOptions
    @Input() showFutureOptions: boolean = true;

    //  TODO: Move this in to SearchFilterDateOptions
    /**
     *  If true, hides options that include Time - only show date options.
     */
    @Input() DateOnly: boolean = false;

    private _DateFilterConfig: SearchFilterDateConfig = new SearchFilterDateConfig()
    /**
     *  Date filter configuration.  If null, all default options are used.
     */
    @Input()
    public get DateFilterConfig(): SearchFilterDateConfig {
        return this._DateFilterConfig;
    }
    public set DateFilterConfig(config: SearchFilterDateConfig) {
        if (config)
            this._DateFilterConfig = config;
    }

    //  Allow more than 24 hours on hourly filters because these filters go from the current time and
    //  the date from/past filters both include today so not possible to do with those.
    public get MAX_HOURS(): number { return 240 }

    public FilterOptions = DateFilterOptions;

    public SelectedDateOption: DateFilterOptions = DateFilterOptions.None;

    public PastDaysFocusEvent: Subject<boolean> = new Subject();
    public PastDaysFormControl: UntypedFormControl;

    public DaysBeforeAndNotIncludingTodayFocusEvent: Subject<boolean> = new Subject();
    public DaysBeforeAndNotIncludingTodayFormControl: UntypedFormControl;

    public PastHoursFocusEvent: Subject<boolean> = new Subject();
    public PastHoursFormControl: UntypedFormControl;

    public NextDaysFocusEvent: Subject<boolean> = new Subject();
    public NextDaysFormControl: UntypedFormControl;

    public DaysAfterAndNotIncludingTodayFocusEvent: Subject<boolean> = new Subject();
    public DaysAfterAndNotIncludingTodayFormControl: UntypedFormControl;

    public NextHoursFocusEvent: Subject<boolean> = new Subject();
    public NextHoursFormControl: UntypedFormControl;

    public SingleDateFormControl: UntypedFormControl;

    public DateRangeStartFormControl: UntypedFormControl;
    public DateRangeEndFormControl: UntypedFormControl;

    public MaxDays: number;

    @ViewChild('pickerSingle', { read: MatDatepicker, static: false })          //  static:false needed because wrapped in an ngIf
    private _PickerSingle: MatDatepicker<any>;

    @ViewChild('pickerRangeStart', { read: MatDatepicker, static: false })      //  static:false needed because wrapped in an ngIf
    private _PickerRangeStart: MatDatepicker<any>;

    constructor(authenticationService: AuthenticationService) {
        super();

        this.searchOperator = null;     //  null means nothing is picked

        this.MaxDays = authenticationService.CurrentUser.MaxDaysInDateRangeQueries ? authenticationService.CurrentUser.MaxDaysInDateRangeQueries : null;

        this.CreateFormControls();
    }

    private CreateFormControls(): void {
        const validators = [Validators.required, Validators.min(1)];
        if (this.MaxDays)
            validators.push(Validators.max(this.MaxDays));

        this.PastDaysFormControl = new UntypedFormControl(null, validators);
        this.DaysBeforeAndNotIncludingTodayFormControl = new UntypedFormControl(null, validators);

        this.NextDaysFormControl = new UntypedFormControl(null, validators);
        this.DaysAfterAndNotIncludingTodayFormControl = new UntypedFormControl(null, validators);

        this.DateRangeStartFormControl = new UntypedFormControl(null, Validators.required);
        this.DateRangeEndFormControl = new UntypedFormControl(null, [Validators.required, () => this.ValidateMaxDays()]);

        this.PastHoursFormControl = new UntypedFormControl(null, [Validators.required, Validators.min(1), Validators.max(this.MAX_HOURS)]);
        this.NextHoursFormControl = new UntypedFormControl(null, [Validators.required, Validators.min(1), Validators.max(this.MAX_HOURS)]);

        this.SingleDateFormControl = new UntypedFormControl(null, Validators.required);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        //  TODO: These options should be removed in favor of the DateFilterConfig so that everything is consolidated and organized together.
        //  For now though, just check the settings and set the cooresponding options in DateFilterConfig.
        if (this.DateOnly)
            this.DateFilterConfig.HideHours();
        if (!this.showFutureOptions)
            this.DateFilterConfig.HideFuture();

        //  Not all of these options require values!  If any that do require them, don't have any, we will just clear() - that's a configuration error.
        switch (this.searchOperator) {
            case SearchFilterOperatorEnum.PastDaysFromToday:                        //  Past: Days before (and including) today
                if (this.values && this.values.length > 0) {
                    if (this.values[0].FilterValue === "0")
                        this.SelectedDateOption = DateFilterOptions.Today;      //  PastDaysFromToday w/value of 0 is the old way of specifing "Today"
                    else {
                        this.PastDaysFormControl.setValue(this.values[0].FilterValue ?? "1");
                        this.SelectedDateOption = DateFilterOptions.PastDaysFromToday;
                    }
                } else
                    this.clear();
                break;
            case SearchFilterOperatorEnum.DaysBeforeAndNotIncludingToday:           //  Past: Days before (and *NOT* including) today
                if (this.values && this.values.length > 0) {
                    this.DaysBeforeAndNotIncludingTodayFormControl.setValue(this.values[0].FilterValue ?? "1");
                    this.SelectedDateOption = DateFilterOptions.DaysBeforeAndNotIncludingToday;
                } else
                    this.clear();
                break;
            case SearchFilterOperatorEnum.PastHoursFromNow:
                if (this.values && this.values.length > 0) {
                    this.PastHoursFormControl.setValue(this.values[0].FilterValue ?? "1");
                    this.SelectedDateOption = DateFilterOptions.PastHoursFromNow;
                } else
                    this.clear();
                break;
            case SearchFilterOperatorEnum.DaysFromToday:
                if (this.values && this.values.length > 0) {
                    if (this.values[0].FilterValue === "0")
                        this.SelectedDateOption = DateFilterOptions.Today;
                    else {
                        this.NextDaysFormControl.setValue(this.values[0].FilterValue ?? "1");
                        this.SelectedDateOption = DateFilterOptions.DaysFromToday;
                    }
                } else
                    this.clear();
                break;
            case SearchFilterOperatorEnum.DaysAfterAndNotIncludingToday:
                if (this.values && this.values.length > 0) {
                    this.DaysAfterAndNotIncludingTodayFormControl.setValue(this.values[0].FilterValue ?? "1");
                    this.SelectedDateOption = DateFilterOptions.DaysAfterAndNotIncludingToday;
                } else
                    this.clear();
                break;
            case SearchFilterOperatorEnum.HoursFromNow:
                if (this.values && this.values.length > 0) {
                    this.NextHoursFormControl.setValue(this.values[0].FilterValue ?? "1");
                    this.SelectedDateOption = DateFilterOptions.HoursFromNow;
                } else
                    this.clear();
                break;
            case SearchFilterOperatorEnum.Today:
                this.SelectedDateOption = DateFilterOptions.Today;
                break;
            case SearchFilterOperatorEnum.Tomorrow:
                this.SelectedDateOption = DateFilterOptions.Tomorrow;
                break;
            case SearchFilterOperatorEnum.Yesterday:
                this.SelectedDateOption = DateFilterOptions.Yesterday;
                break;
            case SearchFilterOperatorEnum.LastWeek:
                this.SelectedDateOption = DateFilterOptions.LastWeek;
                break;
            case SearchFilterOperatorEnum.LastMonth:
                this.SelectedDateOption = DateFilterOptions.LastMonth;
                break;

            case SearchFilterOperatorEnum.Between: {
                if (this.values && this.values.length > 1) {
                    //  FilterValues are stored only as a date so must parse them that way
                    const firstDate = moment(this.values[0].FilterValue, APIDateFormat);
                    const secondDate = moment(this.values[1].FilterValue, APIDateFormat);
                    const dayDiff = secondDate.diff(firstDate, 'days');

                    if (dayDiff === 1) {
                        this.searchOperator = SearchFilterOperatorEnum.SingleDate;
                        this.SelectedDateOption = DateFilterOptions.SingleDate;
                        this.SingleDateFormControl.setValue(firstDate.format(APIDateTimeFormat));
                    }
                    else {
                        //  The values set in to the controls *MUST* be APIDateTimeFormat (or the same value as the Date.toString()).
                        //  Otherwise, the date may fail to parse.  The values stored in the FilterItem are just the mm/dd/yyyy portion
                        //  so must always re-format them when setting.
                        this.searchOperator = SearchFilterOperatorEnum.Between;
                        this.SelectedDateOption = DateFilterOptions.DateRange;
                        this.DateRangeStartFormControl.setValue(firstDate.format(APIDateTimeFormat));
                        this.DateRangeEndFormControl.setValue(secondDate.subtract(1, 'days').format(APIDateTimeFormat));
                    }
                } else
                    this.clear();
                break;
            }
            case SearchFilterOperatorEnum.SingleDate:
                //  Shortcut to set a Between filter on a single date.  Much more straightforward than trying to calculate the second date to match
                //  the logic being done above...
                if (this.values && this.values.length >= 1) {
                    const firstDate = moment(this.values[0].FilterValue, APIDateFormat);
                    this.searchOperator = SearchFilterOperatorEnum.SingleDate;
                    this.SelectedDateOption = DateFilterOptions.SingleDate;
                    this.SingleDateFormControl.setValue(firstDate.format(APIDateTimeFormat));
                } else
                    this.clear();
                break;

            case SearchFilterOperatorEnum.BusinessDaysBeforeAndAfterToday:
                if (this.values && this.values.length > 1) {
                    const firstDate = moment().startOf("day").add(0 - parseInt(this.values[0].FilterValue), "days");
                    const secondDate = moment().startOf("day").add(parseInt(this.values[1].FilterValue), "days");

                    this.SelectedDateOption = DateFilterOptions.DateRange;
                    this.DateRangeStartFormControl.setValue(firstDate.format(APIDateTimeFormat));
                    this.DateRangeEndFormControl.setValue(secondDate.format(APIDateTimeFormat));
                } else
                    this.clear();
                break;
            default:
                this.clear();
                break;
        }

        this.SetupChangeHandlers();

        //  Sets focus to the input for the selected option.  But don't auto open calendar.
        setTimeout(() => this.FocusInputForSelectedOption(false));
    }

    private SetupChangeHandlers(): void {
        this.PastDaysFormControl.valueChanges.pipe(debounceTime(400)).subscribe(val => {
            if ((this.SelectedDateOption === DateFilterOptions.PastDaysFromToday) && this.PastDaysFormControl.valid)
                this.SetDateFilterOperatorAndValue(SearchFilterOperatorEnum.PastDaysFromToday, val);
        });
        this.DaysBeforeAndNotIncludingTodayFormControl.valueChanges.pipe(debounceTime(400)).subscribe(val => {
            if ((this.SelectedDateOption === DateFilterOptions.DaysBeforeAndNotIncludingToday) && this.DaysBeforeAndNotIncludingTodayFormControl.valid)
                this.SetDateFilterOperatorAndValue(SearchFilterOperatorEnum.DaysBeforeAndNotIncludingToday, val);
        });

        this.PastHoursFormControl.valueChanges.pipe(debounceTime(400)).subscribe(val => {
            if ((this.SelectedDateOption === DateFilterOptions.PastHoursFromNow) && this.PastHoursFormControl.valid)
                this.SetDateFilterOperatorAndValue(SearchFilterOperatorEnum.PastHoursFromNow, val);
        });

        this.NextDaysFormControl.valueChanges.pipe(debounceTime(400)).subscribe(val => {
            if ((this.SelectedDateOption === DateFilterOptions.DaysFromToday) && this.NextDaysFormControl.valid)
                this.SetDateFilterOperatorAndValue(SearchFilterOperatorEnum.DaysFromToday, val);
        });
        this.DaysAfterAndNotIncludingTodayFormControl.valueChanges.pipe(debounceTime(400)).subscribe(val => {
            if ((this.SelectedDateOption === DateFilterOptions.DaysAfterAndNotIncludingToday) && this.DaysAfterAndNotIncludingTodayFormControl.valid)
                this.SetDateFilterOperatorAndValue(SearchFilterOperatorEnum.DaysAfterAndNotIncludingToday, val);
        });

        this.NextHoursFormControl.valueChanges.pipe(debounceTime(400)).subscribe(val => {
            if ((this.SelectedDateOption === DateFilterOptions.HoursFromNow) && this.NextHoursFormControl.valid)
                this.SetDateFilterOperatorAndValue(SearchFilterOperatorEnum.HoursFromNow, val);
        });
    }

    public OnSelectedDateOptionChanged(): void {
        switch (this.SelectedDateOption) {
            case DateFilterOptions.None:
                //  TODO:  Previously, this was only happening if "this.values.length > 0".  How can we have values when None is picked and why would that matter???
                this.searchOperator = null;//SearchFilterOperatorEnum.Between;     //  Why is this the default in here???
                this.values = [];
                this.fireChangeEvent();
                break;
            case DateFilterOptions.Today:
                this.SetDateFilterOperatorAndValue(SearchFilterOperatorEnum.PastDaysFromToday, 0);
                break;
            case DateFilterOptions.Yesterday:
                this.searchOperator = SearchFilterOperatorEnum.Yesterday;
                this.values = [];
                this.addValue([new SearchFilterValue("0", "Yesterday")]);//Add in something so we know that there is a filter we need to keep (it will never be used).  All other filters have a value here so we use it to remove out empty filters before saving
                break;
            case DateFilterOptions.Tomorrow:
                this.searchOperator = SearchFilterOperatorEnum.Tomorrow;
                this.values = [];
                this.addValue([new SearchFilterValue("0", "Tomorrow")]);//Add in something so we know that there is a filter we need to keep (it will never be used).  All other filters have a value here so we use it to remove out empty filters before saving
                break;
            case DateFilterOptions.LastWeek:
                this.searchOperator = SearchFilterOperatorEnum.LastWeek;
                this.values = [];
                this.addValue([new SearchFilterValue("0", "Last week")]);//Add in something so we know that there is a filter we need to keep (it will never be used).  All other filters have a value here so we use it to remove out empty filters before saving
                break;
            case DateFilterOptions.LastMonth:
                this.searchOperator = SearchFilterOperatorEnum.LastMonth;
                this.values = [];
                this.addValue([new SearchFilterValue("0", "Last month")]);//Add in something so we know that there is a filter we need to keep (it will never be used).  All other filters have a value here so we use it to remove out empty filters before saving
                break;

            //  For these, we need to trigger the "changed" methods so that if there are current values entered in the fields, they are set
            //  correctly and the the change event is triggered with the updated values.
            //  Otherwise, (for example) if the filter is displayed and initially set to SingleDate, then we click Yesterday, then we click back to
            //  SingleDate, then we Close/Accept the search: The last change event would be when we clicked Yesterday so the wrong operator gets set!
            case DateFilterOptions.SingleDate:
                this.OnSingleDateChanged();
                break;
            case DateFilterOptions.DateRange:
                this.OnDateRangeChanged();
                break;
        }

        setTimeout(() => this.FocusInputForSelectedOption(true));
    }

    private SetDateFilterOperatorAndValue(operator: SearchFilterOperatorEnum, value: number): void {
        this.searchOperator = operator;
        this.values = [];
        this.addValue([new SearchFilterValue(value.toString(), value.toString())]);
    }

    private FilterSingleDate(val: any): void {
        if (!val)
            return;

        const startDate = moment(val);
        if (!startDate || !startDate.isValid())//If it's not a valid date, don't try to search
            return;

        this.searchOperator = SearchFilterOperatorEnum.SingleDate;
        const endDate = startDate.clone().add(1, 'days');
        this.values = [];//Need to clear out any existing values because we only want the values we have here
        this.addValue([new SearchFilterValue(startDate.format(APIDateFormat), null), new SearchFilterValue(endDate.format(APIDateFormat), null)]);
    }

    public OnInputClicked(clickedOption: DateFilterOptions): void {
        if (this.isDisabled)
            return;

        if (this.SelectedDateOption !== clickedOption) {
            this.SelectedDateOption = clickedOption;
            setTimeout(() => this.FocusInputForSelectedOption(true));
        }
    }

    private FocusInputForSelectedOption(openCalendar: boolean): void {
        this.PastDaysFormControl.disable();
        this.DaysBeforeAndNotIncludingTodayFormControl.disable();
        this.PastHoursFormControl.disable();
        this.SingleDateFormControl.disable();
        this.DateRangeStartFormControl.disable();
        this.DateRangeEndFormControl.disable();
        this.NextDaysFormControl.disable();
        this.DaysAfterAndNotIncludingTodayFormControl.disable();
        this.NextHoursFormControl.disable();

        switch (this.SelectedDateOption) {
            case DateFilterOptions.PastDaysFromToday:
                if (!this.isDisabled)
                    this.PastDaysFormControl.enable();
                this.PastDaysFocusEvent.next(true);
                break;
            case DateFilterOptions.DaysBeforeAndNotIncludingToday:
                if (!this.isDisabled)
                    this.DaysBeforeAndNotIncludingTodayFormControl.enable();
                this.DaysBeforeAndNotIncludingTodayFocusEvent.next(true);
                break;
            case DateFilterOptions.PastHoursFromNow:
                if (!this.isDisabled)
                    this.PastHoursFormControl.enable();
                this.PastHoursFocusEvent.next(true);
                break;
            case DateFilterOptions.SingleDate:
                if (!this.isDisabled)
                    this.SingleDateFormControl.enable();
                if (openCalendar)
                    this._PickerSingle.open();
                break;
            case DateFilterOptions.DateRange:
                if (!this.isDisabled) {
                    this.DateRangeStartFormControl.enable();
                    this.DateRangeEndFormControl.enable();
                }
                if (openCalendar)
                    this._PickerRangeStart.open();
                break;
            case DateFilterOptions.DaysFromToday:
                if (!this.isDisabled)
                    this.NextDaysFormControl.enable();
                this.NextDaysFocusEvent.next(true);
                break;
            case DateFilterOptions.DaysAfterAndNotIncludingToday:
                if (!this.isDisabled)
                    this.DaysAfterAndNotIncludingTodayFormControl.enable();
                this.DaysAfterAndNotIncludingTodayFocusEvent.next(true);
                break;
            case DateFilterOptions.HoursFromNow:
                if (!this.isDisabled)
                    this.NextHoursFormControl.enable();
                this.NextHoursFocusEvent.next(true);
                break;
        }
    }

    public OnSingleDateChanged(): void {
        if (this.SingleDateFormControl.value)
            this.FilterSingleDate(this.SingleDateFormControl.value);
    }

    public OnDateRangeChanged(): void {
        //  When the first date is picked, automatically default the others to 30 days prior/past (or MaxDays if that
        //  happens to be set less than 30).  This saves a ton of time if you pick a date in the past - it defaults the
        //  other date to at least be close to the first one you pick to save all the hassle of navigating back to it again.
        const defaultDays = (this.MaxDays && (this.MaxDays < 30)) ? this.MaxDays : 30;
        if (!this.DateRangeStartFormControl.value && this.DateRangeEndFormControl.value) {
            let date = moment(this.DateRangeEndFormControl.value);
            date = date.subtract(defaultDays + 1, "days");
            this.DateRangeStartFormControl.setValue(date.format(APIDateTimeFormat));
        }

        if (this.DateRangeStartFormControl.value && !this.DateRangeEndFormControl.value) {
            let date = moment(this.DateRangeStartFormControl.value);
            date = date.add(defaultDays - 1, "days");
            this.DateRangeEndFormControl.setValue(date.format(APIDateTimeFormat));
        }

        //  Must do this now or even changing the start date will not have checks the validators yet!
        this.DateRangeEndFormControl.updateValueAndValidity();

        //  Must do this in a timeout or the [min] rule on the end date picker will not have been re-evaluated yet.
        //  Issue if we 1) Set start/end dates, 2) Change start date to be after end date (end date is now invalid), 3) Change start to be valid again.
        setTimeout(() => {
            if (this.DateRangeStartFormControl.valid && this.DateRangeEndFormControl.valid) {
                const startDate = moment(this.DateRangeStartFormControl.value);
                const endDate = moment(this.DateRangeEndFormControl.value);
                if (startDate.isSameOrBefore(endDate)) {
                    this.values = [];
                    this.searchOperator = SearchFilterOperatorEnum.Between;
                    this.addValue([
                        new SearchFilterValue(startDate.format(APIDateFormat), null),
                        new SearchFilterValue(endDate.clone().add(1, 'days').format(APIDateFormat), null)   // must clone() b/c moment changes the instance values!
                    ]);
                }
            }
        });
    }

    private ValidateMaxDays(): ValidationErrors | null {
        if (!this.DateRangeStartFormControl?.value || !this.DateRangeEndFormControl?.value || !this.MaxDays)
            return null;

        let maxDate = moment(this.DateRangeStartFormControl.value);
        maxDate = maxDate.add(this.MaxDays - 1, "days");
        if (moment(this.DateRangeEndFormControl.value) <= moment(maxDate))
            return null;

        return CustomValidationError.Create("Max " + String(this.MaxDays) + " days");
    }

    protected clear(): void {
        if (!this.allowEmpty) {
            this.SelectedDateOption = DateFilterOptions.Today;
            this.SetDateFilterOperatorAndValue(SearchFilterOperatorEnum.PastDaysFromToday, 0);
        }
        else
            this.SelectedDateOption = DateFilterOptions.None;
    }
}
