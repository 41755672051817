<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Cancel Ticket {{TicketNumber}}</h1>
<mat-dialog-content class="iq-ticket-entry-section" style="display:flex; flex-direction:column">
    <div>Why is ticket {{TicketNumber}} being canceled?</div>
    <mat-form-field style="padding-top:30px">
        <textarea matInput [formControl]="ReasonFormControl" UpperCase placeholder="Reason" cdkTextareaAutosize
                  iqInputLimiter [MaxColumns]="MaxColumns" [MaxRows]="MaxRows" (InputHint)="InputHint=$event"></textarea>
        <mat-hint align="end" class="accent-color">{{InputHint}}</mat-hint>
    </mat-form-field>
    <div *ngIf="PromptForReplacedByTicketNumber" class="flex-row" style="padding-top:20px">
        <div>
            <label>Replaced by Ticket Number:</label>
            <mat-form-field style="padding-left:20px">
                <input matInput [formControl]="ReplacedByTicketNumberFormControl" type="search" autocomplete="off"
                       placeholder="Ticket Number" [textMask]="TicketNumberMaskOptions" style="width:10em" />
            </mat-form-field>
        </div>
        <mat-checkbox *ngIf="LastTicketNumber" [formControl]="UseLastTicketCreatedFormControl" (change)="UseLastTicketCreatedChanged()"
                      color="primary" style="padding-left:4em; margin-top:5px">
            The last Ticket I created: {{LastTicketNumber}}
        </mat-checkbox>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="display:flex; align-items:center">
    <label>Are you sure you want to cancel this ticket?</label>
    <iq-icon-button button-class="Dialog" icon="Cancel" mat-dialog-close>No</iq-icon-button>
    <iq-icon-button button-class="Dialog" icon="OK" [disabled]="!CanCancel()" (click)="OnSave()">Yes</iq-icon-button>
</mat-dialog-actions>
