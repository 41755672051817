import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import {
    faBellExclamation, faBroadcastTower, faBuilding, faChartLine, faCog, faEnvelopeOpenText, faFlagAlt, faHomeAlt,
    faInboxIn, faReceipt, faShieldAlt, faShovel,
    faTools, faUser, faUsers
} from '@fortawesome/pro-light-svg-icons';
import { faBuilding as solidBuilding, faTrafficCone } from '@fortawesome/pro-solid-svg-icons';
import { Guid } from '@iqSharedUtils/Guid';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { AppUser } from 'Models/Security/AppUser.model';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import screenfull from 'screenfull';
import { AppUpdateService } from 'Services/AppUpdateService';
import { CognitoAdminService } from 'Services/CognitoAdminService';
import { MainMenuService } from 'Services/MainMenuService';
import { PrintingService } from 'Services/Printing.service';
import { RoutingService } from 'Services/RoutingService.service';
import { SettingsService } from 'Services/SettingsService';
import { BasePrimaryView } from "Shared/BaseClasses/BasePrimaryView";
import { AuthenticationService } from './Services/AuthenticationService';

export const menuResize = trigger('menuResize', [
    state('void, exit', style({ transform: 'none', width: '76px' })),
    state('enter', style({ transform: 'none', width: '206px' })),
    transition('* => enter', [
        animate('250ms ease-out')
    ]),
    transition('* => void, * => exit', [
        animate('250ms ease-in')
    ])
]);

export const mainContentResize = trigger('mainContentResize', [
    state('void', style({ transform: 'none', 'margin-left': '0px' })),
    state('exit', style({ transform: 'none', 'margin-left': '76px' })),
    state('enter', style({ transform: 'none', 'margin-left': '206px' })),
    transition('* => enter', [
        animate('250ms ease-out')
    ]),
    transition('* => void, * => exit', [
        animate('250ms ease-in')
    ])
]);

@Component({
    selector: 'desktop-root',
    templateUrl: './DesktopRoot.component.html',
    styleUrls: ['./DesktopRoot.component.scss'],
    animations: [menuResize, mainContentResize]
})
export class DesktopRootComponent extends BasePrimaryView implements OnInit {
    switchCenterIcon = faBuilding;
    switchCenterSolidIcon = solidBuilding;

    homeIcon = faHomeAlt;
    ticketIcon = faReceipt;
    projectIcon = faTrafficCone;
    excavtorIcon = faShovel;
    reportIcon = faChartLine;
    memberIcon = faUsers;
    serviceAreaIcon = faFlagAlt;
    serviceProviderIcon = faBroadcastTower; //faWrench;
    destinationIcon = faInboxIn; //faCity;
    peopleIcon = faUser;
    adminIcon = faCog;
    roleIcon = faShieldAlt;
    commIcon = faEnvelopeOpenText;
    systemAdminIcon = faTools;
    alertsIcon = faBellExclamation;

    //Will need to change
    AdminViewPermission: PermissionsEnum = PermissionsEnum.OneCallCenter_View;

    TicketViewPermission: PermissionsEnum = PermissionsEnum.Ticket_View;
    ExcavatorsViewPermission: PermissionsEnum = PermissionsEnum.ExcavatorCompany_View;
    MembersViewPermission: PermissionsEnum = PermissionsEnum.Member_View;
    PeopleViewPermission: PermissionsEnum = PermissionsEnum.Person_View;
    ServiceAreaViewPermission: PermissionsEnum = PermissionsEnum.ServiceArea_View;
    ServiceProviderViewPermission: PermissionsEnum = PermissionsEnum.ServiceProvider_View;
    DestinationViewPermission: PermissionsEnum = PermissionsEnum.Destination_View;
    RoleViewPermission: PermissionsEnum = PermissionsEnum.Role_View;
    CommunicationsViewPermission: PermissionsEnum = PermissionsEnum.Communications_View;
    ProjectViewPermission: PermissionsEnum = PermissionsEnum.Projects_View;
    SystemStatusViewPermission: PermissionsEnum = PermissionsEnum.SystemStatus_View;
    SystemConfigurationViewPermission: PermissionsEnum = PermissionsEnum.SystemConfiguration_View;

    public ProjectsName: string = null;
    public HaveReports: boolean = false;

    signedIn: Observable<boolean> = this.AuthenticationService.UserIsSignedIn;

    canChangePassword: boolean;
    personID: string;
    name: string;
    public IsSupportUser: boolean;

    public IsUnsupportedEdgeBrowser: boolean;

    isFullScreen: boolean = false;

    //Needs to be true because we need it before the view init because we need it determining if it's mobile or not so we can hide and show the menu
    @ViewChild('sideNavMenu', { static: true }) public sideNavMenu: MatSidenav;
    sideNavMode: string = 'over';
    hideSideNav: boolean = true;
    isMobileSize: boolean = false;
    forceHideSideNav: boolean = true;
    public HideTopMenuBar: boolean = false;
    showMenuText: boolean = true;
    menuHasMouseOver: boolean = false;

    constructor(
        public title: Title,
        authenticationService: AuthenticationService,
        public settingsService: SettingsService,
        renderer: Renderer2,
        //private overlayContainer: OverlayContainer, 
        private router: Router,
        private menuService: MainMenuService,
        private changeDetectorRef: ChangeDetectorRef,
        private routerService: RoutingService,
        private _PrintingService: PrintingService,
        private cognitoAdminService: CognitoAdminService,
        public AppUpdateService: AppUpdateService
    ) {
        super(authenticationService, renderer, settingsService);
        /*These seem to be responsive now. So lets try them and see how it works.  If need be we can always uncomment this out and go back to how it was*/
        ////This is used to prevent animations on dialogs (well anything that uses an overlay container).  We have to do this with material 6.3.1 because they changed the animations on the indicators to use the 'BrowserAnimationsModule', which we had turned off in the previous version
        //const disableAnimations: boolean = true;
        //// get overlay container to set property that disables animations
        //const overlayContainerElement: HTMLElement = this.overlayContainer.getContainerElement();
        //// angular animations renderer hooks up the logic to disable animations into setProperty
        //this.renderer.setProperty(overlayContainerElement, "@.disabled", disableAnimations);
        ////END prevent animations on dialogs
    }

    ngOnInit() {
        //  This is set in polyfills.ts
        this.IsUnsupportedEdgeBrowser = (navigator as any).IsUnsupportedEdgeBrowser;

        //Will change when we create user preferences so don't worry about the wonky-ness if this right now
        try {
            if (!localStorage.getItem('showMenuText'))
                localStorage.setItem('showMenuText', this.showMenuText.toString());
        } catch { }     //  Ignore error if local storage is full!

        this.showMenuText = localStorage.getItem('showMenuText') === true.toString() ? true : false;

        this.menuService.hideMenu.pipe(takeUntil(this.Destroyed))
            .subscribe(val => {
                //If printing then ignore this so that it doesn't close the menu like it's in mobile
                if (this._PrintingService.IsPrinting)
                    return;

                this.hideSideNav = val.hide;
                this.isMobileSize = val.isMobile;
                this.forceHideSideNav = val.fromPage;
                this.HideTopMenuBar = val.HideTopMenuBar;
                this.SetMobile();

                if (this.hideSideNav)
                    this.showMenuText = true;
                else
                    this.showMenuText = localStorage.getItem('showMenuText') === true.toString() ? true : false;

                //Fire change detection to update the html
                this.changeDetectorRef.detectChanges();
            });

        //  Screenfull version 4.1 & 4.2 changed the typescript definitions and they are jacked up.
        //  So sticking with version 4.0.1 to see if they clean it up.
        //  Bug is here (which is also closed!) but has workaround syntax: https://github.com/sindresorhus/screenfull.js/issues/126
        if (screenfull.isEnabled) {
            screenfull.on('change', () => {
                this.isFullScreen = screenfull.isFullscreen;
            });
        }

        this.router.events.pipe(takeUntil(this.Destroyed),
            filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                let title: string = this.CurrentServerCode ?? "Exactix";

                const pageTitle = this.routerService.getRouteTitle();
                if (pageTitle)
                    title += " | " + pageTitle;

                this.title.setTitle(title);
            });
    }

    protected OnAppUserChanged(appUser: AppUser): void {
        this.personID = appUser.ID && appUser.ID !== Guid.empty ? appUser.ID : null;
        this.canChangePassword = !appUser.IsExternalLogin && this.personID !== null;
        this.name = appUser.FullName;
        this.IsSupportUser = appUser.IsSupportUser;

        this.ProjectsName = appUser.ProjectsName;
        this.HaveReports = appUser.HaveReports;
    }

    public menuItemClicked() {
        if (this.hideSideNav)
            this.sideNavMenu.close();
    }

    //kind of dumb we need this extra varable, but the mouse out event fires when the mouse moves from one item to another.  So we need this to keep the
    //  menu from disapearing when we don't want it to.
    mouseInMenu = false;
    public toggleMouseOver() {
        this.mouseInMenu = true;
        if (this.showMenuText)
            return;
        else
            this.menuHasMouseOver = true;
    }
    public toggleMouseOut() {
        this.mouseInMenu = false;

        setTimeout(() => {
            if (this.mouseInMenu === false)
                this.menuHasMouseOver = false;
        }, 1000)
    }
    public toggleSideNav() {
        if (this.hideSideNav) {
            this.sideNavMenu.toggle();
            //For some reason this needs to be called since we hide and show the "hamburger" menu icon
            this.changeDetectorRef.detectChanges();
        }
        else {
            this.showMenuText = !this.showMenuText;
            try {
                localStorage.setItem('showMenuText', this.showMenuText.toString());
            } catch { }     //  Ignore error if local storage is full!
        }
    }    

    private SetMobile() {
        if (this.hideSideNav) {
            this.sideNavMode = 'over';
            this.sideNavMenu.close();
        }
        else {
            this.sideNavMode = 'side';
            this.sideNavMenu.open();
        }
    }

    FullScreenToggle($event: boolean) {
        console.log($event);
    }

    SignOut(): void {
        //  Navigate to the /logout route to do a logout.  It's necessary to do this as a navigate in case
        //  the user logs out when on a page that has a route guard (like ticket entry which has a CanDeactivateGuard
        //  to prompt about discarding changes).  Otherwise, we would log the user out before that guard has
        //  a chance to activate!
        this.router.navigate(["/logout"]);
    }

    ChangePassword() {
        this.cognitoAdminService.CurrentUserChangePassword();
    }
}
