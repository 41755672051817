<div style="display:flex; flex-direction:column">
    <mat-form-field>
        <mat-select placeholder="Service Area Code" [formControl]="ServiceAreasFormControl" [compareWith]="CompareServiceAreas" multiple disableOptionCentering panelClass="positionDropDownBelow">
            <mat-select-trigger>
                {{ServiceAreasFormControl.value | commaSeperatedFormat: 'Code'}}
            </mat-select-trigger>
            <mat-option *ngFor="let sa of Data.ServiceAreas" [value]="sa" style="height: 70px">
                <div fxLayout>
                    <div style="display:flex; flex-direction: column; line-height: 15px">
                        <div>{{ sa.Name }}</div>
                        <div class="iq-caption gray-primary-text">{{ sa.Code }}</div>
                        <div class="iq-caption" fxLayout fxLayoutAlign="flex-start center">
                            <ng-container *ngIf="sa.CurrentResponses == null || sa.CurrentResponses[0].ResponseID == null; else currentResponse;">
                                <mat-icon class="red" style="margin-right: 5px; font-weight:600;">error_outline</mat-icon>
                                Response not entered
                            </ng-container>
                            <ng-template #currentResponse>
                                <ng-container *ngIf="sa.CurrentResponses">
                                    <mat-icon class="green" style="margin-right: 5px; font-weight:600;">check</mat-icon>
                                    {{ sa.CurrentResponses[0].ResponseCode }} - {{ sa.CurrentResponses[0].ResponseDescription }}
                                </ng-container>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </mat-option>
        </mat-select>
        <mat-error iqValidationMessage [control]="ServiceAreasFormControl"></mat-error>
    </mat-form-field>
    <mat-form-field>
        <textarea matInput UpperCase placeholder="Comment" type="text" [formControl]="CommentFormControl"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"></textarea>
        <mat-error iqValidationMessage [control]="CommentFormControl"></mat-error>
    </mat-form-field>
</div>
