<form [formGroup]="formGroup" novalidate autocomplete="off" layout="row" class="container" fxLayout="column">
    <!--<mat-form-field>
            <input matInput placeholder="Name" type="text" formControlName="Name" required />
            <mat-error *ngIf="EditForm.get('Name').invalid">{{GetErrorMessage(EditForm.get('Name'))}}</mat-error>
        </mat-form-field>-->
    <mat-error iqValidationMessage [control]="formGroup"></mat-error>

    <iq-section-item *ngIf="attention" #attentionFocus [IsFirstControlInContainer]="IsFirstControlInContainer" [GroupAutoFocusNext]="FocusOnGroupFocusNext" [Edit]="Edit" [EditAllowed]="CanEdit">
        <div class="view">
            <label>
                <span UpperCase [ngClass]="{'empty-values': !attention.value}">ATTN: {{attention.value}}</span>
            </label>
        </div>
        <div class="edit" style="display:flex; flex-direction:column;">
            <mat-form-field>
                <input matInput UpperCase placeholder="ATTN" role="presentation" type="text" autocomplete="off" formControlName="Attention" [iqFocus]="attentionFocus.FocusEvent" [required]="attentionIsRequired" />
                <mat-error iqValidationMessage [control]="attention"></mat-error>
            </mat-form-field>
        </div>
    </iq-section-item>

    <iq-section-item #add1Focus [Edit]="Edit" [EditAllowed]="CanEdit">
        <div class="view">
            <label>
                <span UpperCase [ngClass]="{'empty-values': !address1.value}">{{address1.value}}</span>
            </label>
        </div>
        <div class="edit" style="display:flex; flex-direction:column;">
            <mat-form-field>
                <input matInput UpperCase placeholder="Address1" role="presentation" type="text" autocomplete="off" formControlName="Address1" [iqFocus]="add1Focus.FocusEvent"
                       [required]="address1IsRequired" maxlength="{{FieldLengths.Address_Address1}}" />
                <mat-error iqValidationMessage [control]="address1"></mat-error>
            </mat-form-field>
        </div>
    </iq-section-item>

    <iq-section-item #add2Focus [Edit]="Edit" [EditAllowed]="CanEdit">
        <div class="view">
            <label>
                <span UpperCase [ngClass]="{'empty-values': !address2.value}">{{address2.value}}</span>
            </label>
        </div>
        <div class="edit" style="display:flex; flex-direction:column;">
            <mat-form-field>
                <input matInput UpperCase placeholder="Address2" role="presentation" type="text" autocomplete="off" formControlName="Address2"
                       [iqFocus]="add2Focus.FocusEvent" [required]="address2IsRequired" maxlength="{{FieldLengths.Address_Address2}}" />
                <mat-error iqValidationMessage [control]="address2"></mat-error>
            </mat-form-field>
        </div>
    </iq-section-item>

    <div style="display:flex; flex-wrap: wrap">
        <iq-section-item #cityFocus style="padding-right:10px;" [Edit]="Edit" [EditAllowed]="CanEdit">
            <div class="view" style="flex-grow: 1;">
                <label>
                    <span UpperCase [ngClass]="{'empty-values': !city.value}">{{city.value}},</span>
                </label>
            </div>
            <div class="edit" style="display:flex; flex-direction:column; flex-grow:1;">
                <mat-form-field>
                    <input matInput UpperCase placeholder="City" role="presentation" type="text" autocomplete="off" formControlName="City"
                           [iqFocus]="cityFocus.FocusEvent" [required]="cityIsRequired" maxlength="{{FieldLengths.Address_City}}" />
                    <mat-error iqValidationMessage [control]="city"></mat-error>
                </mat-form-field>
            </div>
        </iq-section-item>

        <iq-section-item #stateFocus style="padding-right:10px;" [Edit]="Edit" [EditAllowed]="CanEdit">
            <div class="view" style="flex-grow: 0;">
                <label>
                    <span UpperCase [ngClass]="{'empty-values': !state.value}">{{state.value}}</span>
                </label>
            </div>
            <div class="edit" style="display:flex; flex-direction:column; flex-grow:0;">
                <mat-form-field>
                    <input matInput UpperCase placeholder="State" role="presentation" type="text" autocomplete="off" formControlName="State"
                           [iqFocus]="stateFocus.FocusEvent" [required]="stateIsRequired" maxlength="{{FieldLengths.Address_State}}" />
                    <mat-error iqValidationMessage [control]="state"></mat-error>
                </mat-form-field>
            </div>
        </iq-section-item>

        <iq-section-item #zipFocus [Edit]="Edit" [EditAllowed]="CanEdit">
            <div class="view" style="flex-grow: 0;">
                <label>
                    <span UpperCase [ngClass]="{'empty-values': !zip.value}">{{zip.value}}</span>
                </label>
            </div>
            <div class="edit" style="display:flex; flex-direction:column; flex-grow:0;">
                <mat-form-field>
                    <input matInput UpperCase placeholder="Zip" role="presentation" type="text" autocomplete="off" formControlName="Zip"
                           [iqFocus]="zipFocus.FocusEvent" [required]="zipIsRequired" maxlength="{{FieldLengths.Address_Zip}}" />
                    <mat-error iqValidationMessage [control]="zip"></mat-error>
                </mat-form-field>
            </div>
        </iq-section-item>
    </div>

    <iq-section-item *ngIf="county && FieldLengths.Address_County" #countyFocus [IsLastControlInContainer]="!reference && IsLastControlInContainer" [GroupAutoFocusPrevious]="!reference && FocusOnGroupFocusPrevious" [Edit]="Edit" [EditAllowed]="CanEdit">
        <div class="view" style="flex-grow: 0;">
            <label>
                <span UpperCase [ngClass]="{'empty-values': !county.value}">{{county.value}}</span>
            </label>
        </div>
        <div class="edit" style="display:flex; flex-direction:column; flex-grow:0;">
            <mat-form-field>
                <input matInput UpperCase placeholder="County" role="presentation" type="text" autocomplete="off" formControlName="County"
                       [iqFocus]="countyFocus.FocusEvent" [required]="countyIsRequired" maxlength="{{FieldLengths.Address_County}}" />
                <mat-error iqValidationMessage [control]="county"></mat-error>
            </mat-form-field>
        </div>
    </iq-section-item>

    <iq-section-item *ngIf="reference" #referenceFocus [IsLastControlInContainer]="IsLastControlInContainer" [GroupAutoFocusPrevious]="FocusOnGroupFocusPrevious" [Edit]="Edit" [EditAllowed]="CanEdit">
        <div class="view" style="flex-grow: 0;">
            <label>
                <span UpperCase [ngClass]="{'empty-values': !reference.value}">{{reference.value}}</span>
            </label>
        </div>
        <div class="edit" style="display:flex; flex-direction:column; flex-grow:0;">
            <mat-form-field>
                <input matInput UpperCase placeholder="Reference" role="presentation" type="text" autocomplete="off" formControlName="Reference"
                       [iqFocus]="referenceFocus.FocusEvent" [required]="referenceIsRequired" />
                <mat-error iqValidationMessage [control]="reference"></mat-error>
            </mat-form-field>
        </div>
    </iq-section-item>
</form>
