import { Component, Input } from '@angular/core';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { TicketListItemViewEnum } from "../Models/TicketListItemViewEnum";

@Component({
    selector: 'ticket-list-item',
    templateUrl: './TicketListItemView.component.html',
    styleUrls: ['./TicketListItemView.component.scss']
})
export class TicketListItemViewComponent {

    @Input()
    public View: TicketListItemViewEnum;

    @Input()
    public Item: any;

    @Input()
    public SearchColumns: SearchColumn[];

    public TicketListItemViewEnum = TicketListItemViewEnum;
}
