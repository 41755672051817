import { AfterViewInit, ContentChild, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subscription } from 'rxjs';
import { EntryFieldComponentBase } from "Shared/EntryFields/Components/EntryFieldComponentBase.component";
import { EntryFieldDateComponent } from "Shared/EntryFields/Components/EntryFieldDate.component";

// ** The issue with not finding the directive when inside some element (like mat-form-field)
// seems to be an issue with Angular not handling content embedded inside ng-content.
// It's a known issue and it looks like it may get fixed some time after the 6.0 release.
// https://github.com/angular/angular/issues/16299

/**
 * Put this directive on a control to allow the SectionItem component to set focus when
 * Focus is set to it in edit mode.
 * ** If the input control is within a mat-form-field tag, put this directive on the mat-form-field
 * and it will focus correctly.  Otherwise, the @ContentChildren does not find it!
 */
@Directive({
    selector: '[iqFocus]'
})
export class FocusDirective implements AfterViewInit, OnDestroy {

    @Input('iqFocus') _focusEvent: Observable<boolean>;

    @ContentChild(MatFormFieldControl) matInput: MatInput;
    @ContentChild(MatSlideToggle) matSlideToggle: MatSlideToggle;
    @ContentChild(MatCheckbox) matCheckbox: MatCheckbox;
    @ContentChild(EntryFieldDateComponent) ticketEntryDate: EntryFieldDateComponent;
    @ContentChild(EntryFieldComponentBase) _TicketEntryComponent: EntryFieldComponentBase;
    @ContentChild(NgSelectComponent) _NgSelect: NgSelectComponent;

    private _Subscription: Subscription;

    constructor(private el: ElementRef) {
    }

    public ngOnDestroy(): void {
        if (this._Subscription) {
            this._Subscription.unsubscribe();
            this._Subscription = null;
        }

        this.el = null;
        this.matInput = null;
        this.matSlideToggle = null;
        this.matCheckbox = null;
        this.ticketEntryDate = null;
        this._TicketEntryComponent = null;
        this._NgSelect = null;
    }

    ngAfterViewInit() {
        if (this._focusEvent)
            this._Subscription = this._focusEvent.subscribe(s => this.FocusEvent(s));
    }

    private FocusEvent(focus: boolean) {
        if (!focus)
            return;
        
        setTimeout(() => {
            if (this.matInput)
                this.matInput.focus();
            else if (this.matSlideToggle)
                this.matSlideToggle.focus();
            else if (this.matCheckbox)
                this.matCheckbox.focus();
            else if (this.ticketEntryDate)
                this.ticketEntryDate.focus();
            else if (this._TicketEntryComponent)
                this._TicketEntryComponent.Focus();
            else if (this._NgSelect)
                this._NgSelect.focus();
            else if (this.el)
                this.el.nativeElement.focus();
        });
    }
}
