<ng-container *ngIf="AllowExcavatorMarkWorkComplete && AllowedActions">
    <ng-container *ngIf="AllowedActions.CanMarkCompleted">
        <!-- Only the excavator can mark the ticket complete -->
        <iq-icon-button *ngIf="(Ticket.IsWorkComplete === true) || (Ticket.IsWorkComplete === 'Yes')" icon="WorkComplete" button-class="Button-Small"
                        title="Mark this ticket as NOT complete" (click)="ToggleWorkCompleted()">Work is Completed (click to toggle)</iq-icon-button>
        <iq-icon-button *ngIf="(Ticket.IsWorkComplete === false) || (Ticket.IsWorkComplete === 'No')" icon="WorkNotComplete" button-class="Button-Small"
                        title="Mark this ticket as Completed" (click)="ToggleWorkCompleted()"><span style="color:red">Work <b>not</b> Completed</span> (click to toggle)</iq-icon-button>
    </ng-container>
    <ng-container *ngIf="!AllowedActions.CanMarkCompleted">
        <div *ngIf="(Ticket.IsWorkComplete === true) || (Ticket.IsWorkComplete === 'Yes')" style="color:green">Excavator has indicated that Work is Complete.</div>
        <!-- not showing if not complete because that is the default so if the excavator never does this (and most will not),
            it's always and forever going to say it's not complete -->
        <!--<div *ngIf="!Ticket.IsWorkComplete || (Ticket.IsWorkComplete === 'No')" style="color:red">Excavator has not Completed work.</div>-->
    </ng-container>
</ng-container>
