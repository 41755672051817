<div style="display:flex; flex-direction:column">
    <div style="display:flex">
        <mat-form-field style="flex-basis:50%">
            <mat-select #serviceAreaSelect placeholder="Service Area" [formControl]="ServiceAreaInfoFormControl" [compareWith]="CompareServiceAreaInfo">
                <mat-select-trigger>
                    {{ServiceAreaInfoFormControl.value ? ServiceAreaInfoFormControl.value.Code : ''}}
                </mat-select-trigger>

                <mat-option *ngFor="let sa of ServiceAreaInfoList" [value]="sa" style="height:auto; padding-top:10px; padding-bottom:10px">
                    <div style="display:flex; flex-direction: column; line-height: 15px">
                        <div>{{ sa.Name }}</div>
                        <div class="iq-caption gray-primary-text">{{ sa.Code }}</div>
                        <div class="iq-caption" fxLayout fxLayoutAlign="flex-start center">
                            <ng-container *ngIf="sa.NeedsResponse; else currentResponse;">
                                <mat-icon class="red" style="margin-right: 5px; font-weight:600;">error_outline</mat-icon>
                                Response Needed
                            </ng-container>
                            <ng-template #currentResponse>
                                <ng-container *ngIf="sa.CurrentResponses">
                                    <mat-icon class="green" style="margin-right: 5px; font-weight:600;">check</mat-icon>
                                    {{ sa.CurrentResponses[0].ResponseCode }} - {{ sa.CurrentResponses[0].ResponseDescription }}
                                </ng-container>
                            </ng-template>
                        </div>
                    </div>
                </mat-option>
            </mat-select>
            <mat-error iqValidationMessage [control]="ServiceAreaInfoFormControl"></mat-error>
            <mat-hint>{{ServiceAreaInfoFormControl.value?.Name}}</mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="UtilityTypesForCurrentServiceArea" style="padding-left:20px; flex-grow:1">
            <mat-select placeholder="Utilty Type" [formControl]="UtilityTypeFormControl">
                <mat-select-trigger>
                    {{UtilityTypeFormControl.value ? UtilityTypeFormControl.value.Name : ''}}
                </mat-select-trigger>

                <mat-option *ngFor="let type of UtilityTypesForCurrentServiceArea" [value]="type">
                    <div style="display:flex; flex-direction: column; line-height: 15px">
                        <div>{{type.Name}}</div>
                        <div class="iq-caption" fxLayout fxLayoutAlign="flex-start center">
                            <ng-container *ngIf="!type.Response; else currentUtilityResponse;">
                                <mat-icon class="red" style="margin-right: 5px; font-weight:600;">error_outline</mat-icon>
                                Response Needed
                            </ng-container>
                            <ng-template #currentUtilityResponse>
                                <mat-icon class="green" style="margin-right: 5px; font-weight:600;">check</mat-icon>
                                {{ type.Response }}
                            </ng-template>
                        </div>
                    </div>
                </mat-option>
            </mat-select>
            <mat-error iqValidationMessage [control]="UtilityTypeFormControl"></mat-error>
        </mat-form-field>
    </div>

    <!-- matAutocompleteOrigin causes the width of the autocomplete to fill the entire div - instead of being limited to the width of the input -->
    <div matAutocompleteOrigin #responseArea="matAutocompleteOrigin" style="display:flex">
        <mat-form-field>
            <input #responseInput iq-select-on-blur="Code" UpperCase type="text" placeholder="Response" matInput [formControl]="ResponseFormControl"
                   [matAutocomplete]="responseAuto" [matAutocompleteConnectedTo]="responseArea" (click)="ShowPanel()">
            <mat-autocomplete autoActiveFirstOption class="iq-autocomplete" #responseAuto="matAutocomplete" [displayWith]="ResponseDisplayFn">
                <mat-option class="allow-wrap" *ngFor="let responseOption of FilteredResponses | async" [value]="responseOption">
                    <div><span class="iq-subheading-2">{{ responseOption.Code }}</span> - {{ responseOption.Description }}</div>
                </mat-option>
            </mat-autocomplete>
            <mat-error iqValidationMessage [control]="ResponseFormControl"></mat-error>
        </mat-form-field>
        <div class="iq-caption gray-primary-text" style="padding-left:20px; padding-top:10px">{{ResponseFormControl.value?.Description}}</div>
    </div>

    <ng-template dynamic-content></ng-template>

    <mat-form-field>
        <input #commentInput iqDisableAutofill matInput UpperCase placeholder="Comment" [formControl]="CommentFormControl" />
        <mat-error iqValidationMessage [control]="CommentFormControl"></mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="IsLocalUser">
        <input iqDisableAutofill matInput UpperCase placeholder="Respondant Person Name" [formControl]="RespondantFormControl" />
        <mat-error iqValidationMessage [control]="RespondantFormControl"></mat-error>
    </mat-form-field>
</div>
