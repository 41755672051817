import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input, QueryList, ViewChildren } from '@angular/core';
import { SectionItemComponent } from '@iqSharedComponentControls/Section/Item/SectionItem.component';
import { CommonService } from 'Services/CommonService';
import { EntryFormControl } from 'Shared/EntryFields/Forms/EntryFormControl';
import { EntrySectionEventServiceBase } from 'Shared/EntryFields/Services/EntrySectionEventBase.service';
import { EntryPageEventServiceBase } from '../Services/EntryPageEventBase.service';
import { EntryFormGroupBase } from "./EntryFormGroupBase";
import { EntryFormGroupContainer } from "./EntryFormGroupContainer";

@Directive()
export abstract class EntrySectionBase<TFormGroup extends EntryFormGroupBase, TPageEventService extends EntryPageEventServiceBase, TSectionEventService extends EntrySectionEventServiceBase<TPageEventService>>
    extends EntryFormGroupContainer<TFormGroup>
{
    // This is the section code to show the help for.
    public SectionCode: string;

    //  Use this (instead of *ngIf) to conditionally show/hide the section.
    //  Reference this in the section group to make it show/hide dynamically.
    //  Using *ngIf will change the ordering of the event handlers and mess up the tab ordering.
    private _Visible: boolean = true;
    @Input()
    set Visible(visible: boolean) { this._Visible = visible; }
    get Visible(): boolean { return this._Visible; }

    //If this is the last control in the section group set this value.  This way we can set the tabs correctly on the last control for section changes. (set 'GroupAutoFocusPrevious' input on the section item)
    private _LastControlInGroup: boolean = false;
    @Input() set IsLastControlInGroup(val: boolean) {
        this._LastControlInGroup = coerceBooleanProperty(val);
    }
    get IsLastControlInGroup() {
        return this._LastControlInGroup;
    }

    public ForceExpanded: boolean = false;

    protected SectionEventService: TSectionEventService;

    constructor(public CommonService: CommonService, sectionEventService: TSectionEventService) {
        super();
        this.SectionEventService = sectionEventService;
    }

    @ViewChildren(SectionItemComponent) private _SectionItems: QueryList<SectionItemComponent>;

    //  Derived class must implement like this:
    //      @ViewChildren(TicketEntrySectionBase) protected ChildEntrySections: QueryList<TicketEntrySectionBase>;
    //  such that @ViewChildren() selects the derived class name.
    //  Which is necessary because the derived classname is what is "provided" in the providers of each section.
    protected abstract ChildEntrySections: QueryList<EntrySectionBase<TFormGroup, TPageEventService, TSectionEventService>>;

    //  *** Do not call this method from inside a section to focus a field in a *DIFFERENT* section.
    //  This method only focus fields within this section.
    //  In order for a section to focus a field anywhere on the form (in any section), need to do it like this:
    //      this.SectionEventService.PageEventService.FocusFirstControlByName.next(["Excavator.AltContactName"]);
    /**
     * Attempts to find the SectionItemComponent that contains the given property.  If found,
     * sets focus to the section and return true.  Returns false if the section was not found.
     * @param propertyName
     */
    public FocusFirstControlByName(propertyNames: string[]): boolean {
        if (!propertyNames || (propertyNames.length === 0) || !this.ChildEntrySections)
            return false;     //  Something is allowing this to be null, undefined, or empty...

        //  Find the first SectionItemComponent where Name is one of the propertyNames (SectionItemComponent has the Name attribute
        //  set to the propertyName).
        const section = this._SectionItems.find(s =>
            propertyNames.some(name =>
                //  Use indexOf here so that Name can be comma separated to specify a section that contains multiple properties.
                //  Map the property name to handle special cases (i.e. map all dig site properties at once?)
                s.Name && (s.Name.indexOf(this.MapPropertyNameToSectionName(name)) >= 0)
            )
        );

        if (section) {
            //  Found a section that handles one of the property names.  Focus to it and return true so we stop looking.

            //  TODO: This will focus to the section.  Which will focus to the first input control in the section.
            //  May need to handle when the section contains multiple inputs and we're trying to focus to one of the others.
            //  After the section is editable, may be able to find it from the formControl name...?
            //  Or can something be done to handle this in the [iqFocus] directive?
            setTimeout(() => section.Focus());
            return true;
        }

        //  Did not find a section directly inside this component.  But, this component could contain child components
        //  that are also derived from EntrySectionBase (such as the Dig site components).  So check in any of those
        //  too.  There is no way to use a QueryList to get them - this is the only way.
        //  ".some" will iterate the collection until true is returned
        return this.ChildEntrySections.some(child => child.FocusFirstControlByName(propertyNames));
    }

    /**
     *  Iterates over the FormControls in the section and sets focus to the first control found that has a
     *  validation error.  Returns true if set focus or false if not.
     * */
    public FocusFirstControlWithError(): boolean {
        //  Find the first SectionItemComponent where Name is one of the propertyNames (SectionItemComponent has the Name attribute
        //  set to the propertyName).
        const section = this._SectionItems.find(s => {
            if (!s.Name)
                return false;

            return s.Name.split(',').some(propertyName => {
                const control = this.EntryFormGroup.get(propertyName) as EntryFormControl;
                if (!control)
                    return false;

                return control.invalid;
            });
        });

        if (section) {
            //  Found a section that contains an invalid control.  Focus to it and return true so we stop looking.

            //  TODO: This will focus to the section.  Which will focus to the first input control in the section.
            //  May need to handle when the section contains multiple inputs and we're trying to focus to one of the others.
            //  After the section is editable, may be able to find it from the formControl name...?
            //  Or can something be done to handle this in the [iqFocus] directive?
            setTimeout(() => section.Focus());
            return true;
        }

        //  Did not find a section directly inside this component.  But, this component could contain child components
        //  that are also derived from EntrySectionBase (such as the Dig site components).  So check in any of those
        //  too.  There is no way to use a QueryList to get them - this is the only way.
        //  ".some" will iterate the collection until true is returned
        return this.ChildEntrySections.some(child => child.FocusFirstControlWithError());
    }

    /**
     * Override this to map a property name to a section name.  i.e. If a section contains multiple properties,
     * name the section with 1 of them and then map all of the others to that name.  That will allow us to focus
     * to that section for any of the properties inside it.
     * @param propertyName
     */
    protected MapPropertyNameToSectionName(propertyName: string): string {
        return propertyName;
    }
}
