import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yesNoFormat',
    
})
export class YesNoFormatPipe implements PipeTransform {

    transform(value: any): any {
        if (value == null) {//Should null be no??  Maybe make it a passed in config
            return value;
        }

        return value === true ? 'Yes' : 'No';
    }

}
