import { Directive, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

/**
 *  Use this directive to dynamically inject a Component into a page.
 *  Put this directive on an ng-template element, use @ViewChild to get the instance from the page,
 *  then call LoadComponent with the component type you want to create.
 */
@Directive({
    selector: '[dynamic-content]'
})
export class DynamicContentDirective {
    constructor(private _ViewContainerRef: ViewContainerRef, private _ComponentFactoryResolver: ComponentFactoryResolver) { }

    /**
     * Load the component into the element that contains this directive.
     * Returns the instance of the newly created component.
     * @param component
     */
    public LoadComponent(component: any) : any {
        this._ViewContainerRef.clear();

        if (component != null) {
            const componentFactory = this._ComponentFactoryResolver.resolveComponentFactory(component);
            return this._ViewContainerRef.createComponent(componentFactory).instance;
        }
        else
            return null;
    }

    public ClearComponent(): void {
        this._ViewContainerRef.clear();
    }
}
