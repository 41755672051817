import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DeviceDetectorService } from 'Services/DeviceDetector.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

    private _Destroyed: Subject<void> = new Subject();

    constructor(private _Renderer: Renderer2, public DeviceDetectorService: DeviceDetectorService)
    {
        this.DeviceDetectorService.Changed
            .pipe(takeUntil(this._Destroyed))
            .subscribe(() => this.DeviceChanged());
        this.DeviceChanged();
    }

    private DeviceChanged(): void {
        if (this.DeviceDetectorService.IsPhone)
            this._Renderer.addClass(document.body, 'iq-device-size-phone');
        else
            this._Renderer.removeClass(document.body, 'iq-device-size-phone');
    }

    public ngOnDestroy(): void {
        this._Destroyed.next();
        this._Destroyed.complete();
    }
}
