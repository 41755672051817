import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export class SideSlideoutRef {

    public onClose: Subject<any> = new Subject();

    constructor(private overlayRef: OverlayRef) { }

    close(data: any = null) {
        this.onClose.next(data);

        this.overlayRef.detach()
    }
}
