import { AfterViewInit, Directive, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FeatureItemResponse } from '@iqModels/Maps/FeatureItemResponse.model';
import { MapToolsEnum } from 'Enums/MapTools.enum';
import { LatLonBounds } from 'Models/Maps/LatLonBounds.model';
import { MapRouteSegment } from "Models/Maps/MapRouteSegment.model";
import { AppUser } from 'Models/Security/AppUser.model';
import * as ol from 'ol';
import ol_control_Bar from 'ol-ext/control/Bar';
import ol_control_Button from 'ol-ext/control/Button';
import ol_control_LayerSwitcher from 'ol-ext/control/LayerSwitcher';
import ol_control_TextButton from 'ol-ext/control/TextButton';
import ol_control_Toggle from 'ol-ext/control/Toggle';
import { defaults as control_defaults, FullScreen, MousePosition, OverviewMap } from 'ol/control';
import { Coordinate, CoordinateFormat, createStringXY, format } from 'ol/coordinate';
import { EventsKey } from 'ol/events';
import { containsCoordinate as extent_containsCoordinate, Extent } from 'ol/extent';
import { defaults as interaction_defaults } from 'ol/interaction';
import { Group, Layer, Tile } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import { unByKey } from 'ol/Observable';
import * as Proj from 'ol/proj';
import { BingMaps, XYZ } from 'ol/source';
import { Observable, of, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommonService } from 'Services/CommonService';
import { MapSearchService } from 'Services/MapSearchService';
import { PositionErrorDialogComponent } from 'Shared/Components/Maps/Dialogs/PositionErrorDialog.component';
import { MapChangesTileLayer } from 'Shared/Components/Maps/Layers/MapChangesTileLayer';
import { MapFeaturesTileLayer } from 'Shared/Components/Maps/Layers/MapFeaturesTileLayer';
import { MapConstants } from 'Shared/Components/Maps/MapConstants';
import { MapToolService } from 'Shared/Components/Maps/MapToolService';
import { MeasureDistanceTool } from 'Shared/Components/Maps/Tools/MeasureDistanceTool';
import { PushPinTool } from 'Shared/Components/Maps/Tools/PushPinTool';
import { SearchMapButtonComponent } from './Controls/Search/SearchMapButton.component';
import { ToggleBaseMapControl } from "./Controls/ToggleBaseMapControl";
import { GeometryTransformer } from './GeometryTransformer';
import { RouteVectorLayer } from "./Layers/RouteVectorLayer";
import ContextMenu from './ol-contextmenu/main';
//import ContextMenu from 'ol-contextmenu';
import { InformationTool } from './Tools/InformationTool';
import { ShowTooltipsTool } from './Tools/ShowTooltipsTool';

@Directive()
export abstract class MapBaseComponent implements OnInit, OnDestroy, AfterViewInit {
    protected TopControlBar: any;           //  type = ol.control.Bar, but there are no typescript mappings currently available for this
    protected LeftControlBar: any;        //  type = ol.control.Bar, but there are no typescript mappings currently available for this
    protected AllowedMapTools: MapToolsEnum[];

    private _GeoServerTileUrl: string;
    protected _MapBounds: LatLonBounds;
    private _AlternateBaseMap: string;
    private _BingApiKey: string;
    private _MapHEREAppID: string;
    private _MapHEREAppCode: string;
    private _MapHEREApiKey: string;
    private _MapHEREScheme: string;
    private _MapHavePendingBaseMapChanges: boolean = false;

    private _MeasureDistanceTool: MeasureDistanceTool;
    private _PushPinTool: PushPinTool;
    private _InformationTool: InformationTool;
    private _ShowTooltipsTool: ShowTooltipsTool;

    //  Fires when this component is destroyed.  The ProjectMapView and DigsiteMap also track their own
    //  private subscriptions that are used to manage subscriptions on objects that can change without the entire
    //  component being destroyed.
    protected MapBaseDestroyed: Subject<void> = new Subject();

    private _Map: ol.Map;
    public get Map(): ol.Map { return this._Map; }

    private _MapToolService: MapToolService;
    public get MapToolService(): MapToolService { return this._MapToolService; }

    public MapSearchIsVisible: boolean = false;

    //  If/when we support other types of layers (like WFS?), may need a more general class for this.  But at the moment,
    //  all overlay layers are served using a MapFeaturesTileLayer.
    private _MapOverlayLayers: MapFeaturesTileLayer[];
    protected get MapOverlayLayers(): MapFeaturesTileLayer[] { return this._MapOverlayLayers; }

    //  Must be overridden if the map should allow external users to see the map changes (i.e. the Registration map allows this but ticket map does not)
    protected get AllowMapChangesLayerForExternalUsers(): boolean { return false; }

    private _MapChangesTileLayer: MapChangesTileLayer = null;
    private _MapChangesTileLayerVisibilityChangedEventsKey: EventsKey = null;
    protected get MapChangesTileLayer(): MapChangesTileLayer { return this._MapChangesTileLayer; }

    private _ShowMapChanges: boolean = false;
    public get ShowMapChanges(): boolean { return this._ShowMapChanges; }
    public set ShowMapChanges(show: boolean) {
        if (show === this._ShowMapChanges)
            return;     //  without this check, unchecking does not refresh the map about the layer being removed!?!?!?!

        if (this._MapChangesTileLayer)
            this._MapChangesTileLayer.Layer.setVisible(show);
        this._ShowMapChanges = show;
    }

    @ViewChild(SearchMapButtonComponent)
    protected MapSearchButton: SearchMapButtonComponent;

    //  Event that fires when the map is repositioned.
    private _OnMapRepositioned: Subject<void> = new Subject();
    private _MapMoveEndEventsKey: EventsKey = null;
    private _ContextMenuBeforeOpenEventsKey: EventsKey = null;
    public get OnMapRepositioned(): Subject<void> { return this._OnMapRepositioned; }

    constructor(protected CommonService: CommonService, protected MapSearchService: MapSearchService) {
        this._MapToolService = new MapToolService(MapSearchService, CommonService.SettingsService, CommonService.Dialog);
        MapSearchService.MapComponent = this;
    }

    public ngOnInit(): void {
        //  Attempt to init the map.  If not visible (i.e. it's in a tab) then this will not fully initialize it.
        //  In that case, the page that is hosting this map must call OnMapVisible() which will then re-initialize.
        this.InitMap();

        //  Needed to close the context menu because it doesn't do this itself!
        document.addEventListener('click', this._OutsideClickListener);

        //  This finds all button elements inside the map and sets their tabindex to -1.  This prevents the tab
        //  key from switching focus in to the map instead of to the next elements on our input forms.
        if (this.Map) {     //  Should always be set right now - but had an error logged where it wasn't!
            const buttonElements = this.Map.getTargetElement().querySelectorAll("button") as NodeList;
            buttonElements.forEach(n => (n as any).setAttribute("tabindex", -1));
        }
    }

    public ngAfterViewInit(): void {
        this.MapSearchButton.SetMapBase(this);
    }

    public ngOnDestroy(): void {
        //  This is all happening so that when the map component is destroyed by Angular, everything is dereferenced so that
        //  it is all garbage collected properly.  There are some issues with ol-ext and possibly with open layers & Angular
        //  that prevent that from happening if we don't do this.
        //  Much of what is being done here is probably not necessary and is here because I was chasing so many different things I
        //  was seeing.  But once I got to the point where the entire openlayers map was being 100% destroyed, I quit while I was ahead.
        //  To find leaks:
        //  1) Use in-cognito mode (to reduce browser extensions)
        //  2) Do stuff in the app to show/hide the map and then return to a normal state
        //  3) Open dev console and clear the console (otherwise, Chrome debugging may hold on to memory references!)
        //  4) On Memory tab, force a garbage collection
        //  5) Capture snapshot
        //  6) Search for "Map" - should not see one that is the openlayers map.  If there is one, it will be near the top of the list.
        //     Can also search for "Layer", "Tool", "Edit" to find various map related components.
        document.removeEventListener('click', this._OutsideClickListener);
        this._OutsideClickListener = null;

        if (this._MapMoveEndEventsKey) {
            unByKey(this._MapMoveEndEventsKey);
            this._MapMoveEndEventsKey = null;
        }
        if (this._ContextMenuBeforeOpenEventsKey) {
            unByKey(this._ContextMenuBeforeOpenEventsKey);
            this._ContextMenuBeforeOpenEventsKey = null;
        }
        if (this._MapChangesTileLayerVisibilityChangedEventsKey) {
            unByKey(this._MapChangesTileLayerVisibilityChangedEventsKey);
            this._MapChangesTileLayerVisibilityChangedEventsKey = null;
        }

        //  Unregister render handlers in the map.  See: https://github.com/openlayers/openlayers/issues/10689
        if (this._Map) {
            this._Map.setTarget(null);

            this._Map.getControls().getArray().slice().forEach(c => this.DestroyControl(c));

            this._Map.getInteractions().forEach(i => {
                if (i)
                    i.setMap(null);
            })

            if (this._MeasureDistanceTool)
                this._MeasureDistanceTool = this._MeasureDistanceTool.OnDestroy();

            if (this._PushPinTool)
                this._PushPinTool = this._PushPinTool.OnDestroy();

            if (this._InformationTool)
                this._InformationTool = this._InformationTool.OnDestroy();

            if (this._ShowTooltipsTool)
                this._ShowTooltipsTool = this._ShowTooltipsTool.OnDestroy();

            if (this._MapOverlayLayers) {
                this._MapOverlayLayers.forEach(l => l.OnDestroy());
                this._MapOverlayLayers = [];
            }

            this._ContextMenu.setMap(null);
            this._ContextMenu.clear();
            this._ContextMenu = null;

            this.TopControlBar = null;
            this.LeftControlBar = null;

            this._Map = null;
        }

        this._MapToolService = null;
        this.MapSearchService.MapComponent = null;
        this.MapSearchService = null;

        this.MapSearchButton = null;

        this.MapBaseDestroyed.next();
        this.MapBaseDestroyed.complete();
    }

    private DestroyControl(control: any): void {
        if (!control)
            return;

        if (control instanceof ol_control_Bar) {
            const bar = control as ol_control_Bar;
            bar.getControls().slice().forEach(a => this.DestroyControl(a));
        }

        if (control instanceof ol_control_Toggle) {
            const toggle = control as ol_control_Toggle;

            toggle.setInteraction(null);

            const subbar: ol_control_Bar = toggle.getSubBar();
            if (subbar)
                subbar.getControls().slice().forEach(a => this.DestroyControl(a));
        }

        control.setMap(null);

        this._Map.removeControl(control);
    }

    /**
     * Call this method when the map becomes visible.  Only necessary if the map is not initially visible
     * (i.e. it's sitting in a tab).  
     */
    public OnMapVisible(): void {
        if (!this.Map || !this.Map.getTargetElement())
            this.InitMap();
        else {
            this.Map.updateSize();
            if (this.IsMapVisible())
                this.InitVisibleMap();
        }

        setTimeout(() => this.ZoomToBestFit());
    }

    private IsMapVisible(): boolean {
        if (!this.Map)
            return false;

        //  If offsetHeight is 0, it's not visible
        const targetElem = this.Map.getTargetElement() as any;
        if (!targetElem || !targetElem.offsetHeight)
            return false;

        return true;
    }

    //Needs to be true because we need it in the OnInit
    @ViewChild('MapHost', { static: true }) _MapHost;

    private InitMap(): void {
        //  Make sure the current user info is fetched before doing this.  We need the current One Call, some
        //  settings, and permissions to be able to configure the map correctly.  If we are refreshing a page
        //  that contains the map, we need to make sure those settings are loaded!
        this.CommonService.AuthenticationService.CurrentUserObserver()
            .pipe(take(1))
            .subscribe(user => {
                this.InitMapForUser(user);
        });
    }

    private InitMapForUser(user: AppUser): void {
        //  Uses png8 image format because it generates images that are half the size of png with no difference in quality.
        //  png is only useful for satellite images.
        this._GeoServerTileUrl = this.CommonService.SettingsService.GeoServerBaseUrl + '/gwc/service/tms/1.0.0/map:' + user.CurrentOneCallCenterCode + '@EPSG%3A900913@png8/{z}/{x}/{-y}.png8?mapUpdate=' + (user.OneCallCenterSettings.MapUpdateIdentifier || "1");

        this._MapBounds = user.MapBounds;

        this._AlternateBaseMap = user.OneCallCenterSettings.MapAlternateBaseMap;
        this._BingApiKey = user.OneCallCenterSettings.MapBingApiKey;
        this._MapHEREAppID = user.OneCallCenterSettings.MapHEREAppID;
        this._MapHEREAppCode = user.OneCallCenterSettings.MapHEREAppCode;
        this._MapHEREApiKey = user.OneCallCenterSettings.MapHEREApiKey;
        this._MapHEREScheme = user.OneCallCenterSettings.MapHEREScheme;
        this._MapHavePendingBaseMapChanges = (user.OneCallCenterSettings.MCD_HavePendingBaseMapChanges === "true");

        //  TODO: This should not be here.  The allowed map tools could be configured differently for different roles - especially
        //  between a ticket creation role and a service area role (where one applies to ticket map tools and the other to
        //  registration map tools).  Need to move this in to the TicketConfiguration & RegistrationMapParams.
        this.AllowedMapTools = user.AllowedMapTools;

        this._Map = new ol.Map({
            //  Need to use _MapHost.nativeElement so that we find the element that is actually inside
            //  *THIS* map.  If we just specify it as a string, it will find the first element on the entire
            //  page that matches that "id".  Which prevents us from having more than 1 map at at time.
            //  So we can't show a map in a dialog or it will be loaded into the map on the main page!
            //target: 'MapHost',                    //  Matches on element with: id="MapHost"
            target: this._MapHost.nativeElement,    //  Matches on element with #MapHost

            layers: this.BuildMapLayers(),

            interactions: this.BuildInteractions(),

            controls: this.BuildMapControls(),

            //  default projection is EPSG: 3857 which is equivalent to EPSG: 900913
            //  See trivia comment in question: https://gis.stackexchange.com/questions/34276/whats-the-difference-between-epsg4326-and-epsg900913?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa -->
            view: new ol.View({
                //  Note: Map will not load until center & zoom or extent are set!  (ZoomToBestFit() will do this)
                minZoom: 6,
                maxZoom: 20,
                constrainResolution: true,      // constraint the zoom levels to only integer's.  Fixes issues with the tile images being scaled and looking like crap.
                enableRotation: false
            })
        });

        this.AddMapOverlayLayers(user);

        //  Local users always allowed to see the pending map changes.  External users only allowed if
        //  is AllowMapChangesLayerForExternalUsers (i.e. the Registration Map allows this).
        if (this.AllowMapChangesLayerForExternalUsers || user.IsLocalUser)
            this.AddMapChangesLayer();

        this.ConfigureLayerSwitcher();

        if (this.IsMapVisible())
            this.InitVisibleMap();
        else {
            //  If not visible now, it's probably about to be made visible in the message change detection pass.
            setTimeout(() => this.OnMapVisible());
        }
    }

    protected MapIsInitialized: boolean = false;

    private InitVisibleMap(): void {
        if (this.MapIsInitialized || !this.Map)
            return;

        this.MapIsInitialized = true;

        //  The top and bottom control bars - can add additional bars to these as needed
        //  "iq-top" needed on this control bar for it to be repositioned when the map search is open - see ".map-search-visible" in Map.scss
        this.TopControlBar = new ol_control_Bar({ toggleOne: true, group: false, className: "iq-control-bar-container iq-top" }); //  iq-centered-control-bar-container
        this.TopControlBar.setPosition("top");
        this.Map.addControl(this.TopControlBar);
        this.LeftControlBar = new ol_control_Bar({ toggleOne: true, group: false/*, className: "iq-control-bar-container"*/ });
        this.LeftControlBar.setPosition("top-left");
        this.LeftControlBar.setVisible(false);      //  initially set to not be visible - otherwise, when it's empty, it shows the background color of the bar for the amount of padding in the bar!
        this.Map.addControl(this.LeftControlBar);

        //  Do any custom map configuration
        const customLayersLoadingAsync = this.OnMapInitialized(this.Map);

        this.BuildControlBars();

        this._MapMoveEndEventsKey = this.Map.on('moveend', () => {
            this._OnMapRepositioned.next();

            //  For debugging the current zoom level:
            //  console.warn("CurrentZoom=", this.Map.getView().getZoom(), this.Map.getView().getResolution());
        });

        //  If we have custom layers being loaded async, delay setting the initial map position so we don't
        //  flash to the full extents and then zoom to the custom layer.
        if (!customLayersLoadingAsync)
            this.ZoomToBestFit();
        else {
            setTimeout(() => {
                if (this.Map)
                    this.Map.updateSize();
            });            //  Needed (in timeout) or map size may be wrong which affects drawing hit tests!  (try drawing circle that spans entire map - mouse won't snap to entire circle if this is an issue!)
        }
    }

    private BuildMapLayers(): BaseLayer[] {
        const layers = [
            //  Visibility of base map layers are toggled by ToggleBaseMapControl or the LayerSwitcher
            this.CreateBaseMapLayer()
        ];

        if (this._AlternateBaseMap === "BingSatellite") {
            //  Bing Maps example (also shows other imagerySets): https://openlayers.org/en/latest/examples/bing-maps.html
            const bingSatelliteLayer = new Tile({
                visible: false,
                //preload: Infinity,
                source: new BingMaps({
                    key: this._BingApiKey,
                    //  Values for imagerySet: Aerial, AerialWithLabels, Road, RoadOnDemand
                    imagerySet: 'AerialWithLabels'
                })
            });
            bingSatelliteLayer.set("name", MapConstants.LAYERNAME_SATELLITE);
            bingSatelliteLayer.set("title", "Satellite");
            bingSatelliteLayer.set("baseLayer", true);                  //  For LayerSwitcher
            bingSatelliteLayer.set("displayInLayerSwitcher", true);     //  For LayerSwitcher
            layers.push(bingSatelliteLayer);
        } else if (this._AlternateBaseMap === "HERESatellite") {
            //  HERE satellite maps
            //  Developer account page: https://developer.here.com/projects/PROD-8110aacd-f5d7-477c-b40e-222bae229ddd
            //  URL/request info: https://developer.here.com/documentation/raster-tile-api/api-reference.html
            //  Old links: https://developer.here.com/documentation/map-tile/topics/request-constructing.html#request-constructing__table-basic-request-elements
            //             https://developer.here.com/documentation/map-tile/topics/request-constructing.html#request-constructing__table-basic-request-elements

            //  HERE is replacing AppID/AppCode with just ApiKey.  We support both but prefer ApiKey if that is configured.
            //  Formats = png, png8, jpg.  That's in order of decreasing size.  Visually, image quality of jpg is same as png and it's ~20kb/tile for jpg vs 130+kb for png.
            //      * jpg is only recommended for Satellite and Hybrid though.
            let hereURL: string;
            if (this._MapHEREApiKey) {
                //  URL Info: https://developer.here.com/documentation/map-tile/dev_guide/topics/request-constructing.html#request-constructing__base-url
                //hereURL = 'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/jpg?apiKey=' + this._MapHEREApiKey;

                //  use 'hybrid.day' for satellite view using v2 api with street & place labels on top
                //  use 'explore.satellite.day' for hybrid view using v3 api - has larger/easier to read labels
                let scheme = this._MapHEREScheme ? this._MapHEREScheme : 'satellite.day';     

                if (scheme === "explore.satellite.day") {
                    //  This uses the v3 api.  But it does not support the hybrid satellite tiles!
                    //  * Can also return http 429 (too many requests)!  But using size=512 reduces the number of requests so did not see an issue after that.
                    //  HERE info: https://maps.hereapi.com/v3/info?apiKey=u1JpazQ4NoVarkzAbl_7TBlULo4ZRV7f68cn3flmEWA
                    //  As of 5/9/2023:
                    //      imageFormats: jpeg, png, png8
                    //      imageSizes: 256, 512
                    //      projections: mc
                    //      resources: background, base, blank, label
                    //      styles: explore.day, explore.night, explore.satellite.day, satellite.day
                    //      zoomLevels: max=20, min=0
                    hereURL = "https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?apiKey=" + this._MapHEREApiKey;

                    hereURL += "&style=" + scheme;
                    hereURL += "&size=512";

                    //  400=high res - this shows larger/easier to read labels.  May want to make this an option in the future.  But at the moment, this is really
                    //  the only difference between the v2 and v3 apis.
                    //  * Should probably just configure the entire URL (including apiKey)!
                    hereURL += "&ppi=400";

                    if (scheme.startsWith("explore.")) {
                        //  When using one of the "explore" styles, we can specify "features" to include with the images.
                        //  Available features: https://maps.hereapi.com/v3/features?apiKey=u1JpazQ4NoVarkzAbl_7TBlULo4ZRV7f68cn3flmEWA
                        //  The default for each feature is the first mode in the list.  Only pois are enabled by default so turn them off.
                        //  The other options are not needed.
                        hereURL += "&features=pois:disabled";
                    }
                } else
                    hereURL = 'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/' + scheme + '/{z}/{x}/{y}/512/jpg?apiKey=' + this._MapHEREApiKey;
            }
            else {
                //  'https://{1-4}.{base}.maps.cit.api.here.com/{type}/2.1/maptile/newest/{scheme}/{z}/{x}/{y}/256/png?app_id={app_id}&app_code={app_code}'
                //let hereURL = 'https://1.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png?app_id=' + hereAppID + '&app_code=' + hereAppCode;
                hereURL = 'https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/512/jpg?app_id=' + this._MapHEREAppID + '&app_code=' + this._MapHEREAppCode;
            }

            const hereSatelliteLayer = new Tile({
                visible: false,
                source: new XYZ({
                    url: hereURL,
                    attributions: 'Map Tiles &copy; ' + new Date().getFullYear() + ' <a target="_blank" href="http://here.com">HERE</a>',
                    tileSize: 512,      //  All urls also specify 512 - this reduces number of requests for typical map from 9 requests down to 4
                })
            });
            hereSatelliteLayer.set("name", MapConstants.LAYERNAME_SATELLITE);
            hereSatelliteLayer.set("title", "Satellite");
            hereSatelliteLayer.set("baseLayer", true);                  //  For LayerSwitcher
            hereSatelliteLayer.set("displayInLayerSwitcher", true);     //  For LayerSwitcher
            layers.push(hereSatelliteLayer);


            //  Satellite images from ESRI.  For free!  But 3-5 years old and resolution may not be as good as HERE
            //  https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer
            //var worldImageryLayer = new Tile({
            //    visible: false,
            //    source: new XYZ({
            //        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            //        maxZoom: 19
            //    })
            //});
            //worldImageryLayer.set("name", MapConstants.LAYERNAME_SATELLITE);
            //worldImageryLayer.set("title", "Satellite");
            //worldImageryLayer.set("baseLayer", true);                  //  For LayerSwitcher
            //worldImageryLayer.set("displayInLayerSwitcher", true);     //  For LayerSwitcher
            //layers.push(worldImageryLayer);

            //  Other possible options from: https://www.trailnotes.org/FetchMap/TileServeSource.html
            //  Satellite images from the USGS: https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{Z}/{Y}/{X}
            //      Works but does not support low zoom levels - returns 404's'
        }

        //  Can show Google Maps like this.  But it's using their direct tile service and not using their own javascript
        //  map control.  So it is a violation of their usage agreement - so cannot be used in production - test only!
        //new ol.layer.Tile({
        //    name: MapConstants.LAYERNAME_BING,
        //    visible: false,
        //    preload: Infinity,
        //    source: new ol.source.XYZ({
        //        url: 'http://mt1.google.com/vt/lyrs=m@113&hl=en&&x={x}&y={y}&z={z}'     //  Google Roads
        //        //url: 'http://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}'         //  Google Satellite (no roads)
        //        //url: 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'                //  Google Satellite and roads
        //    })
        //})
        //  Information on HERE Map Tiles: https://developer.here.com/documentation/map-tile/topics/introduction.html
        //      Pricing: https://developer.here.com/plans

        //  These are put in a group for the LayerSwitcher
        const group = new Group({ layers: layers });

        //  Needed for LayerSwitcher
        group.set("title", "Base Map");
        group.set("baseLayer", true);
        group.set("openInLayerSwitcher", true);                 //  Causes group to be expanded in the LayerSwitcher
        group.set("displayInLayerSwitcher", true);
        return [group];
    }

    private CreateBaseMapLayer(): Layer<any> {
        const baseMapLayer = new Tile({
            visible: true,
            source: new XYZ({
                url: this._GeoServerTileUrl
            })
        });
        baseMapLayer.set("name", MapConstants.LAYERNAME_ONECALL_BASE);
        baseMapLayer.set("title", "Street Map");
        baseMapLayer.set("baseLayer", true);                    //  For LayerSwitcher
        baseMapLayer.set("displayInLayerSwitcher", true);       //  For LayerSwitcher

        return baseMapLayer;
    }

    //  TODO: May want to also configure base map layers in our MapLayers to make them more configurable.
    //  Then change this to AddMapLayers and add support for creating the Base Map and Satellite layers here.
    private AddMapOverlayLayers(user: AppUser): void {
        if (!user.MapLayers || (user.MapLayers.length === 0))
            return;

        this._MapOverlayLayers = [];
        user.MapLayers
            .filter(mapLayer => (mapLayer.IsInitiallyVisible || mapLayer.CanToggleVisibility) && mapLayer.MapFeatureTypes && (mapLayer.MapFeatureTypes.length > 0))
            .forEach(mapLayer => {
                //  If/when we support other types of layers (like WFS?), may need a more general class for this.  But at the moment,
                //  all overlay layers are served using a MapFeaturesTileLayer.
                this._MapOverlayLayers.push(new MapFeaturesTileLayer(this._Map, this.CommonService.SettingsService.ApiBaseUrl, user.CurrentOneCallCenterCode, mapLayer));
            });
    }

    private AddMapChangesLayer(): void {
        if (!this._MapHavePendingBaseMapChanges)
            return;

        const mapChangesTileURL = this.CommonService.SettingsService.ApiBaseUrl + "/Maps/Tiles/MapChanges/{z}/{x}/{y}";

        this._MapChangesTileLayer = new MapChangesTileLayer(this._Map, mapChangesTileURL, this.CommonService.AuthenticationService);

        //  This keeps the "Show Pending Map Changes" button on the registration page in-sync with the layer switcher
        this._MapChangesTileLayerVisibilityChangedEventsKey = this._MapChangesTileLayer.Layer.on('change:visible', (event) => {
            this._ShowMapChanges = !event.oldValue;
        });
    }

    private BuildInteractions(): any {
        //  interaction.defaults: https://openlayers.org/en/latest/apidoc/module-ol_interaction.html
        return interaction_defaults();
    }

    private _ContextMenu: ContextMenu;
    private _OutsideClickListener = () => {
        if (this._ContextMenu)
            this._ContextMenu.closeMenu();
    }

    private BuildMapControls(): any {
        //  If the One Call shows coords as lat then lon, need to reverse the order of the mouse position coordinates to match
        let coordFormat: CoordinateFormat;
        if (this.CommonService.SettingsService.LatLonCoordinateEnteredLatFirst) {
            //  Same as the built-in createStringXY but reversed: https://github.com/openlayers/openlayers/blob/4fe091c02df92668ac5ee53d371393b95258d907/src/ol/coordinate.js#L143
            coordFormat = function (coordinate) {
                return format(coordinate, '{y}, {x}', 4);
            }
        } else
            coordFormat = createStringXY(4);

        const mousePositionControl = new MousePosition({
            coordinateFormat: coordFormat,
            projection: MapConstants.LATLON_PROJECTION
            //  Can change the default target and css class name like this (to position it somewhere else - like outside of the map)
            //className: 'custom-mouse-position',
            //target: document.getElementById('mouse-position'),
        });

        this._ContextMenu = new ContextMenu({
            //  ContextMenu does not support "auto" (it displays but internal calculations are then all f'd up).
            //  May need to get this from protected property if 200 doesn't work everywhere.
            width: 200,
        });

        //  ignore needed because of this issue: https://github.com/jonataswalker/ol-contextmenu/issues/233
        //  @ts-ignore
        this._ContextMenuBeforeOpenEventsKey = this._ContextMenu.on('beforeopen', () => this.OnContextMenuBeforeOpen());

        return control_defaults({ attributionOptions: { collapsible: false } })
            .extend([
                mousePositionControl,       //  position is set via ".ol-mouse-position" css class in App.scss
                new OverviewMap({
                    layers: [ this.CreateBaseMapLayer() ]
                }),
                new FullScreen(),
                this._ContextMenu
            ]);
    }

    private ConfigureLayerSwitcher(): void {
        //  This counts a Group of map layers as 1 which is fine atm.
        const numLayersInSwitcher = this._Map.getLayers().getArray().filter(l => l.get('displayInLayerSwitcher') === true).length;
        if (numLayersInSwitcher <= 1) {
            //  Only have the base map layer group so just use the Toggle.
            //  TODO: If we support more than 2 base map layers, we'll want to check for that too and use the LayerSwitcher.
            this._Map.addControl(new ToggleBaseMapControl());
            return;
        }

        //  There are more configurations in Map.scss.  Quite a few things that should be options can only be hidden via css...
        //  Code: https://github.com/Viglino/ol-ext/blob/master/src/control/LayerSwitcher.js

        const layerSwitcher = new ol_control_LayerSwitcher({
            mouseover: true,        //  Opens layer switched on mouse hover

            //  Allow user to drag layers around to change order.  May be useful, but then it allows moving layers to be below the base maps.
            //  Have that hidden via css for basemap layers, but no way to prevent another layer from being dragged below.
            reordering: false,
            displayInLayerSwitcher: function (layer) {
                //  We override this default such that layers are opt-in where the default is opt-out
                //  https://github.com/Viglino/ol-ext/blob/master/src/control/LayerSwitcher.js
                return (layer.get('displayInLayerSwitcher') === true);
            }
        });

        layerSwitcher.setHeader("<div class='primary-color' style='font-size:large; text-decoration:underline; padding-bottom:10px'>Layers:<div>");

        //  ascii codes and symbols w / html representation: http://www.theasciicode.com.ar/extended-ascii-code/congruence-relation-symbol-ascii-code-240.html -->
        const labelNode = document.createElement("div");
        labelNode.innerHTML = "&equiv;";
        layerSwitcher.button.appendChild(labelNode);
        layerSwitcher.button.className = "ol-control";

        this._Map.addControl(layerSwitcher);
    }

    protected BuildControlBars(): void {

        //  Font-awesome icons: https://fontawesome.com/icons?d=gallery
        //  Material icons: https://material.io/tools/icons/?style=baseline
        //      Material style info: http://google.github.io/material-design-icons/#icon-font-for-the-web
        //      Reference like this: <i class="material-icons">timeline</i>
        //          * Our project has a font-size of 14px which may be too small - can add new style tags to resize the icons.

        this._MeasureDistanceTool = new MeasureDistanceTool(this.Map, this.MapToolService);
        this._PushPinTool = new PushPinTool(this.Map, this.MapToolService);
        this._InformationTool = new InformationTool(this.Map, this.MapToolService);
        this._ShowTooltipsTool = new ShowTooltipsTool(this.Map, this.MapToolService);

        //  Show Tooltips Tool
        const showTooltipsToggle = new ol_control_Toggle({
            html: '<i class="far fa-comment-alt" ></i>',              //  Can reference Material icon like this: <i class="material-icons">timeline</i>
            title: 'Show Map Features at the current cursor position',
            interaction: this._ShowTooltipsTool.Interaction,
            active: localStorage.getItem("hideMapTooltips") === null,
            onToggle: function (on) {
                try {
                    if (on)
                        localStorage.removeItem("hideMapTooltips");
                    else
                        localStorage.setItem("hideMapTooltips", "1");
                } catch {
                    //  Ignore error if local storage is full!
                }
            }
        });
        this.TopControlBar.addControl(showTooltipsToggle);

        //  Information Tool
        const informationToggle = new ol_control_Toggle({
            html: '<i class="fas fa-info" ></i>',              //  Can reference Material icon like this: <i class="material-icons">timeline</i>
            title: 'Show Map Information for Location',
            interaction: this._InformationTool.Interaction,
            active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
            bar: new ol_control_Bar({
                controls: [
                    new ol_control_TextButton({
                        html: 'clear',
                        //title: "Clear",
                        handleClick: () => this._InformationTool.Clear()
                    }),
                    new ol_control_TextButton({
                        html: 'done',
                        //title: "Done",
                        handleClick: () => this._InformationTool.Interaction.setActive(false)
                    })
                ]
            })
        });
        this.TopControlBar.addControl(informationToggle);

        //  Measure Distance Tool
        //  ol.control.Toggle: https://github.com/Viglino/ol-ext/blob/master/src/control/Toggle.js
        const measureDistanceToggle = new ol_control_Toggle({
            html: '<i class="fas fa-ruler" ></i>',              //  Can reference Material icon like this: <i class="material-icons">timeline</i>
            title: 'Measure Distance',
            interaction: this._MeasureDistanceTool.Interaction,
            active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
            bar: new ol_control_Bar({
                controls: [
                    new ol_control_TextButton({
                        html: 'clear',
                        title: "Clear all measurements",
                        handleClick: () => this._MeasureDistanceTool.Clear()
                    }),
                    new ol_control_TextButton({
                        html: 'done',
                        title: "Stop measuring",
                        handleClick: () => this._MeasureDistanceTool.Interaction.setActive(false)
                    })
                ]
            })
        });
        this.TopControlBar.addControl(measureDistanceToggle);

        //  Push Pin Tool
        const pushPinToggle = new ol_control_Toggle({
            html: '<i class="fas fa-map-marker-alt" ></i>',              //  Can reference Material icon like this: <i class="material-icons">timeline</i>
            title: 'Add Push Pins',
            interaction: this._PushPinTool.Interaction,
            active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
            bar: new ol_control_Bar({
                controls: [
                    new ol_control_TextButton({
                        html: 'clear',
                        title: "Clear all Push Pins",
                        handleClick: () => this._PushPinTool.Clear()
                    }),
                    new ol_control_TextButton({
                        html: 'done',
                        title: "Stop adding Push Pins",
                        handleClick: () => this._PushPinTool.Interaction.setActive(false)
                    })
                ]
            })
        });
        this.TopControlBar.addControl(pushPinToggle);

        if (this.AllowedMapTools.indexOf(MapToolsEnum.CurrentPosition) >= 0) {
            const currentLocationButton = new ol_control_Button({
                html: '<i class="fas fa-location-arrow"></i>',
                title: 'Position to your current location',
                handleClick: () => this.PositionToCurrentLocation()
            });
            this.TopControlBar.addControl(currentLocationButton);
        }
    }

    public PositionToCurrentLocation(): void {
        //  Can check permission using "navigator.permissions" but it's not supported on Safari.  And not sure what the possible values
        //  are that are returned in the "state" property.  When user denied it, has value of "denied".
        //  https://caniuse.com/permissions-api
        //  https://stackoverflow.com/questions/6092400/is-there-a-way-to-check-if-geolocation-has-been-declined-with-javascript
        //navigator.permissions.query({ name: 'geolocation' }).then(result => console.log("geoLocation permissoins:", result));

        const options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 };

        navigator.geolocation.getCurrentPosition((pos) => {
            if (pos && this._MapBounds) {
                if ((pos.coords.longitude < this._MapBounds.MinX) || (pos.coords.longitude > this._MapBounds.MaxX)
                    || (pos.coords.latitude < this._MapBounds.MinY) || (pos.coords.latitude > this._MapBounds.MaxY)) {
                    this.CommonService.ToastrService.warning("Your current location is not within the bounds of the One Call Center");
                    this.HandleCurrentPositionNotInMapBounds();
                }

                const coord = Proj.transform([pos.coords.longitude, pos.coords.latitude], MapConstants.LATLON_PROJECTION, MapConstants.MAP_PROJECTION);
                this.ZoomToCoordinate(coord, 17);
            }
        }, (error) => { this.HandleCurrentPositionError(error) }, options);
    }

    /**
     * error is set if we failed to get the current position.  If error is null, the current position is not within the map bounds.
     * @param error
     */
    protected HandleCurrentPositionError(error: GeolocationPositionError): void {
        this.CommonService.Dialog.open(PositionErrorDialogComponent, { data: error });
    }

    protected HandleCurrentPositionNotInMapBounds(): void {
        this.ZoomToFullMapExtents();
    }

    /**
     * Override this to do any custom map configuration (i.e. add layers or controls).
     * Return true if custom layers are being loaded async and we should delay setting the initial map position.
     * @param map
     */
    protected abstract OnMapInitialized(map: ol.Map): boolean;

    /**
     * Override this to change how to zoom to the best fit for the current map.  i.e. to zoom to the extents
     * of another layer.
     * The default implementation will zoom to the initial x/y/zoom based on the system configuration.
     */
    public ZoomToBestFit(): void {
        if (!this.Map)
            return;

        const extents = this.GetBestFitExtents();
        if (extents)
            this.ZoomToExtent(extents);
        else
            this.ZoomToFullMapExtents();
    }

    /**
     * Zoom to the full map extents (as configured in OneCallSettings).
     * */
    public ZoomToFullMapExtents(): void {
        setTimeout(() => {
            if (this.Map) {
                this.Map.updateSize();            //  Needed (in timeout) or map size may be wrong which affects drawing hit tests!  (try drawing circle that spans entire map - mouse won't snap to entire circle if this is an issue!)

                //  1/29/2022: Not using initial/x/y/zoom any more - now using the MapBounds which come from the envelope of the State boundary(s).
                //  true value here makes it fit better for the state-level view (may extend off the view but leaves much less whitespace).
                this.ZoomToLatLonBounds(this._MapBounds, 0, true);
            }
        });
    }

    public ZoomToCoordinate(coord: Coordinate, zoom: number): void {
        if (!this.Map)
            return;     //  Map not initialized yet

        //  calculateExtent throws an exception if we have not positioned the map at all yet!
        try {
            const bounds = this.Map.getView().calculateExtent();
            if (extent_containsCoordinate(bounds, coord)) {
                this.Map.getView().animate({ center: coord, zoom: zoom });
                return;
            }
        } catch { /* ignore */ }

        this.Map.getView().setCenter(coord);
        this.Map.getView().setZoom(zoom);
    }

    public ZoomToLatLonBounds(bounds: LatLonBounds, padding: number = 0, nearest: boolean = false, maxZoom?: number): void {
        if (bounds) {
            const latLonExtents = [bounds.MinX, bounds.MinY, bounds.MaxX, bounds.MaxY] as Extent;
            const mapExtents = GeometryTransformer.TransformExtent(latLonExtents, MapConstants.LATLON_PROJECTION, MapConstants.MAP_PROJECTION);
            if (mapExtents) {
                this.ZoomToExtent(mapExtents, padding, nearest, maxZoom);
                return;
            }
        }

        this.ZoomToFullMapExtents();
    }

    //  nearest is the OpenLayers name for the option.
    //  From their docs: If the view constrainResolution option is true, get the nearest extent instead of the closest that actually fits the view.
    //  Translation: When false, fit the entire extents in to the view snapped to the next zoom level that fits the extent.
    //               When true, fits the extents such that the smallest dimension exactly fits and the largest may extend out of view.
    public ZoomToExtent(extents: Extent, padding: number = 80, nearest: boolean = false, maxZoom?: number): void {
        if (!extents)
            return;

        //  Timeout needed here in some cases - when we set the dig site when the map is not visible and then
        //  make the map visible.  With it, the map zooms in REALLY close on the dig site for some reason...
        setTimeout(() => {
            if (this.Map) {
                this.Map.updateSize();            //  Needed (in timeout) or map size may be wrong which affects drawing hit tests!  (try drawing circle that spans entire map - mouse won't snap to entire circle if this is an issue!)
                this.Map.getView().fit(extents, { padding: [padding, padding, padding, padding], nearest: nearest, maxZoom: maxZoom });
            }
        });
    }

    /**
     *  Returns the current view extents in Lat/Lon coordinates.
     */
    public CurrentViewExtents(): Extent {
        const extents = this.Map.getView().calculateExtent(this.Map.getSize());
        return GeometryTransformer.TransformExtent(extents, MapConstants.MAP_PROJECTION, MapConstants.LATLON_PROJECTION);
    }

    protected abstract GetBestFitExtents(): Extent;

    protected IsDrawingToolActive(): boolean {
        //  If we are currently drawing, disable the context menu so that it doesn't actually open
        //  Should not be possible for these tools to not be created yet, but saw some weird errors that looked like they were!
        if (this._MeasureDistanceTool && this._MeasureDistanceTool.IsActive())
            return true;
        if (this._PushPinTool && this._PushPinTool.IsActive())
            return true;
        if (this._InformationTool && this._InformationTool.IsActive())
            return true;
        return false;
    }

    private OnContextMenuBeforeOpen(): void {
        //  If we are currently drawing, disable the context menu so that it doesn't actually open
        if (this.IsDrawingToolActive()) {
            //  Disabling the context menu does not stop the event propogation.  So it results in the
            //  browsers default menu showing!  So best we can do is show an empty menu and then close it right away.
            //this._ContextMenu.disable();
            this._ContextMenu.clear();
            setTimeout(() => {
                if (this._ContextMenu)
                    this._ContextMenu.closeMenu();
            });
            return;
        }

        //this._ContextMenu.enable();
        this.RebuildContextMenu();
    }

    protected RebuildContextMenu(): void {
        //  Note: Can't track if the menu is dirty and needs to be rebuilt because we now have multiple
        //  "tools" that can cause this to happen.  Building this is fast so not worth the effort
        //  to try to track changes in all of those places.

        this._ContextMenu.clear();

        if (!this.MapSearchButton.IsEmpty()) {
            this._ContextMenu.extend([
                {
                    text: '<i class="fas fa-crosshairs"></i>Clear Map Search',
                    classname: 'iq-image-item',
                    callback: () => {
                        this.MapSearchButton.ClearSearchLayer();
                    }
                }
            ]);
        }

        const customItems = this.BuildContextMenuItems();

        if (customItems && (customItems.length > 0)) {
            this._ContextMenu.extend(customItems);
            this._ContextMenu.extend(['-']);      //  Adds a separator
        }

        //  Always add in the defaults (zoom in/out).
        this._ContextMenu.extend(this._ContextMenu.getDefaultItems());

        if (this._ContextMenu.isOpen()) {
            //  Context menu is already open.  As of v5 of ol-contextmenu, it does not handle changes to the menu items
            //  correctly if it is already displayed.  To force it to update it's positioning and such, we have to call
            //  this (protected) method.  That forces it to lay itself out again.
            //  This fixes an issue if you are editing a ticket, have not already fetched the service area list, and you
            //  right - click on the map.  Without this change, the "Service Areas" sub-menu is all jacked up.
            //  And alternative to calling the protected method is to call the put "updatePosition" method.  But that requires
            //  passing the pixel of the menu and to get that in here required a bunch of changes to add it to a couple methods.
            //  That pixel is available in the event that is passed to the beforeopen event handler.
            //  Decided to risk it.  Could probably make our own derived class and then expose a public method...
            if ((this._ContextMenu as any).positionContainer)
                (this._ContextMenu as any).positionContainer();     //  At least being safe about it - if this changes, the menu will may just look jacked up
        }
    }

    /**
     * Override this to return any custom context menu items that should be displayed in the right-click menu.
     * This will be called before the context menu is displayed.
     */
    protected BuildContextMenuItems(): any[] {
        const contextMenuItems = [];

        //  Should not be possible for these tools to not be created yet, but saw some weird errors that looked like they were!
        if (this._MeasureDistanceTool && !this._MeasureDistanceTool.IsEmpty()) {
            contextMenuItems.push({
                text: '<i class="fas fa-ruler" ></i>Clear Measurements',
                classname: 'iq-image-item',
                callback: () => this._MeasureDistanceTool.Clear()
            });
        }

        if (this._PushPinTool && !this._PushPinTool.IsEmpty()) {
            contextMenuItems.push({
                text: '<i class="fas fa-map-marker-alt" ></i>Clear Push Pins',
                classname: 'iq-image-item',
                callback: () => this._PushPinTool.Clear()
            });
        }

        return contextMenuItems;
    }

    public ShowFeature(layerName: string, featureName: string, geometryJson: object): void {
        const featureItem = new FeatureItemResponse(layerName, featureName, geometryJson);
        this.MapSearchButton.ShowFeatureOnMap(featureItem);
    }

    public get CurrentSearchFeature(): FeatureItemResponse {
        return this.MapSearchButton.CurrentFeatureOnMap;
    }

    public ClearMapSearch(): void {
        this.MapSearchButton.ClearSearchLayer();
    }

    public GetAdditionalMapFeaturesForPopup(pixel: Coordinate, selectionBox: Extent): Observable<{ Features: FeatureItemResponse[], Exclusive: boolean }> {
        let features = this._RouteVectorLayer ? this._RouteVectorLayer.GetFeaturesInExtent(selectionBox, "Route Segment", "name") : [];

        if (this.MapChangesTileLayer)
            features = features.concat(this.MapChangesTileLayer.GetFeatureAttributesAtPixel(pixel));

        return of({ Features: features, Exclusive: false });
    }

    public abstract get CurrentStateAbbreviation(): string;
    public abstract get CurrentCountyName(): string;
    public abstract get MapSearchFilterBounds(): Extent;

    private _RouteVectorLayer: RouteVectorLayer;

    public ShowRoute(routeList: MapRouteSegment[]): void {
        if (!routeList || (routeList.length === 0)) {
            this.ClearRoute();
            return;
        }

        if (!this._RouteVectorLayer) {
            this._RouteVectorLayer = new RouteVectorLayer(MapConstants.LAYERNAME_UNBUFFERED_DIGSITE, this.MapToolService);
            this.Map.addLayer(this._RouteVectorLayer.Layer);
        }

        let isFirst = true;
        routeList.forEach(r => {
            this._RouteVectorLayer.LoadGeoJSON(r.GeometryJson, r.Label, isFirst);
            isFirst = false;
        });
    }

    public ClearRoute(): void {
        if (this._RouteVectorLayer)
            this.Map.removeLayer(this._RouteVectorLayer.Layer);
        this._RouteVectorLayer = null;
    }

}
