import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { SendCopyToComponent } from '../../TicketSavedDialog/SendCopyTo/SendCopyTo.component';

@Component({
    templateUrl: './TicketCanceledConfirmationDialog.component.html',
    styleUrls: ['./TicketCanceledConfirmationDialog.component.scss']
})
export class TicketCanceledConfirmationDialogComponent {
    @ViewChild('sendCopyTo', { static: true })
    public _SendCopyTo: SendCopyToComponent;

    constructor(private _DialogRef: MatDialogRef<TicketCanceledConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public Ticket: Ticket)
    {
    }

    public SendToExcavator(): void {
        this._SendCopyTo.SendToExcavator();
        this._DialogRef.close();
    }
}
