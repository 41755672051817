import { Component } from '@angular/core';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { SettingsService } from 'Services/SettingsService';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { PhoneTicketListBase } from '../PhoneTicketListBase';

@Component({
    templateUrl: './PhoneTicketSearch.component.html',
    styleUrls: ['./PhoneTicketSearch.component.scss'],
    animations: [slideInFromRight]
})
export class PhoneTicketSearchComponent extends PhoneTicketListBase {

    constructor(protected ticketService: TicketService, protected settingsService: SettingsService) {
        super(ticketService, settingsService);
    }
}
