
export class FindAffectedServiceAreasRequest {
    //  TicketTypeID and LocateTypeID should always be provided.  But if they are not (maybe for testing or just general lookup using only geometry?),
    //  we will not factor them in when looking up the affected service areas.
    //  TicketID and ForUpdateOfTicketID should be set when editing a ticket so that we also get ticket response info in the results.
    //  ForUpdateOfTicketID is needed when editing because some TicketTypes do not allow recalculating the service areas (so need to fetch previous list)
    //  and also need to bring forward the previous Suppress flags for some ticket types.

    constructor(
        public GeometryJson: object,

        public TicketTypeID: string,
        public LocateTypeID: string,
        public NearEdgeOfRoad: string,      //  Used by NY for their ServiceAreaTicketAssignmentFilter (does not assign service area if has matching value)

        public TicketNumber: string,
        public ForUpdateOfTicketID: string) {
    }
}
