import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FeatureItemResponse } from '@iqModels/Maps/FeatureItemResponse.model';
import { FeatureSearchResponse } from '@iqModels/Maps/FeatureSearchResponse.model';
import { LatLonBounds } from '@iqModels/Maps/LatLonBounds.model';
import { DigsiteEnteredTypeEnum } from 'Enums/DigsiteEnteredType.enum';
import { DigSiteIntersectionItemTypeEnum } from 'Enums/DigSiteIntersectionItemType.enum';
import { DigSiteStreetItemTypeEnum } from 'Enums/DigSiteStreetItemType.enum';
import { GeocodeTypeEnum } from 'Enums/GeocodeType.enum';
import { DigSite } from 'Models/DigSites/DigSite.model';
import { CallerPlaceGeocodeRequest } from "Models/Maps/CallerPlaceGeocodeRequest.model";
import { CallerPlaceSearchRequest } from 'Models/Maps/CallerPlaceSearchRequest.model';
import { GeocodeRequest } from 'Models/Maps/GeocodeRequest.model';
import { GeocodeResponse } from 'Models/Maps/GeocodeResponse.model';
import { SearchAutocompleteRequest } from 'Models/Maps/SearchAutocompleteRequest.model';
import { SearchTRSQGridRequest } from 'Models/Maps/SearchTRSQGridRequest.model';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import { Observable, of } from "rxjs";
import { SettingsService } from 'Services/SettingsService';
import { GeometryTransformer } from 'Shared/Components/Maps/GeometryTransformer';
import { GeometryUtils } from 'Shared/Components/Maps/GeometryUtils';
import { MapConstants } from 'Shared/Components/Maps/MapConstants';
import { MapBaseComponent } from '../Shared/Components/Maps/MapBase.component';


@Injectable({
    providedIn: 'root'
})
export class MapSearchService {
    private _MapComponent: MapBaseComponent;
    set MapComponent(mapComponent: MapBaseComponent) {
        this._MapComponent = mapComponent;
    }

    constructor(private http: HttpClient, private settingsService: SettingsService) { }

    public FeaturesForBuffering(minX: number, maxX: number, minY: number, maxY: number, zoom: number): Observable<FeatureSearchResponse> {
        return new Observable<FeatureSearchResponse>(observer => {
            //  Make sure zoom is an int or the api fails to parse the request!
            const request = new LatLonBounds(minX, maxX, minY, maxY, Math.round(zoom));

            const url = this.settingsService.ApiBaseUrl + "/Maps/Search/FeaturesForBuffering";
            this.http.post<FeatureSearchResponse>(url, request)
                .subscribe(
                    response => {
                        observer.next(response);
                        observer.complete();
                    },
                    err => {
                        observer.error(err);
                        observer.complete();
                    }
                );
        });
    }

    public CreatePopupContentForLocation(pixel: Coordinate, center: Coordinate, resolution: number, zoom: number): Observable<string> {
        if (!this._MapComponent)
            return of(null);        //  Can happen due to bad timing when being destroyed.

        return new Observable<string>(observer => {
            const selectionBox = this.ComputeSelectionBox(center, resolution);

            this._MapComponent.GetAdditionalMapFeaturesForPopup(pixel, selectionBox)
                .subscribe(additionalFeatures => {
                    if (additionalFeatures.Exclusive && additionalFeatures.Features && (additionalFeatures.Features.length > 0)) {
                        //  Map provided features that need to be shown exclusively - only show them and do not fetch base map features to show
                        observer.next(this.CreatePopupContentForFeatures(additionalFeatures.Features, selectionBox));
                        observer.complete();
                    } else {
                        //  Do not have exclusive features to show so fetch base map features.
                        //  ol.Extent = minx, miny, maxx, maxy
                        const request = new LatLonBounds(selectionBox[0], selectionBox[2], selectionBox[1], selectionBox[3], Math.round(zoom));

                        const url = this.settingsService.ApiBaseUrl + "/Maps/Search/FeaturesAtLocation";
                        this.http.post<FeatureSearchResponse>(url, request)
                            .subscribe(response => {
                                //  Append the 2 feature lists if we have both
                                let featureItems = [];
                                if (response && response.Items)
                                    featureItems = response.Items;
                                if (additionalFeatures.Features)
                                    featureItems = featureItems.concat(additionalFeatures.Features);

                                observer.next(this.CreatePopupContentForFeatures(featureItems, selectionBox));
                                observer.complete();
                            }, err => {
                                observer.error(err);
                                observer.complete();
                            });
                    }
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    private ComputeSelectionBox(center: Coordinate, resolution: number): Extent {
        //  15 pixel tolerance (radius).  Multiply by resolution gives the tolerance in map coordinates.
        //  This changes the size of the select box based on the current resolution.
        const offset = 15 * resolution;

        const nwCornerMapProj: Coordinate = [center[0] - offset, center[1] + offset];
        const seCornerMapProj: Coordinate = [center[0] + offset, center[1] - offset];

        const nwCornerLL = GeometryTransformer.TransformCoordinate(nwCornerMapProj, null, MapConstants.LATLON_PROJECTION);
        const seCornerLL = GeometryTransformer.TransformCoordinate(seCornerMapProj, null, MapConstants.LATLON_PROJECTION);

        //  minx, miny, maxx, maxy
        return [nwCornerLL[0], seCornerLL[1], seCornerLL[0], nwCornerLL[1]];
    }

    private CreatePopupContentForFeatures(featureItems: FeatureItemResponse[], selectionBox: Extent): string {
        if (!featureItems || (featureItems.length === 0))
            return null;

        //  It is possible to get Geoserver to give us images for the legend of each layer via the WMS GetLegendGraphic api:
        //  https://docs.geoserver.org/latest/en/user/services/wms/get_legend_graphic/index.html
        //  Relationship of Scale to Zoom (needed to request image at specific zoom level):
        //      Would need to convert current zoom to scale:
        //      https://docs.geoserver.org/latest/en/user/styling/ysld/reference/scalezoom.html
        //  Example URLs:
        //      This is for the layer name: "FL MergedStreetDisplays"
        //          http://4iqonecall.dynu.net:5003/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0&FORMAT=image/png&WIDTH=50&HEIGHT=50&LAYER=FL+MergedStreetDisplays&SCALE=1000000
        //          Would need to figure out how to request for specific rules or feature types.  Probably need to modify the map layer config to add rule names or something...
        //      This is for the entire FL811 layer group - includes all layers
        //          http://4iqonecall.dynu.net:5003/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0&FORMAT=image/png&WIDTH=50&HEIGHT=50&LAYER=FL811&SCALE=1000000
        //          Some show text so need to figure out what is triggering those

        let content = "<div>";

        featureItems.forEach(item => {
            content += this.FormatFeatureForPopupContent(item);
        });

        content += "</div>";

        return content;
    }

    private FormatFeatureForPopupContent(item: FeatureItemResponse): string {

        let layerName = item.LayerName;
        if (layerName === "City/Place") {
            if (item.FeatureTypeName && (item.FeatureTypeName !== ""))
                layerName = item.FeatureTypeName;
            else if (this.settingsService.PlaceNameLabel !== "Place")
                layerName = this.settingsService.PlaceNameLabel;
        }

        return "<div><span class='iq-info-layer-name'>" + layerName + ":</span>" + item.FeatureName + "</div>";
    }

    public Autocomplete(request: SearchAutocompleteRequest): Observable<FeatureSearchResponse> {
        return new Observable<FeatureSearchResponse>(observer => {
            const url = this.settingsService.ApiBaseUrl + "/Maps/Search/Autocomplete";
            this.http.post<FeatureSearchResponse>(url, request)
                .subscribe(
                    response => {
                        observer.next(response);
                        observer.complete();
                    },
                    () => {
                        //  Don't return an error.  We have a single observable set up on property changes
                        //  which calls this method inside it.  If we return an error, it ends the entire property
                        //  change subscription.
                        observer.next(null);
                        observer.complete();
                    }
                );
        });
    }

    public Grids(request: SearchTRSQGridRequest): Observable<FeatureSearchResponse> {
        return new Observable<FeatureSearchResponse>(observer => {
            const url = this.settingsService.ApiBaseUrl + "/Maps/Search/Grids";
            this.http.post<FeatureSearchResponse>(url, request)
                .subscribe(
                    response => {
                        observer.next(response);
                        observer.complete();
                    },
                    () => {
                        //  Don't return an error.  We have a single observable set up on property changes
                        //  which calls this method inside it.  If we return an error, it ends the entire property
                        //  change subscription.
                        observer.next(null);
                        observer.complete();
                    }
                );
        });
    }

    public GeocodeLocation(state: string, county: string, place: string, streetAddress: string, crossStreet: string): Observable<GeocodeResponse> {
        //  Construct a TicketDigSite to be used to geocode
        //  This will also geocode to county/place depending on what information is provided (it automatically falls back)
        const digsite: DigSite = {
            DigsiteEnteredType: DigsiteEnteredTypeEnum.Intersection,
            Intersections: {
                Inter1: {
                    ItemType: DigSiteIntersectionItemTypeEnum.Inter1,
                    State: state,
                    CountyName: county,
                    PlaceName: place,
                    Streets: {
                        Street: {
                            ItemType: DigSiteStreetItemTypeEnum.Street,
                            EnteredStreetAddress: streetAddress,    // the server will parse this into the components
                        },
                        CrossSt: {
                            ItemType: DigSiteStreetItemTypeEnum.CrossSt,
                            EnteredStreetAddress: crossStreet,    // the server will parse this into the components
                        }
                    }
                }
            }
        };

        const request = new GeocodeRequest(digsite, GeocodeTypeEnum.Intersection, null, null, false, false, true);

        return new Observable<GeocodeResponse>(observer => {
            this.http.post<GeocodeResponse>(this.settingsService.ApiBaseUrl + "/Maps/Geocoder/GeocodeDigSite", request)
                .subscribe(
                    response => {
                        observer.next(response);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                    }
                );
        });
    }

    public CallerPlaceSearch(state: string, county: string, digSiteGeoJson: object): Observable<string[]> {
        const centroid = GeometryUtils.CentroidOfGeoJson(digSiteGeoJson);
        if (!centroid)
            return of([]);

        const request = new CallerPlaceSearchRequest(state, county, centroid);
        return this.http.post<string[]>(this.settingsService.ApiBaseUrl + "/Maps/Place/CallerPlaceSearch", request);
    }

    public GeocodeCallerPlace(state: string, county: string, digSiteGeoJson: object, callerPlace: string): Observable<GeocodeResponse> {
        const centroid = GeometryUtils.CentroidOfGeoJson(digSiteGeoJson);
        if (!centroid || !callerPlace || (callerPlace === ""))
            return of(null);

        const request = new CallerPlaceGeocodeRequest(state, county, centroid, callerPlace);
        return this.http.post<GeocodeResponse>(this.settingsService.ApiBaseUrl + "/Maps/Place/CallerPlaceGeocode", request);
    }
}
