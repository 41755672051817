<div *ngIf="ShowPleaseWait" class="loading-shade">
    <mat-spinner></mat-spinner>
</div>
<mat-card style="text-align:center;">
    <mat-card-title *ngIf="ShowPleaseWait">Please wait</mat-card-title>
    <ng-container *ngIf="!ShowPleaseWait">
        <mat-card-title>Error connecting to Google account:</mat-card-title>
        <div style="padding-top:20px">We require a first and last name during registration and your Google account does not have one or both of those values configured in your account profile.</div>
        <div style="padding-top:20px">
            Please go to
            <a target="_blank" href="https://myaccount.google.com/personal-info">https://myaccount.google.com/personal-info</a>
            and set your Name.
        </div>
        <div style="padding-top:20px">Please be sure to set both your First and Last Name.</div>
        <div style="padding-top:50px; text-align:initial; display:flex; flex-direction:column; align-items:center">
            <div>If you need further assistance, please contact the One Call Center and provide them with a screenshot of this page.</div>
            <div style="padding-top:20px">
                <div>Error: {{Error}}</div>
                <div>Description: {{ErrorDescription}}</div>
            </div>
        </div>
    </ng-container>
</mat-card>
