<div class="ol-unselectable ol-control iq-map-search-button"
     style="position:absolute; top:.5em; left: .5em; z-index:1; display:flex; background-color:unset">
    <div [ngClass]="{'ol-active':ShowPanel}">
        <button (click)="TogglePanel()" title="Search">
            <i class="fas fa-search"></i>
        </button>
    </div>
    <div *ngIf="ShowPanel" style="padding-left:10px; display:flex">
        <div class="rounded-border" style="margin-left:4px; padding: 5px; background-color:white">
            <mat-form-field appearance="outline" style="width:22em" class="autocomplete-mask no-hint-or-error" matAutocompleteOrigin #origin="matAutocompleteOrigin">
                <mat-label>{{SearchLabel}}</mat-label>
                <!-- UpperCase - does not do anything on this input control... -->
                <input #searchMaskedInput type="search" autocomplete="off" matInput [textMask]="MaskOptions" [formControl]="TextMaskValueFormControl" placeholder="{{SearchPlaceholder}}"
                       class="search-input" (focus)="OnSearchMaskedInputFocused()" (keydown)="OnSearchMaskedInputKeyDown($event)" />
                <input #searchAutocompleteInput type="search" style="visibility:hidden; height:0px; vertical-align:top"
                       matInput [formControl]="SearchValueFormControl" [matAutocomplete]="mainAutocomplete" [matAutocompleteConnectedTo]="origin"/>
                <mat-autocomplete class="iq-autocomplete" #mainAutocomplete="matAutocomplete" panelWidth="auto" [displayWith]="FeatureItemDisplayFn"
                                  autoActiveFirstOption (optionSelected)="FeatureItemSelected($event)">
                    <mat-option PreventFocusStealOnClick *ngFor="let feature of SearchResults | async" [value]="feature">
                        <iq-map-feature-autocomplete-option [model]="feature"></iq-map-feature-autocomplete-option>
                    </mat-option>
                </mat-autocomplete>
                <!-- TODO: Put options in the menuSearchTypes menu into menuOptions? -->
                <!--       Then maybe just use regex/pattern in the input to always have a single input control? -->
                <mat-icon matPrefix [matMenuTriggerFor]="menuOptions" (menuOpened)="OnMenuOpened()" class="options-icon" title="search options">expand_more</mat-icon>
                <mat-icon matSuffix (click)="ClearSearchValue()">close</mat-icon>
            </mat-form-field>
            <div style="padding-top:5px; display:flex; width:100%; font-size:75%; color:rgba(0, 0, 0, 0.6)">
                <div style="display:flex; flex-direction:column; flex-grow:1" [innerHTML]="SearchHint | trustHtml"></div>
                <!-- [options]="ToolTipOptions" -->
                <!-- content-type="template" -->
                <mat-icon class="primary-color tooltip-icon" [tooltip]="HtmlContent" [options]="ToolTipOptions">help_outline</mat-icon>
                <ng-template #HtmlContent>
                    <!-- todo: May want to consider creating a HelpTooltipService where we can configure all of these help tooltips
            in 1 place.  And then be able to vary some of them based on the One Call.  Or even dynamically configure them,
            store them in the database, and fetch them as needed.  But that's overkill at the moment.  -->
                    <div>
                        <ng-container *ngIf="SearchType===SearchTypeEnum.Name">
                            <p><b>Search for map features by name</b></p>
                            <p>Search for names of Streets, Addresses, Intersections, Points of Interest, {{PluralPlaceNameLabel}}, and Counties.</p>
                            <p>Enter intersections with the street names separated by an &amp; character.<br />i.e.: <i>main st &amp; park rd</i></p>
                            <p>Narrow the search to a specific {{PlaceNameLabel}} by including the {{PlaceNameLabel}} name after the feature name.</p>
                        </ng-container>
                        <ng-container *ngIf="SearchType===SearchTypeEnum.TRSQLong">
                            <p><b>Search for TRSQ grids</b></p>
                            <div style="text-align:initial">
                                <div>The format of the grid code is:</div>
                                <div class="help-trsq-section">
                                    <div><b>Township</b>: ##.# followed by N or S</div>
                                    <div><b>Range</b>: ##.# followed by E or W</div>
                                    <div><b>Section</b>: 2 digits</div>
                                    <div><b>Quarter Section</b>: NE, NW, SE, or SW</div>
                                </div>
                            </div>
                            <p>i.e. T01.0S R04.0E 16 NW</p>
                        </ng-container>
                        <ng-container *ngIf="SearchType===SearchTypeEnum.TRSShort">
                            <p><b>Search for TRS grids</b></p>
                            <div style="text-align:initial">
                                <div>The format of the grid code is:</div>
                                <div class="help-trsq-section">
                                    <div><b>Township</b>: 2 digits followed by N or S</div>
                                    <div><b>Range</b>: 2 digits followed by E or W</div>
                                    <div><b>Section</b>: 2-3 digits</div>
                                </div>
                            </div>
                            <p>i.e. 19S 30E 03<br />or 01S 23W 456</p>
                        </ng-container>
                        <ng-container *ngIf="SearchType===SearchTypeEnum.LatLonCoord">
                            <p><b>Position map to Lat/Lon Coordinate</b></p>
                            <p>Coordinates are entered as either decimal numbers or degrees-minutes-seconds in the NAD83 coordinate system.</p>
                            <p>{{LatLonExample}}</p>
                            <p>Press ENTER to position the map.</p>
                        </ng-container>
                    </div>
                </ng-template>
            </div>
            <div style="font-size:75%; color:rgba(0, 0, 0, 0.6)">* Search limited to 5 miles of the current map view</div>
            <mat-menu #menuOptions="matMenu">
                <button mat-menu-item (click)="SetSearchType(SearchTypeEnum.Name)">
                    <mat-icon style="font-size:24px; width:24px; height:24px">map</mat-icon>
                    <span>{{SearchByNameLabel}}</span>
                </button>
                <button *ngIf="MapSearchTRSQGridType == TRSQGridTypeEnum.TRSQ_Long" mat-menu-item (click)="SetSearchType(SearchTypeEnum.TRSQLong)">
                    <mat-icon style="font-size:24px; width:24px; height:24px">grid_on</mat-icon>
                    <span>TRSQ Grids</span>
                </button>
                <button *ngIf="MapSearchTRSQGridType == TRSQGridTypeEnum.TRS_Short" mat-menu-item (click)="SetSearchType(SearchTypeEnum.TRSShort)">
                    <mat-icon style="font-size:24px; width:24px; height:24px">grid_on</mat-icon>
                    <span>TRS Grids</span>
                </button>
                <button mat-menu-item (click)="SetSearchType(SearchTypeEnum.LatLonCoord)">
                    <mat-icon style="font-size:24px; width:24px; height:24px">my_location</mat-icon>
                    <span>Lat/Lon Coordinate</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item *ngIf="HasBoundsFilter">
                    <mat-icon *ngIf="AreaConstraint===3" style="font-size:24px; width:24px; height:24px; color:green">check</mat-icon>
                    <span (click)="AreaConstraint=3" [ngClass]="{ 'nocheck' : AreaConstraint!==3}">Near Dig Site</span>
                </button>
                <button mat-menu-item>
                    <mat-icon *ngIf="AreaConstraint===2" style="font-size:24px; width:24px; height:24px; color:green">check</mat-icon>
                    <span (click)="AreaConstraint=2" [ngClass]="{ 'nocheck' : AreaConstraint!==2}">Within map view</span>
                </button>
                <button mat-menu-item *ngIf="HasState">
                    <mat-icon *ngIf="AreaConstraint===1" style="font-size:24px; width:24px; height:24px; color:green">check</mat-icon>
                    <span (click)="AreaConstraint=1" [ngClass]="{ 'nocheck' : AreaConstraint!==1}">State-wide</span>
                </button>
                <button mat-menu-item *ngIf="!HasState">
                    <mat-icon *ngIf="AreaConstraint===0" style="font-size:24px; width:24px; height:24px; color:green">check</mat-icon>
                    <span (click)="AreaConstraint=0" [ngClass]="{ 'nocheck' : AreaConstraint!==0}">Entire Map</span>
                </button>
                <ng-container *ngIf="History.length">
                    <mat-divider></mat-divider>
                    <button mat-menu-item [matMenuTriggerFor]="menuHistory">History</button>
                </ng-container>
            </mat-menu>
            <mat-menu #menuHistory="matMenu">
                <ng-template matMenuContent>
                    <button *ngFor="let item of History" mat-menu-item (click)="SelectItem(item)">{{FeatureItemDisplay(item)}}</button>
                </ng-template>
            </mat-menu>
        </div>
    </div>
</div>
