import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from 'Services/SettingsService';
import { TicketMapItem } from 'Models/Tickets/TicketMapItem.model';
import { TicketMapItemsRequest } from 'Models/Tickets/TicketMapItemsRequest.model';
import { LatLonBounds } from 'Models/Maps/LatLonBounds.model';

@Injectable({
    providedIn: 'root'
})
export class TicketMapViewerService {
    constructor(private _HttpClient: HttpClient, private _SettingsService: SettingsService) {
    }

    public MapItems(ticketIDs: string[]): Observable<TicketMapItem[]> {
        let request = new TicketMapItemsRequest(ticketIDs);
        return this._HttpClient.post<TicketMapItem[]>(this._SettingsService.ApiBaseUrl + "/Tickets/MapItems", request);
    }

    public Extents(): Observable<LatLonBounds> {
        return this._HttpClient.get<LatLonBounds>(this._SettingsService.ApiBaseUrl + "/Maps/Tiles/Tickets/Extents");
    }
}
