<busy-overlay [show]="isBusy" setParentPosition="false"></busy-overlay>
<div fxLayout="column" [formGroup]="group" style="width: 100%;" [@simpleFadeAnimation] *ngIf="!switchingViews">
    <mat-form-field fxFlex style="padding-bottom:10px">
        <input matInput #usernameInput placeholder="Username" type="text" formControlName="Username" (keyup.enter)="trySignIn()" />
        <mat-error iq-aws-cognito-validator [control]="username"></mat-error>
        <mat-hint align="end">* typically, your email address</mat-hint>
    </mat-form-field>

    <iq-aws-cognito-password-input fxFlex #passwordInput [FormControl]="password" (keyup.enter)="trySignIn()"></iq-aws-cognito-password-input>

    <label class="mat-caption mat-error" style="padding-bottom: 10px;" *ngIf="_errorMessage" fxFlex>{{_errorMessage}}</label>

    <button mat-flat-button (click)="trySignIn()" color="primary" [disabled]="(!group.valid && !isAutoFilled) || isBusy">
        Sign In
    </button>

    <div fxFlex class="account-help-actions">
        <div class="create-account" *ngIf="AllowCreateLogin && usingAlternateConfig == null">
            No account?
            <a class="link" (click)="onSignUp()">
                Sign up
            </a>
        </div>

        <div class="forgot-password">
            <a class="link" (click)="onForgotPassword()">Reset/Forgot password</a>
        </div>        
    </div>


    <div *ngIf="AlternateCognitoConfigs && AlternateCognitoConfigs.length > 0" fxLayout="column" fxLayoutAlign="center center" style="padding-top: 40px;">
        <div fxLayout fxLayoutAlign="center center" fxFlex class="google-width" style="padding-bottom: 10px;">
            <div style="height: 0; border-top: solid 1px gray; position: relative; top: 1px;" fxFlex="noshrink"></div>
            <div fxFlex="nogrow">or sign in with</div>
            <div style="height: 0; border-top: solid 1px gray; position: relative; top: 1px;" fxFlex="noshrink"></div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="15px">
            <button *ngIf="usingAlternateConfig != null" mat-button class="alt-sign-in-container" name="defaultAppName" title="Sign in with {{defaultAppName}}" (click)="returnToDefaultConfig()">
                <img class="alt-sign-in-img" [src]="defaultAppImage">
            </button>

            <ng-container *ngFor="let alt of AlternateCognitoConfigs">
                <ng-container *ngIf="alt.IsGoogle; else notFederatedProvider">
                    <button *ngIf="alt.IsGoogle" type="submit" class="google-width google-button" name="Google" title="Sign in with Google" (click)="signInWithAltConfig(alt)"></button>
                </ng-container>

                <ng-template #notFederatedProvider>
                    <button *ngIf="usingAlternateConfig == null || usingAlternateConfig.Name != alt.Name" mat-button class="alt-sign-in-container" name="alt.Name" title="Sign in with {{alt.Name}}" (click)="signInWithAltConfig(alt)">
                        <ng-container *ngIf="alt.SignInImage; else noImgTemplate">
                            <img class="alt-sign-in-img" [src]="alt.SignInImage">
                        </ng-container>
                        <ng-template #noImgTemplate>
                            Sign in with {{alt.Name}}
                        </ng-template>
                    </button>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>
