import { Control } from 'ol/control';
import { Options } from "ol/control/Control";
import { Group } from "ol/layer";
import BaseLayer from "ol/layer/Base";
import Map from 'ol/Map';
import { MapConstants } from "Shared/Components/Maps/MapConstants";

//  Example from OpenLayers: https://github.com/openlayers/openlayers/blob/master/src/ol/control/FullScreen.js
//  https://openlayers.org/en/latest/examples/custom-controls.html
//  https://stackoverflow.com/questions/25927895/openlayers-3-custom-controls-in-typescript

export class ToggleBaseMapControl extends Control {

    constructor(opt_options?: Options) {
        const options = opt_options ? opt_options : { element: document.createElement('div') };

        super(options);

        //  Css class of this control - styled in Map.scss
        const cssClassName = 'iq-switch-base-map-control';

        //  ascii codes and symbols w / html representation: http://www.theasciicode.com.ar/extended-ascii-code/congruence-relation-symbol-ascii-code-240.html -->
        const labelNode = document.createElement("div");
        labelNode.innerHTML = "&equiv;";

        const button = document.createElement('button');
        button.className = cssClassName;
        button.setAttribute('type', 'button');
        button.title = "Toggle Base Map";
        button.addEventListener('click', () => this.OnClick(), false);
        button.appendChild(labelNode);

        options.element.className = cssClassName + ' ' + MapConstants.CLASS_UNSELECTABLE + ' ' + MapConstants.CLASS_CONTROL;
        options.element.appendChild(button);
    }

    private OnClick() {
        const map = this.getMap() as Map;

        const toggle = (layer: BaseLayer) => {
            const name = layer.get("name");
            if ((name === MapConstants.LAYERNAME_ONECALL_BASE) || (name === MapConstants.LAYERNAME_SATELLITE))
                layer.setVisible(!layer.getVisible());
        };

        map.getLayers().forEach(l => {
            if (l instanceof Group)
                l.getLayers().forEach(childLayer => toggle(childLayer));
            else
                toggle(l);
        });
    }
}
