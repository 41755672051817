import { Component, Input } from '@angular/core';
import { faCog, faEye, faFilter, faSave, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft, faEllipsisV } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'iq-phone-button',
    templateUrl: './PhoneButton.component.html',
    styleUrls: ['./PhoneButton.component.scss']
})
export class PhoneButtonComponent {

    @Input()
    public disabled: boolean;

    @Input()
    public Text: "Actions" | "Back" | "Cancel" | "Close" | "Filter" | "Save" | "Settings" | "View";

    public get Icon(): any {
        switch (this.Text) {
            case "Actions":
                return faEllipsisV;
            case "Back":
                return faArrowLeft;
            case "Cancel":
            case "Close":
                return faTimes;
            case "Filter":
                return faFilter;
            case "Save":
                return faSave;
            case "Settings":
                return faCog;
            case "View":
                return faEye;
            default:
                return faTimes;
        }
    }

    constructor() {
    }
}
