class ValidationPatternsClass {
    //  Don't define these as strings - they don't always work!
    //  When it was a string, emails were allowed to be entered like this "blah@blah" and "blah@blah,com"!!!
    //  Note that the domain suffix can be much longer than just 5 chars we originally had.
    //  AZ has users trying to use "email@something.services".  And this page shows a massive list of other
    //  suffixes: https://en.wikipedia.org/wiki/List_of_Internet_top-level_domains
    //  So allowing up to 20 chars which should hopefully be enough.
    readonly emailPattern: RegExp = /^[&a-zA-Z0-9.'_%+-=#]+@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,20})$/;
    readonly phonePattern: RegExp = /[0-9]{10,15}/;
    readonly faxPattern: RegExp = /[0-9]{10}/;
    readonly urlPattern: RegExp = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;   // found here https://mathiasbynens.be/demo/url-regex as @stephenhay's method

    constructor() {}
}
export const IQ_VALIDATOR_PATTERNS = new ValidationPatternsClass();
