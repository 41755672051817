import { Injectable } from '@angular/core';
import { ExcavatorSaveActionEnum } from 'Enums/ExcavatorSaveAction.enum';
import { TicketEntryExcavatorInfoResponse } from 'Models/Tickets/TicketEntryExcavatorInfoResponse.model';
import { DigsiteMapComponent } from 'Pages/Tickets/Details/Components/DigsiteMap/DigsiteMap.component';
import { TicketSidePanelEnum } from 'Pages/Tickets/Details/Components/SideContent/TicketSidePanel.enum';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { EntryPageEventServiceBase } from 'Shared/EntryFields/Services/EntryPageEventBase.service';

/**
 * This service should be added to the constructor of any component where it's needed.
 * The main instance of it is created in the main entry/details page.  So any children within that page
 * (including directives) can add this service into it's constructor and it will get a shared instance of it.
 */
@Injectable({
    providedIn: 'root'
})
export class TicketEntryPageEventService extends EntryPageEventServiceBase {

    constructor() {
        super();

        this.InitHandlers();
    }

    public OnDestroy(): void {
        super.OnDestroy();

        //  Reset all of these so that this service does not retain handles to any instances - causes memory leaks!
        this.InitHandlers();
    }

    private InitHandlers(): void {
        this.HaveUnsavedMapChanges = () => false;
        this.SaveMapChanges = () => of(true);
        this.DiscardMapChanges = () => true;
        this.DigSiteMap = null;

        this.ShowTicketTypeDisclaimer = null;
    }

    public SidePanel: Subject<TicketSidePanelEnum> = new Subject<TicketSidePanelEnum>();

    public MapIsVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public MapIsFullscreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public HelpContent: BehaviorSubject<string> = new BehaviorSubject(null);

    //  Triggered when the user clicks the "copy" link on the excavator side panel.  Handled by the ExcavatorBase
    //  because doing this also affects how it tracks changes (for the update/add links).
    public CopyChangedExcavatorInfoToForm: Subject<TicketEntryExcavatorInfoResponse> = new Subject<TicketEntryExcavatorInfoResponse>();

    public SelectExcavatorOffice: Subject<string> = new Subject<string>();
    public SelectExcavatorHomeownerContact: Subject<boolean> = new Subject<boolean>();
    public SetCompanySaveAction: Subject<ExcavatorSaveActionEnum> = new Subject<ExcavatorSaveActionEnum>();
    public SetOfficeSaveAction: Subject<ExcavatorSaveActionEnum> = new Subject<ExcavatorSaveActionEnum>();
    public SetContactSaveAction: Subject<ExcavatorSaveActionEnum> = new Subject<ExcavatorSaveActionEnum>();

    public HaveUnsavedMapChanges: () => boolean = () => false;
    public SaveMapChanges: () => Observable<boolean> = () => of(true);
    public DiscardMapChanges: () => boolean = () => true;
    public DigSiteMap: DigsiteMapComponent;

    public ShowTicketTypeDisclaimer: () => void;
}
