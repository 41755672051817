import { TicketSiteContactTypeEnum } from "Enums/TicketSiteContact.enum";
import { SiteContactSaveActionEnum } from "Enums/SiteContactSaveAction.enum";
import { AdditionalContactTypeEnum } from 'Enums/AdditionalContactType.enum';

export class TicketSiteContact {

    public ExcavatorContactID: string;
    public ExcavatorOfficeID: string;

    public SiteContactType: TicketSiteContactTypeEnum;

    public Order: number;

    public Name: string;

    public MainPhoneType?: AdditionalContactTypeEnum;
    public MainPhone?: string;

    public Email?: string;

    //  This flag is used to determine if the excavator contact specified in this record should be updated, inserted, and/or
    //  linked to the excavator office on the ticket.  This flag is set by the client during ticket entry - not stored.
    public SaveAction: SiteContactSaveActionEnum;

    public PersonID?: string;
    public Login?: string;
}
