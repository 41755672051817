import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { TicketEntryAllowedTicketActions } from 'Models/Tickets/TicketEntryAllowedTicketActions';
import { TicketActionsService } from 'Pages/Tickets/Services/TicketActions.service';
import { AuthenticationService } from '../../../../Services/AuthenticationService';

@Component({
    selector: 'ticket-work-complete',
    templateUrl: './TicketWorkComplete.component.html',
    styleUrls: ['./TicketWorkComplete.component.scss']
})
export class TicketWorkCompleteComponent {

    @Input()
    public AllowedActions: TicketEntryAllowedTicketActions;

    //  Ticket or a ticket list item
    @Input()
    public Ticket: any;

    public AllowExcavatorMarkWorkComplete: boolean;

    constructor(authenticationService: AuthenticationService, private _TicketActionsService: TicketActionsService)
    {
        this.AllowExcavatorMarkWorkComplete = coerceBooleanProperty(authenticationService.CurrentUser?.OneCallCenterSettings?.AllowExcavatorMarkWorkComplete);
    }

    public ToggleWorkCompleted(): void {
        //  Ticket is either a Ticket or a ticket list item.  And if it's a list item, it's a string of Yes or No (because can be displayed as a column).
        const isComplete = (this.Ticket.IsWorkComplete === "Yes") || (this.Ticket.IsWorkComplete === true);

        this._TicketActionsService.MarkWorkCompleted(this.Ticket.ID, !isComplete)
            .subscribe(() => {
                //  IsWorkComplete has been toggled.  Update it here in case it's visible (and in case the
                //  user re-opens the menu) and invalidate the menu so it's re-built.
                if (typeof this.Ticket.IsWorkComplete === 'string')
                    this.Ticket.IsWorkComplete = isComplete ? "No" : "Yes";
                else
                    this.Ticket.IsWorkComplete = !isComplete;
            });
    }
}
