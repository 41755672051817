//  Check out this url to build this out a little more so it's more like the material modal (animation events, etc):
//  https://blog.thoughtram.io/angular/2017/11/27/custom-overlays-with-angulars-cdk-part-two.html

import { Injectable, TemplateRef, ViewContainerRef, InjectionToken, Injector } from '@angular/core';
import { TemplatePortal, ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { OverlayRef, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { SideSlideoutRef } from './SideSlideoutRef';

export const IQ_FLYOUT_DATA = new InjectionToken<{}>('IQ_FLYOUT_DATA');
export const IQ_FLYOUT_REF = new InjectionToken<SideSlideoutRef>('IQ_FLYOUT_REF');

@Injectable()
export class SideSlideoutService {

    constructor(private overlay: Overlay, private injector: Injector) { }

    private GetOverlayRef(Side: "Left" | "Right", width: string | number): OverlayRef {
        const positionStrategy = Side === "Left" ? this.overlay.position().global().left() : this.overlay.position().global().right();
        //.flexibleConnectedTo(this.parentElem)
        //.withPositions([
        //    {
        //        overlayX: 'start',
        //        overlayY: 'bottom',
        //        originX: 'start',
        //        originY: 'bottom'
        //    },
        //    {
        //        overlayX: 'start',
        //        overlayY: 'center',
        //        originX: 'start',
        //        originY: 'bottom'
        //    }
        //]);
        //If we want to offset them then add these back in with the values we want
        //.withDefaultOffsetX(-20)
        //.withDefaultOffsetY(-20)

        const config = new OverlayConfig({
            positionStrategy: positionStrategy,
            //backdropClass: 'cdk-overlay-transparent-backdrop',
            hasBackdrop: true,
            disposeOnNavigation: true
        });

        if (width)
            config.width = width;

        return this.overlay.create(config);
    }

    /**
     * Slide out a component
     * @param Side Which side the slidout should be on
     * @param component Component to use in the slideout
     * @param _viewContainerRef
     * @param componentData Data to pass to the the component.  To access the data in the Component add this to the contructor: @Inject(IQ_CONTAINER_DATA) public componentData: any
     * @param overlayWidth Width to make the slideout
     */
    AttachComponent(Side: "Left" | "Right", component: ComponentType<any>, _viewContainerRef: ViewContainerRef, componentData: any, overlayWidth: string | number = null): SideSlideoutRef {
        const overlayRef = this.GetOverlayRef(Side, overlayWidth);

        const ref = new SideSlideoutRef(overlayRef);

        const injectorTokens = new WeakMap();
        injectorTokens.set(IQ_FLYOUT_DATA, componentData);
        injectorTokens.set(IQ_FLYOUT_REF, ref);
        const injector = new PortalInjector(this.injector, injectorTokens);

        const template = new ComponentPortal(component, _viewContainerRef, injector);
        overlayRef.attach(template);
        overlayRef.backdropClick().subscribe(val => {
            this.Detach(overlayRef);
        });
        overlayRef.keydownEvents().subscribe(val => {
            if (val.code === 'Escape')
                this.Detach(overlayRef);
        });

        return ref;
    }

    /**
     * Side out a template item
     * @param Side Which side the slidout should be on
     * @param _template Template to use in the slideout
     * @param _viewContainerRef
     * @param overlayWidth Width to make the slideout
     */
    Attach(Side: "Left" | "Right", _template: TemplateRef<any>, _viewContainerRef: ViewContainerRef, overlayWidth: string | number = null): OverlayRef {
        const overlayRef = this.GetOverlayRef(Side, overlayWidth);

        const template = new TemplatePortal(_template, _viewContainerRef);
        overlayRef.attach(template);
        overlayRef.backdropClick().subscribe(val => {
            this.Detach(overlayRef);
        });
        overlayRef.keydownEvents().subscribe(val => {
            if (val.code === 'Escape')
                this.Detach(overlayRef);
        });

        return overlayRef;
    }

    Detach(overlayRef: OverlayRef) {
        if (overlayRef)
            overlayRef.detach();
    }
}
