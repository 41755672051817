import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';

//import { MatTableModule,  MatTableDataSource } from '@angular/material/table';

import { CdkTableModule } from '@angular/cdk/table';

import { FlexLayoutModule, MediaMarshaller } from "@angular/flex-layout";

import { OverlayModule } from '@angular/cdk/overlay';

//import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

//  @coachcare/datepicker has been deprecated and replaced by @matheo/datepicker
//  And the new library does not support Moment.  So just use the native date adapter and do this on the result
//  if you need a moment date: moment(formControl.value).
//  It handles parsing the output format of the date adapter.
import { MatDatepickerModule } from '@matheo/datepicker';
import { MatNativeDateModule } from '@matheo/datepicker/core';

const materialModules: any[] = [
    MatSidenavModule, MatToolbarModule, MatCardModule, MatListModule, MatInputModule, MatSelectModule, MatOptionModule,
    MatButtonModule, MatIconModule, MatTableModule, MatExpansionModule, MatSlideToggleModule, MatCheckboxModule, MatRadioModule,
    MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatProgressBarModule,
    MatDialogModule, MatGridListModule, MatAutocompleteModule, MatTabsModule, MatMenuModule, MatTooltipModule, MatChipsModule, MatRippleModule, MatBadgeModule, MatStepperModule,
    CdkTableModule,
    FlexLayoutModule,

    MatDatepickerModule, MatNativeDateModule,

    //ExperimentalScrollingModule,

    OverlayModule,

    ScrollingModule,
    DragDropModule
];

@NgModule({
    imports: materialModules,
    exports: materialModules,
})
export class MaterialModule {

    //  This is a hack to fix this known issue with flex-layout: https://github.com/angular/flex-layout/issues/1201
    //  The issue happens when printing on the ticket text tab.  The printing changes the flex layout to small
    //  and does not reset it.  This hack restores it to what it should be.
    //  Need to watch the issue and see when it gets fixed.  I subscribed to it so hopefully will see when/if it happens.
    //  Also, there are some other places that reference the FlexLayoutModule but don't think any of them print.
    //  If we have this issue somewhere else, that may be why - not sure this hack will affect places that
    //  import it via another module.  The easy fix for that would be to not import it directly and import it from this module.
    lastValue;
    public constructor(
        m: MediaMarshaller,
    ) {
        // hack until resolve: https://github.com/angular/flex-layout/issues/1201
        // @ts-ignore
        m.subject.subscribe(() => {
            // @ts-ignore
            if (m.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0) {
                // @ts-ignore
                this.lastValue = [...m.activatedBreakpoints];
            } else {
                // @ts-ignore
                m.activatedBreakpoints = [...this.lastValue];
                // @ts-ignore
                m.hook.collectActivations = () => { };
                // @ts-ignore
                m.hook.deactivations = [...this.lastValue];
            }
        });
    }
}


import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
@Injectable()
export class MatPaginatorIntl4IQ extends MatPaginatorIntl {
    //Don't want to change these, so don't need to fill them out
    //itemsPerPageLabel = 'Items per page';
    //nextPageLabel = 'Next page';
    //previousPageLabel = 'Previous page';

    getRangeLabel = function (page: number, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return 'Page 1 of 1';
        }

        return "Page " + String(page + 1) + " of " + String(Math.ceil(length / pageSize));
    };
}
